import React, { useState, useEffect, useContext, useRef } from "react";
import { Tree } from "antd";
import { SyncOutlined, DownOutlined, LoadingOutlined } from "@ant-design/icons";
import { useRecoilState } from "recoil";
import { userDataAtom } from "../../store/store";
import {
  ContractTabContext,
  CreateContractContext,
} from "./ContractTabContext";
import { contractTypeOptions, getNodeIcon } from "../../utils/common";
import { getContractTreeApi } from "../../api/contractsApi";

const CreateContractTree = () => {
  const { defaultBreadcrumbs } = useContext(ContractTabContext);
  const {
    initialFetch,
    setInitialFetch,
    selectedType,
    setTreePathRepresent,
    paginateTreeData,
    setPaginateTreeData,
    nodeMap,
    setNodeMap,
    supplierTree,
    setSupplierTree,
    tabBreadcrumbs,
    setTabBreadcrumbs,
    selectedKeys,
    setSelectedKeys,
    expandedKeys,
    setExpandedKeys,
    scrollLoad,
    setScrollLoad,
    loading,
    setLoading,
    loadingMore,
    setLoadingMore,
  } = useContext(CreateContractContext);
  const userData = useRecoilState(userDataAtom);
  const [expandLoader, setExpandLoader] = useState(false);
  const [selectedNode, setSelectedNode] = useState(null);
  const scrollContainerRef = useRef(null);

  const selectedTypeRef = useRef(selectedType);

  useEffect(() => {
    console.log("initialFetch: ", initialFetch);

    if (!initialFetch) {
      fetchAndSetSupplierTree(true);
      setInitialFetch(true);
    }
  }, []);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    const handleScroll = (e) => {
      const container = e.target;
      const isBottom =
        container.scrollTop + container.clientHeight >= container.scrollHeight;

      if (isBottom && !loadingMore && scrollLoad) {
        fetchAndSetSupplierTree(true);
      }
    };

    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [loadingMore, scrollLoad]);

  useEffect(() => {
    console.log("selectedType: ", selectedType);
    console.log("supplierTree: ", supplierTree);
    if (selectedTypeRef.current !== selectedType) {
      selectedTypeRef.current = selectedType;
      const updatedTree = updateTreeWithDisabledField(
        supplierTree,
        selectedType
      );
      setSupplierTree(updatedTree);
      setTreePathRepresent([]);
      setSelectedKeys([]);
      setTabBreadcrumbs([...defaultBreadcrumbs]);
    }
  }, [selectedType]);

  const treeTitleRender = (node) => {
    return (
      <span
        style={{
          display: "flex",
          alignItems: "center",
          padding: "5px 5px",
          color: node.disabled
            ? "var(--color-solid-darkgrey)"
            : "var(--color-solid-darkergrey)",
        }}
      >
        {getNodeIcon(node.type, node.disabled ? "inactive" : "active")}
        <span
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: 13,
            fontWeight: 600,
            width: "100%",
          }}
        >
          {node.title}
        </span>
      </span>
    );
  };

  const switcherIcon = (node) => {
    // Return loading icon if this node is currently loading
    if (selectedNode === node._id && expandLoader) {
      return (
        <SyncOutlined
          spin
          style={{ color: "var(--color-solid-darkerblue)", fontSize: 12 }}
        />
      );
    }
    return <DownOutlined />;
  };

  const fetchAndSetSupplierTree = async (loadMore = false) => {
    if (!scrollLoad) {
      console.log("No more results to load");
      return;
    }

    if (!loading && !loadingMore) {
      loadMore ? setLoadingMore(true) : setLoading(true);
      try {
        const contractTreeRes = await getContractTreeApi(
          userData[0].id,
          paginateTreeData.pageNo,
          paginateTreeData.limit,
          undefined,
          undefined,
          "Supplier"
        );

        if (contractTreeRes.docs && contractTreeRes.docs.length > 0) {
          const combinedTreeData = loadMore
            ? [...(supplierTree || []), ...contractTreeRes.docs]
            : contractTreeRes.docs;

          const updatedTree = updateTreeWithDisabledField(
            combinedTreeData,
            selectedTypeRef.current
          );

          setSupplierTree(updatedTree);
          buildNodeMap(contractTreeRes.docs);

          // Check if we have fetched all the data
          const totalFetchedDocs = updatedTree.length;
          if (totalFetchedDocs >= contractTreeRes.totalDocs) {
            setScrollLoad(false);
            console.log("All documents are loaded.");
          } else if (loadMore) {
            setPaginateTreeData((prev) => prev + 1);
          }
        } else {
          // No more data to load
          setScrollLoad(false);
          setSupplierTree((prev) => [...(prev || [])]);
          console.log("No more results to load");
        }
      } catch (error) {
        console.error("There was an issue fetching supplier tree: ", error);
      } finally {
        loadMore ? setLoadingMore(false) : setLoading(false);
      }
    }
  };

  const updateTreeWithDisabledField = (tree, selectedType) => {
    if (tree) {
      const updateNode = (node) => {
        const allowedTypes = contractTypeOptions[node.type] || [];
        const isDisabled = !allowedTypes.includes(selectedType);

        const updatedNode = {
          ...node,
          disabled: isDisabled,
        };

        if (
          updatedNode.children &&
          updatedNode.children.length > 0 &&
          updatedNode.children[0] !== true
        ) {
          updatedNode.children = updatedNode.children.map(updateNode);
        }

        return updatedNode;
      };

      return tree.map(updateNode);
    } else {
      return null;
    }
  };

  const buildNodeMap = (tree) => {
    const generatedNodeMap = new Map();

    const traverseTree = (nodes) => {
      for (const node of nodes) {
        generatedNodeMap.set(node._id, node);
        if (
          node.children &&
          node.children.length &&
          typeof node.children !== "boolean"
        ) {
          traverseTree(node.children);
        }
      }
    };

    traverseTree(tree);
    setNodeMap((prevNodeMap) => {
      const updatedNodeMap = new Map(prevNodeMap);
      generatedNodeMap.forEach((value, key) => {
        updatedNodeMap.set(key, value);
      });
      return updatedNodeMap;
    });
    return generatedNodeMap;
  };

  const addChildrenToTree = (tree, contractId, newChildren, depth) => {
    // Create a map of all nodes in the tree for quick access
    const generatedNodeMap = buildNodeMap(tree);

    // Check if the node exists and update its children
    const nodeToUpdate = generatedNodeMap.get(contractId);
    if (nodeToUpdate) {
      const updatedChildren = newChildren.map((child) => {
        const allowedTypes = contractTypeOptions[child.type] || [];
        const isDisabled = !allowedTypes.includes(selectedType);

        return {
          ...child,
          depth, // Set the depth based on the parent's depth
          disabled: isDisabled,
        };
      });

      nodeToUpdate.children = updatedChildren;
    }

    return [...tree];
  };

  const findParentNode = (childNodeId, nodeMap) => {
    for (let node of nodeMap.values()) {
      if (node.children && Array.isArray(node.children)) {
        const hasChild = node.children.some(
          (child) => child._id === childNodeId
        );
        if (hasChild) {
          return node;
        }
      }
    }
    return null;
  };

  const onTreeSelect = (_, info) => {
    console.log("selected", info);
    setSelectedKeys([info.node._id]);

    // Check if the selected node's apiArgs is the same as the last breadcrumb's apiArgs
    // If so, there is no need to update tabBreadcrumbs
    if (
      JSON.stringify({
        supplier_id: info.node.supplier_name ? info.node._id : undefined,
        contract_id: info.node._id,
        type: info.node.type,
      }) !== JSON.stringify(tabBreadcrumbs[tabBreadcrumbs.length - 1].apiArgs)
    ) {
      let newBreadcrumbs = [];
      newBreadcrumbs.push(defaultBreadcrumbs[0]);
      // Use nodeMap to build the path from the selected node up to the root
      let currentNode = nodeMap.get(info.node._id);
      const breadcrumbTrail = [];
      let newTreeData = null;
      let lastInsertedNode = null;

      while (currentNode) {
        const breadcrumb = {
          title: currentNode.title,
          apiArgs: {
            supplier_id: currentNode.supplier_name
              ? currentNode._id
              : undefined,
            contract_id: currentNode._id,
            type: currentNode.type,
          },
        };
        breadcrumbTrail.unshift(breadcrumb);

        // Build tree node based on current node
        const treeNode = {
          key: currentNode._id,
          title: currentNode.title,
          type: currentNode.type,
          children: lastInsertedNode ? [lastInsertedNode] : [],
        };

        // Update lastInsertedNode to the current node for the next iteration
        lastInsertedNode = treeNode;

        // Search for the parent node in the nodeMap
        const parentNode = findParentNode(currentNode._id, nodeMap);

        if (!parentNode) {
          newTreeData = treeNode;
          break;
        }
        currentNode = parentNode;
      }

      const insertNewNodeAtDeepest = (node) => {
        if (!node.children || node.children.length === 0) {
          node.children = [
            {
              key: `new-contract-${selectedType}`,
              title: `New ${
                selectedType === "MSA" ? "Framework Agreement" : selectedType
              }`,
              type: selectedType,
              children: [],
            },
          ];
        } else {
          insertNewNodeAtDeepest(node.children[node.children.length - 1]);
        }
      };

      if (newTreeData) {
        insertNewNodeAtDeepest(newTreeData);
      }

      newBreadcrumbs.push(...breadcrumbTrail);

      console.log("newBreadcrumbs: ", newBreadcrumbs);

      setTabBreadcrumbs(newBreadcrumbs);
      setTreePathRepresent([newTreeData]);
    }
  };

  const onTreeExpand = async (_, info) => {
    console.log("expanded", info);

    if (info.expanded) {
      // Check if the current node has children loaded
      if (
        info.node.children &&
        Array.isArray(info.node.children) &&
        info.node.children.length > 0 &&
        typeof info.node.children[0] !== "boolean"
      ) {
        // If children are already loaded, no need to make an API call
        console.log("Children already loaded, skipping API call");
        setExpandedKeys((prev) => [...prev, info.node.key]);
        return;
      }

      setExpandLoader(true);
      setSelectedNode(info.node._id);

      // Make an API call only if children are not loaded or if children are true
      const newTree = await getContractTreeApi(
        userData[0].id,
        undefined,
        undefined,
        info.node.supplier_name ? info.node._id : undefined,
        info.node._id,
        info.node.type
      ).catch((error) =>
        console.error("There was an error fetching tree children: ", error)
      );

      setSupplierTree((prevTree) => {
        const parentDepth = info.node.depth || 0;
        const updatedTree = addChildrenToTree(
          prevTree,
          info.node._id,
          newTree[0].children,
          parentDepth + 1
        );
        return updatedTree;
      });

      buildNodeMap(newTree[0].children);

      setExpandedKeys((prev) => [...prev, info.node.key]);
      setExpandLoader(false);
      setSelectedNode(null);
    } else {
      // If the node is collapsing, just remove it from the expandedKeys
      setExpandedKeys((prev) => prev.filter((key) => key !== info.node.key));
    }
  };

  return (
    <div
      ref={scrollContainerRef}
      style={{
        // height: "calc(100vh - 550px)",
        height: "100%",
        border: "1px solid #d9d9d9",
        borderRadius: 10,
        overflowY: "auto",
      }}
    >
      <Tree
        showLine
        blockNode
        selectedKeys={selectedKeys}
        expandedKeys={expandedKeys}
        className="create-contract-tree"
        rootClassName="create-contract-tree-wrapper"
        treeData={supplierTree}
        onSelect={onTreeSelect}
        onExpand={onTreeExpand}
        switcherIcon={switcherIcon}
        titleRender={treeTitleRender}
      />
      {supplierTree && loadingMore && (
        <div className="loading-more">
          <LoadingOutlined className="loading-more-icon" />
          Loading suppliers
        </div>
      )}
    </div>
  );
};

export default CreateContractTree;
