// External Imports
import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Image,
  Row,
  Col,
  Tree,
  Layout,
  Table,
  Pagination,
  Empty,
  Spin,
  Card,
  Splitter,
  Modal,
  Space,
} from "antd";
import {
  EyeOutlined,
  FileOutlined,
  DownOutlined,
  LoadingOutlined,
  UpOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { useRecoilValue } from "recoil";
import RecentJourneyMessage from "../../../RecentJourneyMessage";

// Internal Imports
import FolderImg from "../../../assets/images/icons/folder.svg";
import {
  SupplierTreeDataAtom,
  RoleAccessAtom,
  userDataAtom,
  HeaderChangerAtom,
} from "../../../store/store";
// import "../../../assets/style/setup.css";
// import "../../../assets/style/contracttree.css";
import "../../../assets/style/suppliertree.css";
import frameworkAgreement from "../../../assets/images/icons/framework_agreement.svg";
import supplement from "../../../assets/images/icons/supplement.svg";
import sow from "../../../assets/images/icons/sow.svg";
import ccn from "../../../assets/images/icons/ccn.svg";
import edit from "../../../assets/images/icons/edit_pen.svg";
import edit_disable from "../../../assets/images/icons/edit_disable.png";
import clone_disable from "../../../assets/images/icons/clone_disable.png";
import confirmation from "../../../assets/images/icons/confirmation.svg";
import { createActivityLogApi } from "../../../api/logApi";
import account_tree from "../../../assets/images/icons/account_tree.svg";
import {
  getActionByContractApi,
  getActionBySupplierApi,
  getAllActionPaginationApi,
} from "../../../api/actionManagementApi";
import duplicate from "../../../assets/images/icons/duplicate.svg";
import { CancelButton, CreateButton } from "../../GlobalButton";
import { ContractTabContext } from "../../Contract/ContractTabContext";
import { getNodeIcon } from "../../../utils/common";
import { getContractTreeApi } from "../../../api/contractsApi";
import Breadcrumbs from "../../Contract/Breadcrumbs";

// Supplier-Tree render function
const { TreeNode } = Tree;
const renderTreeNodes = (data) => {
  data.map((item) => {
    if (
      item.children !== "undefined" && item.children
        ? item.children.length !== 0
        : ""
    ) {
      return (
        <>
          <TreeNode
            title={
              <div
                className="title-div"
                style={{ display: "inline-block", width: "100%" }}
              >
                <span>
                  {item.contract_name ? item.contract_name : item.supplier_name}
                </span>
                {/* <span className="pin">
                  {item.pinned ? (
                    <PushpinFilled
                      style={{ fontSize: "11px", marginRight: "1px" }}
                    />
                  ) : (
                    ""
                  )}
                </span> */}
              </div>
            }
            icon={
              item.contract_type === "Supplier" ? (
                <>
                  <span>
                    <Image src={FolderImg} preview={false} width={15} />
                  </span>
                </>
              ) : item.contract_type === "MSA" ? (
                <>
                  <span>
                    <Image
                      src={frameworkAgreement}
                      preview={false}
                      width={15}
                    />
                  </span>
                </>
              ) : item.contract_type === "Supplement" ? (
                <>
                  <span>
                    <Image src={supplement} preview={false} width={20} />
                  </span>
                </>
              ) : item.contract_type === "Sub-Supplement" ? (
                <>
                  <span>
                    <Image src={supplement} preview={false} width={20} />
                  </span>
                </>
              ) : item.contract_type === "SOW" ? (
                <>
                  <span>
                    <Image src={sow} preview={false} width={20} />
                  </span>
                </>
              ) : item.contract_type === "CCN" ? (
                <>
                  <span>
                    <Image src={ccn} preview={false} width={20} />
                  </span>
                </>
              ) : (
                <>
                  <FileOutlined />
                </>
              )
            }
            key={item._id}
            id={item._id}
          >
            {renderTreeNodes(item.children)}
          </TreeNode>
        </>
      );
    }
    return (
      <TreeNode
        title={
          <span>
            {item.contract_name ? item.contract_name : item.supplier_name}
          </span>
        }
        key={item._id}
        id={item._id}
        icon={
          item.contract_type === "Supplier" ? (
            <>
              <span>
                <Image src={FolderImg} preview={false} width={15} />
              </span>
            </>
          ) : item.contract_type === "MSA" ? (
            <>
              <span>
                <Image src={frameworkAgreement} preview={false} width={15} />
              </span>
            </>
          ) : item.contract_type === "Supplement" ? (
            <>
              <span>
                <Image src={supplement} preview={false} width={20} />
              </span>
            </>
          ) : item.contract_type === "Sub-Supplement" ? (
            <>
              <span>
                <Image src={supplement} preview={false} width={20} />
              </span>
            </>
          ) : item.contract_type === "SOW" ? (
            <>
              <span>
                <Image src={sow} preview={false} width={20} />
              </span>
            </>
          ) : item.contract_type === "CCN" ? (
            <>
              <span>
                <Image src={ccn} preview={false} width={20} />
              </span>
            </>
          ) : (
            <>
              <FileOutlined />
            </>
          )
        }
      />
    );
  });
};

// Functional component for Root Action
const RootActions = () => {
  const { tabs, setTabs, activeKey, defaultBreadcrumbs, handleAddTab } =
    useContext(ContractTabContext);

  const roleUser = useRecoilState(RoleAccessAtom); //global state for getting logged-in user role access data
  const userData = useRecoilState(userDataAtom); //global state for getting logged-in user data
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom); //global state for changing header
  const history = useNavigate(); //react-router-dom navigate method
  let selected = false; //selected tree node

  // useState hooks used in this component
  const [sortedColumn, setSortedColumn] = useState(null); //state for column sort indicator in table
  const [sortOrder, setSortOrder] = useState(null); //state for column sort order(asc/des) indicator in table
  const [createModel, setCreateModel] = useState(
    localStorage.getItem("wf-create") ? true : false
  ); //state for create root action confirmation modal open/close
  const [loader, setLoader] = useState(false); //table loader
  const supplierTreeData = useRecoilValue(SupplierTreeDataAtom); //supplier tree data from global state
  const [expandedKeys, setExpandedKeys] = useState([]); //expand tree
  const [pathId, setPathId] = useState(["home"]); //selected node path id
  const [alignedData, setAlignedData] = useState([]); //supplier data aligned
  const [flattenedData, setFlattenedData] = useState([]); //flaten supplier tree data
  const [tableData, setTableData] = useState([]); //root action table data
  const [responseData, setResponseData] = useState([]); //tree data
  const [finalPath, setFinalPath] = useState([
    { id: "home", name: "home", type: "" },
  ]); //selected tree node path
  // const [selectedItem, setSelectedItem] = useState(null); //selected node in tree
  const [selectedContract, setSelectedContract] = useState(null); //selected contract in tree
  const [paginateData, setPaginateData] = useState({
    pageNo: 1,
    limit: 20,
    total: 0,
  }); // root action table pagination
  const [sortColumnOptions, setSortColumnOptions] = useState({});

  const [expandLoader, setExpandLoader] = useState(false);
  const [supplierTree, setSupplierTree] = useState(null);
  const [nodeMap, setNodeMap] = useState();
  const [contractTableData, setContractTableData] = useState([]);
  const [selectedNode, setSelectedNode] = useState(null);
  // const [expandedKeys, setExpandedKeys] = useState([]);
  // const [sortOrder, setSortOrder] = useState(null);
  // const [sortedColumn, setSortedColumn] = useState(null);
  const [paginateTreeData, setPaginateTreeData] = useState({
    pageNo: 1,
    limit: 20,
    total: 0,
  });
  const [paginateTableData, setPaginateTableData] = useState({
    pageNo: 1,
    limit: 20,
    total: 0,
  });
  const [supplierTreeLoader, setSupplierTreeLoader] = useState(true);
  const [contractTreeTableLoader, setContractTreeTableLoader] = useState(true);
  const [breadcrumbsLoader, setBreadcrumbsLoader] = useState(false);
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [tabBreadcrumbs, setTabBreadcrumbs] = useState(defaultBreadcrumbs);
  const timeoutRef = useRef(null);
  const [panelSizes, setPanelSizes] = useState(() => {
    return [
      (window.innerWidth - 40) * 0.17,
      (window.innerWidth - 40) * (1 - 0.17),
    ];
  });

  // function for rooot action table sorting
  const isColumnSorted = (column) => sortedColumn === column;

  useEffect(() => {
    document.title = "Root Action | Judo";
    sessionStorage.setItem("Action", "AllAction");
  }, []);

  useEffect(() => {
    console.log("selectedContract", selectedContract);
  }, [selectedContract]);

  useEffect(() => {
    setSelectedContract(null);
    setFinalPath([{ id: "home", name: "home", type: "" }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // set supplier tree data from global state
    if (supplierTreeData.length) {
      setResponseData([...supplierTreeData]);
      let newData = [...supplierTreeData];
      setFlattenedData(flat(newData));
      setAlignedData(newData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplierTreeData]);

  // function for list all root actions
  const getAllAction = (page, limit, sortColumn) => {
    setLoader(true);
    getAllActionPaginationApi(page, limit, sortColumn).then((res) => {
      console.log("action-res: ", res);

      setLoader(false);
      setPaginateData({
        ...paginateData,
        pageNo: page,
        limit: limit,
        total: res.totalDocs,
      });
      const actionData = res.docs.map((item, index) => {
        let tableRow = {
          key: item.id ? item.id : item._id,
          action_name: item.action_name,
          action_status: item.action_status,
          status_level: item.action_level,
          action_stats: `(0-0-0-0)`,
        };
        return tableRow;
      });
      setTableData(actionData);
    });
  };

  useEffect(() => {
    if (sessionStorage.getItem("Action") === "ContractAction") {
      let ContractData = JSON.parse(sessionStorage.getItem("ContractAction"));
      getContractAction(
        ContractData.page,
        ContractData.limit,
        ContractData.contractId,
        ContractData.pathOnModify,
        ContractData.selectedContractDetail
      );
    } else if (sessionStorage.getItem("Action") === "SupplierAction") {
      let SupplierData = JSON.parse(sessionStorage.getItem("SupplierAction"));
      getSupplierAction(
        SupplierData && SupplierData.page ? SupplierData.page : 1,
        SupplierData && SupplierData.limit ? SupplierData.limit : 10,
        SupplierData && SupplierData.supplierId ? SupplierData.supplierId : null
      );
    } else if (!selectedContract) {
      getAllAction(1, 20, sortColumnOptions);
      let actionData = {
        page: 1,
        limit: 20,
      };
      sessionStorage.setItem("Action", "AllAction");
      sessionStorage.setItem("AllAction", JSON.stringify(actionData));
    }
  }, []);

  useEffect(() => {
    if (!selectedContract) {
      getAllAction(1, 20, sortColumnOptions);
      let actionData = {
        page: 1,
        limit: 20,
      };
      sessionStorage.setItem("Action", "AllAction");
      sessionStorage.setItem("AllAction", JSON.stringify(actionData));
    }
  }, [selectedContract]);

  const renderColumnTitle = (column_title, dataIndex) => {
    return (
      <>
        <Row align="middle" type="flex">
          <Col
            sm={18}
            md={20}
            lg={20}
            xl={21}
            className="sorted-title"
            title={`${column_title}`}
          >
            {column_title}
          </Col>
          <Col sm={6} md={4} lg={4} xl={3} align="right">
            {isColumnSorted(dataIndex) && (
              <div className="custom-sort">
                {sortOrder === "ascend" && <UpOutlined />}
                {sortOrder === "descend" && <DownOutlined />}
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  };

  // root action table columns
  const columns = [
    {
      title: renderColumnTitle("Action Name", "action_name"),
      dataIndex: "action_name",
      align: "left",
      sorter: true,
      sortOrder: isColumnSorted("action_name") ? sortOrder : false,
      show: true,
    },
    {
      title: renderColumnTitle("Action Status", "action_status"),
      dataIndex: "action_status",
      align: "left",
      sorter: true,
      sortOrder: isColumnSorted("action_status") ? sortOrder : false,
      show: true,
    },
    {
      title: renderColumnTitle("Status Level", "status_level"),
      dataIndex: "status_level",
      align: "left",
      sorter: true,
      sortOrder: isColumnSorted("status_level") ? sortOrder : false,
      show: true,
    },
    {
      title: renderColumnTitle("Action stats (TD-OP-OD-UP)", "action_stats"),
      dataIndex: "action_stats",
      align: "left",
      sorter: true,
      sortOrder: isColumnSorted("action_stats") ? sortOrder : false,
      show: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      show:
        (roleUser[0] &&
          (!roleUser[0].page_access.action_management.root_action.view ||
            !roleUser[0].page_access.action_management.root_action.create)) ||
        (selectedContract && selectedContract.type === "supplier") ||
        !selectedContract
          ? false
          : true,
    },
  ];

  const colToShow = columns.filter((col) => col.show);

  // function for flatten supplier tree data
  const flat = (array) => {
    let result = [];
    array.forEach(function (a) {
      result.push(a);
      if (Array.isArray(a.children)) {
        result = result.concat(flat(a.children));
      }
    });
    return result;
  };

  // secondary function for set path of selected node
  const getPath = (model, id) => {
    let path,
      item = {
        id: model._id,
        name: model.contract_name ? model.contract_name : model.supplier_name,
        type: model.contract_type ? model.contract_type : "Supplier",
      };

    if (!model || typeof model !== "object") return;

    if (model._id === id) return [item];

    (model.children || []).some((child) => (path = getPath(child, id)));
    return path && [item, ...path];
  };

  // primary function for set path of selected node
  const getTopPath = (currentId) => {
    if (currentId !== "home") {
      responseData.some((res) => {
        const pathVal = getPath(res, currentId);
        if (pathVal) {
          setFinalPath([{ id: "home", name: "home", type: "" }, ...pathVal]);
          return pathVal;
        }
        return false;
      });
    } else {
      const finalP = [{ id: "home", name: "home", type: "" }];
      setFinalPath(finalP);
    }
  };

  // function for handle sorting in root action table
  const handleTableChange = (_, __, sorter) => {
    const { column, order } = sorter;
    const newOrder = order || (sortOrder === "ascend" ? "descend" : "ascend");

    const sortOptions = column
      ? {
          column_name: column.dataIndex,
          order: newOrder,
        }
      : {
          ...sortColumnOptions,
          order: newOrder,
        };

    if (column) {
      setSortedColumn(column.dataIndex);
    }

    setSortColumnOptions(sortOptions);
    setSortOrder(newOrder);

    getAllAction(paginateData.pageNo, paginateData.limit, sortOptions);
  };

  // function for handle tree expand
  const onExpand = (expandedKeys) => {
    setExpandedKeys(expandedKeys);
  };

  // on select tree node
  const onSelect = (e, selectedKeys) => {
    setPathId([...pathId, selectedKeys._id]);
    getTopPath(selectedKeys._id);
    let nestArr = recursivelyFindKeyValue("id", selectedKeys._id, alignedData);
    let obj = nestArr.containingArray.find((o) => o._id === selectedKeys._id);
    setSelectedItem(flattenedData.find((node1) => node1._id === obj._id));
    if (selectedKeys.contract_name) {
      let selectedContractDetail = {
        id: selectedKeys._id,
        type: selectedKeys.contract_type,
        supplier: selectedKeys.supplier_id,
      };
      setSelectedContract(selectedContractDetail);
      let finalPathOnModify = [{ id: "home", name: "home", type: "" }];
      if (selectedKeys._id !== "home") {
        responseData.some((res) => {
          const pathVal = getPath(res, selectedKeys._id);
          if (pathVal) {
            finalPathOnModify = [...finalPathOnModify, ...pathVal];
          }
        });
      }
      // set table data regards selected node
      getContractAction(
        1,
        10,
        selectedKeys._id,
        finalPathOnModify,
        selectedContractDetail
      );
      let ContractActionData = {
        page: 1,
        limit: 10,
        contractId: selectedKeys._id,
        pathOnModify: finalPathOnModify,
        selectedContractDetail: selectedContractDetail,
      };
      sessionStorage.setItem("Action", "ContractAction");
      sessionStorage.setItem(
        "ContractAction",
        JSON.stringify(ContractActionData)
      );
    } else {
      let selectedContractDetail = {
        id: selectedKeys._id,
        type: "supplier",
      };
      setSelectedContract(selectedContractDetail);
      getSupplierAction(1, 10, selectedKeys._id);
      let SupplierActionData = {
        page: 1,
        limit: 10,
        supplierId: selectedKeys._id,
      };
      sessionStorage.setItem("Action", "SupplierAction");
      sessionStorage.setItem(
        "SupplierAction",
        JSON.stringify(SupplierActionData)
      );
    }
  };

  // on select node in breadcrumb
  const onSelectBreadcrumb = (selectedKeys) => {
    if (selectedKeys === null) {
      selectedKeys = "home";
      setSelectedContract(null);
    }

    if (selectedKeys !== "home") {
      setPathId((prevState) => [...prevState, selectedKeys]);
      getTopPath(selectedKeys);

      let nestArr = recursivelyFindKeyValue("id", selectedKeys, responseData);
      let obj = nestArr.containingArray.find((o) => o._id === selectedKeys);
      setSelectedItem(flattenedData.find((node1) => node1._id === obj._id));
      if (selectedKeys.split("_").length === 4) {
        let selectedContractDetail = {
          id: selectedKeys,
          type: obj.contract_type,
          supplier: obj.supplier_id,
        };
        setSelectedContract(selectedContractDetail);
        let finalPathOnModify = [{ id: "home", name: "home", type: "" }];
        if (selectedKeys !== "home") {
          responseData.some((res) => {
            const pathVal = getPath(res, selectedKeys);
            if (pathVal) {
              finalPathOnModify = [...finalPathOnModify, ...pathVal];
            }
          });
        }
        // set table data regards selected node
        getContractAction(
          1,
          10,
          selectedKeys,
          finalPathOnModify,
          selectedContractDetail
        );
        let ContractActionData = {
          page: 1,
          limit: 10,
          contractId: selectedKeys,
          pathOnModify: finalPathOnModify,
          selectedContractDetail: selectedContractDetail,
        };
        sessionStorage.setItem("Action", "ContractAction");
        sessionStorage.setItem(
          "ContractAction",
          JSON.stringify(ContractActionData)
        );
      } else {
        let selectedContractDetail = {
          id: selectedKeys,
          type: "supplier",
        };
        setSelectedContract(selectedContractDetail);
        getSupplierAction(1, 10, selectedKeys);
        let SupplierActionData = {
          page: 1,
          limit: 10,
          supplierId: selectedKeys,
        };
        sessionStorage.setItem("Action", "SupplierAction");
        sessionStorage.setItem(
          "SupplierAction",
          JSON.stringify(SupplierActionData)
        );
      }
    } else {
      setPathId((prevState) => [...prevState, "home"]);
      getTopPath("home");
      let newData = [...responseData];
      setAlignedData(newData);
      setSelectedContract(null);
    }
  };

  // function for find key of selected item in breadcrumb
  const recursivelyFindKeyValue = (key, keyValue, list) => {
    for (let i = 0; i < list.length; i++) {
      const item = list[i];

      for (const key of Object.keys(item)) {
        if (Array.isArray(item[key])) {
          const res = recursivelyFindKeyValue(key, keyValue, item[key]);
          if (res.found === true) return res;
        } else if (item[key] === keyValue) {
          return { found: true, containingArray: list, keyValue: keyValue };
        }
      }
    }

    return { found: false, containingArray: [], keyValue: keyValue };
  };

  // function for list root actions according to selected contract
  const getContractAction = (
    page,
    limit,
    contractId,
    breadcrumbs,
    selectedContractDetail
  ) => {
    setLoader(true);
    getActionByContractApi(contractId, page, limit).then((res) => {
      console.log("resss", res);

      setLoader(false);
      setPaginateData({
        ...paginateData,
        pageNo: page,
        limit: limit,
        total: res.totalDocs,
      });
      const actionData = res.docs.map((item, index) => {
        let tableRow = {
          key: item.id ? item.id : item._id,
          action_name: (
            <span
              style={{
                color: "var(--color-solid-darkblue)",
                fontWeight: "100",
                cursor: "pointer",
              }}
              onClick={() => {
                const userId = userData[0].id
                  ? userData[0].id
                  : userData[0]._id;

                // if (
                //   userId == item.action_owner ||
                //   (userId == item.action_approver &&
                //     roleUser &&
                //     roleUser[0] &&
                //     roleUser[0].page_access.action_management.root_action.view)
                // ) {
                setHeaderChanger({
                  header: "actionManagement",
                  headerItem: "1",
                  headerItemName: "actionManagement",
                  stateSetter: {
                    actionData: item,
                    breadcrumbs: breadcrumbs,
                    mode: {
                      view: true,
                      edit: false,
                      clone: false,
                      create: false,
                    },
                  },
                });

                let actionLog = {
                  ss_id: item._id,
                  ss_name: item.action_name,
                  code: "root_action",
                  author: userId,
                  action: "view",
                  notes: RecentJourneyMessage.VIEW_ACTION_MANAGEMENT,
                  updated_on: new Date(),
                };
                createActivityLogApi(actionLog);
                history(`/action-management/view/root-action`, {
                  state: {
                    actionData: item,
                    breadcrumbs: breadcrumbs,
                    mode: {
                      view: true,
                      edit: false,
                      clone: false,
                      create: false,
                    },
                  },
                });
                // }
              }}
            >
              {item.action_name}
            </span>
          ),
          action_status: item.action_status,
          status_level: item.action_level,
          action_stats: `(0-0-0-0)`,
          action: (
            <>
              <div
                style={{
                  justifyContent: "space-evenly",
                  display: "flex",
                }}
              >
                {roleUser &&
                  roleUser[0] &&
                  roleUser[0].page_access.action_management.root_action
                    .view && (
                    <EyeOutlined
                      title="View"
                      // style={
                      //   (userData[0].id ? userData[0].id : userData[0]._id) !==
                      //     item.action_owner ||
                      //   (userData[0].id ? userData[0].id : userData[0]._id) !==
                      //     item.action_approver
                      //     ? {
                      //         fontSize: "20px",
                      //         color: "var(--color-solid-lightgrey)",
                      //         pointerEvents: "none",
                      //         cursor: "not-allowed",
                      //       }
                      //     : {
                      //         fontSize: "20px",
                      //         color: "var(--color-solid-darkgrey)",
                      //       }
                      // }
                      style={{
                        fontSize: "20px",
                        color: "var(--color-solid-darkgrey)",
                      }}
                      className="cursor-pointer"
                      onClick={() => {
                        setHeaderChanger({
                          header: "actionManagement",
                          headerItem: "1",
                          headerItemName: "actionManagement",
                          stateSetter: {
                            actionData: item,
                            breadcrumbs: breadcrumbs,
                            mode: {
                              view: true,
                              edit: false,
                              clone: false,
                              create: false,
                            },
                          },
                        });
                        let ssLog = {
                          ss_id: item.id ? item.id : item._id,
                          ss_name: item.action_name,
                          code: "root_action",
                          author: userData[0].id
                            ? userData[0].id
                            : userData[0]._id,
                          action: "view",
                          notes: RecentJourneyMessage.VIEW_ACTION_MANAGEMENT,
                          updated_on: new Date(),
                        };
                        createActivityLogApi(ssLog);
                        history(`/action-management/view/root-action`, {
                          state: {
                            actionData: item,
                            breadcrumbs: breadcrumbs,
                            mode: {
                              view: true,
                              edit: false,
                              clone: false,
                              create: false,
                            },
                          },
                        });
                      }}
                    />
                  )}
                {/* {(userData[0].id ? userData[0].id : userData[0]._id) ==
                  item.action_owner ||
                ((userData[0].id ? userData[0].id : userData[0]._id) ==
                  item.action_approver &&
                  roleUser &&
                  roleUser[0] &&
                  roleUser[0].page_access.action_management.root_action
                    .create) ? ( */}
                <Image
                  title="Edit"
                  src={edit}
                  preview={false}
                  style={{
                    width: "20px",
                  }}
                  className="cursor-pointer"
                  // onClick={(e) => {
                  //   e.preventDefault();
                  // }}
                  onClick={() => {
                    setHeaderChanger({
                      header: "actionManagement",
                      headerItem: "1",
                      headerItemName: "actionManagement",
                      stateSetter: {
                        actionData: item,
                        breadcrumbs: breadcrumbs,
                        mode: {
                          view: false,
                          edit: true,
                          clone: false,
                          create: false,
                        },
                      },
                    });
                    history(`/action-management/edit/root-action`, {
                      state: {
                        actionData: item,
                        breadcrumbs: breadcrumbs,
                        mode: {
                          view: false,
                          edit: true,
                          clone: false,
                          create: false,
                        },
                      },
                    });
                  }}
                />
                {/* ) : (
                  <Image
                    title="New Image"
                    src={edit_disable}
                    preview={false}
                    style={{
                      width: "20px",
                      cursor: "default",
                    }}
                    className="cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  />
                )} */}

                {/* {(userData[0].id ? userData[0].id : userData[0]._id) ==
                  item.action_owner ||
                ((userData[0].id ? userData[0].id : userData[0]._id) ==
                  item.action_approver &&
                  roleUser &&
                  roleUser[0] &&
                  roleUser[0].page_access.action_management.root_action
                    .create) ? ( */}
                <Image
                  title="Clone"
                  src={duplicate}
                  preview={false}
                  style={{ width: "20px" }}
                  className="cursor-pointer"
                  onClick={() => {
                    setHeaderChanger({
                      header: "actionManagement",
                      headerItem: "1",
                      headerItemName: "actionManagement",
                      stateSetter: {
                        actionData: item,
                        breadcrumbs: breadcrumbs,
                        mode: {
                          view: false,
                          edit: false,
                          clone: true,
                          create: false,
                        },
                      },
                    });
                    history(`/action-management/clone/root-action`, {
                      state: {
                        actionData: item,
                        breadcrumbs: breadcrumbs,
                        mode: {
                          view: false,
                          edit: false,
                          clone: true,
                          create: false,
                        },
                      },
                    });
                  }}
                />
                {/* ) : (
                  <Image
                    title="New Image"
                    src={clone_disable}
                    preview={false}
                    style={{
                      width: "20px",
                      cursor: "default",
                    }}
                    className="cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  />
                )} */}
              </div>
            </>
          ),
        };
        return tableRow;
      });
      setTableData(actionData);
    });
  };

  // function for list root actions according to selected contract
  const getSupplierAction = (page, limit, supplierId) => {
    setLoader(true);
    getActionBySupplierApi(supplierId, page, limit).then((res) => {
      setLoader(false);
      setPaginateData({
        ...paginateData,
        pageNo: page,
        limit: limit,
        total: res.totalDocs,
      });
      const actionData = res.docs.map((item, index) => {
        let tableRow = {
          key: item.id ? item.id : item._id,
          action_name: item.action_name,
          action_status: item.action_status,
          status_level: item.action_level,
          action_stats: `(0-0-0-0)`,
        };
        return tableRow;
      });
      setTableData(actionData);
    });
  };

  // root action table pagination handler
  const onPageChange = (page, limit) => {
    if (selectedContract && selectedContract.type === "supplier") {
      getSupplierAction(page, limit, selectedContract.id);
      let SupplierActionData = {
        page: 1,
        limit: 10,
        supplierId: selectedContract.id,
      };
      sessionStorage.setItem("Action", "SupplierAction");
      sessionStorage.setItem(
        "SupplierAction",
        JSON.stringify(SupplierActionData)
      );
    } else if (!selectedContract) {
      getAllAction(page, limit, sortColumnOptions);
      let actionData = {
        page: page,
        limit: limit,
      };
      sessionStorage.setItem("Action", "AllAction");
      sessionStorage.setItem("AllAction", JSON.stringify(actionData));
    } else {
      getContractAction(
        page,
        limit,
        selectedContract.id,
        finalPath,
        selectedContract
      );
      let ContractActionData = {
        page: 1,
        limit: 10,
        contractId: selectedContract.id,
        pathOnModify: finalPath,
        selectedContractDetail: selectedContract,
      };
      sessionStorage.setItem("Action", "ContractAction");
      sessionStorage.setItem(
        "ContractAction",
        JSON.stringify(ContractActionData)
      );
    }
  };

  useEffect(() => {
    fetchAndSetSupplierTree();
  }, []);

  const fetchAndSetSupplierTree = async () => {
    if (!supplierTree) {
      setSupplierTreeLoader(true);
      try {
        const contractTreeRes = await getContractTreeApi(
          userData[0].id,
          paginateTreeData.pageNo,
          paginateTreeData.limit,
          undefined,
          undefined,
          "Supplier"
        );
        console.log("contractTreeRes: ", contractTreeRes);

        if (!contractTreeRes.docs.length) {
          throw new Error(
            "The requested contract is unavailable or access is restricted."
          );
        }

        setPaginateTreeData((prev) => ({
          ...prev,
          total: contractTreeRes.totalDocs,
        }));
        setSupplierTree(contractTreeRes.docs);
        buildNodeMap(contractTreeRes.docs);
      } catch (error) {
        console.error("There was an issue fetching supplier tree: ", error);
        setSelectedItem([{ type: "error" }]);
      } finally {
        setSupplierTreeLoader(false);
      }
    }
  };

  const buildNodeMap = (tree) => {
    const generatedNodeMap = new Map();

    const traverseTree = (nodes) => {
      for (const node of nodes) {
        generatedNodeMap.set(node._id, node);
        if (
          node.children &&
          node.children.length &&
          typeof node.children !== "boolean"
        ) {
          traverseTree(node.children);
        }
      }
    };

    traverseTree(tree);
    setNodeMap((prevNodeMap) => {
      const updatedNodeMap = new Map(prevNodeMap);
      generatedNodeMap.forEach((value, key) => {
        updatedNodeMap.set(key, value);
      });
      return updatedNodeMap;
    });
    return generatedNodeMap;
  };

  const addChildrenToTree = (tree, contractId, newChildren, depth) => {
    // Create a map of all nodes in the tree for quick access
    const generatedNodeMap = buildNodeMap(tree);

    // Check if the node exists and update its children
    const nodeToUpdate = generatedNodeMap.get(contractId);
    if (nodeToUpdate) {
      const updatedChildren = newChildren.map((child) => ({
        ...child,
        depth, // Set the depth based on the parent's depth
      }));

      nodeToUpdate.children = updatedChildren;
    }

    return [...tree];
  };

  const findParentNode = (childNodeId, nodeMap) => {
    for (let node of nodeMap.values()) {
      if (node.children && Array.isArray(node.children)) {
        const hasChild = node.children.some(
          (child) => child._id === childNodeId
        );
        if (hasChild) {
          return node; // Return the parent node
        }
      }
    }
    return null;
  };

  useEffect(() => {
    console.log("tabBreadcrumbs: ", tabBreadcrumbs);
  }, [tabBreadcrumbs]);

  const onTreeSelect = (_, info) => {
    console.log("selected", info);

    let newBreadcrumbs = [];
    newBreadcrumbs.push(defaultBreadcrumbs[0]);
    // Use nodeMap to build the path from the selected node up to the root
    let currentNode = nodeMap.get(info.node._id);
    const breadcrumbTrail = [];

    while (currentNode) {
      const breadcrumb = {
        title: currentNode.title,
        apiArgs: {
          supplier_id: currentNode.supplier_name ? currentNode._id : undefined,
          contract_id: currentNode._id,
          type: currentNode.type,
        },
      };
      breadcrumbTrail.unshift(breadcrumb);
      // Search for the parent node in the nodeMap
      const parentNode = findParentNode(currentNode._id, nodeMap);

      if (!parentNode) {
        break;
      }
      currentNode = parentNode;
    }

    newBreadcrumbs.push(...breadcrumbTrail);

    const slicedBreadcrumbs = newBreadcrumbs.slice(0, -1);

    const newTabBreadcrumbs = [
      ...slicedBreadcrumbs,
      {
        title: info.node.title,
        apiArgs: {
          supplier_id: info.node.supplier_name ? info.node._id : undefined,
          contract_id: info.node._id,
          type: info.node.type,
        },
        inspectItem: true,
      },
    ];

    console.log("finalPath: ", finalPath);
    console.log("newTabBreadcrumbs: ", newTabBreadcrumbs);

    const defaultFinalPath = [{ id: "home", name: "home", type: "" }];

    const creationPath = [
      ...defaultFinalPath,
      ...tabBreadcrumbs
        .filter((breadcrumb) => breadcrumb.title !== "Suppliers")
        .map((breadcrumb) => ({
          id:
            breadcrumb.apiArgs.contract_id ||
            breadcrumb.apiArgs.supplier_id ||
            "home",
          name: breadcrumb.title,
          type: breadcrumb.apiArgs.type || "",
        })),
    ];

    console.log("creationPath: ", creationPath);

    setFinalPath(creationPath);
    setTabBreadcrumbs(newTabBreadcrumbs);

    if (!info.node.supplier_name) {
      let selectedContractDetail = {
        id: info.node._id,
        type: info.node.type,
        supplier: info.node.supplier_id,
      };
      setSelectedContract(selectedContractDetail);
      let finalPathOnModify = [{ id: "home", name: "home", type: "" }];
      if (info.node._id !== "home") {
        responseData.some((res) => {
          const pathVal = getPath(res, info.node._id);
          if (pathVal) {
            finalPathOnModify = [...finalPathOnModify, ...pathVal];
          }
        });
      }
      // set table data regards selected node
      getContractAction(
        1,
        10,
        info.node._id,
        newTabBreadcrumbs,
        selectedContractDetail
      );
      let ContractActionData = {
        page: 1,
        limit: 10,
        contractId: info.node._id,
        pathOnModify: finalPathOnModify,
        selectedContractDetail: selectedContractDetail,
      };
      sessionStorage.setItem("Action", "ContractAction");
      sessionStorage.setItem(
        "ContractAction",
        JSON.stringify(ContractActionData)
      );
    } else {
      let selectedContractDetail = {
        id: info.node._id,
        type: "supplier",
      };
      setSelectedContract(selectedContractDetail);
      getSupplierAction(1, 10, info.node._id);
      let SupplierActionData = {
        page: 1,
        limit: 10,
        supplierId: info.node._id,
      };
      sessionStorage.setItem("Action", "SupplierAction");
      sessionStorage.setItem(
        "SupplierAction",
        JSON.stringify(SupplierActionData)
      );
    }

    // setPathId([...pathId, selectedKeys._id]);
    // getTopPath(selectedKeys._id);
    // let nestArr = recursivelyFindKeyValue("id", selectedKeys._id, alignedData);
    // let obj = nestArr.containingArray.find((o) => o._id === selectedKeys._id);
    // setSelectedItem(flattenedData.find((node1) => node1._id === obj._id));
    // if (selectedKeys.contract_name) {
    //   let selectedContractDetail = {
    //     id: selectedKeys._id,
    //     type: selectedKeys.contract_type,
    //     supplier: selectedKeys.supplier_id,
    //   };
    //   setSelectedContract(selectedContractDetail);
    //   let finalPathOnModify = [{ id: "home", name: "home", type: "" }];
    //   if (selectedKeys._id !== "home") {
    //     responseData.some((res) => {
    //       const pathVal = getPath(res, selectedKeys._id);
    //       if (pathVal) {
    //         finalPathOnModify = [...finalPathOnModify, ...pathVal];
    //       }
    //     });
    //   }
    //   // set table data regards selected node
    //   getContractAction(
    //     1,
    //     10,
    //     selectedKeys._id,
    //     finalPathOnModify,
    //     selectedContractDetail
    //   );
    //   let ContractActionData = {
    //     page: 1,
    //     limit: 10,
    //     contractId: selectedKeys._id,
    //     pathOnModify: finalPathOnModify,
    //     selectedContractDetail: selectedContractDetail,
    //   };
    //   sessionStorage.setItem("Action", "ContractAction");
    //   sessionStorage.setItem(
    //     "ContractAction",
    //     JSON.stringify(ContractActionData)
    //   );
    // } else {
    //   let selectedContractDetail = {
    //     id: selectedKeys._id,
    //     type: "supplier",
    //   };
    //   setSelectedContract(selectedContractDetail);
    //   getSupplierAction(1, 10, selectedKeys._id);
    //   let SupplierActionData = {
    //     page: 1,
    //     limit: 10,
    //     supplierId: selectedKeys._id,
    //   };
    //   sessionStorage.setItem("Action", "SupplierAction");
    //   sessionStorage.setItem(
    //     "SupplierAction",
    //     JSON.stringify(SupplierActionData)
    //   );
    // }

    // Check if the selected node's apiArgs is the same as the last breadcrumb's apiArgs
    // If so, there is no need to update tabBreadcrumbs
    // if (
    //   JSON.stringify({
    //     supplier_id: info.node.supplier_name ? info.node._id : undefined,
    //     contract_id: info.node._id,
    //     type: info.node.type,
    //   }) !== JSON.stringify(tabBreadcrumbs[tabBreadcrumbs.length - 1].apiArgs)
    // ) {
    //   let newBreadcrumbs = [];
    //   newBreadcrumbs.push(defaultBreadcrumbs[0]);
    //   // Use nodeMap to build the path from the selected node up to the root
    //   let currentNode = nodeMap.get(info.node._id);
    //   const breadcrumbTrail = [];

    //   while (currentNode) {
    //     const breadcrumb = {
    //       title: currentNode.title,
    //       apiArgs: {
    //         supplier_id: currentNode.supplier_name
    //           ? currentNode._id
    //           : undefined,
    //         contract_id: currentNode._id,
    //         type: currentNode.type,
    //       },
    //     };
    //     breadcrumbTrail.unshift(breadcrumb);
    //     // Search for the parent node in the nodeMap
    //     const parentNode = findParentNode(currentNode._id, nodeMap);

    //     if (!parentNode) {
    //       break;
    //     }
    //     currentNode = parentNode;
    //   }

    //   newBreadcrumbs.push(...breadcrumbTrail);

    //   // Check if the child node(Contract) has no children and if so, opens the child node in a new tab
    //   if (info.node.children.length === 0 && info.node.type !== "Supplier") {
    //     const slicedBreadcrumbs = newBreadcrumbs.slice(0, -1);

    //     const newTabBreadcrumbs = [
    //       ...slicedBreadcrumbs,
    //       {
    //         title: info.node.title,
    //         apiArgs: {
    //           supplier_id: info.node.supplier_name ? info.node._id : undefined,
    //           contract_id: info.node._id,
    //           type: info.node.type,
    //         },
    //         inspectItem: true,
    //       },
    //     ];

    //     setTabBreadcrumbs(slicedBreadcrumbs);

    //     // This may seem unconventional, but the use of setTimeout to add a new tab allows the
    //     // useEffect with tabBreadcrumbs dependency (updates tabs state) to complete before adding a new tab (also updates tabs state).
    //     // This slight delay helps to ensure that the  latest tabs state is used when calling handleAddTab.
    //     // By using a delay of 0, handleAddTab's execution is pushed to the end of the event loop,
    //     // so that the current tab's breadcrumbs updates correctly and a new tab is added with the new breadcrumbs.

    //     // Clears any existing timeout before setting a new one.
    //     // We do this to prevent the setTimeout from hogging the application and causing a memory leak.
    //     // So we use a useEffect cleanup function to clear the timeout.
    //     if (timeoutRef.current) {
    //       clearTimeout(timeoutRef.current);
    //     }

    //     // let contractLog = {
    //     //   contract_id: info.node._id,
    //     //   contract_name: info.node.title,
    //     //   code: "Contract-Viewed",
    //     //   author: userData[0].id,
    //     //   action: "Viewed",
    //     //   notes: "Contract viewed",
    //     //   updated_on: new Date(),
    //     // };
    //     // createActivityLogApi(contractLog);

    //     // timeoutRef.current = setTimeout(() => {
    //     //   handleAddTab({
    //     //     id: info.node._id,
    //     //     title: info.node.title,
    //     //     breadcrumbs: newTabBreadcrumbs,
    //     //     children: <ContractRouter breadcrumbs={newTabBreadcrumbs} />,
    //     //     type: info.node.type,
    //     //   });
    //     // }, 0);
    //   } else {
    //     setTabBreadcrumbs(newBreadcrumbs);
    //   }
    // }
  };

  const onTreeExpand = async (_, info) => {
    console.log("expanded", info);

    if (info.expanded) {
      // Check if the current node has children loaded
      if (
        info.node.children &&
        Array.isArray(info.node.children) &&
        info.node.children.length > 0 &&
        typeof info.node.children[0] !== "boolean"
      ) {
        // If children are already loaded, no need to make an API call
        console.log("Children already loaded, skipping API call");
        setExpandedKeys((prev) => [...prev, info.node._id]);
        return;
      }

      setExpandLoader(true);
      setSelectedNode(info.node._id);

      // Make an API call only if children are not loaded or if children are true
      const newTree = await getContractTreeApi(
        userData[0].id,
        undefined,
        undefined,
        info.node.supplier_name ? info.node._id : undefined,
        info.node._id,
        info.node.type
      ).catch((error) =>
        console.error("There was an error fetching tree children: ", error)
      );

      console.log("newTree: ", newTree);

      if (newTree) {
        setSupplierTree((prevTree) => {
          const parentDepth = info.node.depth || 0;
          const updatedTree = addChildrenToTree(
            prevTree,
            info.node._id,
            newTree[0].children,
            parentDepth + 1
          );
          return updatedTree;
        });

        buildNodeMap(newTree[0].children);

        setExpandedKeys((prev) => [...prev, info.node._id]);
        setExpandLoader(false);
        setSelectedNode(null);
      } else {
        setSelectedItem([{ type: "error" }]);
        setExpandLoader(false);
        setSelectedNode(null);
      }
    } else {
      // If the node is collapsing, just remove it from the expandedKeys
      setExpandedKeys((prev) => prev.filter((key) => key !== info.node._id));
    }
  };

  const treeTitleRender = (node) => {
    const availableWidth = panelSizes[0] - 75 - (node.depth || 0) * 24;

    return (
      <span
        style={{
          display: "flex",
          alignItems: "center",
          padding: "3px 5px",
          color: "var(--color-solid-darkergrey)",
        }}
      >
        {getNodeIcon(node.type)}
        <span
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: 12,
            fontWeight: 600,
            width: availableWidth,
          }}
        >
          {node.title}
        </span>
      </span>
    );
  };

  const switcherIcon = (node) => {
    // Return loading icon if this node is currently loading
    if (selectedNode === node._id && expandLoader) {
      return (
        <SyncOutlined
          spin
          style={{ color: "var(--color-solid-darkerblue)", fontSize: 12 }}
        />
      );
    }
    return <DownOutlined />;
  };

  const modifyPanelTransition = (action) => {
    const panels = document.querySelectorAll(
      ".contract-tree-splitter .ant-splitter-panel"
    );

    console.log(panels);

    panels.forEach((panel) => {
      if (action === "start") panel.style.transition = "none";
      else if (action === "end")
        panel.style.transition =
          "flex-basis 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)";
    });
  };

  useEffect(() => {
    const handleResize = () => {
      const newSize = [
        (window.innerWidth - 10) * 0.17,
        (window.innerWidth - 10) * (1 - 0.17),
      ];
      setPanelSizes(newSize);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const onTreePageChange = async (page, limit) => {
    setSupplierTreeLoader(true);
    const contractTreeRes = await getContractTreeApi(
      userData[0].id,
      page,
      limit,
      undefined,
      undefined,
      "Supplier"
    );
    console.log("contractTreeRes: ", contractTreeRes);

    if (!contractTreeRes.docs.length) {
      throw new Error(
        "The requested contract is unavailable or access is restricted."
      );
    }

    setPaginateTreeData((prev) => ({
      ...prev,
      pageNo: page,
      limit: limit,
      total: contractTreeRes.totalDocs,
    }));
    setSupplierTree(contractTreeRes.docs);
    buildNodeMap(contractTreeRes.docs);
  };

  return (
    // <Layout className="header-layout">
    //   <div id="action-management-id" className="contract-pipeline">
    //     <Row align="middle" type="flex" gutter={[12, 0]} className="m-0">
    //       {/* Selected node breadcrumb */}
    //       <Col
    //         className="p-0 vertical-align"
    //         xs={24}
    //         sm={24}
    //         md={
    //           roleUser &&
    //           roleUser[0] &&
    //           roleUser[0].page_access.action_management.root_action.create
    //             ? 15
    //             : 24
    //         }
    //         lg={
    //           roleUser &&
    //           roleUser[0] &&
    //           roleUser[0].page_access.action_management.root_action.create
    //             ? 15
    //             : 24
    //         }
    //         xl={
    //           roleUser &&
    //           roleUser[0] &&
    //           roleUser[0].page_access.action_management.root_action.create
    //             ? 22
    //             : 24
    //         }
    //       >
    //         <div className="action-breadcrumb">
    //           <Breadcrumbs
    //             tabBreadcrumbs={tabBreadcrumbs}
    //             setTabBreadcrumbs={setTabBreadcrumbs}
    //           />
    //         </div>
    //       </Col>

    //       {roleUser &&
    //         roleUser[0] &&
    //         roleUser[0].page_access.action_management.root_action.create && (
    //           <Col xl={2} md={3} xs={24} className="vertical-align">
    //             <div className="">
    //               {/* Root action create button */}
    //               <CreateButton
    //                 disabled={
    //                   sessionStorage.getItem("Action") === "ContractAction"
    //                     ? false
    //                     : (selectedContract &&
    //                         selectedContract.type === "supplier") ||
    //                       !selectedContract ||
    //                       !sessionStorage.getItem("Action") === "ContractAction"
    //                 }
    //                 style={{
    //                   color: "white",
    //                   backgroundColor: "var(--color-solid-darkblue)",
    //                 }}
    //                 onClick={() => setCreateModel(true)}
    //               />
    //             </div>

    //             {/* Confirmation modal for create */}
    //             <Modal
    //               className="create-wf-modal"
    //               footer={null}
    //               open={createModel}
    //               onCancel={() => {
    //                 setCreateModel(false);
    //               }}
    //               width={450}
    //             >
    //               <Space align="">
    //                 <Image src={confirmation} preview={false} />
    //                 <div>
    //                   <h3>Confirmation</h3>
    //                   <span style={{ fontSize: "12px" }}>
    //                     For creating the Action Instance
    //                   </span>
    //                 </div>
    //               </Space>
    //               {/* Mini map for new creation action level */}
    //               <Row gutter={[16, 8]}>
    //                 <Col span={24}>
    //                   <div className="mt-5">
    //                     <b>Creation Flow</b>
    //                   </div>
    //                   <div className="creation-flow-div">
    //                     <Space
    //                       direction="vertical"
    //                       size="middle"
    //                       style={{
    //                         display: "flex",
    //                       }}
    //                     >
    //                       {finalPath
    //                         .filter((data) => data.type !== "")
    //                         .map((item, index) => (
    //                           <>
    //                             <div
    //                               style={{
    //                                 marginLeft: index > 0 && `${index * 20}px`,
    //                                 paddingLeft: "10px",
    //                                 borderLeft: index > 0 && "2px solid black",
    //                               }}
    //                               id="flow-btn-bottom"
    //                             >
    //                               <span className="flow-btn-new">
    //                                 <Image src={FolderImg} preview={false} />
    //                                 &nbsp;&nbsp;
    //                                 {item.name}
    //                               </span>
    //                               &nbsp;&nbsp;
    //                               <span style={{ fontSize: "12px" }}>
    //                                 {item.type}
    //                               </span>
    //                             </div>
    //                           </>
    //                         ))}
    //                       <div
    //                         style={{
    //                           marginLeft:
    //                             finalPath.filter((data) => data.type !== "")
    //                               .length > 0 &&
    //                             `${
    //                               finalPath.filter((data) => data.type !== "")
    //                                 .length * 20
    //                             }px`,
    //                           paddingLeft: "10px",
    //                           borderLeft:
    //                             finalPath.filter((data) => data.type !== "")
    //                               .length > 0 && "2px solid black",
    //                         }}
    //                         id="flow-btn-bottom"
    //                       >
    //                         <span className="flow-btn-new">
    //                           <Image src={FolderImg} preview={false} />
    //                           &nbsp;&nbsp; New Action
    //                         </span>
    //                       </div>
    //                     </Space>
    //                   </div>
    //                 </Col>
    //               </Row>
    //               <Row gutter={[16, 8]} className="mt-10">
    //                 <Col
    //                   span={16}
    //                   offset={4}
    //                   // className="text-center"
    //                 >
    //                   <Row type="flex" align={"middle"}>
    //                     <Col span={11} offset={1}>
    //                       {/* button for Cancel root action creation */}
    //                       <CancelButton
    //                         onClick={() => {
    //                           setCreateModel(false);
    //                         }}
    //                       />
    //                     </Col>
    //                     <Col span={11} offset={1}>
    //                       {/* create root action button */}
    //                       <CreateButton
    //                         onClick={() => {
    //                           history(`/action-management/root-action/create`, {
    //                             state: {
    //                               finalPath: finalPath,
    //                               selectedContract: selectedContract,
    //                             },
    //                           });
    //                         }}
    //                       />
    //                     </Col>
    //                   </Row>
    //                 </Col>
    //               </Row>
    //             </Modal>
    //           </Col>
    //         )}
    //     </Row>

    //     <Row align="top" type="flex" gutter={[12, 0]} className="mt-10">
    //       {/* Supplier with contract tree */}
    //       <Col
    //         className="p-0"
    //         xs={24}
    //         sm={24}
    //         md={{
    //           span: 4,
    //           offset: 1,
    //         }}
    //         lg={{
    //           span: 4,
    //           offset: 1,
    //         }}
    //         xl={{
    //           span: 4,
    //           offset: 0,
    //         }}
    //       >
    //         <div
    //           style={{
    //             borderRadius: 10,
    //             background: "var(--color-solid-white)",
    //             height: "calc(100vh - 130px)",
    //           }}
    //         >
    //           <Tree
    //             treeData={supplierTree}
    //             className="supplier-contract-tree"
    //             rootClassName={
    //               paginateTreeData.total <= 20
    //                 ? "supplier-contract-tree-wrapper-stretched"
    //                 : "supplier-contract-tree-wrapper"
    //             }
    //             showLine
    //             switcherIcon={switcherIcon}
    //             onSelect={onTreeSelect}
    //             onExpand={onTreeExpand}
    //             expandedKeys={expandedKeys}
    //             titleRender={treeTitleRender}
    //           />
    //         </div>
    //       </Col>
    //       <Col
    //         xs={24}
    //         sm={24}
    //         md={{ span: 18, offset: 0 }}
    //         lg={{ span: 18, offset: 0 }}
    //         xl={{ span: 20, offset: 0 }}
    //         style={{ minHeight: "calc(100vh - 150px)" }}
    //       >
    //         <>
    //           {/* Root Action list table for selected node */}
    //           <Table
    //             onChange={handleTableChange}
    //             dataSource={tableData}
    //             loading={loader}
    //             rowClassName={() => "contract-tree-table-row"}
    //             columns={colToShow}
    //             showSorterTooltip={false}
    //             bordered
    //             size="large "
    //             scroll={{ x: "calc(300px + 50%)", y: "calc(100vh - 240px)" }}
    //             pagination={false}
    //             className="system-setup-table"
    //             id="action-table"
    //           />

    //           {/* table pagination */}
    //           <div className="float-right mb-15 mt-15">
    //             <Pagination
    //               defaultPageSize={paginateData.limit}
    //               responsive
    //               current={paginateData.pageNo}
    //               total={paginateData.total}
    //               showSizeChanger
    //               onShowSizeChange={onPageChange}
    //               onChange={onPageChange}
    //             />
    //           </div>
    //         </>
    //       </Col>
    //     </Row>
    //   </div>
    // </Layout>
    <Layout className="am-tree-layout">
      <Row gutter={[8, 8]} align="middle">
        <Col span={21}>
          <Card
            id="breadcrumb-card-id"
            rootClassName="breadcrumb-card"
            bordered={false}
          >
            <Breadcrumbs
              tabBreadcrumbs={tabBreadcrumbs}
              setTabBreadcrumbs={setTabBreadcrumbs}
            />
          </Card>
        </Col>
        <Col span={3}>
          {roleUser &&
            roleUser[0] &&
            roleUser[0].page_access.action_management.root_action.create && (
              <CreateButton
                disabled={
                  sessionStorage.getItem("Action") === "ContractAction"
                    ? false
                    : (selectedContract &&
                        selectedContract.type === "supplier") ||
                      !selectedContract ||
                      !sessionStorage.getItem("Action") === "ContractAction"
                }
                onClick={() => setCreateModel(true)}
              />
            )}
        </Col>
        <Col span={24}>
          <Splitter
            className="contract-tree-splitter"
            style={{ height: "calc(100vh - 125px)" }}
            onResize={setPanelSizes}
            onResizeStart={() => modifyPanelTransition("start")}
            onResizeEnd={() => modifyPanelTransition("end")}
          >
            <Splitter.Panel
              collapsible
              // defaultSize={panelSizes[0]}
              defaultSize="17%"
              min="17%"
              max="50%"
            >
              {/* <Spin
                spinning={supplierTreeLoader}
                indicator={<LoadingOutlined style={{ fontSize: 40 }} />}
                style={{ width: 10 }}
              > */}
              {supplierTree && supplierTree.length === 0 ? (
                <Empty
                  rootClassName={"contract-tree-empty-stretched"}
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={<span>No Active Suppliers</span>}
                />
              ) : (
                <Tree
                  treeData={supplierTree}
                  className="supplier-contract-tree"
                  rootClassName={
                    paginateTreeData.total <= 20
                      ? "action-tree-wrapper-stretched"
                      : "action-tree-wrapper"
                  }
                  showLine
                  switcherIcon={switcherIcon}
                  onSelect={onTreeSelect}
                  onExpand={onTreeExpand}
                  expandedKeys={expandedKeys}
                  titleRender={treeTitleRender}
                />
              )}
              {/* </Spin> */}
              <Pagination
                hideOnSinglePage
                showLessItems
                showSizeChanger={false}
                className="mt-10"
                align="end"
                defaultPageSize={paginateTreeData.limit}
                current={paginateTreeData.pageNo}
                total={paginateTreeData.total}
                onChange={onTreePageChange}
              />
            </Splitter.Panel>
            <Splitter.Panel collapsible={false} style={{ marginLeft: 6 }}>
              <Spin
                // spinning={contractTreeTableLoader}
                spinning={false}
                indicator={<LoadingOutlined style={{ fontSize: 40 }} />}
              >
                <Table
                  onChange={handleTableChange}
                  dataSource={tableData}
                  loading={loader}
                  rowClassName={() => "contract-tree-table-row"}
                  columns={colToShow}
                  showSorterTooltip={false}
                  bordered
                  size="large "
                  scroll={{
                    x: "calc(300px + 50%)",
                    y: "calc(100vh - 240px)",
                  }}
                  pagination={false}
                  className="system-setup-table"
                  id="action-table"
                />
              </Spin>
            </Splitter.Panel>
          </Splitter>
        </Col>
      </Row>
      {/* Confirmation modal for create */}
      <Modal
        className="create-wf-modal"
        footer={null}
        open={createModel}
        onCancel={() => {
          setCreateModel(false);
        }}
        width={450}
      >
        <Space align="">
          <Image src={confirmation} preview={false} />
          <div>
            <h3>Confirmation</h3>
            <span style={{ fontSize: "12px" }}>
              For creating the Action Instance
            </span>
          </div>
        </Space>
        {/* Mini map for new creation action level */}
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <div className="mt-5">
              <b>Creation Flow</b>
            </div>
            <div className="creation-flow-div">
              <Space
                direction="vertical"
                size="middle"
                style={{
                  display: "flex",
                }}
              >
                {tabBreadcrumbs
                  .filter((data) => data.type !== "")
                  .map((item, index) => (
                    <>
                      <div
                        style={{
                          marginLeft: index > 0 && `${index * 20}px`,
                          paddingLeft: "10px",
                          borderLeft: index > 0 && "2px solid black",
                        }}
                        id="flow-btn-bottom"
                      >
                        <span className="flow-btn-new">
                          <Image src={FolderImg} preview={false} />
                          &nbsp;&nbsp;
                          {item.title}
                        </span>
                        &nbsp;&nbsp;
                        <span style={{ fontSize: "12px" }}>{item.type}</span>
                      </div>
                    </>
                  ))}
                <div
                  style={{
                    marginLeft:
                      tabBreadcrumbs.filter((data) => data.type !== "").length >
                        0 &&
                      `${
                        tabBreadcrumbs.filter((data) => data.type !== "")
                          .length * 20
                      }px`,
                    paddingLeft: "10px",
                    borderLeft:
                      tabBreadcrumbs.filter((data) => data.type !== "").length >
                        0 && "2px solid black",
                  }}
                  id="flow-btn-bottom"
                >
                  <span className="flow-btn-new">
                    <Image src={FolderImg} preview={false} />
                    &nbsp;&nbsp; New Action
                  </span>
                </div>
              </Space>
            </div>
          </Col>
        </Row>
        <Row gutter={[16, 8]} className="mt-10">
          <Col
            span={16}
            offset={4}
            // className="text-center"
          >
            <Row type="flex" align={"middle"}>
              <Col span={11} offset={1}>
                {/* button for Cancel root action creation */}
                <CancelButton
                  onClick={() => {
                    setCreateModel(false);
                  }}
                />
              </Col>
              <Col span={11} offset={1}>
                {/* create root action button */}
                <CreateButton
                  onClick={() => {
                    history(`/action-management/create/root-action`, {
                      state: {
                        breadcrumbs: tabBreadcrumbs,
                        mode: {
                          view: false,
                          edit: false,
                          clone: false,
                          create: true,
                        },
                        selectedContract: selectedContract,
                      },
                    });
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
    </Layout>
  );
};

// export Root Action Component
export default RootActions;
