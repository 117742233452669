// External imports
import React, { useState, useContext } from "react";
import {
  Layout,
  Row,
  Col,
  Image,
  Card,
  Button,
  Modal,
  Empty,
  Table,
  Pagination,
} from "antd"; //antd components
import {
  PlusOutlined,
  ArrowLeftOutlined,
  CloseCircleOutlined,
  ReloadOutlined,
} from "@ant-design/icons"; //antd icons
import "../assets/style/dashboard.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import TopHeader from "../components/Header";
import EventCalendar from "../components/EventCalendar";
import RecentSearch from "../components/RecentSearch";
import OpenAction from "../assets/images/icons/folder_open.svg";
import Milestone from "../assets/images/icons/milestone.svg";
import Obligation from "../assets/images/icons/obligation.svg";
import ActiveSuppliersIcon from "../assets/images/icons/active_suppliers_icon.svg";
import AcvIcon from "../assets/images/icons/acv_icon.svg";
import ContractExpiryIcon from "../assets/images/icons/contract_expiry_icon.svg";
import PipelineContractIcon from "../assets/images/icons/pipeline_contracts_icon.svg";
import PipelineContractValueIcon from "../assets/images/icons/pipeline_contracts_value_icon.svg";
import BudgetConsumedIcon from "../assets/images/icons/budget_consumed_icon.svg";
import OverdueActionIcon from "../assets/images/icons/overdue_actions_icon.svg";
import SlaHitrateIcon from "../assets/images/icons/sla_hitrate_icon.svg";
import ArrowUp from "../assets/images/icons/arrow_up.svg";
import ArrowDown from "../assets/images/icons/arrow_down.svg";
import ContractSearchIcon from "../assets/images/icons/contracts_search_icon.svg";
import frameworkAgreement_active from "../assets/images/icons/framework_agreement_selected.svg";
import supplement from "../assets/images/icons/supplement_selected.svg";
import ccn from "../assets/images/icons/ccn_selected.svg";
import sow from "../assets/images/icons/sow_selected.svg";
import cr from "../assets/images/icons/cr_selected.svg";
import others from "../assets/images/icons/others.svg";
import person_active from "../assets/images/icons/person_4.png";
import adminPanel_active from "../assets/images/icons/admin_panel_active.png";
import priceChange_active from "../assets/images/icons/cost_center_active.png";
import rateCard_active from "../assets/images/icons/rate_card_active.png";
import pricingProfile_active from "../assets/images/icons/pricing_profile_active.png";
import invoicingProfile_active from "../assets/images/icons/invoicing_profile_active.png";
import fxSetup_active from "../assets/images/icons/fx_setup_active.png";
import workflow_active from "../assets/images/icons/workflow_active.png";
import buildReportInactive from "../assets/images/icons/build_report_inactive.svg";
import groups_active from "../assets/images/icons/groups_active.png";
import BatchPredictionIcon from "../assets/images/icons/batch_prediction 1.png";
import {
  getOpenActionsCountApi,
  getOverdueActionsCountApi,
} from "../api/actionInstanceApi";
import { getSingleActionDataApi } from "../api/actionManagementApi";
import { getInstanceByIdApi } from "../api/actionInstanceApi";
import { getUserLogApi } from "../api/logApi";
import {
  getActiveSupplierCount,
  getSingleSupplierDataApi,
  getSupplierAcvTcv,
  treeDataApi,
} from "../api/suppliersApi";
import { getSingleLegalEntityDataApi } from "../api/legalEntityApi";
import { getSingleCostCenterDataApi } from "../api/costCenterApi";
import { getSingleRateCardDataApi } from "../api/ratecardApi";
import { getSingleFxTableDataApi } from "../api/fxTableApi";
import { getPricingProfileByIdApi } from "../api/pricingProfileApi";
import { getSingleInvoicingProfileDataApi } from "../api/invoicingProfileApi";
import { getSingleWorkflowsApi } from "../api/workflowApi";
import {
  contractPipelineApi,
  getAllAcvTcvApi,
  getContractBreadcrumbsApi,
  getContractExpiryCount,
  getSingleContractApi,
  SubmittedContractsCount,
} from "../api/contractsApi";
import { getSingleRolesApi } from "../api/rolesApi";
import { getSingleGroupApi } from "../api/groupsApi";
import { DashboardAtom, userDataAtom } from "../store/store";
import { useRecoilState } from "recoil";
import { HeaderChangerAtom, isLiteAtom, TabChangerAtom } from "../store/store";
import { useEffect } from "react";
import dayjs from "dayjs";
import { getUserByIdApi } from "../api/usersApi";
import {
  clauseLibraryByIdApi,
  contracttemplateByIdApi,
} from "../api/contractAuthorityApi";
import ContractRouter from "../components/Contract/ContractRouter";
import {
  convertBreadcrumbs,
  isEmptyObject,
  navigateBasedOnContractStatus,
} from "../utils/common";
import { ContractTabContext } from "../components/Contract/ContractTabContext";

// functional component for dashboard page
function DashboardPage() {
  const { handleAddTab } = useContext(ContractTabContext);
  const [lite] = useRecoilState(isLiteAtom);
  const userData = useRecoilState(userDataAtom); //global state
  const [dashboardData, setDashboardData] = useRecoilState(DashboardAtom);
  const [createContractTabClick, setCreateContractTabClick] =
    useRecoilState(TabChangerAtom);
  const { Content } = Layout;
  const history = useNavigate();
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  useEffect(
    () =>
      setHeaderChanger({
        header: "home",
        headerItem: "1",
        headerItemName: "home",
      }),
    []
  );
  let header = headerChanger.header;

  const menuKey = "1";
  const [eventsSeeMore, setEventsSeeMore] = useState(false);
  const [seeMore, setSeeMore] = useState(false);
  const [recentSearch, setRecentSearch] = useState(false);
  const [searchVal, setSearchVal] = useState(null);
  const [selectedWidget, setSelectedWidget] = useState(["1", "2", "3"]);
  const [tempselectedWidget, setTempSelectedWidget] = useState(["1", "2", "3"]);
  const [selectedPrev, setSelectedPrev] = useState(searchVal);
  const [recentJourneys, setRecentJourneys] = useState([]);
  const [myQueue, setMyQueue] = useState([]);
  const [seeMoreModal, setSeeMoreModal] = useState(false);
  const [recentLoader, setRecentLoader] = useState(true);
  const [queueLoader, setQueueLoader] = useState(true);
  const [actionInstanceCount, setActionInstanceCount] = useState("");
  const [pipelineCount, setPiplineCount] = useState("");
  const [approvedCount, setApprovedCount] = useState("");
  const [hideOverviewSection, setHideOverviewSection] = useState(false);
  const [hideRecentSection, setHideRecentSection] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeSupplierData, setActiveSupplierData] = useState([]);
  const [isFlipped, setIsFlipped] = useState(false);
  const [activeSupplierCount, setActiveSupplierCount] = useState(0);
  const [activeSupplierLoader, setActiveSupplierLoader] = useState(true);
  const [acvTcvLoader, setAcvTcvLoader] = useState(true);
  const [expandOverviewSection, setExpandOverviewSection] = useState(false);
  const [totalPipelineCount, setTotalPipelineCount] = useState(0);
  const [totalPipelineLoader, setTotalPipelineLoader] = useState(true);
  const [overdueActionCount, setOverdueActionCount] = useState(0);
  const [overdueActionLoader, setOverdueActionLoader] = useState(true);
  const [totalSpend, setTotalSpend] = useState(0);
  const [totalSpendLoader, setTotalSpendLoader] = useState(true);
  const [totalExpiry, setTotalExpiry] = useState(0);
  const [totalExpiryLoader, setTotalExpiryLoader] = useState(true);
  const [paginateData, setPaginateData] = useState({});

  const OPTIONS = ["1", "2", "3", "4", "5", "6"];

  const [submittedContractsCount, setSubmittedContractsCount] = useState(0);

  const filteredOptions = OPTIONS.filter((o) => !selectedWidget.includes(o));

  const seeMoreDisplay = (val) => {
    setSeeMore(val);
  };

  const eventsSeeMoreStatus = (val) => {
    setEventsSeeMore(val);
  };

  const searchView = (status, val) => {
    setRecentSearch(status);
    setSearchVal(val);
  };

  useEffect(() => {
    getUserLogApi(userData[0].id).then((res) => {
      console.log("res", res);

      setRecentJourneys(res.slice(0, 10)); //Setting the last 10 logs to state variable
      setRecentLoader(false);
    });
    contractPipelineApi(
      1,
      4,
      userData[0].id,
      "null",
      {},
      userData[0].id,
      {}
    ).then((res) => {
      setMyQueue(res.docs);
      setPiplineCount(res.totalDocs);
      setQueueLoader(false);
    });
    if (!dashboardData.action_instance_count) {
      getOpenActionsCountApi(userData[0].id).then((count) => {
        setActionInstanceCount(count);
        setDashboardData((prev) => ({
          ...prev,
          action_instance_count: count,
        }));
      });
    } else if (dashboardData.action_instance_count) {
      setActionInstanceCount(dashboardData.action_instance_count);
    }
    // getAllApprovedContracts().then((res) => {
    //   setApprovedCount(res.length);
    // });
    if (!dashboardData.supplier_count) {
      getActiveSupplierCount().then((count) => {
        setActiveSupplierCount(count);
        setActiveSupplierLoader(false);
        setDashboardData((prev) => ({
          ...prev,
          supplier_count: count,
        }));
        setPaginateData({
          pageNo: 1,
          limit: 20,
          total: count,
        });
      });
    } else if (dashboardData.supplier_count) {
      setActiveSupplierCount(dashboardData.supplier_count);
      setPaginateData({
        ...paginateData,
        pageNo: 1,
        limit: 20,
        total: dashboardData.supplier_count,
      });

      setActiveSupplierLoader(false);
    }
    if (!dashboardData.overdue_actions_count) {
      getOverdueActionsCountApi(userData[0].id).then((count) => {
        setOverdueActionCount(count);
        setOverdueActionLoader(false);
        setDashboardData((prev) => ({
          ...prev,
          overdue_actions_count: count,
        }));
      });
    } else if (dashboardData.overdue_actions_count) {
      setOverdueActionCount(dashboardData.overdue_actions_count);
      setOverdueActionLoader(false);
    }

    if (!dashboardData.total_spend) {
      getTotalSpend();
    } else if (dashboardData.total_spend) {
      setTotalSpend(dashboardData.total_spend);
      setTotalSpendLoader(false);
    }

    getTotalExpiry();
  }, []);

  // to get contract-submitted count in lite
  useEffect(() => {
    SubmittedContractsCount().then((res) => {
      console.log("resdd************", res);
      setSubmittedContractsCount(res.count);
    });
  }, []);

  const getBreadcrumbs = (data, supplierName, contractName) => {
    let path = [];
    const findInArray = (arr) => {
      for (const item of arr) {
        if (item.supplier_name === supplierName) {
          path.push({
            contract_names: item.supplier_name,
            contract_id: item._id,
            contract_types: item.contract_type,
          });
          return findInChildren(item.children);
        }
      }
      return false;
    };
    const findInChildren = (children) => {
      for (const child of children) {
        path.push({
          contract_names: child.contract_name,
          contract_id: child._id,
          contract_types: child.contract_type,
        });
        if (
          child.contract_name === contractName ||
          (child.children && findInChildren(child.children))
        ) {
          return true;
        }
        path.pop();
      }
      return false;
    };
    if (!findInArray(data)) {
      return path.concat({ contract_names: contractName });
    }
    return path;
  };

  const recentJourneysData =
    recentJourneys &&
    recentJourneys.slice(0, 4).map((data, index) => {
      //Show only last 4 logs on the Dashboard
      return {
        key: index,
        date: dayjs(data.updated_on).format("DD MMM YYYY, HH:mm"),
        value:
          data.action === "Approved"
            ? "Contract Approved"
            : data.action === "Rejected"
            ? "Contract Rejected"
            : data.action === "Returned"
            ? "Contract Returned"
            : data.notes,
        id: data.ss_id ? data.ss_id : data.contract_id,
        name: data.ss_id
          ? data.ss_name
          : data.contract_id
          ? data.contract_name
            ? data.contract_name
            : data.contract_id
          : "",
        tab: data.ss_id
          ? ["roles", "users", "groups"].includes(data.code)
            ? "user_management"
            : ["root_action", "action_instance"].includes(data.code)
            ? "action_management"
            : ["clause_library", "template"].includes(data.code)
            ? "contract_authority"
            : "system_setup"
          : "contract",
        code: data.code,
        action: data.action,
      };
    });

  const viewRecentJourney = (item) => {
    console.log("Item: ", item);
    const organizationSetup = ["supplier_setup", "legal_entity", "cost_center"];
    const contractSetup = [
      "rate_card",
      "fx_table",
      "pricing_profile",
      "invoicing_profile",
      "workflow",
    ];
    const userManagement = ["roles", "users", "groups"];
    const actionManagement = ["root_action", "action_instance"];
    const contractAuthority = ["clause_library", "template"];
    const showSystemSetup = (data, headerTab, action, correctTab) => {
      console.log("showSystemSetup Data: ", data);
      if (!data || data.length === 0) {
        data = {};
        action = "delete";
      }
      setHeaderChanger({
        header: "systemSetup",
        headerItem: contractSetup.includes(item.code) ? "2" : "1",
        headerItemName: "systemSetup",
        stateSetter: data,
      });

      if (action === "delete") {
        const tabKeys = {
          "organization-setup": {
            "supplier-setup": "1",
            "legal-entity": "2",
            "cost-center": "3",
          },
          "contract-setup": {
            "rate-card": "1",
            "fx-table": "2",
            "pricing-profile": "3",
            "invoicing-profile": "4",
            workflow: "5",
          },
        };

        const deletedTabKey = tabKeys[headerTab]?.[correctTab] || "";
        const sessionKey =
          headerTab === "organization-setup" ? "orgTabKey" : "conTabKey";

        console.log("deletedTabKey:", deletedTabKey);
        console.log("condition:", sessionKey);

        sessionStorage.setItem(sessionKey, deletedTabKey);
        history("/system-setup");
      } else {
        history(`/${headerTab}/${action}/${correctTab}`, {
          state: { data, flag: "view" },
        });
      }
    };

    const showUserManagement = (data, headerTab, correctTab, action) => {
      console.log("dataaaa*****", data);
      console.log("headerTab*****", headerTab);
      console.log("correctTab*****", correctTab);
      console.log("action", action);
      if (!data || data.length === 0) {
        data = {};
        action = "delete";
      }
      let tabKey =
        correctTab === "roles"
          ? "1"
          : correctTab === "users"
          ? "2"
          : correctTab === "groups"
          ? "3"
          : "";
      setHeaderChanger({
        header: "userManagement",
        headerItem: tabKey,
        headerItemName: "userManagement",
        stateSetter: data,
      });
      sessionStorage.setItem("userTabKey", tabKey);
      if (action === "delete") {
        history("/user-management");
      } else {
        history(
          correctTab === "users"
            ? `/${headerTab}/user/${action}`
            : `/${headerTab}/${correctTab}/view`,
          {
            state: data,
          }
        );
      }
    };
    const showActionManagement = async (
      data,
      headerTab,
      subTab,
      correctTab
    ) => {
      setHeaderChanger({
        header: "actionManagement",
        headerItem: subTab === "root_action" ? "1" : "2",
        headerItemName: headerChanger.headerItemName,
        stateSetter: subTab === "root_action" ? data : data[0],
      });
      if (subTab === "root_action") {
        const supplierData = await treeDataApi(userData[0].id, "ContractTree");
        const actionSupplier = supplierData.find(
          (supplier) => supplier._id === data.supplier_id
        );
        const actionContract = await getSingleContractApi(data.contract_id);
        let actionBreadcrumbs = getBreadcrumbs(
          supplierData,
          actionSupplier.supplier_name,
          actionContract.docs[0].contract_name
        );
        actionBreadcrumbs = actionBreadcrumbs.map((breadcrumb) => ({
          id: breadcrumb.contract_id,
          name: breadcrumb.contract_names,
          type: breadcrumb.contract_types,
        }));
        actionBreadcrumbs.unshift({ id: "home", name: "home", type: "" });
        history(`/${headerTab}/${correctTab}`, {
          state: { actionData: data, finalPath: actionBreadcrumbs },
        });
      } else {
        history(`/${headerTab}/${correctTab}`, {
          state: data[0],
        });
      }
    };
    const showContractAuthority = (data, headerTab, action, correctTab) => {
      history(`/${headerTab}/${action}/${correctTab}`, {
        state: data,
      });
    };
    const headerTab = organizationSetup.includes(item.code)
      ? "organization-setup"
      : contractSetup.includes(item.code)
      ? "contract-setup"
      : userManagement.includes(item.code)
      ? "user-management"
      : actionManagement.includes(item.code)
      ? "action-management"
      : contractAuthority.includes(item.code)
      ? "contract_authority"
      : "";
    const correctTab = item.code.split("_").join("-");
    console.log("item", item);

    if (item.tab === "system_setup") {
      switch (item.code) {
        case "supplier_setup":
          if (item.action === "delete") {
            showSystemSetup({}, headerTab, item.action, correctTab);
          } else {
            getSingleSupplierDataApi(item.id).then((res) => {
              showSystemSetup(res, headerTab, item.action, correctTab);
            });
          }
          break;
        case "legal_entity":
          if (item.action === "delete") {
            showSystemSetup({}, headerTab, item.action, correctTab);
          } else {
            getSingleLegalEntityDataApi(item.id).then((res) => {
              showSystemSetup(res, headerTab, item.action, correctTab);
            });
          }
          break;
        case "cost_center":
          if (item.action === "delete") {
            showSystemSetup({}, headerTab, item.action, correctTab);
          } else {
            getSingleCostCenterDataApi(item.id).then((res) => {
              showSystemSetup(res, headerTab, item.action, correctTab);
            });
          }
          break;
        case "rate_card":
          if (item.action === "delete") {
            showSystemSetup({}, headerTab, item.action, correctTab);
          } else {
            getSingleRateCardDataApi(item.id).then((res) => {
              showSystemSetup(res, headerTab, item.action, correctTab);
            });
          }
          break;
        case "fx_table":
          if (item.action === "delete") {
            showSystemSetup({}, headerTab, item.action, correctTab);
          } else {
            getSingleFxTableDataApi(item.id).then((res) => {
              showSystemSetup(res, headerTab, item.action, correctTab);
            });
          }
          break;
        case "pricing_profile":
          if (item.action === "delete") {
            showSystemSetup({}, headerTab, item.action, correctTab);
          } else {
            getPricingProfileByIdApi(item.id).then((res) => {
              const normalizedData =
                typeof res === "object" && !isEmptyObject(res)
                  ? {
                      ...res,
                      rate_card: Array.isArray(res.rate_card)
                        ? res.rate_card[0] || {}
                        : res.rate_card || {},
                    }
                  : "";

              showSystemSetup(
                normalizedData,
                headerTab,
                item.action,
                correctTab
              );
              console.log("State data after showSystemSetup: ", normalizedData);
            });
          }
          break;
        case "invoicing_profile":
          if (item.action === "delete") {
            showSystemSetup({}, headerTab, item.action, correctTab);
          } else {
            getSingleInvoicingProfileDataApi(item.id).then((res) => {
              showSystemSetup(res, headerTab, item.action, correctTab);
            });
          }
          break;
        case "workflow":
          if (item.action === "delete") {
            showSystemSetup({}, headerTab, item.action, correctTab);
          } else {
            getSingleWorkflowsApi(item.id).then((res) => {
              showSystemSetup(res, headerTab, item.action, correctTab);
            });
          }
          break;
      }
    } else if (item.tab === "user_management") {
      switch (item.code) {
        case "roles":
          if (item.action === "delete") {
            showUserManagement("", headerTab, item.code, item.action);
          } else {
            getSingleRolesApi(item.id).then((res) => {
              showUserManagement(
                res.docs[0] || "",
                headerTab,
                item.code,
                item.action
              );
            });
          }
          break;
        case "users":
          if (item.action === "delete") {
            showUserManagement("", headerTab, item.code, item.action);
          } else {
            getUserByIdApi(item.id).then((res) => {
              showUserManagement(
                res.docs[0] && res.docs[0].status === "Active"
                  ? res.docs[0]
                  : "",
                headerTab,
                item.code,
                item.action
              );
            });
          }
          break;
        case "groups":
          if (item.action === "delete") {
            showUserManagement("", headerTab, item.code, item.action);
          } else {
            getSingleGroupApi(item.id).then((res) => {
              showUserManagement(
                res.docs[0] || "",
                headerTab,
                item.code,
                item.action
              );
            });
          }
          break;
      }
    } else if (item.tab === "action_management") {
      if (item.code === "root_action") {
        getSingleActionDataApi(item.id).then((res) => {
          showActionManagement(
            res[0],
            headerTab,
            item.code,
            `${item.action + "-action"}`
          );
        });
      } else if (item.code === "action_instance") {
        getInstanceByIdApi(item.id).then((res) => {
          showActionManagement(
            res,
            headerTab,
            item.code,
            `${item.action + "-action-instance"}`
          );
        });
      }
    } else if (item.tab === "contract_authority") {
      if (item.code === "clause_library") {
        if (item.action !== "delete") {
          clauseLibraryByIdApi(item.id).then((res) => {
            setHeaderChanger({
              header: "contractAuthority",
              headerItem: "1",
              headerItemName: "contractAuthority",
              stateSetter: res.docs[0],
            });
            showContractAuthority(
              res.docs[0],
              headerTab,
              item.action,
              correctTab
            );
          });
        } else {
          setHeaderChanger({
            header: "contractAuthority",
            headerItem: "1",
            headerItemName: "contractAuthority",
            stateSetter: null,
          });
          history("/contract_authority/clause-library");
        }
      } else if (item.code === "template") {
        if (item.action !== "delete") {
          contracttemplateByIdApi(item.id).then((res) => {
            setHeaderChanger({
              header: "contractAuthority",
              headerItem: "2",
              headerItemName: "contractAuthority",
              stateSetter: res.docs[0],
            });
            showContractAuthority(
              res.docs[0],
              headerTab,
              item.action,
              correctTab
            );
          });
        } else {
          setHeaderChanger({
            header: "contractAuthority",
            headerItem: "2",
            headerItemName: "contractAuthority",
            stateSetter: null,
          });
          history("/contract_authority/template");
        }
      }
    }
  };

  const viewContractByLog = async (item) => {
    console.log("Item: ", item);
    await getContractBreadcrumbsApi(item.id).then((breadcrumbRes) => {
      if (breadcrumbRes) {
        navigateBasedOnContractStatus(
          breadcrumbRes,
          history,
          setHeaderChanger,
          handleAddTab
        );
      }
    });
  };

  const myQueueData =
    myQueue &&
    myQueue.map((data, index) => {
      return {
        key: index,
        id: data._id,
        type: data.contract_type,
        value: data.contract_name,
        supplier: data.supplier_name,
        status: data.contract_status,
        time: dayjs(data.updatedAt).format("DD MMM YYYY"),
      };
    });

  const viewMyQueue = async (item) => {
    await getContractBreadcrumbsApi(item.id).then((breadcrumbRes) => {
      console.log("breadcrumbRes: ", breadcrumbRes);
      if (breadcrumbRes) {
        const contractBreadcrumbs = [...convertBreadcrumbs(breadcrumbRes)];
        setHeaderChanger({
          header: "contract",
          headerItem: "2",
          headerItemName: "pipeline",
          stateSetter: {
            contract_id: breadcrumbRes._id,
            mode: { view: true, edit: false, create: false },
            breadcrumbs: contractBreadcrumbs,
            location: "pipeline",
          },
        });
        history("/contract/view/form", {
          state: {
            contract_id: breadcrumbRes._id,
            mode: { view: true, edit: false, create: false },
            breadcrumbs: contractBreadcrumbs,
            location: "pipeline",
          },
        });
      }
    });
  };

  const recentJourneysExpandedData =
    recentJourneys &&
    recentJourneys.map((data, index) => {
      //Showing last 10 logs when See More is clicked
      return {
        key: index,
        date: dayjs(data.updated_on).format("DD MMM YYYY, HH:mm"),
        value:
          data.action === "Approved"
            ? "Contract Approved"
            : data.action === "Rejected"
            ? "Contract Rejected"
            : data.action === "Returned"
            ? "Contract Returned"
            : data.notes,
        id: data.ss_id ? data.ss_id : data.contract_id,
        name: data.ss_id
          ? data.ss_name
          : data.contract_id
          ? data.contract_name
            ? data.contract_name
            : data.contract_id
          : "",
        tab: data.ss_id
          ? ["roles", "users", "groups"].includes(data.code)
            ? "user_management"
            : ["root_action", "action_instance"].includes(data.code)
            ? "action_management"
            : "system_setup"
          : "contract",
        code: data.code,
        action: data.action,
      };
    });

  const onClickPrevSearchVal = (val) => {
    setSelectedPrev(val);
  };

  const getImageSource = (item) => {
    // console.log("item: ", item);

    if (item.type) {
      switch (item.type) {
        case "MSA":
          return frameworkAgreement_active;
        case "Supplement":
        case "Sub-Supplement":
          return supplement;
        case "CCN":
          return ccn;
        case "SOW":
          return sow;
        case "CR":
          return cr;
        default:
          return others;
      }
    }

    switch (item.tab) {
      case "contract":
        return frameworkAgreement_active;
      case "system_setup":
        return item.code === "supplier_setup"
          ? person_active
          : item.code === "legal_entity"
          ? adminPanel_active
          : item.code === "cost_center"
          ? priceChange_active
          : item.code === "rate_card"
          ? rateCard_active
          : item.code === "fx-table"
          ? fxSetup_active
          : item.code === "pricing_profile"
          ? pricingProfile_active
          : item.code === "invoicing_profile"
          ? invoicingProfile_active
          : workflow_active;
      case "action_management":
        return ContractSearchIcon;
      case "user_management":
        return item.code === "roles"
          ? person_active
          : item.code === "users"
          ? adminPanel_active
          : groups_active;
      case "contract_authority":
        return BatchPredictionIcon;
    }
  };

  const formatNumber = (num) => {
    if (num >= 1e9) {
      return parseFloat((num / 1e9).toFixed(2)).toString() + " B";
    } else if (num >= 1e6) {
      return parseFloat((num / 1e6).toFixed(2)).toString() + " M";
    } else if (num >= 1e3) {
      return parseFloat((num / 1e3).toFixed(2)).toString() + " K";
    } else {
      return num.toString();
    }
  };

  const getTotalSpend = async () => {
    setTotalSpendLoader(true);

    await getAllAcvTcvApi().then((res) => {
      setTotalSpend(res);
      setDashboardData((prev) => ({
        ...prev,
        total_spend: res,
      }));
      setTotalSpendLoader(false);
    });
  };

  const getTotalExpiry = async () => {
    const contractExpiryCount = await getContractExpiryCount();

    setTotalExpiry(contractExpiryCount);
    setDashboardData((prev) => ({
      ...prev,
      total_expiry: parseFloat(contractExpiryCount),
    }));
    setTotalExpiryLoader(false);
  };

  const showAcvTcvModal = () => {
    genActiveSupplierData(1, 20);
    setIsFlipped(true);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsFlipped(false);
    setIsModalOpen(false);
  };

  const handleCardClick = () => {
    setIsFlipped(true);
    showAcvTcvModal();
  };

  const activeSupplierColumns = [
    {
      title: "Supplier",
      dataIndex: "supplier_name",
      align: "center",
      ellipsis: true,
    },
    {
      title: "Contract Value",
      dataIndex: "supplier_cv",
      align: "center",
      ellipsis: true,
    },
    {
      title: "Aggregate Value",
      dataIndex: "supplier_av",
      align: "center",
      ellipsis: true,
    },
    {
      title: "Engagement Value",
      dataIndex: "supplier_ev",
      align: "center",
      ellipsis: true,
    },
  ];

  const genActiveSupplierData = async (page, limit) => {
    let tableData = [];
    setAcvTcvLoader(true);
    await getSupplierAcvTcv(page, limit).then((res) => {
      setPaginateData({
        ...paginateData,
        pageNo: page,
        limit: limit,
      });

      res.map((supplier) => {
        tableData.push({
          ...supplier,
          supplier_cv: supplier.supplier_cv
            ? supplier.supplier_cv.toLocaleString("en-us")
            : 0,
          supplier_av: supplier.supplier_av
            ? supplier.supplier_av.toLocaleString("en-us")
            : 0,
          supplier_ev: supplier.supplier_ev
            ? supplier.supplier_ev.toLocaleString("en-us")
            : 0,
          key: supplier.supplier_id,
        });
      });

      setActiveSupplierData(tableData);
      setAcvTcvLoader(false);
    });
  };

  const onPageChange = (page, limit) => {
    genActiveSupplierData(page, limit);
  };

  useEffect(() => {
    document.title = "Dashboard | Judo";
  }, []);

  return (
    <div id="dashboard" className="dashboard-bg">
      <Layout className="header-layout">
        <TopHeader
          headerChange={header}
          eventsSeeMore={eventsSeeMore}
          seeMoreDisplay={seeMoreDisplay}
          eventsSeeMoreStatus={eventsSeeMoreStatus}
          searchView={searchView}
          menuKey={menuKey}
          selectedPrev={selectedPrev}
          logo={true}
          feedRow={true}
          searchRow={true}
        />
      </Layout>

      <Layout
        style={{
          marginTop: "105px",
          height: "calc(100vh - 110px)",
          transition: "height 600ms ease, margin 600ms ease",
        }}
        className="dashboard-content-layout"
      >
        <Content>
          {!recentSearch && !seeMore && (
            <div
              className={
                hideOverviewSection
                  ? "section-wrapper-overview"
                  : hideRecentSection
                  ? "section-wrapper-recent"
                  : "section-wrapper"
              }
            >
              <div
                className={`overview-section ${
                  expandOverviewSection ? "hidden" : ""
                }`}
              >
                <Row type="flex" gutter={[25, 0]}>
                  <Col key="active_suppliers" span={6}>
                    <div
                      className={`flip-container ${isFlipped ? "flipped" : ""}`}
                    >
                      <div className="flipper">
                        <div className="front" onClick={handleCardClick}>
                          <Card
                            loading={activeSupplierLoader}
                            hoverable
                            className="overview-card"
                          >
                            <Row type="flex" align="middle">
                              <Col span={7} align="right">
                                <Image
                                  src={ActiveSuppliersIcon}
                                  preview={false}
                                  width={70}
                                  height={70}
                                />
                              </Col>
                              <Col span={17} align="center">
                                <p className="overview-card-text-number">
                                  {activeSupplierCount === 0
                                    ? "0"
                                    : String(activeSupplierCount).padStart(
                                        2,
                                        "0"
                                      )}
                                </p>
                                <p className="overview-card-text">
                                  Active Suppliers
                                </p>
                              </Col>
                            </Row>
                          </Card>
                        </div>
                        <div className="back">
                          <Modal
                            className="active-supplier-modal"
                            open={isModalOpen}
                            onCancel={handleCancel}
                            footer={null}
                            centered
                            width={"97%"}
                            mask={false}
                            closeIcon={
                              <CloseCircleOutlined style={{ color: "#000" }} />
                            }
                          >
                            <Row>
                              <Col span={24} align="center">
                                <Col span={12}>
                                  <Table
                                    loading={acvTcvLoader}
                                    columns={activeSupplierColumns}
                                    dataSource={activeSupplierData}
                                    pagination={false}
                                    scroll={{ y: "calc(100vh - 300px)" }}
                                    className="data-table mt-30"
                                  />
                                  <Row className="mt-20 mb-10">
                                    <Col span={24} align="right">
                                      <Pagination
                                        defaultPageSize={paginateData.limit}
                                        responsive
                                        current={paginateData.pageNo}
                                        total={paginateData.total}
                                        showSizeChanger
                                        onShowSizeChange={onPageChange}
                                        onChange={onPageChange}
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                              </Col>
                              <Col span={24} align="center">
                                <p
                                  className="supplier-see-more"
                                  onClick={() => {
                                    setHeaderChanger({
                                      header: "contract",
                                      headerItem: "1",
                                      headerItemName: "contractTree",
                                    });
                                    history("/contract");
                                  }}
                                >
                                  See more
                                </p>
                              </Col>
                            </Row>
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col key="acv" span={6}>
                    <Card
                      loading={totalSpendLoader}
                      hoverable
                      className="overview-card"
                      onClick={() => {
                        setHeaderChanger({
                          header: "contract",
                          headerItem: "4",
                          headerItemName: "contract",
                        });
                        history("/contract", {
                          state: { listSpend: true },
                        });
                      }}
                    >
                      <Row type="flex" align="middle">
                        <Col span={7} align="right">
                          <Image
                            src={AcvIcon}
                            preview={false}
                            width={70}
                            height={70}
                          />
                        </Col>
                        <Col span={17} align="center">
                          <div
                            className="overview-card-text-number"
                            title={`€${totalSpend.toLocaleString("en-US")}`}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <p>{formatNumber(totalSpend)}</p>
                            {totalSpend !== 0 && (
                              <p
                                style={{
                                  marginLeft: 9,
                                  marginBottom: 12,
                                  fontSize: 8,
                                  alignSelf: "flex-end",
                                  color: "#05b384",
                                  borderRadius: 10,
                                  padding: "0px 5px 0px 5px",
                                  border: "1px solid #05b384",
                                }}
                              >
                                EUR
                              </p>
                            )}
                          </div>
                          <p className="overview-card-text">
                            Annual Contract Value{" "}
                            <Button
                              title="Refresh ACV"
                              className="dashboard-reload-button"
                              icon={
                                <ReloadOutlined className="dashboard-reload-icon" />
                              }
                              onClick={(event) => {
                                event.stopPropagation();
                                getTotalSpend();
                              }}
                            />
                          </p>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col key="expiry_in" span={6}>
                    <Card
                      loading={totalExpiryLoader}
                      hoverable
                      className="overview-card"
                      onClick={() => {
                        setHeaderChanger({
                          header: "contract",
                          headerItem: "4",
                          headerItemName: "contract",
                        });
                        history("/contract", {
                          state: { listExpiry: true },
                        });
                      }}
                    >
                      <Row type="flex" align="middle">
                        <Col span={7} align="right">
                          <Image
                            src={ContractExpiryIcon}
                            preview={false}
                            width={70}
                            height={70}
                          />
                        </Col>
                        <Col span={17} align="center">
                          <p className="overview-card-text-number">
                            {totalExpiry == 0
                              ? "0"
                              : String(totalExpiry).padStart(2, "0")}
                          </p>
                          <p className="overview-card-text">
                            Expiry in next 6 months
                          </p>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  {/* <Col key="overdue_actions" span={6}>
                    <Card
                      hoverable
                      className="overview-card"
                      loading={overdueActionLoader}
                      onClick={() => {
                        history("/action-management");
                        setHeaderChanger({
                          header: "actionManagement",
                          headerItem: "2",
                          headerItemName: headerChanger.headerItemName,
                        });
                      }}
                    >
                      <Row type="flex" align="middle">
                        <Col span={7} align="right">
                          <Image
                            src={OverdueActionIcon}
                            preview={false}
                            width={70}
                            height={70}
                          />
                        </Col>
                        <Col span={17} align="center">
                          <p className="overview-card-text-number">
                            {overdueActionCount == 0
                              ? "0"
                              : String(overdueActionCount).padStart(2, "0")}
                          </p>
                          <p className="overview-card-text">
                            No. of Overdue Actions
                          </p>
                        </Col>
                      </Row> */}
                  <Col key="overdue_actions" span={6}>
                    <Card
                      hoverable
                      className="overview-card"
                      loading={overdueActionLoader}
                      onClick={() => {
                        if (lite) {
                          history("/contract");
                          setHeaderChanger({
                            header: "contract",
                            headerItem: "2",
                            headerItemName: "contractPipeline",
                          });
                        } else {
                          history("/action-management");
                          setHeaderChanger({
                            header: "actionManagement",
                            headerItem: "2",
                            headerItemName: headerChanger.headerItemName,
                          });
                        }
                      }}
                    >
                      {lite ? (
                        <Row type="flex" align="middle">
                          <Col span={7} align="right">
                            <Image
                              src={OverdueActionIcon}
                              preview={false}
                              width={70}
                              height={70}
                            />
                          </Col>
                          <Col span={17} align="center">
                            <p className="overview-card-text-number">
                              {submittedContractsCount === 0
                                ? "00"
                                : String(submittedContractsCount).padStart(
                                    2,
                                    "0"
                                  )}
                            </p>
                            <p className="overview-card-text">
                              No. of In-Force contracts
                            </p>
                          </Col>
                        </Row>
                      ) : (
                        <Row type="flex" align="middle">
                          <Col span={7} align="right">
                            <Image
                              src={OverdueActionIcon}
                              preview={false}
                              width={70}
                              height={70}
                            />
                          </Col>
                          <Col span={17} align="center">
                            <p className="overview-card-text-number">
                              {overdueActionCount == 0
                                ? "0"
                                : String(overdueActionCount).padStart(2, "0")}
                            </p>
                            <p className="overview-card-text">
                              No. of Overdue Actions
                            </p>
                          </Col>
                        </Row>
                      )}
                    </Card>
                  </Col>
                  {/* {expandOverviewSection && (
                    <>
                      <Col key="value_of_pipeline" span={6}>
                        <Card
                          loading={totalSpendLoader}
                          hoverable
                          className="overview-card"
                          onClick={() => {
                            setHeaderChanger({
                              header: "contract",
                              headerItem: "4",
                              headerItemName: "contract",
                            });
                            history("/contract", {
                              state: { listSpend: true },
                            });
                          }}
                        >
                          <Row type="flex" align="middle">
                            <Col span={7} align="right">
                              <Image
                                src={PipelineContractValueIcon}
                                preview={false}
                                width={70}
                                height={70}
                              />
                            </Col>
                            <Col span={17} align="center">
                              <p
                                className="overview-card-text-number"
                                style={{
                                  fontSize: 25,
                                  paddingBottom: 8,
                                }}
                              >
                                {totalSpend}
                              </p>
                              <p className="overview-card-text">
                                Value of Contracts in Pipeline
                              </p>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                      <Col key="no_of_pipeline" span={6}>
                        <Card
                          hoverable
                          className="overview-card"
                          loading={totalPipelineLoader}
                          onClick={() => {
                            setHeaderChanger({
                              header: "contract",
                              headerItem: "2",
                              headerItemName: "contractPipeline",
                            });
                            history("/contract", {
                              state: { switchChecked: false },
                            });
                          }}
                        >
                          <Row type="flex" align="middle">
                            <Col span={7} align="right">
                              <Image
                                src={PipelineContractIcon}
                                preview={false}
                                width={70}
                                height={70}
                              />
                            </Col>
                            <Col span={17} align="center">
                              <p className="overview-card-text-number">
                                {totalPipelineCount}
                              </p>
                              <p className="overview-card-text">
                                No. of Contracts in Pipeline
                              </p>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                      <Col key="budget_consumed" span={6}>
                        <Card hoverable className="overview-card">
                          <Row type="flex" align="middle">
                            <Col span={7} align="right">
                              <Image
                                src={BudgetConsumedIcon}
                                preview={false}
                                width={70}
                                height={70}
                              />
                            </Col>
                            <Col span={17} align="center">
                              <p className="overview-card-text-number">04</p>
                              <p className="overview-card-text">
                                Budget Consumed %
                              </p>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                      <Col key="sla_hitrate" span={6}>
                        <Card hoverable className="overview-card">
                          <Row type="flex" align="middle">
                            <Col span={7} align="right">
                              <Image
                                src={SlaHitrateIcon}
                                preview={false}
                                width={70}
                                height={70}
                              />
                            </Col>
                            <Col span={17} align="center">
                              <p className="overview-card-text-number">10</p>
                              <p className="overview-card-text">SLA Hitrate</p>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    </>
                  )} */}
                </Row>
                {/* <div
                  className="arrow-up"
                  onClick={() => {
                    setExpandOverviewSection(!expandOverviewSection);
                    if (!expandOverviewSection) getTotalSpend();
                  }}
                >
                  <Image
                    src={expandOverviewSection ? ArrowUp : ArrowDown}
                    preview={false}
                  />
                </div> */}
              </div>
              <div
                className={`recent-queue-section ${
                  hideRecentSection ? "hidden" : ""
                }`}
              >
                <Row
                  gutter={[8, 0]}
                  style={{
                    display: "flex",
                    flexFlow: "row wrap",
                    justifyContent: "flex end",
                  }}
                  className="cards-row"
                >
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={6}
                    xl={6}
                    style={{ order: selectedWidget.indexOf("1") + 1 }}
                  >
                    <Card
                      className="glass-card"
                      loading={
                        actionInstanceCount.length !== 0 &&
                        pipelineCount.length !== 0
                          ? false
                          : true
                      }
                    >
                      <Card
                        className="card-type1 card1"
                        onClick={() => {
                          if (lite) {
                            history("/contract");
                            setCreateContractTabClick((prev) => prev + 1);
                            setHeaderChanger({
                              header: "contract",
                              headerItem: "3",
                              headerItemName: "createContract",
                            });
                          } else {
                            history("/action-management");
                            setHeaderChanger({
                              header: "actionManagement",
                              headerItem: "2",
                              headerItemName: headerChanger.headerItemName,
                            });
                          }
                        }}
                      >
                        {lite ? (
                          <Row align="middle" type="flex">
                            <Col span={4} className="vertical-align">
                              <Image
                                src={OpenAction}
                                preview={false}
                                width={30}
                              />
                            </Col>
                            <Col span={12} className="vertical-align">
                              <span>Create Contract</span>
                            </Col>
                          </Row>
                        ) : (
                          <Row align="middle" type="flex">
                            <Col span={4} className="vertical-align">
                              <Image
                                src={OpenAction}
                                preview={false}
                                width={30}
                              />
                            </Col>
                            <Col span={12} className="vertical-align">
                              <span>Open Action</span>
                            </Col>
                            <Col
                              span={8}
                              align="right"
                              className="vertical-align"
                            >
                              <span style={{ fontSize: 22 }}>
                                {actionInstanceCount === 0
                                  ? "0"
                                  : String(actionInstanceCount).padStart(
                                      2,
                                      "0"
                                    )}
                              </span>
                            </Col>
                          </Row>
                        )}
                      </Card>
                      <PlusOutlined
                        style={{
                          color: "var(--color-solid-darkgrey)",
                          fontSize: "18px",
                          marginTop: "105px",
                        }}
                        className="cursor-pointer"
                      />
                    </Card>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={9}
                    xl={9}
                    style={{ order: selectedWidget.indexOf("2") + 1 }}
                  >
                    <Card className="card-type2" loading={recentLoader}>
                      <p>Recent Journeys </p>
                      {recentJourneysData.length === 0 && (
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          imageStyle={{
                            height: "calc(100vh - 560px)",
                          }}
                          description={
                            <span>
                              Currently there are no records of your recent
                              journeys
                            </span>
                          }
                        ></Empty>
                      )}
                      {recentJourneysData &&
                        recentJourneysData.map((item) => (
                          <>
                            <Card className="card-type2-sub mb-10">
                              <Row align="middle" type="flex">
                                <Col span={22} className="vertical-align">
                                  <span
                                    className="processed-list-items"
                                    title={`${item.name} - ${item.value}`}
                                  >
                                    <Image
                                      src={getImageSource(item)}
                                      preview={false}
                                      height={18}
                                      width={18}
                                      style={{ marginTop: -5 }}
                                    />
                                    <span
                                      style={{
                                        color: "#000",
                                        fontSize: 13,
                                        fontWeight: 600,
                                        display: "inline",
                                        marginLeft: 10,
                                      }}
                                    >
                                      {item.name}
                                    </span>
                                    &nbsp; &#8226; &nbsp;
                                    <span
                                      style={{
                                        fontSize: 13,
                                        fontWeight: 600,
                                        color: "var(--color-solid-darkergrey)",
                                      }}
                                    >
                                      {item.value}
                                    </span>
                                  </span>
                                </Col>
                                <Col
                                  span={2}
                                  className="vertical-align text-right"
                                >
                                  <span>
                                    <a
                                      className="view-text"
                                      onClick={() =>
                                        item.tab !== "contract"
                                          ? viewRecentJourney(item)
                                          : viewContractByLog(item)
                                      }
                                    >
                                      View
                                    </a>
                                  </span>
                                </Col>
                              </Row>
                            </Card>
                          </>
                        ))}
                      {recentJourneys.length !== 0 &&
                      recentJourneys.length > 4 ? (
                        <div className="see-more text-center">
                          <a onClick={() => setSeeMoreModal(true)}>See more</a>
                        </div>
                      ) : (
                        recentJourneys.length < 5 && (
                          <div className="see-more">
                            <br />
                          </div>
                        )
                      )}
                      <Modal
                        title={"Recent Journeys"}
                        open={seeMoreModal}
                        centered
                        closable
                        footer={null}
                        width={800}
                        classNames={{
                          header: "see-more-header",
                          body: "see-more-body",
                          mask: "see-more-mask",
                          wrapper: "see-more-wrapper",
                        }}
                        onCancel={() => setSeeMoreModal(false)}
                      >
                        <Card className="card-type2">
                          {recentJourneysExpandedData.length === 0 && (
                            <Empty
                              image={Empty.PRESENTED_IMAGE_SIMPLE}
                              imageStyle={{
                                height: "calc(100vh - 560px)",
                              }}
                              description={
                                <span>
                                  Currently there are no records in your recent
                                  journeys
                                </span>
                              }
                            ></Empty>
                          )}
                          {recentJourneysExpandedData &&
                            recentJourneysExpandedData.map((item) => (
                              <>
                                <Card className="card-type2-sub mt-10">
                                  <Row align="middle" type="flex">
                                    <Col span={22} className="vertical-align">
                                      <span
                                        className="processed-list-items"
                                        title={`${item.name} - ${item.value}`}
                                      >
                                        <Image
                                          src={getImageSource(item)}
                                          preview={false}
                                          height={18}
                                          width={18}
                                          style={{ marginTop: -5 }}
                                        />
                                        <span
                                          style={{
                                            color: "#000",
                                            fontSize: 13,
                                            fontWeight: 600,
                                            display: "inline",
                                            marginLeft: 10,
                                          }}
                                        >
                                          {item.name}
                                        </span>
                                        &nbsp; &#8226; &nbsp;{item.value}
                                      </span>
                                    </Col>
                                    <Col
                                      span={2}
                                      className="vertical-align text-right"
                                    >
                                      <span>
                                        <a
                                          className="view-text"
                                          onClick={() =>
                                            item.tab !== "contract"
                                              ? viewRecentJourney(item)
                                              : viewContractByLog(item)
                                          }
                                        >
                                          View
                                        </a>
                                      </span>
                                    </Col>
                                  </Row>
                                </Card>
                              </>
                            ))}
                        </Card>
                      </Modal>
                    </Card>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={9}
                    xl={9}
                    style={{ order: selectedWidget.indexOf("2") + 1 }}
                  >
                    <Card className="card-type2" loading={queueLoader}>
                      <p
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setHeaderChanger({
                            header: "contract",
                            headerItem: "2",
                            headerItemName: "contractPipeline",
                          });
                          history("/contract", {
                            state: { switchChecked: true },
                          });
                        }}
                      >
                        My Queue{" "}
                      </p>
                      {myQueueData.length === 0 && (
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          imageStyle={{
                            height: "calc(100vh - 560px)",
                          }}
                          description={
                            <span>
                              Currently there are no items in your queue
                            </span>
                          }
                        ></Empty>
                      )}
                      {myQueueData &&
                        myQueueData.map((item) => (
                          <>
                            <Card className="card-type2-sub mb-10">
                              <Row align="middle" type="flex">
                                <Col span={20} className="vertical-align">
                                  <span
                                    className="processed-list-items"
                                    title={`${item.value} - ${
                                      item.supplier
                                    } - ${item.status.split("-").join(" ")}`}
                                  >
                                    <Image
                                      src={getImageSource(item)}
                                      preview={false}
                                      height={18}
                                      width={18}
                                      style={{ marginTop: -5 }}
                                    />
                                    <span
                                      style={{
                                        color: "#000",
                                        fontSize: 13,
                                        fontWeight: 600,
                                        display: "inline",
                                        marginLeft: 10,
                                      }}
                                    >
                                      {item.value}
                                    </span>
                                    &nbsp; &#8226; &nbsp;{item.supplier}&nbsp;
                                    &#8226; &nbsp;
                                    {item.status
                                      .split("-")
                                      .map(
                                        (word) =>
                                          word.charAt(0).toUpperCase() +
                                          word.slice(1)
                                      )
                                      .join(" ")
                                      .replace("Contract", "")
                                      .trim()}
                                  </span>
                                </Col>
                                <Col
                                  span={4}
                                  className="vertical-align text-right"
                                >
                                  <span
                                    className="view-text"
                                    style={{
                                      color: "var(--color-solid-darkerblue)",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => viewMyQueue(item)}
                                  >
                                    View
                                  </span>
                                </Col>
                              </Row>
                            </Card>
                          </>
                        ))}

                      {(myQueue.length === 0 || myQueue.length !== 0) &&
                        recentJourneys.length !== 0 && (
                          <div
                            className="text-center"
                            style={{
                              paddingBottom: 10,
                              marginTop: 12,
                            }}
                          >
                            <br />
                          </div>
                        )}
                    </Card>
                  </Col>
                </Row>
                {/* <div
                  className="arrow-down"
                  onClick={() => {
                    setHideRecentSection(true);
                    setHideOverviewSection(false);
                  }}
                >
                  <Image src={ArrowDown} preview={false} />
                </div> */}
              </div>
              {(hideOverviewSection || hideRecentSection) && (
                <div className="button-wrapper">
                  <Row>
                    <Col span={24} align="center">
                      <Button
                        type="primary"
                        className="section-reset-btn"
                        icon={<ArrowLeftOutlined />}
                        onClick={() => {
                          setHideOverviewSection(false);
                          setHideRecentSection(false);
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              )}
            </div>
          )}

          <>
            {/* {(selectedWidget.indexOf("1") > -1 || showAll) &&
                  (selectedWidget.indexOf("1") < 3 ||
                    (selectedWidget.indexOf("1") > 2 && showAll)) && ( */}
            {/* )} */}
            {/* {(selectedWidget.indexOf("2") > -1 || showAll) &&
                  (selectedWidget.indexOf("2") < 3 ||
                    (selectedWidget.indexOf("2") > 2 && showAll)) && ( */}
            {/* )} */}
            {/* {(selectedWidget.indexOf("2") > -1 || showAll) &&
                  (selectedWidget.indexOf("2") < 3 ||
                    (selectedWidget.indexOf("2") > 2 && showAll)) && ( */}
            {/* )} */}
            {/* {(selectedWidget.indexOf("3") > -1 || showAll) &&
                  (selectedWidget.indexOf("3") < 3 ||
                    (selectedWidget.indexOf("3") > 2 && showAll)) && (
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={8}
                      xl={7}
                      className="mb-20"
                      style={{ order: selectedWidget.indexOf("3") + 1 }}
                    >
                      <Card className="card-type3">
                        <p>
                          Events &nbsp;&nbsp;
                          <span className="to-do-list">To Do List</span>{" "}
                          <span className="float-right">
                            <PlusOutlined />
                          </span>
                        </p>
                        {events &&
                          events.map((item) => (
                            <>
                              <Card className="card-type3-sub mt-10">
                                <Row align="middle" type="flex">
                                  <Col span={3} className="vertical-align">
                                    <div
                                      className={`sq-card sq-card${item.key}`}
                                    ></div>
                                  </Col>
                                  <Col span={12} className="vertical-align">
                                    <span className="events-list-items">
                                      {item.value}
                                    </span>
                                  </Col>
                                  <Col
                                    span={9}
                                    className="vertical-align text-right"
                                  >
                                    <span>
                                      <a>{item.time}</a>
                                    </span>
                                  </Col>
                                </Row>
                              </Card>
                            </>
                          ))}

                        <div className="see-more text-center mt-15">
                          <a
                            onClick={() => {
                              setEventsSeeMore(true);
                            }}
                          >
                            see more
                          </a>
                        </div>
                      </Card>
                    </Col>
                  )} */}
            {/* {(selectedWidget.indexOf("4") > -1 || showAll) &&
                  (selectedWidget.indexOf("4") < 3 ||
                    (selectedWidget.indexOf("4") > 2 && showAll)) && (
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={8}
                      xl={7}
                      className="mb-10"
                      style={{ order: selectedWidget.indexOf("4") + 1 }}
                    >
                      <Card className="glass-card">
                        <Card className="card-type1 card1">
                          <Row align="middle" type="flex">
                            <Col span={5} className="vertical-align">
                              <Image
                                src={OpenAction}
                                preview={false}
                                width={30}
                              />
                            </Col>
                            <Col span={16} className="vertical-align">
                              <span>Open Action</span>
                            </Col>
                            <Col span={3} className="vertical-align">
                              <span>11</span>
                            </Col>
                          </Row>
                        </Card>
                        <Card className="card-type1 card2">
                          <Row align="middle" type="flex">
                            <Col span={5} className="vertical-align">
                              <Image
                                src={Milestone}
                                preview={false}
                                width={30}
                              />
                            </Col>
                            <Col span={16} className="vertical-align">
                              <span>Milestones</span>
                            </Col>
                            <Col span={3} className="vertical-align">
                              <span>06</span>
                            </Col>
                          </Row>
                        </Card>
                        <Card className="card-type1 card3">
                          <Row align="middle" type="flex">
                            <Col span={5} className="vertical-align">
                              <Image
                                src={Obligation}
                                preview={false}
                                width={30}
                              />
                            </Col>
                            <Col span={16} className="vertical-align">
                              <span>Obligations</span>
                            </Col>
                            <Col span={3} className="vertical-align">
                              <span>29</span>
                            </Col>
                          </Row>
                        </Card>
                        <div className="text-center">
                          <PlusOutlined
                            style={{
                              color: "var(--color-solid-darkgrey)",
                              fontSize: "18px",
                              marginTop: "17px",
                              marginBottom: "5px",
                            }}
                            className="cursor-pointer"
                          />
                        </div>
                      </Card>
                    </Col>
                  )}
                {(selectedWidget.indexOf("5") > -1 || showAll) &&
                  (selectedWidget.indexOf("5") < 3 ||
                    (selectedWidget.indexOf("5") > 2 && showAll)) && (
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={8}
                      xl={10}
                      className="mb-10"
                      style={{ order: selectedWidget.indexOf("5") + 1 }}
                    >
                      <Card className="card-type2">
                        <p>
                          Processed{" "}
                          <span className="float-right">
                            <PlusOutlined />
                          </span>
                        </p>
                        {processed &&
                          processed.map((item) => (
                            <>
                              <Card className="card-type2-sub mt-10">
                                <Row align="middle" type="flex">
                                  <Col span={19} className="vertical-align">
                                    <span className="processed-list-items">
                                      {item.value}
                                    </span>
                                  </Col>
                                  <Col
                                    span={5}
                                    className="vertical-align text-right"
                                  >
                                    <span>
                                      <a className="view-text">View</a>
                                    </span>
                                  </Col>
                                </Row>
                              </Card>
                            </>
                          ))}

                        <div className="see-more text-center mt-20">
                          <a>see more</a>
                        </div>
                      </Card>
                    </Col>
                  )}
                {(selectedWidget.indexOf("6") > -1 || showAll) &&
                  (selectedWidget.indexOf("6") < 3 ||
                    (selectedWidget.indexOf("6") > 2 && showAll)) && (
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={8}
                      xl={7}
                      className="mb-10"
                      style={{ order: selectedWidget.indexOf("6") + 1 }}
                    >
                      <Card className="card-type3">
                        <p>
                          Events &nbsp;&nbsp;
                          <span className="to-do-list">To Do List</span>{" "}
                          <span className="float-right">
                            <PlusOutlined />
                          </span>
                        </p>
                        {events &&
                          events.map((item) => (
                            <>
                              <Card className="card-type3-sub mt-10">
                                <Row align="middle" type="flex">
                                  <Col span={3} className="vertical-align">
                                    <div
                                      className={`sq-card sq-card${item.key}`}
                                    ></div>
                                  </Col>
                                  <Col span={12} className="vertical-align">
                                    <span>{item.value}</span>
                                  </Col>
                                  <Col
                                    span={9}
                                    className="vertical-align text-right"
                                  >
                                    <span>
                                      <a>{item.time}</a>
                                    </span>
                                  </Col>
                                </Row>
                              </Card>
                            </>
                          ))}

                        <div className="see-more text-center mt-20">
                          <a
                            href="javascript:void(0)"
                            onClick={() => {
                              setEventsSeeMore(true);
                            }}
                          >
                            see more
                          </a>
                        </div>
                      </Card>
                    </Col>
                  )} */}

            {/* <Row gutter={[24, 0]} className="mt-20">
                <Col span={4} offset={10}>
                  <div className="text-center">
                    <Button
                      onClick={() => {
                        showAll
                          ? setSelectedWidget(tempselectedWidget)
                          : setSelectedWidget(OPTIONS);
                        setShowAll(!showAll);
                      }}
                      className="add-icon"
                      shape="round"
                      icon={showAll ? <MinusOutlined /> : <PlusOutlined />}
                      size="medium"
                    />
                  </div>
                </Col>
              </Row> */}

            {/* {!recentSearch && seeMore && <EventCalendar />}

          {recentSearch && (
            <RecentSearch
              onClickPrevSearchVal={onClickPrevSearchVal}
              searchVal={searchVal}
            />
          )}

          <Drawer
            className="widget-config-drawer"
            title="Widget Configuration"
            placement="right"
            onClose={() => {
              setConfigDrawer(false);
            }}
            open={configDrawer}
          >
            <Select
              mode="multiple"
              placeholder="Inserted are removed"
              value={selectedWidget}
              onChange={(val) => {
                setSelectedWidget(val);
                setTempSelectedWidget(val);
              }}
              style={{
                width: "100%",
              }}
              options={filteredOptions.map((item) => ({
                value: item,
                label: item,
              }))}
            />
          </Drawer> */}
          </>
        </Content>
      </Layout>
    </div>
  );
}

export default DashboardPage;
