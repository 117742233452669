// External Imports
import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Image,
  Row,
  Col,
  Button,
  Form,
  Input,
  Select,
  Layout,
  DatePicker,
  Modal,
  InputNumber,
  Dropdown,
  Space,
  Card,
  Tabs,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useRecoilState } from "recoil";
import { CaretDownOutlined } from "@ant-design/icons";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

// Internal Imports
import {
  HeaderChangerAtom,
  userDataAtom,
  RoleAccessAtom,
} from "../../../store/store";
import TopHeader from "../../Header";
import "../../../assets/style/actionmanagement.css";
import arrow_left from "../../../assets/images/icons/arrow_left.svg";
import arrow_right_disable from "../../../assets/images/icons/arrow_right_disable.svg";
import arrow_back from "../../../assets/images/icons/arrow_back_ios.svg";
import "../../../assets/style/setup.css";
import "../../../assets/style/checkselect.css";
import account_tree from "../../../assets/images/icons/account_tree.svg";
import { getUsersApi } from "../../../api/usersApi";
import repeat from "../../../assets/images/icons/repeat.svg";
import repeat_small from "../../../assets/images/icons/repeat_small.svg";
import {
  createRootActionAPi,
  editRootActionAPi,
} from "../../../api/actionManagementApi";
import { createActivityLogApi } from "../../../api/logApi";
import PopupModal from "../../../PopupModal";
import ModalMessage from "../../../ModalMessage";
import { CancelButton, OkButton, SaveButton } from "../../GlobalButton";
import { getAllGroupApi } from "../../../api/groupsApi";
import NotAuthorized from "../../NotAuthorized";
import { ContractTabContext } from "../../Contract/ContractTabContext";
import Breadcrumbs from "../../Contract/Breadcrumbs";
import { mode } from "crypto-js";
import DataTable from "../../Tables/DataTable";

const RootActionController = () => {
  const { tabs, setTabs, activeKey, defaultBreadcrumbs, handleAddTab } =
    useContext(ContractTabContext);

  const history = useNavigate(); //react-router-dom navigate method
  const [form] = Form.useForm(); //custom hook for managing form
  const userData = useRecoilState(userDataAtom); //global state for getting logged-in user data
  // State variable to hold user role deta
  const roleUser = useRecoilState(RoleAccessAtom);
  const popupModalInstance = PopupModal(); //global popup modal
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom); //Global state for header
  let { state } = useLocation(); //state data which is sent from parent component
  if (state === null || state === undefined) {
    state = headerChanger.stateSetter;
  }
  dayjs.extend(utc);
  dayjs.extend(timezone);
  // useState hooks used in this component
  const [createModel, setCreateModel] = useState(false); //useState hook for open custom trigger create modal
  const [users, setUsers] = useState([]); //state for set all users in application
  const [groups, setGroups] = useState([]); //Stateto set all groups in application
  const [timeCount, setTimeCount] = useState(
    state &&
      state.actionData &&
      state.actionData.custom_repeat &&
      state.actionData.custom_repeat.time_count
      ? state.actionData.custom_repeat.time_count
      : 1
  ); // state for set custom trigger time-count
  const [unitofTime, setUnitofTime] = useState(
    state &&
      state.actionData &&
      state.actionData.custom_repeat &&
      state.actionData.custom_repeat.unit_of_time
      ? state.actionData.custom_repeat.unit_of_time
      : "Week"
  ); // state for set custom trigger unit-of-time
  const [selectedDays, setSelectedDays] = useState(
    state &&
      state.actionData &&
      state.actionData.custom_repeat &&
      state.actionData.custom_repeat.days &&
      state.actionData.custom_repeat.days > 0
      ? state.actionData.custom_repeat.days
      : ["Monday"]
  ); // state for set custom trigger selected days
  const [selectedDaysIndex, setSelectedDaysIndex] = useState([0]); // state for set custom trigger selected days corresponding index for css use
  const [customDates, setCustomDates] = useState({
    startDate:
      state &&
      state.actionData &&
      state.actionData.custom_repeat &&
      state.actionData.custom_repeat.start_date &&
      state.actionData.custom_repeat.start_date,
    endDate:
      state &&
      state.actionData &&
      state.actionData.custom_repeat &&
      state.actionData.custom_repeat.end_date &&
      state.actionData.custom_repeat.end_date,
  }); // state for set custom trigger start date and end date
  const [actionRepeat, setActionRepeat] = useState(
    state &&
      state.actionData &&
      state.actionData.action_repeat &&
      state.actionData.action_repeat
  ); // state for set action trigger type
  const [loader, setLoader] = useState(false); //submit loader on api integration
  const [saveTableLoader, setSaveTableLoader] = useState(false); //save loader on api integration
  const [stateButton, setStateButton] = useState(1); //state for define save and submit button loader
  const [customRepeat, setCustomRepeat] = useState({
    time_count:
      state &&
      state.actionData &&
      state.actionData.custom_repeat &&
      state.actionData.custom_repeat.time_count
        ? state.actionData.custom_repeat.time_count
        : 1,
    unit_of_time:
      state &&
      state.actionData &&
      state.actionData.custom_repeat &&
      state.actionData.custom_repeat.unit_of_time
        ? state.actionData.custom_repeat.unit_of_time
        : "Week",
    days:
      state &&
      state.actionData &&
      state.actionData.custom_repeat &&
      state.actionData.custom_repeat.days &&
      state.actionData.custom_repeat.days.length > 0
        ? state.actionData.custom_repeat.days
        : ["Monday"],
  }); // state for store all data regarding custom trigger
  const [actionDates, setActionDates] = useState({
    startDate:
      state &&
      state.actionData &&
      state.actionData.start_date &&
      state.actionData.start_date,
    endDate:
      state &&
      state.actionData &&
      state.actionData.end_date &&
      state.actionData.end_date,
    triggerDate:
      state &&
      state.actionData &&
      state.actionData.trigger_date &&
      state.actionData.trigger_date,
    dueDate:
      state &&
      state.actionData &&
      state.actionData.due_date &&
      dayjs.utc(state.actionData.due_date).format("DD MMM YYYY"),
  }); // state for store startdate, enddate, triggerdate and duedate of action
  const [dueIn, setDueIn] = useState(
    state &&
      state.actionData &&
      state.actionData.due_in &&
      state.actionData.due_in
  ); // state to hold value of trigger input
  const [errorMessage, setErrorMessage] = useState(""); // state to handle trigger input error message
  const [accessGranted, setAccessGranted] = useState();
  const [mainPageloading, setMainPageLoading] = useState(true); // Initialize main page loading state
  const [tabBreadcrumbs, setTabBreadcrumbs] = useState(state.breadcrumbs);
  const [createdId, setCreatedId] = useState("");
  const [tabKey, setTabKey] = useState("1"); //store active tab key
  const [dataSource, setDataSource] = useState([]); // datasource for action instance table
  const [tableLoader, setTableLoader] = useState(false); //action instance table loader

  useEffect(() => {
    const checkAccess = () => {
      const hasAccess =
        roleUser &&
        roleUser[0] &&
        roleUser[0].page_access.system_setup.supplier_setup.view;
      setAccessGranted(hasAccess);
      setMainPageLoading(false);
    };

    checkAccess();
  }, [roleUser]);

  let header = headerChanger.header; // assign header key to a variable
  const menuKey = "6"; //menu key of current page
  // form layout label and formitem alignment prop
  const formItemLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 18,
    },
  };
  const actionType = ["Obligation", "Performance", "Actions", "Issues"]; //action type dropdown options
  const actionCategory = ["Contractual", "Commercial", "Operational"]; //action category dropdown options
  const actionPriority = ["High", "Medium", "Low"]; //action priority dropdown options
  const days = ["M", "T", "W", "T", "F", "S", "S"]; //Days shortcut array for button in custom trigger
  const unitTime = [
    {
      label: "Week",
      key: "Week",
    },
    {
      label: "Month",
      key: "Month",
    },
    {
      label: "Year",
      key: "Year",
    },
  ]; //options for unit of time in custom trigger
  const items = [
    {
      key: "Dont Repeat",
      label: "Dont Repeat",
    },
    {
      key: "Daily",
      label: "Daily",
    },
    {
      key: "Weekly",
      label: "Weekly",
    },
    {
      key: "Monthly",
      label: "Monthly",
    },
    {
      key: "Yearly",
      label: "Yearly",
    },
    {
      key: "Custom",
      label: "Custom",
      onClick: () => {
        setCreateModel(true);
      },
    },
  ]; //menu items for action repeat dropdown

  useEffect(() => {
    console.log("state: ", state);

    console.log("mode: ", state.mode);
    console.log("breadcrumbs: ", state.breadcrumbs);

    if (state.mode.create) {
      setActionRepeat("Dont Repeat");
    } else {
      form.setFieldsValue({
        ...state.actionData,
        start_date: state.actionData.start_date
          ? dayjs.utc(state.actionData.start_date)
          : undefined,
        end_date: state.actionData.end_date
          ? dayjs.utc(state.actionData.end_date)
          : undefined,
        trigger_date: state.actionData.trigger_date
          ? dayjs.utc(state.actionData.trigger_date)
          : undefined,
      });
    }
  }, [state]);

  useEffect(() => {
    //   api integration for get all users in app
    getUsersApi().then((res) => {
      setUsers(res); // set response from api to 'users' state
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAllGroupApi().then((res) => {
      console.log("group-res", res);
      setGroups(res); //ser response from getgroupsapi to 'groups' state
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTabBreadcrumbs(state.breadcrumbs);
    // setting initial value for the state 'selectedDaysIndex' according to data from parent
    if (
      state &&
      state.actionData &&
      state.actionData.custom_repeat &&
      state.actionData.custom_repeat.days &&
      state.actionData.custom_repeat.days
    ) {
      // Define a mapping object
      const dayNumberMapping = {
        Monday: 0,
        Tuesday: 1,
        Wednesday: 2,
        Thursday: 3,
        Friday: 4,
        Saturday: 5,
        Sunday: 6,
      };
      // Create a new array by mapping the days to their corresponding numbers
      const resultDayIndex =
        state &&
        state.actionData &&
        state.actionData.custom_repeat &&
        state.actionData.custom_repeat.days &&
        state.actionData.custom_repeat.days.length > 0 &&
        state.actionData.custom_repeat.days.map((day) => dayNumberMapping[day]);
      setSelectedDaysIndex(resultDayIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    setTableLoader(true);
    if (!state.mode.create) {
      if (
        state &&
        state.actionData &&
        state.actionData.action_repeat &&
        state.actionData.action_repeat === "Don't Repeat"
      ) {
        let instanceData = [
          {
            iteration_no: 1,
            action_name: `${
              state &&
              state.actionData &&
              state.actionData.action_name &&
              state.actionData.action_name
            } - 1`,
            instance_status:
              state &&
              state.actionData &&
              state.actionData.action_instance &&
              state.actionData.action_instance.length === 0
                ? "Upcoming Action"
                : state &&
                  state.actionData &&
                  state.actionData.action_instance[0].instance_status &&
                  state.actionData.action_instance[0].instance_status,
            trigger_date:
              state &&
              state.actionData &&
              state.actionData.trigger_date &&
              dayjs.utc(state.actionData.trigger_date).format("DD MMM YYYY"),
            due_date:
              state &&
              state.actionData &&
              state.actionData.due_date &&
              dayjs.utc(state.actionData.due_date).format("DD MMM YYYY"),
            owner:
              state &&
              state.actionData &&
              state.actionData.action_owner_name &&
              state.actionData.action_owner_name,
          },
        ];
        setDataSource(instanceData);
        setTableLoader(false);
      }

      if (
        state &&
        state.actionData &&
        state.actionData.action_repeat &&
        state.actionData.action_repeat === "Custom"
      ) {
        console.log("custom instance");
        // const instanceData = [];
        // const {
        //   trigger_date,
        //   end_date,
        //   action_owner_name,
        //   due_in,
        //   custom_repeat,
        // } = state && state.actionData && state.actionData;
        // let iterationNo = 1;
        // let currentDate = new Date(trigger_date);

        // // function formatDate(date) {
        // //   const options = { day: "2-digit", month: "short", year: "numeric" };
        // //   return new Date(date).toLocaleDateString("en-US", options);
        // // }

        // // Function to add days to a date
        // const addDays = (date, days) => {
        //   const result = new Date(date);
        //   result.setDate(result.getDate() + days);
        //   return result;
        // };

        // // Function to add weeks to a date
        // const addWeeks = (date, weeks) => {
        //   const result = new Date(date);
        //   result.setDate(result.getDate() + weeks * 7);
        //   return result;
        // };

        // // Function to add months to a date
        // const addMonths = (date, months) => {
        //   const result = new Date(date);
        //   result.setMonth(result.getMonth() + months);
        //   return result;
        // };

        // // Function to add years to a date
        // const addYears = (date, years) => {
        //   const result = new Date(date);
        //   result.setFullYear(result.getFullYear() + years);
        //   return result;
        // };

        // while (currentDate <= new Date(end_date)) {
        //   const existingInstance =
        //     state &&
        //     state.actionData &&
        //     state.actionData.action_instance &&
        //     state.actionData.action_instance.find(
        //       (instance) => instance.iteration_no === iterationNo++
        //     );
        //   let instanceStatus = "Upcoming";
        //   if (existingInstance) {
        //     instanceStatus = existingInstance.instance_status;
        //   }

        //   custom_repeat.days.forEach((day) => {
        //     const dayIndex = [
        //       "Sunday",
        //       "Monday",
        //       "Tuesday",
        //       "Wednesday",
        //       "Thursday",
        //       "Friday",
        //       "Saturday",
        //     ].indexOf(day);
        //     const dayDifference = dayIndex - currentDate.getDay();
        //     let targetDate;

        //     if (dayDifference >= 0) {
        //       targetDate = addDays(currentDate, dayDifference);
        //     } else {
        //       targetDate = addDays(currentDate, dayDifference + 7);
        //     }

        //     if (targetDate <= new Date(end_date)) {
        //       instanceData.push({
        //         iteration_no: iterationNo,
        //         trigger_date: targetDate,
        //         due_date: addDays(targetDate, due_in),
        //         action_name: `${
        //           state &&
        //           state.actionData &&
        //           state.actionData.action_name &&
        //           state.actionData.action_name
        //         } - ${iterationNo}`,
        //         instance_status: instanceStatus,
        //         owner: action_owner_name,
        //       });
        //     }
        //     iterationNo++;
        //   });

        //   switch (custom_repeat.unit_of_time) {
        //     case "Week":
        //       currentDate = addWeeks(currentDate, custom_repeat.time_count);
        //       break;
        //     case "Month":
        //       currentDate = addMonths(currentDate, custom_repeat.time_count);
        //       break;
        //     case "Year":
        //       currentDate = addYears(currentDate, custom_repeat.time_count);
        //       break;
        //     default:
        //       break;
        //   }
        // }

        function generateIterations(input) {
          const instanceData = [];
          let iterationNo = 1;
          let currentDate = new Date(input.trigger_date);

          function formatDate(date) {
            const options = { day: "2-digit", month: "short", year: "numeric" };
            const formattedDate = new Date(date);
            formattedDate.setUTCDate(formattedDate.getUTCDate() - 1); // Subtract one day
            return formattedDate.toLocaleDateString("en-US", options);
          }

          // Function to add days to a date
          const addDays = (date, days) => {
            const result = new Date(date);
            result.setUTCDate(result.getUTCDate() + days);
            return result;
          };

          // Function to add weeks to a date
          const addWeeks = (date, weeks) => {
            const result = new Date(date);
            result.setUTCDate(result.getUTCDate() + weeks * 7);
            return result;
          };

          // Function to add months to a date
          const addMonths = (date, months) => {
            const result = new Date(date);
            result.setUTCMonth(result.getUTCMonth() + months);
            return result;
          };

          // Function to add years to a date
          const addYears = (date, years) => {
            const result = new Date(date);
            result.setUTCFullYear(result.getUTCFullYear() + years);
            return result;
          };

          while (currentDate <= new Date(input.end_date)) {
            const existingInstance =
              state &&
              state.actionData &&
              state.actionData.action_instance &&
              state.actionData.action_instance.find(
                (instance) => instance.iteration_no === iterationNo++
              );
            let instanceStatus = "Upcoming";
            if (existingInstance) {
              instanceStatus = existingInstance.instance_status;
            }

            input.custom_repeat.days.forEach((day) => {
              const dayIndex = [
                "Sunday",
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
              ].indexOf(day);
              const dayDifference = dayIndex - currentDate.getUTCDay();
              let targetDate;

              if (dayDifference >= 0) {
                targetDate = addDays(currentDate, dayDifference);
              } else {
                targetDate = addDays(currentDate, dayDifference + 7);
              }

              if (targetDate <= new Date(input.end_date)) {
                instanceData.push({
                  iteration_no: iterationNo,
                  trigger_date: formatDate(targetDate), // Convert to ISO string for consistent formatting
                  due_date: formatDate(addDays(targetDate, input.due_in)),
                  action_name: `${
                    state &&
                    state.actionData &&
                    state.actionData.action_name &&
                    state.actionData.action_name
                  } - ${iterationNo}`,
                  instance_status: instanceStatus,
                  owner: input.action_owner_name,
                });
                iterationNo++;
              }
            });

            switch (input.custom_repeat.unit_of_time) {
              case "Week":
                currentDate = addWeeks(
                  currentDate,
                  input.custom_repeat.time_count
                );
                break;
              case "Month":
                currentDate = addMonths(
                  currentDate,
                  input.custom_repeat.time_count
                );
                break;
              case "Year":
                currentDate = addYears(
                  currentDate,
                  input.custom_repeat.time_count
                );
                break;
              default:
                break;
            }
          }

          return instanceData;
        }

        // Example usage:
        const input = {
          start_date: "2024-05-08T18:30:00.000Z",
          end_date: "2024-12-31T18:30:00.000Z",
          due_in: 1,
          trigger_date: "2024-05-13T18:30:00.000Z",
          due_date: "2024-05-14T18:30:00.000Z",
          action_repeat: "Custom",
          custom_repeat: {
            time_count: "3",
            unit_of_time: "Week",
            days: ["Monday", "Wednesday"],
          },
        };

        const instanceData = generateIterations(
          state && state.actionData && state.actionData
        );

        console.log("instanceData", instanceData);
        setDataSource(instanceData);
        setTableLoader(false);
      } else {
        const instanceData = [];
        const { trigger_date, end_date, action_owner_name, due_in } =
          state && state.actionData && state.actionData;
        let currentTriggerDate = dayjs.utc(trigger_date);
        let iteration = 1;

        while (currentTriggerDate.isBefore(dayjs.utc(end_date).add(1, "day"))) {
          let dueIn = currentTriggerDate.clone().add(due_in, "days");

          const existingInstance =
            state &&
            state.actionData &&
            state.actionData.action_instance &&
            state.actionData.action_instance.find(
              (instance) => instance.iteration_no === iteration
            );
          let instanceStatus = "Upcoming";
          if (existingInstance) {
            instanceStatus = existingInstance.instance_status;
          }

          instanceData.push({
            iteration_no: iteration,
            trigger_date: dayjs
              .utc(currentTriggerDate.toISOString())
              .format("DD MMM YYYY"),
            due_date: dayjs.utc(dueIn.toISOString()).format("DD MMM YYYY"),
            action_name: `${
              state &&
              state.actionData &&
              state.actionData.action_name &&
              state.actionData.action_name
            } - ${iteration}`,
            instance_status: instanceStatus,
            owner: action_owner_name,
          });

          iteration++;
          currentTriggerDate =
            state &&
            state.actionData &&
            state.actionData.action_repeat &&
            state.actionData.action_repeat === "Daily"
              ? currentTriggerDate.clone().add(1, "day")
              : state &&
                state.actionData &&
                state.actionData.action_repeat &&
                state.actionData.action_repeat === "Yearly"
              ? currentTriggerDate.clone().add(1, "year")
              : state &&
                state.actionData &&
                state.actionData.action_repeat &&
                state.actionData.action_repeat === "Weekly"
              ? currentTriggerDate.clone().add(1, "week")
              : currentTriggerDate.clone().add(1, "month");
        }
        console.log("instanceData", instanceData);
        setDataSource(instanceData);
        setTableLoader(false);
      }
    }
  }, [state]);

  // function for handle time count change on custom trigger
  const onChangeCount = (item) => {
    setTimeCount(item); //set changed value in timeCount state
  };

  // function for handle unit of time change on custom trigger
  const onChangeUnitOfTime = (item) => {
    setUnitofTime(item); //set changed value in unitOfTime state
  };

  // function for handle selected days on custom trigger
  const onSelectDays = (index) => {
    let dayName =
      index === 0
        ? "Monday"
        : index === 1
        ? "Tuesday"
        : index === 2
        ? "Wednesday"
        : index === 3
        ? "Thursday"
        : index === 4
        ? "Friday"
        : index === 5
        ? "Saturday"
        : "Sunday"; // assign an index value for each days

    // if selectedDays state already have currently selected day then, remove it from selectedDays else push it to selectedDays
    if (selectedDays.includes(dayName)) {
      if (selectedDays.length > 1) {
        setSelectedDays(selectedDays.filter((days) => days !== dayName)); // remove
      }
    } else {
      setSelectedDays([...selectedDays, dayName]); //push
    }

    // if selectedDaysIndex state already have currently selected day's corresponding index value then, remove it from selectedDaysIndex else push it.
    if (selectedDaysIndex.includes(index)) {
      if (selectedDaysIndex.length > 1) {
        setSelectedDaysIndex(selectedDaysIndex.filter((ind) => ind !== index)); //remove
      }
    } else {
      setSelectedDaysIndex([...selectedDaysIndex, index]); //push
    }
  };

  // function for handle change of action repeat
  const onChangeActionRepeat = (item) => {
    setActionRepeat(item.key); //set changed value to actionRepeat state
  };

  // function for handle, close custom trigger modal
  const closeCustomTriggerModal = () => {
    // Define a mapping object
    const dayNumberMapping = {
      Monday: 0,
      Tuesday: 1,
      Wednesday: 2,
      Thursday: 3,
      Friday: 4,
      Saturday: 5,
      Sunday: 6,
    };
    // Create a new array by mapping the days to their corresponding numbers
    const resultDayIndex =
      customRepeat.days &&
      customRepeat.days.length > 0 &&
      customRepeat.days.map((day) => dayNumberMapping[day]);

    // if user click cancel after enter all data in custom trigger form, set all field values to it,s previous date.
    setCreateModel(false);
    setTimeCount(customRepeat.time_count);
    setUnitofTime(customRepeat.unit_of_time);
    setSelectedDays(customRepeat.days);
    setSelectedDaysIndex(resultDayIndex);
    setCustomDates({
      startDate: customRepeat.start_date,
      endDate: customRepeat.end_date,
    });
  };

  // function for handle, save custom trigger
  const saveCustomTrigger = () => {
    // if user click save data in custom trigger form, update all state's regarding to custom trigger.
    setCustomRepeat({
      ...customRepeat,
      time_count: timeCount,
      unit_of_time: unitofTime,
      days: selectedDays,
    });
    setCreateModel(false); // close modal after stored all data
  };

  // function for disable dates
  const disabledDate = (dateValue, field) => {
    // disable date in start-date datepicker
    if (field === "start_date") {
      if (!actionDates.endDate) {
        return dateValue && dateValue < moment().startOf("day");
      }
      const isAfterEndDate = dateValue.isAfter(actionDates.endDate, "day");
      const isPastDate = dateValue && dateValue < moment().startOf("day");
      return isAfterEndDate || isPastDate;
    }

    // disable date in end-date datepicker
    if (field === "end_date") {
      if (!actionDates.startDate) {
        return false;
      }
      return dateValue.isBefore(actionDates.startDate, "day");
    }

    // disable date in trigger-date datepicker
    // if (field === "trigger_before") {
    //   if (!actionDates.dueDate) {
    //     return false;
    //   }
    //   return dateValue.isAfter(actionDates.dueDate, "day");
    // }

    // disable date in due-date datepicker
    if (field === "trigger_date") {
      if (!actionDates.startDate || !actionDates.endDate) {
        return false;
      }
      const startDatePlusOne = moment(actionDates.startDate).add(1, "day");
      return (
        dateValue.isBefore(startDatePlusOne, "day") ||
        dateValue.isAfter(actionDates.endDate, "day")
      );
    }

    // disable date in custom-trigger-start-date datepicker
    // if (field === "custom_start_date") {
    //   if (!customDates.endDate) {
    //     return false;
    //   }
    //   return dateValue.isAfter(customDates.endDate, "day");
    // }

    // disable date in custom-trigger-end-date datepicker
    if (field === "custom_end_date") {
      if (!customDates.startDate) {
        return false;
      }
      return dateValue.isBefore(customDates.startDate, "day");
    }
  };

  // function for handle date change in all datepicker's in form
  const onDateChange = (date, dateString, field) => {
    // for start-date datepicker
    if (field === "start_date") {
      setActionDates({
        ...actionDates,
        startDate: dateString,
      });
    }

    // for end-date datepicker
    if (field === "end_date") {
      setActionDates({
        ...actionDates,
        endDate: dateString,
      });
    }

    // for trigger-date datepicker
    // if (field === "trigger_before") {
    //   setActionDates({
    //     ...actionDates,
    //     triggerBefore: dateString,
    //   });
    // }

    // for due-date datepicker
    if (field === "trigger_date") {
      setActionDates({
        ...actionDates,
        triggerDate: dateString,
      });
    }
  };

  // handle trigger_before input validate
  const handleTriggerChange = (e) => {
    const pattern = /^(?:[1-9]|[12]\d|3[01])$/; // Regex pattern to allow numbers from 1 to 31
    if (pattern.test(e) || e === "") {
      setDueIn(e);
      setErrorMessage("");
      let due_date = moment(new Date(actionDates.triggerDate))
        .clone()
        .add(e, "day");
      setActionDates({
        ...actionDates,
        dueDate: moment(due_date).format("DD MMM YYYY"),
      });
    } else {
      setErrorMessage("Please enter a number between 1 and 31");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const rootActionForm = () => {
    return (
      <>
        {/* Main form content */}
        <div
          className="action-div-head mt-10"
          style={state.mode.view ? { height: "calc(100vh - 205px)" } : {}}
        >
          <div className="contract-main-div">
            <div className="edit-invoice-entity-div">
              {/* Basic */}
              <div
                className="action-body-head"
                style={{ textAlign: "left", color: "black" }}
              >
                <div className="contract-main-div ">
                  <div
                    className="edit-invoice-entity-div"
                    style={{ marginTop: 30 }}
                  >
                    <h4 className="access-title">Basic</h4>
                    <div className="action-new-div mt-20 m-10">
                      <div className="create-entity-div">
                        <Row
                          align="middle"
                          type="flex"
                          gutter={[24, 16]}
                          className="m-0"
                        >
                          <Col span={24}>
                            <div className="create-supplier-div m-10">
                              <Row align="middle" type="flex">
                                {/* Action Name */}
                                <Col span={6}>
                                  <Form.Item
                                    {...formItemLayout}
                                    className="org-supplier-label"
                                    name="action_name"
                                    label="Action Name"
                                    colon={false}
                                    rules={[
                                      {
                                        required: true,
                                        message: `Action Name is required`,
                                        pattern: /^(?!\s*$).+/,
                                      },
                                    ]}
                                  >
                                    <Input placeholder="Enter Action Name" />
                                  </Form.Item>
                                </Col>

                                {/* Action Description */}
                                <Col span={14}>
                                  <Form.Item
                                    {...formItemLayout}
                                    className="org-supplier-label"
                                    name="action_description"
                                    label="Description"
                                    colon={false}
                                    rules={[
                                      {
                                        required:
                                          stateButton === 2 ? true : false,
                                        message: `Description is required`,
                                        pattern: /^(?!\s*$).+/,
                                      },
                                    ]}
                                  >
                                    <Input placeholder="Enter your description" />
                                  </Form.Item>
                                </Col>
                              </Row>

                              <Row align="middle" type="flex">
                                {/* Action Category */}
                                <Col span={6}>
                                  <Form.Item
                                    {...formItemLayout}
                                    className="org-supplier-label"
                                    name="action_category"
                                    label="Category"
                                    colon={false}
                                    rules={[
                                      {
                                        required:
                                          stateButton === 2 ? true : false,
                                        message: `Please Select Category`,
                                      },
                                    ]}
                                  >
                                    <Select
                                      allowClear
                                      showSearch={true}
                                      placeholder="Choose category"
                                      options={
                                        actionCategory &&
                                        actionCategory.map((data) => ({
                                          key: data,
                                          label: data,
                                          value: data,
                                        }))
                                      }
                                    ></Select>
                                  </Form.Item>
                                </Col>

                                {/* Action Type */}
                                <Col span={6}>
                                  <Form.Item
                                    {...formItemLayout}
                                    className="org-supplier-label"
                                    name="action_type"
                                    label="Type"
                                    colon={false}
                                    rules={[
                                      {
                                        required:
                                          stateButton === 2 ? true : false,
                                        message: `Please Select Action Type`,
                                      },
                                    ]}
                                  >
                                    <Select
                                      allowClear
                                      showSearch={true}
                                      placeholder="Choose Type"
                                      options={
                                        actionType &&
                                        actionType.map((data) => ({
                                          key: data,
                                          label: data,
                                          value: data,
                                        }))
                                      }
                                    ></Select>
                                  </Form.Item>
                                </Col>

                                {/* Action Priority */}
                                <Col span={6}>
                                  <Form.Item
                                    {...formItemLayout}
                                    className="org-supplier-label"
                                    name="action_priority"
                                    label="Priority"
                                    colon={false}
                                    rules={[
                                      {
                                        required:
                                          stateButton === 2 ? true : false,
                                        message: `Please Select Priority`,
                                      },
                                    ]}
                                  >
                                    <Select
                                      allowClear
                                      showSearch={true}
                                      placeholder="Choose Priority"
                                      options={
                                        actionPriority &&
                                        actionPriority.map((data) => ({
                                          key: data,
                                          label: data,
                                          value: data,
                                        }))
                                      }
                                    ></Select>
                                  </Form.Item>
                                </Col>
                              </Row>

                              <Row align="middle" type="flex">
                                {/* Action Start Date */}
                                <Col span={6}>
                                  <Form.Item
                                    {...formItemLayout}
                                    className="org-supplier-label"
                                    name="start_date"
                                    label="Start Date"
                                    colon={false}
                                    rules={[
                                      {
                                        required:
                                          stateButton === 2 ? true : false,
                                        message: `Please Select Start Date`,
                                      },
                                    ]}
                                  >
                                    <DatePicker
                                      disabledDate={(dateValue) =>
                                        dateValue.isBefore(
                                          dayjs().startOf("day"),
                                          "day"
                                        )
                                      }
                                      onChange={(date, dateString) => {
                                        setActionDates((prev) => ({
                                          ...prev,
                                          startDate: dateString,
                                          endDate: undefined,
                                          triggerDate: undefined,
                                          dueDate: undefined,
                                        }));
                                        setDueIn();
                                        form.resetFields([
                                          "end_date",
                                          "trigger_date",
                                          "due_in",
                                          "due_date",
                                        ]);
                                      }}
                                      format={"DD MMM YYYY"}
                                      className="report-input"
                                      placeholder="Select Start Date"
                                    />
                                  </Form.Item>
                                </Col>

                                {/* Action end Date */}
                                <Col span={6}>
                                  <Form.Item
                                    {...formItemLayout}
                                    className="org-supplier-label"
                                    name="end_date"
                                    label="End Date"
                                    colon={false}
                                    rules={[
                                      {
                                        required:
                                          stateButton === 2 ? true : false,
                                        message: `Please Select End Date`,
                                      },
                                    ]}
                                  >
                                    <DatePicker
                                      disabled={!actionDates.startDate}
                                      disabledDate={(current) => {
                                        if (
                                          !current ||
                                          !actionDates.startDate
                                        ) {
                                          return false;
                                        }
                                        const minDate = moment(
                                          actionDates.startDate
                                        ).add(1, "day");

                                        return current.isBefore(minDate, "day");
                                      }}
                                      onChange={(date, dateString) => {
                                        setActionDates((prev) => ({
                                          ...prev,
                                          endDate: dateString,
                                          triggerDate: undefined,
                                          dueDate: undefined,
                                        }));
                                        setDueIn();
                                        form.resetFields([
                                          "trigger_date",
                                          "due_in",
                                          "due_date",
                                        ]);
                                      }}
                                      format={"DD MMM YYYY"}
                                      className="report-input"
                                      placeholder="Select End Date"
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Trigger & Repeat */}
              <div
                className="action-body-head"
                style={{ textAlign: "left", color: "black" }}
              >
                <div className="contract-main-div ">
                  <div
                    className="edit-invoice-entity-div"
                    style={{ marginTop: 30 }}
                  >
                    <h4 className="access-title">Trigger & Repeat</h4>
                    <div className="action-new-div mt-20 m-10">
                      <div className="create-entity-div">
                        <Row
                          align="middle"
                          type="flex"
                          gutter={[24, 16]}
                          className="m-0"
                        >
                          <Col span={24}>
                            <div className="create-supplier-div m-10">
                              <Row align="middle" type="flex">
                                {/* Action Trigger Date */}
                                <Col span={6}>
                                  <Form.Item
                                    {...formItemLayout}
                                    className="org-supplier-label"
                                    name="trigger_date"
                                    label="Trigger Date"
                                    colon={false}
                                    rules={[
                                      {
                                        required:
                                          stateButton === 2 ? true : false,
                                        message: `Please Select Trigger Date`,
                                      },
                                    ]}
                                  >
                                    <DatePicker
                                      disabled={
                                        !actionDates.startDate ||
                                        !actionDates.endDate
                                      }
                                      disabledDate={(dateValue) =>
                                        disabledDate(dateValue, "trigger_date")
                                      }
                                      onChange={(date, dateString) =>
                                        onDateChange(
                                          date,
                                          dateString,
                                          "trigger_date"
                                        )
                                      }
                                      format={"DD MMM YYYY"}
                                      className="report-input"
                                      placeholder="Select Trigger Date"
                                    />
                                  </Form.Item>
                                </Col>
                                {/* Action Repeat */}
                                <Col span={6}>
                                  <Form.Item
                                    {...formItemLayout}
                                    className="org-supplier-label"
                                    name="action_repeat"
                                    label="Action Repeat"
                                    colon={false}
                                  >
                                    <Dropdown
                                      disabled={!actionDates.triggerDate}
                                      trigger={["click"]}
                                      menu={{
                                        items,
                                        selectable: true,
                                        defaultSelectedKeys: [actionRepeat],
                                        onClick: (item) =>
                                          onChangeActionRepeat(item),
                                        className: "trigger-dropdown",
                                      }}
                                      placement="bottomLeft"
                                    >
                                      <Button
                                        type="primary"
                                        shape="round"
                                        style={{
                                          width: "100%",
                                          fontSize: 13,
                                          fontWeight: 600,
                                          color: "var(--color-solid-white)",
                                        }}
                                      >
                                        {actionRepeat}
                                      </Button>
                                    </Dropdown>
                                  </Form.Item>

                                  {/* Modal for custom trigger */}
                                  {createModel && (
                                    <Modal
                                      maskClosable={false}
                                      closeIcon={false}
                                      className="create-wf-modal"
                                      footer={null}
                                      open={createModel}
                                      onCancel={() => {
                                        setCreateModel(false);
                                      }}
                                      width={450}
                                    >
                                      {" "}
                                      <div>
                                        <Col span={24}>
                                          <div>
                                            <Space align="">
                                              <Image
                                                src={repeat}
                                                preview={false}
                                              />
                                              <div>
                                                <h3>Repeat</h3>
                                                <span
                                                  style={{
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  For Repeat the Action Instance
                                                </span>
                                              </div>
                                            </Space>

                                            <div className="mt-15">
                                              <Row>
                                                <Col span={16} offset={4}>
                                                  <>
                                                    <Image
                                                      src={repeat_small}
                                                      preview={false}
                                                    />
                                                    &nbsp;&nbsp;
                                                    <Space>
                                                      Repeat &nbsp;every &nbsp;
                                                    </Space>
                                                    {/* Time count select dropdown */}
                                                    <Select
                                                      className="custom-trigger-select"
                                                      suffixIcon={
                                                        <CaretDownOutlined
                                                          style={{
                                                            color: "black",
                                                          }}
                                                        />
                                                      }
                                                      onChange={(value) =>
                                                        onChangeCount(value)
                                                      }
                                                      defaultValue={timeCount}
                                                      showSearch={true}
                                                      options={Array.from(
                                                        { length: 99 },
                                                        (_, index) => ({
                                                          label: (
                                                            index + 1
                                                          ).toString(),
                                                          key: (
                                                            index + 1
                                                          ).toString(),
                                                          value: (
                                                            index + 1
                                                          ).toString(),
                                                        })
                                                      )}
                                                    ></Select>
                                                    &nbsp;
                                                    {/* Unit of time select dropdown */}
                                                    <Select
                                                      className="custom-trigger-select"
                                                      suffixIcon={
                                                        <CaretDownOutlined
                                                          style={{
                                                            color: "black",
                                                          }}
                                                        />
                                                      }
                                                      defaultValue={unitofTime}
                                                      onChange={(value) =>
                                                        onChangeUnitOfTime(
                                                          value
                                                        )
                                                      }
                                                      showSearch={true}
                                                      options={
                                                        unitTime &&
                                                        unitTime.map(
                                                          (data) => ({
                                                            key: data.key,
                                                            label: data.label,
                                                            value: data.key,
                                                          })
                                                        )
                                                      }
                                                    ></Select>
                                                  </>
                                                </Col>
                                              </Row>

                                              {/* Days select Button's */}
                                              <Row>
                                                <Col span={16} offset={4}>
                                                  <div
                                                    className="mt-10"
                                                    style={{
                                                      display: "flex",
                                                      justifyContent:
                                                        "space-between",
                                                    }}
                                                  >
                                                    {days &&
                                                      days.length > 0 &&
                                                      days.map((day, index) => {
                                                        return (
                                                          <Button
                                                            onClick={() =>
                                                              onSelectDays(
                                                                index
                                                              )
                                                            }
                                                            type="primary"
                                                            style={{
                                                              cursor: "pointer",
                                                              height: 30,
                                                              width: 30,
                                                              padding: 0,
                                                              backgroundColor:
                                                                selectedDaysIndex.includes(
                                                                  index
                                                                )
                                                                  ? "var(--color-solid-darkblue)"
                                                                  : "grey",
                                                              marginRight: 5,
                                                              boxShadow: "none",
                                                            }}
                                                          >
                                                            {day}
                                                          </Button>
                                                        );
                                                      })}
                                                  </div>
                                                </Col>
                                              </Row>

                                              {/* Row for render selected days */}
                                              <Row className="mt-10">
                                                <Col
                                                  span={16}
                                                  offset={4}
                                                  className="text-center"
                                                  style={{
                                                    fontWeight: "600",
                                                  }}
                                                >
                                                  Occurs Every{" "}
                                                  {selectedDays &&
                                                    selectedDays.length > 0 &&
                                                    selectedDays.map(
                                                      (day, index) => {
                                                        return (
                                                          <>
                                                            <span>{day}</span>
                                                            <span>
                                                              {selectedDays.length -
                                                                1 !==
                                                              index
                                                                ? ", "
                                                                : ""}
                                                            </span>
                                                          </>
                                                        );
                                                      }
                                                    )}
                                                </Col>
                                              </Row>
                                              <Row
                                                gutter={[16, 8]}
                                                className="mt-10"
                                              >
                                                <Col
                                                  span={16}
                                                  offset={4}
                                                  // className="text-center"
                                                >
                                                  <Row
                                                    type="flex"
                                                    align={"middle"}
                                                  >
                                                    <Col span={11} offset={1}>
                                                      {/* Cancel button */}
                                                      <CancelButton
                                                        onClick={
                                                          closeCustomTriggerModal
                                                        }
                                                      />
                                                    </Col>
                                                    <Col span={11} offset={1}>
                                                      {/* Save custom trigger button */}
                                                      <SaveButton
                                                        onClick={
                                                          saveCustomTrigger
                                                        }
                                                      />
                                                    </Col>
                                                  </Row>
                                                </Col>
                                              </Row>
                                            </div>
                                          </div>
                                        </Col>
                                      </div>
                                    </Modal>
                                  )}
                                </Col>
                              </Row>
                              <Row align="middle" type="flex">
                                {/* Action Due In */}
                                <Col span={6}>
                                  <Form.Item
                                    {...formItemLayout}
                                    className="org-supplier-label"
                                    name="due_in"
                                    label="Due In"
                                    colon={false}
                                  >
                                    <InputNumber
                                      disabled={!actionDates.triggerDate}
                                      onChange={(e) => handleTriggerChange(e)}
                                      style={{ width: "100%" }}
                                      type="number"
                                      placeholder="Enter a number"
                                      // max={31}
                                      min={1}
                                      className=""
                                      size="large"
                                      defaultValue={dueIn}
                                    />
                                    {errorMessage && (
                                      <div style={{ color: "red" }}>
                                        {errorMessage}
                                      </div>
                                    )}
                                  </Form.Item>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    {...formItemLayout}
                                    className="org-supplier-label"
                                    label="Due Date"
                                    colon={false}
                                  >
                                    <Input
                                      disabled={!actionDates.triggerDate}
                                      readOnly
                                      value={actionDates.dueDate}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Assignment */}
              <div
                className="action-body-head "
                style={{ textAlign: "left", color: "black" }}
              >
                <div className="contract-main-div ">
                  <div
                    className="edit-invoice-entity-div"
                    style={{ marginTop: 30 }}
                  >
                    <h4 className="access-title">Assignment</h4>
                    <div className="action-new-div mt-20 m-10 mb-10">
                      <div className="create-entity-div">
                        <Row
                          align="middle"
                          type="flex"
                          gutter={[24, 16]}
                          className="m-0"
                        >
                          <Col span={24}>
                            <div className="create-supplier-div m-10">
                              <Row align="middle" type="flex">
                                {/* Action Owner */}
                                <Col span={6}>
                                  <Form.Item
                                    {...formItemLayout}
                                    className="org-supplier-label"
                                    name="action_owner"
                                    label="Action Owner"
                                    colon={false}
                                    rules={[
                                      {
                                        required:
                                          stateButton === 2 ? true : false,
                                        message: `Please Select Action Owner`,
                                      },
                                    ]}
                                  >
                                    <Select
                                      allowClear
                                      showSearch={true}
                                      placeholder="Choose category"
                                      options={[
                                        ...(users &&
                                          users
                                            .filter(
                                              (filData) =>
                                                filData.roleData.page_access
                                                  .action_management.root_action
                                                  .create
                                            )
                                            .map((user) => ({
                                              key: user._id,
                                              label: `${user.first_name} ${user.last_name}`,
                                              value: user._id,
                                            }))),
                                        ...(groups &&
                                          groups.map((group) => ({
                                            key: group._id,
                                            label: group.group_name,
                                            value: group._id,
                                          }))),
                                      ]}
                                    ></Select>
                                  </Form.Item>
                                </Col>

                                {/* Action Approver */}
                                <Col span={6}>
                                  <Form.Item
                                    {...formItemLayout}
                                    className="org-supplier-label"
                                    name="action_approver"
                                    label="Action Approver"
                                    colon={false}
                                    rules={[
                                      {
                                        required:
                                          stateButton === 2 ? true : false,
                                        message: `Please Select Action Approver`,
                                      },
                                    ]}
                                  >
                                    <Select
                                      allowClear
                                      showSearch={true}
                                      placeholder="Choose category"
                                      options={[
                                        ...(users &&
                                          users
                                            .filter(
                                              (filData) =>
                                                filData.roleData.page_access
                                                  .action_management.root_action
                                                  .create
                                            )
                                            .map((user) => ({
                                              key: user._id,
                                              label: `${user.first_name} ${user.last_name}`,
                                              value: user._id,
                                            }))),
                                        ...(groups &&
                                          groups.map((group) => ({
                                            key: group._id,
                                            label: group.group_name,
                                            value: group._id,
                                          }))),
                                      ]}
                                    ></Select>
                                  </Form.Item>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const tabItems = [
    {
      key: "1",
      label: "Root Action",
    },
    {
      key: "2",
      label: "Action Instance",
    },
    {
      key: "3",
      label: "Entity Log",
    },
  ];

  // action instance table column
  const columns = [
    {
      title: "Iteration No",
      dataIndex: "iteration_no",
      key: "iteration_no",
    },
    {
      title: "Name",
      dataIndex: "action_name",
      key: "action_name",
    },
    {
      title: "Status",
      dataIndex: "instance_status",
      key: "instance_status",
    },
    {
      title: "Trigger Date",
      dataIndex: "trigger_date",
      key: "trigger_date",
    },
    {
      title: "Due Date",
      dataIndex: "due_date",
      key: "due_date",
    },
    {
      title: "Owner",
      dataIndex: "owner",
      key: "owner",
    },
  ];

  // function for handle on tab change
  const onChangeTabs = (key) => {
    setTabKey(key);
  };

  const createActivityLog = (response, values, notes) => {
    const logEntry = {
      ss_id: response.id ? response.id : response._id,
      ss_name: values["action_name"],
      code: "root_action",
      author: userData[0].id ? userData[0].id : userData[0]._id,
      action: "view",
      notes: notes,
      updated_on: new Date(),
    };

    // API call to create activity log
    createActivityLogApi(logEntry);
  };

  const prepareValues = (state, userData, actionDates, otherFields) => {
    const baseValues = {
      ...otherFields,
      action_repeat: actionRepeat,
      custom_repeat:
        actionRepeat !== "Custom"
          ? {}
          : {
              time_count: timeCount,
              unit_of_time: unitofTime,
              days: selectedDays,
            },
      due_in: dueIn ? Number(dueIn) : undefined,
      due_date: actionDates.dueDate ? dayjs(actionDates.dueDate) : undefined,
      contract_id: state.mode.create
        ? state && state.selectedContract.id
        : state.mode.edit || state.mode.clone
        ? state.actionData.contract_id
        : undefined,
      supplier_id: state.mode.create
        ? state && state.selectedContract.supplier
        : state.mode.edit || state.mode.clone
        ? state.actionData.supplier_id
        : undefined,
      action_level: state.mode.create
        ? state && state.selectedContract.type
        : state.mode.edit || state.mode.clone
        ? state.actionData.action_level
        : undefined,
      updated_by: userData[0].id ? userData[0].id : userData[0]._id,
      updated_on: new Date(),
      created_by:
        userData && userData[0] && userData[0].id
          ? userData[0].id
          : userData[0]._id
          ? userData[0]._id
          : "",
      created_on: state.mode.create
        ? new Date()
        : (state.mode.edit || state.mode.clone) &&
          state.actionData &&
          (state.actionData.createdAt || state.actionData.created_on)
        ? state.actionData.createdAt || state.actionData.created_on
        : undefined,
    };

    return baseValues;
  };

  const onFinish = async (values) => {
    const formValues = prepareValues(state, userData, actionDates, {
      ...values,
    });

    console.log("formValues", formValues);

    if (stateButton === 1) {
      // Save as Draft
      formValues["action_status"] = "Draft";
      setSaveTableLoader(true);
      if (state.mode.create || state.mode.clone) {
        if (createdId === "") {
          await createRootActionAPi(formValues).then((res) => {
            console.log("createRes: ", res);

            setCreatedId(res._id || res.id);
            createActivityLog(res, formValues, "Created Root Action as Draft");
            setSaveTableLoader(false);
            popupModalInstance.successModal(
              formValues.action_name,
              ModalMessage.RA_Create_Save,
              ``
            );
          });
        } else {
          await editRootActionAPi(createdId, formValues).then((res) => {
            createActivityLog(
              res,
              formValues,
              "Updated Root Action & Saved As Draft"
            );
            setSaveTableLoader(false);
            popupModalInstance.successModal(
              formValues.action_name,
              ModalMessage.RA_Edit_Save,
              ``
            );
          });
        }
      } else if (state.mode.edit) {
        await editRootActionAPi(state.actionData._id, formValues).then(
          (res) => {
            createActivityLog(
              res,
              formValues,
              "Updated Root Action & Saved As Draft"
            );
            setSaveTableLoader(false);
            popupModalInstance.successModal(
              formValues.action_name,
              ModalMessage.RA_Edit_Save,
              ``
            );
          }
        );
      }
    }

    if (stateButton === 2) {
      // Submit as Active
      formValues["action_status"] = "Active";
      setLoader(true);

      if (state.mode.create || state.mode.clone) {
        if (createdId == "") {
          popupModalInstance
            .infoModal(ModalMessage.RA_Create_Submit)
            .then(async (userChoice) => {
              if (userChoice === "ok") {
                await createRootActionAPi(formValues).then((res) => {
                  createActivityLog(res, formValues, "Created Root Action");
                  setLoader(false);
                  history(`/action-management`, {
                    state: {
                      finalPath: state && state.finalPath,
                      selectedContract: state && state.selectedContract,
                    },
                  });
                });
              } else if (userChoice === "cancel") {
                setLoader(false);
              }
            });
        } else {
          popupModalInstance
            .infoModal(ModalMessage.RA_Create_Submit)
            .then(async (userChoice) => {
              if (userChoice === "ok") {
                await editRootActionAPi(createdId, formValues).then((res) => {
                  createActivityLog(res, formValues, "Root Action Submitted");
                  setLoader(false);
                  history(`/action-management`);
                });
              } else if (userChoice === "cancel") {
                setLoader(false);
              }
            });
        }
      } else if (state.mode.edit) {
        popupModalInstance
          .infoModal(ModalMessage.RA_Edit_Submit)
          .then(async (userChoice) => {
            if (userChoice === "ok") {
              await editRootActionAPi(state.actionData._id, formValues).then(
                (res) => {
                  createActivityLog(res, formValues, "Root Action Submitted");
                  setLoader(false);
                  history(`/action-management`);
                }
              );
            } else if (userChoice === "cancel") {
              setLoader(false);
            }
          });
      }
    }
  };

  //error on form submit
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Layout className="header-layout">
      {/* Header for component */}
      <TopHeader
        headerChange={header}
        feedRow={false}
        searchRow={false}
        menuKey={menuKey}
        sticky=""
      />
      {/* Edit Root Action form */}
      {mainPageloading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh - 60px)",
          }}
        >
          <LoadingOutlined
            style={{
              fontSize: 50,
              color: "var(--color-solid-darkerblue)",
            }}
            spin
          />
        </div>
      ) : accessGranted ? (
        <Form
          form={form}
          onFinish={onFinish}
          onKeyDown={handleKeyDown}
          onFinishFailed={onFinishFailed}
        >
          <div id="action-management-id" className="contract-pipeline">
            <Row align="middle" type="flex" gutter={[8, 0]}>
              {/* Breadcrumb for define action level */}
              <Col span={state.mode.view ? 24 : 18}>
                <Card
                  id="breadcrumb-card-id"
                  rootClassName="breadcrumb-card"
                  bordered={false}
                >
                  <Row align="middle" type="flex" gutter={[8, 16]}>
                    <Col span={1}>
                      <Image
                        src={arrow_left}
                        style={{ width: 25 }}
                        preview={false}
                        className="cursor-pointer"
                        onClick={() => history(`/action-management`)}
                      />
                    </Col>
                    <Col span={22}>
                      <Breadcrumbs
                        tabBreadcrumbs={tabBreadcrumbs}
                        setTabBreadcrumbs={setTabBreadcrumbs}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
              {!state.mode.view && (
                <>
                  {/* Cancel button */}
                  <Col xl={2} md={4} xs={24} className="vertical-align">
                    <div className="">
                      <CancelButton
                        onClick={() => history(`/action-management`)}
                      />
                    </div>
                  </Col>

                  {/* Save Button */}
                  <Col xl={2} md={4} xs={24} className="vertical-align">
                    <div className="">
                      <SaveButton
                        htmlType="submit"
                        onClick={() => setStateButton(1)}
                        loading={saveTableLoader}
                      />
                    </div>
                  </Col>

                  {/* Ok Button */}
                  <Col xl={2} md={4} xs={24} className="vertical-align">
                    <div className="">
                      <OkButton
                        htmlType="submit"
                        onClick={() => setStateButton(2)}
                        loading={loader}
                      />
                    </div>
                  </Col>
                </>
              )}
            </Row>
            {state.mode.view ? (
              <>
                {/* Tab for view root action and action instance */}
                <Tabs
                  className="action-instance-tab mt-10"
                  defaultActiveKey="1"
                  items={tabItems}
                  onChange={onChangeTabs}
                />
                {/* Root action tab content */}
                {tabKey === "1" && rootActionForm()}
                {/* Action instance tab content */}
                {tabKey === "2" && (
                  <div className="action-div-head-view-instance">
                    <Row className="mt-10">
                      <DataTable
                        type="root-action"
                        dataTableData={dataSource}
                        colData={columns}
                        loading={tableLoader}
                      />
                    </Row>
                  </div>
                )}
                {/* Entity Log table */}
                {tabKey === "3" && (
                  <div className="action-div-head-view-instance">
                    <Row className="mt-10">
                      <DataTable type="entityLog" logData={state.actionData} />
                    </Row>
                  </div>
                )}
              </>
            ) : (
              rootActionForm()
            )}
          </div>
        </Form>
      ) : (
        <NotAuthorized redirect={`/action-management`} />
      )}
    </Layout>
  );
};

export default RootActionController;
