import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
import CryptoJS from "crypto-js";
import key from "./AtomAndSelectorKeys";
import { sessionStorageEffect } from "../utils/common";

const { persistAtom } = recoilPersist();

const SECRET_KEY = "mysecretkey";

const decryptAndRetrieveUserData = () => {
  const encryptedUserData = sessionStorage.getItem("headerKey");

  if (encryptedUserData) {
    // Decrypt the data
    const bytes = CryptoJS.AES.decrypt(encryptedUserData, SECRET_KEY);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
    // Use the decrypted data (for example, set it in state)
    // setGetUser(decryptedData);
  }
};

const test = atom({
  key: "test",
  default: {},
});

const HeaderChangerAtom = atom({
  key: key.HEADERCHANGER_UPDATE,
  default: sessionStorage.getItem("headerKey")
    ? // ? JSON.parse(sessionStorage.getItem("headerKey"))
      decryptAndRetrieveUserData()
    : {
        header: "home",
        headerItem: "1",
        headerItemName: "home",
        stateSetter: {},
      },
});

const TabChangerAtom = atom({
  key: key.TABCHANGER_UPDATE,
  default: 0,
  effects_UNSTABLE: [persistAtom],
});

const NotificationListAtom = atom({
  key: key.NOTIFICATIONLIST_UPDATE,
  default: [],
  effects_UNSTABLE: [persistAtom],
});

const NotificationUnreadAtom = atom({
  key: key.NOTIFICATIONUNREAD_UPDATE,
  default: 0,
  effects_UNSTABLE: [persistAtom],
});

const SpendOverviewUpdateAtom = atom({
  key: key.SPENDOVERVIEW_UPDATE,
  default: {
    selectedCol: ["Contract", "CYV", "TCV", "Start Date", "End Date"],
    unSelectedCol: [],
  },
});

const ContractSetUpUpdateAtom = atom({
  key: key.CONTRACTSETUP_UPDATE,
  default: {
    selectedCol: ["Contract", "CYV", "TCV", "Start Date", "End Date"],
    unSelectedCol: [],
  },
});

const InvoicingViewAtom = atom({
  key: key.INVOICINGVIEW_UPDATE,
  default: {
    selectedCol: [
      "Currency",
      "Invoice",
      "PO#",
      "Year",
      "Month",
      "Value",
      "Eur Value",
    ],
    unSelectedCol: [],
  },
});

const SupplierSetupUpdateAtom = atom({
  key: key.SUPPLIERSETUP_UPDATE,
  default: {
    selectedCol: [
      "Supplier ID",
      "Supplier Name",
      "Supplier Name Short",
      "Supplier Entity",
      "Supplier Status",
      "Supplier Country",
      "Supplier Address",
      "Created On",
      "Created By",
      "Updated On",
      "Updated By",
    ],
    unSelectedCol: [],
  },
});

const LegalEntityUpdateAtom = atom({
  key: key.LEGALENTITY_UPDATE,
  default: {
    selectedCol: [],
    unSelectedCol: [
      "Billing Currency",
      "Billing From Entity",
      "Billing To Entity",
      "Billing Address",
      "Client Company Code",
      "Budget Approver L1",
      "Budget Approver L2",
      "Budget Approver L3",
    ],
  },
});

const UserTokensAtom = atom({
  key: key.USER_TOKENS,
  default: {},
  effects_UNSTABLE: [sessionStorageEffect(key.USER_TOKENS)],
});

const SupplierTreeDataAtom = atom({
  key: key.SUPPLIER_TREE_DATA,
  default: [],
});

const ContractTreeUpdateAtom = atom({
  key: key.CONTRACT_TREE_UPDATE,
  default: {
    selectedCol: ["Name", "Contract Type", "Status", "Created Date"],
    unSelectedCol: [],
  },
});

const ContractPipelineUpdateAtom = atom({
  key: key.CONTRACT_PIPELINE_UPDATE,
  default: {
    selectedCol: [
      "Contract Name",
      "Contract Type",
      "Supplier Name",
      "Contract Status",
      "Created On",
      "Action Owner",
    ],
    unSelectedCol: [
      // "Contract Value ACV",
      // "Contract Value TCV",
      // "Billing Profile",
      // "Budget Approver",
      // "Contract Currency",
      // "Current Owner",
      "Created By",
      // "Demand Details",
      "Contract Start Date",
      "Contract End Date",
      // "For Legal Entity",
      "Parent CID",
      "Parent Type",
      // "Service Manager",
      "Signed On",
      // "System Currency",
      "Workflow Status",
    ],
  },
});

const RoleAccessAtom = atom({
  key: key.ROLE_ACCESS_DATA,
  default: {},
  effects_UNSTABLE: [persistAtom],
});

const userDataAtom = atom({
  key: key.USER_DATA,
  default: {},
  effects_UNSTABLE: [persistAtom],
});

const DashboardAtom = atom({
  key: key.DASHBOARD_DATA,
  default: {},
});

const ContractAddTabAtom = atom({
  key: key.CONTRACTADDTAB_UPDATE,
  default:
    sessionStorage.getItem("contractTab") &&
    sessionStorage.getItem("contractTab")
      ? JSON.parse(sessionStorage.getItem("contractTab"))
      : [],
});

// to store group name
const GroupNameAtom = atom({
  key: key.GROUP_NAME,
  default: {},
});

// organizational setup name
const SupplierNameAtom = atom({
  key: key.SUPPLIER_NAME,
  default: {},
});

const LegalEntityAtom = atom({
  key: key.LEGAL_ENTITY_NAME,
  default: {},
});

const CostCenterAtom = atom({
  key: key.COST_CENTER_NAME,
  default: {},
});

// contract setup name
const RateCardAtom = atom({
  key: key.RATECARD_NAME,
  default: {},
});

const FxAtom = atom({
  key: key.FX_NAME,
  default: {},
});

const PricingProfileAtom = atom({
  key: key.PRICING_PROFILE_NAME,
  default: {},
});

const InvoicingProfileAtom = atom({
  key: key.INVOICING_PROFILE_NAME,
  default: {},
});

const WorkflowNameAtom = atom({
  key: key.WORKFLOW_NAME,
  default: {},
});

const DocuSignContract = atom({
  key: key.DOCUSIGN_CONTRACT,
  default: {},
});

const DocuSignUri = atom({
  key: key.DOCUSIGN_URI,
  default: [],
});

const isLiteAtom = atom({
  key: key.IS_LITE,
  default: false,
  effects_UNSTABLE: [persistAtom],
});

export {
  test,
  HeaderChangerAtom,
  NotificationListAtom,
  NotificationUnreadAtom,
  SpendOverviewUpdateAtom,
  ContractSetUpUpdateAtom,
  InvoicingViewAtom,
  SupplierSetupUpdateAtom,
  LegalEntityUpdateAtom,
  UserTokensAtom,
  SupplierTreeDataAtom,
  ContractTreeUpdateAtom,
  ContractPipelineUpdateAtom,
  RoleAccessAtom,
  DashboardAtom,
  userDataAtom,
  ContractAddTabAtom,
  GroupNameAtom,
  TabChangerAtom,
  SupplierNameAtom,
  LegalEntityAtom,
  CostCenterAtom,
  RateCardAtom,
  FxAtom,
  PricingProfileAtom,
  InvoicingProfileAtom,
  WorkflowNameAtom,
  DocuSignContract,
  DocuSignUri,
  isLiteAtom,
};
