import React, { useState, useEffect } from "react";
import { Layout, Row, Col, Card, Image, Input, Form } from "antd"; // Antd components
import TopHeader from "../../Header";
import { useRecoilState } from "recoil";
import { HeaderChangerAtom, userDataAtom } from "../../../store/store";
import arrow_left from "../../../assets/images/icons/arrow_left.svg";
import arrow_back from "../../../assets/images/icons/arrow_back_ios.svg";
import arrow_right_disable from "../../../assets/images/icons/arrow_right_disable.svg";
import { CancelButton, SubmitButton, SaveButton } from "../../GlobalButton";
import { useNavigate, useLocation } from "react-router-dom";
import PopupModal from "../../../PopupModal";
import ModalMessage from "../../../ModalMessage";
import RecentJourneyMessage from "../../../RecentJourneyMessage";
import {
  saveTemplateApi,
  updateTemplateApi,
} from "../../../api/contractAuthorityApi";
import { createActivityLogApi } from "../../../api/logApi";

const EditPreview = ({ editState, editTemplateFile, uploadPreview }) => {
  const { state } = useLocation();
  console.log("Location state:", state);
  console.log("editState", editState);
  const effectiveState = state || editState;
  console.log("Effective state:", effectiveState);

  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  const header = headerChanger.header;
  const navigate = useNavigate();
  const menuKey = "5";
  const [saveTableLoader, setSaveTableLoader] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [stateButton, setStateButton] = useState(1);
  const popupModalInstance = PopupModal();
  const [templateField, setTemplateField] = useState({
    msa: effectiveState.msa || null,
    contract_template_name: effectiveState.contract_template_name || null,
    supplier_id: effectiveState.supplier_id || null,
    template_description: effectiveState.template_description || null,
  });
  const [clauseContent, setClauseContent] = useState(
    effectiveState.clause_content || []
  );
  const [msaName, setMsaName] = useState(effectiveState.msa_name || "");
  const userData = useRecoilState(userDataAtom)[0]; // Get user data from global state
  console.log("userData", userData);
  const [initialClauseContent, setInitialClauseContent] = useState(
    effectiveState.clause_content || []
  );
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    console.log("Initial clause content:", initialClauseContent);
    setLoader(initialClauseContent.length > 0 ? false : true);
  }, [initialClauseContent]);

  // const onFinish = (values) => {
  //   Object.keys(templateField).forEach((key) => {
  //     if (!templateField[key]) {
  //       templateField[key] = "null";
  //     }
  //   });
  //   values = {
  //     ...templateField,
  //     clause_content:
  //       clauseContent.length > 0 ? clauseContent : initialClauseContent,
  //     updated_on: new Date(),
  //     updated_by: userData.id,
  //   };
  //   // values["msa_name"] = msaName;

  //   if (stateButton === 1) {
  //     values["status"] = "Draft";
  //     updateTemplateApi(effectiveState.id || effectiveState._id, values)
  //       .then((res) => {
  //         popupModalInstance.successModal(
  //           "",
  //           ModalMessage.TEM_Edit_Info,
  //           "/contract_authority/template"
  //         );
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   } else if (stateButton === 2) {
  //     values["status"] = "Active";
  //     popupModalInstance
  //       .infoModal(ModalMessage.TEM_Edit_Submit)
  //       .then(async (userChoice) => {
  //         if (userChoice === "ok") {
  //           await updateTemplateApi(
  //             effectiveState.id || effectiveState._id,
  //             values
  //           )
  //             .then((res) => {
  //               popupModalInstance.successModal(
  //                 "",
  //                 ModalMessage.TEM_Edit_Info,
  //                 "/contract_authority/template"
  //               );
  //             })
  //             .catch((err) => {
  //               console.log(err);
  //             });
  //         }
  //         if (userChoice === "cancel") {
  //           setSaveTableLoader(false);
  //         }
  //       });
  //   } else if (stateButton === 3) {
  //     navigate("/contract_authority/template/edit-preview", {
  //       state: {
  //         contract_template_name: templateField.contract_template_name,
  //         clause_content: clauseContent,
  //         viewTemp: false,
  //       },
  //     });
  //   }
  // };

  const stripHtml = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  };

  const onFinish = (values) => {
    Object.keys(templateField).forEach((key) => {
      if (!templateField[key]) {
        templateField[key] = "null";
      }
    });
    values = {
      ...templateField,
      clause_content:
        clauseContent.length > 0 ? clauseContent : initialClauseContent,
      updated_on: new Date(),
      updated_by: userData.id,
    };

    if (stateButton === 1) {
      values["status"] = "Draft";
      updateTemplateApi(effectiveState.id || effectiveState._id, values)
        .then((res) => {
          let ssLog = {
            ss_id: effectiveState.id || effectiveState._id,
            ss_name: values.contract_template_name,
            code: "template",
            author: userData.id,
            action: "edit",
            notes: RecentJourneyMessage.EDIT_TEMPLATE,
            updated_on: new Date(),
          };
          createActivityLogApi(ssLog);
          popupModalInstance.successModal(
            "",
            ModalMessage.TEM_Edit_Info,
            "/contract_authority/template"
          );
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (stateButton === 2) {
      values["status"] = "Active";
      popupModalInstance
        .infoModal(ModalMessage.TEM_Edit_Submit)
        .then(async (userChoice) => {
          console.log("userChoice", userChoice);
          if (userChoice === "ok") {
            await updateTemplateApi(
              effectiveState.id || effectiveState._id,
              values
            )
              .then((res) => {
                let ssLog = {
                  ss_id: effectiveState.id || effectiveState._id,
                  ss_name: values.contract_template_name,
                  code: "template",
                  author: userData.id,
                  action: "edit",
                  notes: RecentJourneyMessage.EDIT_TEMPLATE,
                  updated_on: new Date(),
                };
                createActivityLogApi(ssLog);
                popupModalInstance.successModal(
                  "",
                  ModalMessage.TEM_Edit_Info,
                  "/contract_authority/template"
                );
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (userChoice === "cancel") {
            setSaveTableLoader(false);
          }
        });
    }
    // else if (stateButton === 3) {
    //   navigate("/contract_authority/template/edit-preview", {
    //     state: {
    //       contract_template_name: templateField.contract_template_name,
    //       clause_content: clauseContent,
    //       viewTemp: false,
    //     },
    //   });
    // }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <Layout className="header-layout">
      <TopHeader
        headerChange={header}
        feedRow={false}
        searchRow={false}
        menuKey={menuKey}
        sticky=""
      />
      <Form onFinish={onFinish} onKeyDown={handleKeyDown}>
        <div className="system-setup1">
          <Row align="middle" type="flex" gutter={[8, 16]} className="m-10">
            <Col
              className="vertical-align"
              xs={24}
              sm={16}
              md={18}
              lg={18}
              xl={18}
            >
              <Card className="setup-header-card">
                <Row type="flex" gutter={[8, 16]}>
                  <Col
                    className="vertical-align"
                    xs={6}
                    sm={6}
                    md={4}
                    lg={4}
                    xl={2}
                  >
                    <Row type="flex" gutter={[8, 16]}>
                      <Col span={12}>
                        {effectiveState.viewTemp ? (
                          <Image
                            src={arrow_left}
                            style={{ width: 25 }}
                            preview={false}
                            className="cursor-pointer"
                          />
                        ) : (
                          <Image
                            src={arrow_left}
                            style={{ width: 25 }}
                            preview={false}
                            className="cursor-pointer"
                            onClick={() =>
                              navigate(`/contract_authority/edit/template`, {
                                state: { ...state },
                              })
                            }
                          />
                        )}
                      </Col>
                      <Col span={12}>
                        <Image
                          src={arrow_right_disable}
                          style={{ width: 20 }}
                          preview={false}
                          className="cursor-not-allowed"
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    className="vertical-align"
                    xs={17}
                    sm={17}
                    md={19}
                    lg={19}
                    xl={22}
                  >
                    <span>Contract Authority</span> &nbsp; &nbsp;
                    <Image src={arrow_back} preview={false} /> &nbsp; &nbsp;
                    <span
                      className="cursor-pointer"
                      onClick={() => navigate(`/contract_authority/template`)}
                    >
                      Templates
                    </span>
                    &nbsp; &nbsp;
                    <Image src={arrow_back} preview={false} /> &nbsp; &nbsp;
                    {effectiveState.viewTemp ? (
                      <span>Preview Template</span>
                    ) : (
                      <span>Edit Templates</span>
                    )}
                  </Col>
                </Row>
              </Card>
            </Col>
            {!effectiveState.viewTemp && (
              <>
                <Col
                  className="vertical-align"
                  xs={8}
                  sm={4}
                  md={3}
                  lg={3}
                  xl={2}
                >
                  <CancelButton
                    onClick={() => navigate(`/contract_authority/template`)}
                  />
                </Col>
                <Col
                  className="vertical-align"
                  xs={8}
                  sm={4}
                  md={3}
                  lg={3}
                  xl={2}
                >
                  <SaveButton
                    type="primary"
                    htmlType="submit"
                    loader={saveTableLoader}
                    onClick={() => setStateButton(1)}
                    disabled={effectiveState.status === "Active" ? true : false}
                  />
                </Col>
                <Col
                  className="vertical-align"
                  xs={8}
                  sm={4}
                  md={3}
                  lg={3}
                  xl={2}
                >
                  <SubmitButton
                    type="primary"
                    htmlType="submit"
                    loader={submitLoader}
                    onClick={() => setStateButton(2)}
                  />
                </Col>
              </>
            )}
          </Row>
          <div className="price-profile-content m-10">
            <Row align="middle" type="flex" gutter={[8, 16]} className="m-0">
              <div className="create-Preview-div">
                <Col span={12} offset={6}>
                  <div className="preview-div mb-10">
                    <div className="template-head mb-25">
                      {effectiveState && effectiveState.contract_template_name}
                    </div>
                    {effectiveState &&
                      effectiveState.clause_content.map((pdfObject) => {
                        return (
                          <div className="mt-15" key={pdfObject.h}>
                            <h4>{pdfObject.h}</h4>
                            <p className="mt-10">
                              {pdfObject.p !== "" ? (
                                stripHtml(pdfObject.p)
                              ) : (
                                <div className="empty-tag">
                                  {/* "This clause is empty" */}
                                </div>
                              )}
                            </p>
                          </div>
                        );
                      })}
                  </div>
                </Col>
              </div>
            </Row>
          </div>
        </div>
      </Form>
    </Layout>
  );
};

export default EditPreview;
