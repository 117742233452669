import React, { useEffect, useState } from "react";
import {
  Layout,
  Row,
  Col,
  Space,
  Image,
  Form,
  Select,
  Badge,
  Table,
  DatePicker,
  Pagination,
  message,
  Checkbox,
  Tabs,
  Input,
  Tag,
  Button,
  Spin,
} from "antd";
import {
  DownOutlined,
  UpOutlined,
  FilterOutlined,
  EyeOutlined,
  RightOutlined,
  CloseCircleOutlined,
  SaveOutlined,
  CloseOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import "../../assets/style/setup.css";
import "../../assets/style/contractpipeline.css";
import UpArrow from "../../assets/images/icons/arrow_up_icon.svg";
import DownArrow from "../../assets/images/icons/arrow_down_icon.svg";
import save_filter from "../../assets/images/icons/save_filter.svg";
import arrow_left from "../../assets/images/icons/arrow_left.svg";
import searchIcon from "../../assets/images/icons/search.svg";
import ContractFile from "./ContractFile";
import { getAllowedSupplierList } from "../../api/suppliersApi";
import {
  getAcvTcvApi,
  getContractBySupplier,
  getContractListFiltersApi,
  getContractParentData,
  searchContractListApi,
} from "../../api/contractsApi";
import { createFiltersApi, getFiltersApi } from "../../api/usersApi";
import { OkButton } from "../GlobalButton";
import { userDataAtom, HeaderChangerAtom } from "../../store/store";
import { useRecoilState } from "recoil";
import moment from "moment";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { useLocation } from "react-router";
import GlobalFilter from "../GlobalFilter";
import { convertBreadcrumbs } from "../../utils/common";
import ContractController from "./ContractController";
dayjs.extend(duration);

const ContractList = () => {
  //global state varaiable hold the dashboard user data to display
  const userData = useRecoilState(userDataAtom);
  let { state } = useLocation();
  let listSpend;
  let listExpiry;
  if (state !== null && state !== undefined) {
    if (state.listSpend) ({ listSpend } = state);
    if (state.listExpiry) ({ listExpiry } = state);
  }
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  const [contentSize, setContentSize] = useState("maximize");
  const [supplierData, setSupplierData] = useState([]);
  const [msaData, setMsaData] = useState([]);
  const [msaChildren, setMsaChildren] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [sortOrder, setSortOrder] = useState(null);
  const [sortedColumn, setSortedColumn] = useState(null);
  const [formValues, setFormValues] = useState({});
  const [paginateData, setPaginateData] = useState({
    pageNo: 1,
    limit: 20,
    total: 0,
  }); //Contract List table pagination
  // State variable to hold screen size
  const [screenSize, setScreenSize] = useState("min");
  // State variable to hold contract id and path
  const [finalPath, setFinalPath] = useState([]);
  // State variable to hold all filter conditions
  const [selectedContract, setSelectedContract] = useState();
  // State variable to hold all filter conditions
  const [allFilterVal, setAllFilterVal] = useState({});
  const [form] = Form.useForm();
  const [contractDates, setContractDates] = useState({
    start_date: null,
    end_date: null,
  });
  const [selectedSupplier, setSelectedSupplier] = useState([]);
  const [selectedMsa, setSelectedMsa] = useState([]);
  const [selectedContractName, setSelectedContractName] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [supplierLoader, setSupplierLoader] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [msaLoader, setMsaLoader] = useState(false);
  const [contractLoader, setContractLoader] = useState(false);
  const [listSpendValues, setListSpendValues] = useState(
    state && state.listSpend
      ? {
          supplier_name: "All",
          msa: "All",
          start_date: undefined,
          end_date: undefined,
          status: ["Contract-in-Execution"],
        }
      : state &&
          state.listExpiry && {
            supplier_name: "All",
            msa: "All",
            start_date: undefined,
            end_date: undefined,
            status: ["Contract-in-Execution"],
          }
  );
  const [formLoader, setFormLoader] = useState(
    listSpend || listExpiry ? true : false
  );

  useEffect(() => {
    getAllowedSupplierList(userData[0].id).then((res) => {
      let supplierMaster = res.map((data) => {
        delete data.children;
        return data;
      });
      supplierMaster = [
        {
          _id: "All",
          supplier_name: "All",
        },
        ...supplierMaster,
      ];
      setSupplierLoader(true);
      setSupplierData(supplierMaster);
    });

    console.log("state: ", state);
  }, []);

  useEffect(() => {
    const initializeForm = (statusValues) => {
      setTableLoader(true);
      handleSupplierChange(["All"]);
      handleMsaChange(["All"]);
      setSelectedContractName(["All"]);
      form.setFieldsValue({
        contract_name: ["All"],
        status: statusValues,
      });
    };

    const fetchMsaChilds = async (statusValues) => {
      const res = await getContractParentData(["All"], "MSA");
      const msaChilds = [
        {
          _id: "All",
          contract_name: "All",
        },
        ...res,
      ];

      setListSpendValues((prevValues) => ({
        ...prevValues,
        contract_name: msaChilds
          .filter((contract) => contract._id !== "All")
          .map((contract) => contract._id),
      }));

      initializeForm(statusValues);
      getContractList(listSpendValues, 1, 20);
    };

    if (state && (state.listSpend || state.listExpiry)) {
      fetchMsaChilds(["Contract-in-Execution"]);
    }
  }, [state]);

  useEffect(() => {
    if (listSpend || listExpiry) {
      if (listSpendValues.hasOwnProperty("contract_name")) {
        setFormLoader(false);
      }
    }
  }, [listSpendValues]);

  const onChangeContentSize = (size) => {
    setContentSize(size);
  };

  const formItemLayout = {
    labelCol: {
      span: 24,
    },
  };

  //Function which returns column title along with sorting indicators
  const renderColumnTitle = (column_title, dataIndex) => {
    return (
      <>
        <Row align="middle" type="flex">
          <Col
            sm={18}
            md={20}
            lg={20}
            xl={21}
            className="sorted-title"
            title={`${column_title}`}
          >
            {column_title}
          </Col>
          <Col sm={6} md={4} lg={4} xl={3} align="right">
            {isColumnSorted(dataIndex) && (
              <div className="custom-sort">
                {sortOrder === "ascend" && <UpOutlined />}
                {sortOrder === "descend" && <DownOutlined />}
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  };

  const isColumnSorted = (column) => sortedColumn === column;

  const columns =
    !listSpend && !listExpiry
      ? [
          {
            title: renderColumnTitle("Contract Name", "contract_name"),
            dataIndex: "contract_name",
            align: "left",
            sorter: (a, b) =>
              a.contract_name.props.children[1].props.children.localeCompare(
                b.contract_name.props.children[1].props.children
              ),
            sortOrder: isColumnSorted("contract_name") ? sortOrder : false,
            ellipsis: true,
          },
          {
            title: renderColumnTitle("Contract Type", "contract_type"),
            dataIndex: "contract_type",
            align: "left",
            sorter: (a, b) =>
              a.contract_type.props.children[1].props.children.localeCompare(
                b.contract_type.props.children[1].props.children
              ),
            sortOrder: isColumnSorted("contract_type") ? sortOrder : false,
            width: 110,
          },
          {
            title: renderColumnTitle("Supplier Name", "supplier_name"),
            dataIndex: "supplier_name",
            align: "left",
            sorter: (a, b) => a.supplier_name.localeCompare(b.supplier_name),
            sortOrder: isColumnSorted("supplier_name") ? sortOrder : false,
            ellipsis: true,
          },
          {
            title: renderColumnTitle("Status", "status"),
            dataIndex: "status",
            align: "left",
            sorter: (a, b) => a.status.localeCompare(b.status),
            sortOrder: isColumnSorted("status") ? sortOrder : false,
          },
          {
            title: renderColumnTitle("Created On", "created_on"),
            dataIndex: "created_on",
            align: "left",
            sorter: (a, b) => a.created_on.localeCompare(b.created_on),
            sortOrder: isColumnSorted("created_on") ? sortOrder : false,
            width: 120,
          },
          {
            title: renderColumnTitle("Created By", "created_by"),
            dataIndex: "created_by_username",
            align: "left",
            sorter: (a, b) => a.created_by.localeCompare(b.created_by),
            sortOrder: isColumnSorted("created_by_username")
              ? sortOrder
              : false,
          },
          {
            title: "Action",
            dataIndex: "action",
            align: "center",
            width: 120,
          },
        ]
      : listExpiry
      ? [
          {
            title: renderColumnTitle("Contract Name", "contract_name"),
            dataIndex: "contract_name",
            align: "left",
            sorter: (a, b) =>
              a.contract_name.props.children[1].props.children.localeCompare(
                b.contract_name.props.children[1].props.children
              ),
            sortOrder: isColumnSorted("contract_name") ? sortOrder : false,
            width: "30%",
          },
          {
            title: renderColumnTitle("Contract Type", "contract_type"),
            dataIndex: "contract_type",
            align: "left",
            sorter: (a, b) =>
              a.contract_type.props.children[1].props.children.localeCompare(
                b.contract_type.props.children[1].props.children
              ),
            sortOrder: isColumnSorted("contract_type") ? sortOrder : false,
            width: 200,
          },
          {
            title: renderColumnTitle("Supplier Name", "supplier_name"),
            dataIndex: "supplier_name",
            align: "left",
            sorter: (a, b) => a.supplier_name.localeCompare(b.supplier_name),
            sortOrder: isColumnSorted("supplier_name") ? sortOrder : false,
          },
          {
            title: renderColumnTitle("End Date", "end_date"),
            dataIndex: "end_date",
            align: "left",
            // sorter: (a, b) => a.spend - b.spend,
            sortOrder: isColumnSorted("end_date") ? sortOrder : false,
          },
          {
            title: renderColumnTitle("Expiry In", "expiry"),
            dataIndex: "expiry",
            align: "left",
            // sorter: (a, b) => a.spend - b.spend,
            sortOrder: isColumnSorted("expiry") ? sortOrder : false,
          },
        ]
      : listSpend && [
          {
            title: renderColumnTitle("Contract Name", "contract_name"),
            dataIndex: "contract_name",
            align: "left",
            sorter: (a, b) =>
              a.contract_name.props.children[1].props.children.localeCompare(
                b.contract_name.props.children[1].props.children
              ),
            sortOrder: isColumnSorted("contract_name") ? sortOrder : false,
            width: "30%",
          },
          {
            title: renderColumnTitle("Contract Type", "contract_type"),
            dataIndex: "contract_type",
            align: "left",
            sorter: (a, b) =>
              a.contract_type.props.children[1].props.children.localeCompare(
                b.contract_type.props.children[1].props.children
              ),
            sortOrder: isColumnSorted("contract_type") ? sortOrder : false,
            width: 200,
          },
          {
            title: renderColumnTitle("Contract Status", "status"),
            dataIndex: "status",
            align: "left",
            sorter: (a, b) => a.status.localeCompare(b.status),
            sortOrder: isColumnSorted("status") ? sortOrder : false,
          },
          {
            title: renderColumnTitle("Supplier Name", "supplier_name"),
            dataIndex: "supplier_name",
            align: "left",
            sorter: (a, b) => a.supplier_name.localeCompare(b.supplier_name),
            sortOrder: isColumnSorted("supplier_name") ? sortOrder : false,
          },
          {
            title: renderColumnTitle("Spend", "spend"),
            dataIndex: "spend",
            align: "left",
            sorter: (a, b) => a.spend - b.spend,
            sortOrder: isColumnSorted("spend") ? sortOrder : false,
          },
        ];

  const getIndividualAcvTcv = async (contractId) => {
    const acvTcv = await getAcvTcvApi(contractId);
    return acvTcv;
  };

  const getContractList = async (values, page, limit) => {
    console.log("values: ", values);

    if (Object.keys(values).length !== 0) {
      setTableLoader(true);
    } else {
      return;
    }
    const res = await searchContractListApi(
      values,
      page,
      limit,
      allFilterVal,
      listSpend ? "listSpend" : listExpiry ? "listExpiry" : undefined
    );
    setPaginateData({
      ...paginateData,
      pageNo: page,
      limit: limit,
      total: res ? res.totalDocs : 0,
    });

    console.log("searchRes: ", res);

    if (res && res.docs) {
      const contractListDataPromises = res.docs.map(async (item) => {
        let tableRow = {
          key: item.id ? item.id : item._id,
          contract_name: (
            <span
              onClick={() => setSelectedContract(item)}
              style={{
                color: "var(--color-solid-darkblue)",
                fontWeight: "100",
                cursor: "pointer",
              }}
            >
              {item.contract_name}
            </span>
          ),
          contract_type: item.contract_type,
          supplier_id: item.supplier_id,
          supplier_name: item.supplier_name,
          status: item.contract_status.split("-").join(" "),
          created_on: moment(item.created_on).format("DD/MM/YYYY"),
          created_by_username: item.created_user_name,
          created_by: item.created_by,
          end_date: dayjs(item.end_date).format("DD/MM/YYYY"),
          action: (
            <div
              style={{
                justifyContent: "space-evenly",
                display: "flex",
              }}
            >
              <EyeOutlined
                title="View"
                style={{
                  fontSize: "20px",
                  color: "var(--color-solid-darkgrey)",
                }}
                className="cursor-pointer"
                onClick={() => setSelectedContract(item)}
              />
            </div>
          ),
        };

        if (listSpend) {
          // Adding spend value when trying to view Spend estimates for each contract
          const { tcv } = await getIndividualAcvTcv(
            item.id ? item.id : item._id
          );

          tableRow.spend = (
            <span style={{ color: "#4cbb17" }}>
              {parseFloat(tcv).toFixed(2)}
            </span>
          );
        } else if (listExpiry) {
          const endDate = dayjs(item.end_date);

          const today = dayjs();
          const sixMonthsFromNow = today.add(6, "month");

          if (endDate.isBefore(today)) {
            tableRow.expiry = "Expired";
          } else if (endDate.isBefore(sixMonthsFromNow)) {
            const daysUntilEndDate = endDate.diff(today, "day");
            tableRow.expiry = `${daysUntilEndDate} days`;
          } else {
            tableRow.expiry = "Active";
          }
        }

        return tableRow;
      });

      const contractListData = await Promise.all(contractListDataPromises);

      console.log("contractListData: ", contractListData);

      if (contractListData.length > 0) {
        if (listExpiry) {
          const filteredContracts = contractListData.filter(
            (contract) => contract.expiry !== "Active"
          );
          console.log("filteredContracts: ", filteredContracts);
          setTableData(filteredContracts);
        } else {
          setTableData(contractListData);
        }
        setTableLoader(false);
        setContentSize("minimize");
      } else {
        setTableLoader(false);
        setTableData([]);
      }
    } else {
      setTableData([]);
    }
  };

  const onFinish = (values) => {
    if (values.contract_name && values.contract_name.includes("All")) {
      values.contract_name = msaChildren
        .filter((contract) => contract._id !== "All")
        .map((contract) => contract._id);
    }
    setFormValues(values);
    getContractList(values, paginateData.pageNo, paginateData.limit);
  };

  // Contract List table pagination handler
  const onPageChange = (page, limit) => {
    if (!listSpend && !listExpiry) {
      getContractList(formValues, page, limit);
    } else {
      getContractList(listSpendValues, page, limit);
    }
  };

  const handleSupplierChange = (value) => {
    setSelectedSupplier(value);
    if (value.includes("All")) {
      form.setFieldsValue({
        supplier_name: ["All"],
      });
    }
    if (value.length !== 0) {
      setMsaLoader(true);
      getContractBySupplier(value, "MSA").then((res) => {
        let msa = [
          {
            _id: "All",
            contract_name: "All",
          },
          ...res,
        ];
        setMsaLoader(false);
        setMsaData(msa);
      });
    } else {
      setMsaData([]);
      form.setFieldsValue({
        msa: undefined,
        contract_name: undefined,
        status: undefined,
        start_date: undefined,
        end_date: undefined,
      });
      setMsaChildren([]);
      setMsaData([]);
      setSelectedMsa([]);
      setSelectedContractName([]);
      setSelectedStatus([]);
      setTableData([]);
    }
  };

  const handleMsaChange = (value) => {
    setSelectedMsa(value);
    if (value.includes("All")) {
      form.setFieldsValue({
        msa: ["All"],
      });
    }
    if (value.length !== 0) {
      setContractLoader(true);
      getContractParentData(value, "MSA").then((res) => {
        let msaChilds = [];
        if (value.includes("All")) {
          msaChilds = [
            {
              _id: "All",
              contract_name: "All",
            },
            ...res,
          ];
        } else {
          msaChilds = [
            {
              _id: "All",
              contract_name: "All",
            },
            ...res.map((contract) => contract.children).flat(),
          ];
        }
        setContractLoader(false);
        setMsaChildren(msaChilds);
      });
    } else {
      setMsaChildren([]);
    }
  };

  const disabledDate = (dateValue, field) => {
    // disable date in start-date datepicker
    if (field === "start_date") {
      if (!contractDates.end_date) {
        return dateValue && dateValue < moment().startOf("day");
      }
      const isAfterEndDate = dateValue.isAfter(contractDates.end_date, "day");
      const isPastDate = dateValue && dateValue < moment().startOf("day");
      if (isAfterEndDate || isPastDate) {
        // Clear end date if start date is set beyond end date
        setContractDates({
          ...contractDates,
          end_date: null,
        });
      }
      return isAfterEndDate || isPastDate;
    }

    // disable date in end-date datepicker
    if (field === "end_date") {
      if (!contractDates.start_date) {
        return false;
      }
      return dateValue.isBefore(contractDates.start_date, "day");
    }
  };

  useEffect(() => {
    console.log("tableData: ", tableData);
  }, [tableData]);

  /* Function to handle after select the filter data to filter the table data, after the change the
       allFilterVal state
     */
  useEffect(() => {
    if (Object.keys(allFilterVal || {}).length === 0) {
      getContractList(formValues, 1, 20);
    } else {
      for (let key in allFilterVal) {
        if (
          Array.isArray(allFilterVal[key]) &&
          allFilterVal[key].length === 0
        ) {
          delete allFilterVal[key];
        }
      }
      if (Object.keys(allFilterVal || {}).length === 0) {
        getContractList(formValues, 1, 20);
      } else {
        getContractList(formValues, 1, 20);
      }
    }
  }, [allFilterVal]);

  useEffect(() => {
    setSelectedContract();
  }, [headerChanger]);

  useEffect(() => {
    document.title = "Contract List | Judo";
  }, []);

  return (
    <Layout>
      <div
        className={selectedContract ? "pipeline-click" : "contract-pipeline"}
      >
        {!selectedContract && (
          <>
            <div
              className={`action-body ${
                contentSize === "maximize"
                  ? "transition-height maximize"
                  : "transition-height minimize"
              }`}
              style={{ textAlign: "left", color: "black" }}
            >
              <div className="action-new-div">
                <Spin spinning={formLoader} size="medium">
                  <div className="create-entity-div">
                    <Row
                      align="middle"
                      type="flex"
                      style={{
                        padding:
                          contentSize === "maximize"
                            ? "12px 30px 0px"
                            : "5px 30px",
                      }}
                    >
                      <Col span={20} className="vertical-align">
                        <h4 className="entity-text">Search Contract</h4>
                      </Col>
                      <Col span={4} className="text-end">
                        {contentSize !== "maximize" && (
                          <Space>
                            <Image
                              className="cursor-pointer expand-contract-icon"
                              onClick={() => onChangeContentSize("maximize")}
                              src={DownArrow}
                              preview={false}
                            />
                          </Space>
                        )}
                      </Col>
                    </Row>
                    {contentSize === "maximize" && (
                      <>
                        <hr
                          className="mt-10 view-supplier-hr"
                          style={{ color: "grey" }}
                        />
                        <div className="create-supplier-div action-body-head">
                          <Form
                            form={form}
                            onFinish={onFinish}
                            initialValues={{ ...formValues }}
                          >
                            <Row align="middle" type="flex" gutter={[64, 4]}>
                              <Col span={8}>
                                <Form.Item
                                  {...formItemLayout}
                                  className="org-supplier-label"
                                  name="supplier_name"
                                  label="SUPPLIER NAME"
                                  colon={false}
                                >
                                  <Select
                                    allowClear
                                    showSearch={true}
                                    placeholder="Choose Supplier"
                                    mode="multiple"
                                    maxTagCount={2}
                                    notFoundContent={
                                      supplierLoader && <LoadingOutlined />
                                    }
                                    onChange={handleSupplierChange}
                                    options={
                                      supplierData &&
                                      supplierData.map((data) => ({
                                        key: data.id ? data.id : data._id,
                                        label: data.supplier_name,
                                        value: data.id ? data.id : data._id,
                                        disabled:
                                          selectedSupplier.length > 0 &&
                                          selectedSupplier.includes("All") &&
                                          data._id !== "All",
                                      }))
                                    }
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item
                                  {...formItemLayout}
                                  className="org-supplier-label"
                                  name="msa"
                                  label="MSA"
                                  colon={false}
                                >
                                  <Select
                                    allowClear
                                    showSearch={true}
                                    placeholder="Choose MSA"
                                    mode="multiple"
                                    maxTagCount={2}
                                    onChange={handleMsaChange}
                                    notFoundContent={
                                      msaLoader && <LoadingOutlined />
                                    }
                                    options={
                                      msaData &&
                                      msaData.map((data) => ({
                                        key: data.id ? data.id : data._id,
                                        label: data.contract_name,
                                        value: data.id ? data.id : data._id,
                                        disabled:
                                          selectedMsa.length > 0 &&
                                          selectedMsa.includes("All") &&
                                          data._id !== "All",
                                      }))
                                    }
                                    onClear={() => setMsaChildren([])}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item
                                  {...formItemLayout}
                                  className="org-supplier-label"
                                  name="contract_name"
                                  label="CONTRACT"
                                  colon={false}
                                >
                                  <Select
                                    allowClear
                                    showSearch
                                    placeholder="Choose Contract"
                                    mode="multiple"
                                    maxTagCount={2}
                                    notFoundContent={
                                      contractLoader && <LoadingOutlined />
                                    }
                                    onChange={(value) => {
                                      setSelectedContractName(value);
                                      if (value.includes("All")) {
                                        form.setFieldsValue({
                                          contract_name: ["All"],
                                        });
                                      }
                                    }}
                                    options={
                                      msaChildren &&
                                      msaChildren.map((data) => ({
                                        key: data.id
                                          ? `${data.id} - ${data.contract_name}`
                                          : `${data._id} - ${data.contract_name}`,
                                        label: data.contract_name,
                                        value: data.id ? data.id : data._id,
                                        disabled:
                                          selectedContractName.length > 0 &&
                                          selectedContractName.includes(
                                            "All"
                                          ) &&
                                          data._id !== "All",
                                      }))
                                    }
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item
                                  {...formItemLayout}
                                  className="org-supplier-label"
                                  name="status"
                                  label="STATUS"
                                  colon={false}
                                >
                                  <Select
                                    allowClear
                                    mode="multiple"
                                    showSearch={true}
                                    placeholder="Choose Contract Status"
                                    maxTagCount={2}
                                    onChange={(value) => {
                                      setSelectedStatus(value);
                                      if (value.includes("All")) {
                                        form.setFieldsValue({
                                          status: ["All"],
                                        });
                                      }
                                    }}
                                    options={[
                                      {
                                        value: "All",
                                        label: "All",
                                      },
                                      {
                                        value: "Contract-in-Draft",
                                        label: "Contract In Draft",
                                        disabled:
                                          selectedStatus.length > 0 &&
                                          selectedStatus.includes("All"),
                                      },
                                      {
                                        value: "Contract-Submitted",
                                        label: "Contract Submitted",
                                        disabled:
                                          selectedStatus.length > 0 &&
                                          selectedStatus.includes("All"),
                                      },
                                      {
                                        value: "Submitted-for-Approval",
                                        label: "Submitted For Approval",
                                        disabled:
                                          selectedStatus.length > 0 &&
                                          selectedStatus.includes("All"),
                                      },
                                      {
                                        value: "Contract-Returned",
                                        label: "Contract Returned",
                                        disabled:
                                          selectedStatus.length > 0 &&
                                          selectedStatus.includes("All"),
                                      },
                                      {
                                        value: "Contract-in-Execution",
                                        label: "Contract In Execution",
                                        disabled:
                                          selectedStatus.length > 0 &&
                                          selectedStatus.includes("All"),
                                      },
                                      {
                                        value: "Contract Terminated",
                                        label: "Contract Terminated",
                                        disabled:
                                          selectedStatus.length > 0 &&
                                          selectedStatus.includes("All"),
                                      },
                                    ]}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item
                                  {...formItemLayout}
                                  className="org-supplier-label"
                                  name="start_date"
                                  label="START DATE"
                                  colon={false}
                                >
                                  <DatePicker
                                    disabledDate={(dateValue) => {
                                      if (
                                        contractDates.start_date !== null ||
                                        contractDates.start_date !== undefined
                                      ) {
                                        disabledDate(dateValue, "start_date");
                                      }
                                    }}
                                    format={"DD MMMM YYYY"}
                                    className="report-input"
                                    placeholder="Select Start Date"
                                    onChange={(date) =>
                                      setContractDates({
                                        ...contractDates,
                                        start_date: date,
                                      })
                                    }
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item
                                  {...formItemLayout}
                                  className="org-supplier-label"
                                  name="end_date"
                                  label="END DATE"
                                  colon={false}
                                >
                                  <DatePicker
                                    disabledDate={(dateValue) =>
                                      disabledDate(dateValue, "end_date")
                                    }
                                    format={"DD MMMM YYYY"}
                                    className="report-input"
                                    placeholder="Select End Date"
                                    onChange={(date) =>
                                      setContractDates({
                                        ...contractDates,
                                        end_date: date,
                                      })
                                    }
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                            <Row align="middle" type="flex" className="mt-20">
                              <Col span={13} align="right" className="mb-15">
                                <Col span={4}>
                                  <OkButton
                                    htmlType="submit"
                                    disabled={selectedSupplier.length === 0}
                                  />
                                </Col>
                              </Col>
                              <Col span={11} align="right" className="mb-15">
                                <Image
                                  className="cursor-pointer expand-contract-icon"
                                  onClick={() =>
                                    onChangeContentSize("minimize")
                                  }
                                  src={UpArrow}
                                  style={{
                                    pointerEvents:
                                      contentSize === "minimize" && "none",
                                  }}
                                  preview={false}
                                />
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      </>
                    )}
                  </div>
                </Spin>
              </div>
            </div>
            <Row className="mt-10">
              <Col span={24} align="center" className="p-0 vertical-align">
                <GlobalFilter
                  selectedTab={"contractList"}
                  initialTableData={tableData}
                  allFilterVal={allFilterVal}
                  setAllFilterVal={setAllFilterVal}
                  searchParams={formValues}
                />
              </Col>
            </Row>
            <Table
              loading={tableLoader}
              rowClassName={() => "contract-tree-table-row"}
              dataSource={tableData}
              columns={columns}
              showSorterTooltip={false}
              bordered
              size="large "
              scroll={
                tableData.length === 0
                  ? {}
                  : {
                      x: "calc(300px + 50%)",
                      y: "calc(100vh - 280px)",
                    }
              }
              pagination={false}
              className="system-setup-table mt-10"
            />
            <div className="float-right mb-15 mt-15">
              <Pagination
                defaultPageSize={paginateData.limit}
                responsive
                current={paginateData.pageNo}
                total={paginateData.total}
                showSizeChanger
                onShowSizeChange={onPageChange}
                onChange={onPageChange}
              />
            </div>
          </>
        )}
        {selectedContract && (
          <>
            <Row type="flex" gutter={[8, 0]}>
              <Col span={24}>
                <ContractController
                  mode={{ view: true, edit: false, create: false }}
                  breadcrumbs={convertBreadcrumbs(selectedContract)}
                  location={"noHeader"}
                />
              </Col>
            </Row>
          </>
        )}
      </div>
    </Layout>
  );
};

export default ContractList;
