import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { Outlet } from "react-router";
import { DatePicker } from "antd";
import axios from "axios";
import key from "../store/AtomAndSelectorKeys";
import { refreshTokenApi } from "../api/usersApi";
import AccountTreeInactive from "../assets/images/icons/account_tree.svg";
import AccountTreeActive from "../assets/images/icons/account_tree_active.svg";
import FolderActive from "../assets/images/icons/folder_active.svg";
import FolderInactive from "../assets/images/icons/folder_inactive.svg";
import MsaActive from "../assets/images/icons/msa_active.svg";
import MsaInactive from "../assets/images/icons/msa_inactive.svg";
import SupplementActive from "../assets/images/icons/supplement_active.svg";
import SupplementInactive from "../assets/images/icons/supplement_inactive.svg";
import CcnActive from "../assets/images/icons/ccn_active.svg";
import CcnInactive from "../assets/images/icons/ccn_inactive.svg";
import SowActive from "../assets/images/icons/sow_active.svg";
import SowInactive from "../assets/images/icons/sow_inactive.svg";
import CrActive from "../assets/images/icons/cr_active.svg";
import CrInactive from "../assets/images/icons/cr_inactive.svg";
import OthersActive from "../assets/images/icons/others_active.svg";
import OthersInactive from "../assets/images/icons/others_inactive.svg";
import ContractRouter from "../components/Contract/ContractRouter";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export const axiosInstanceUpload = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: { "Content-Type": "multipart/form-data" },
});

axiosInstance.interceptors.request.use(async (config) => {
  let token = JSON.parse(localStorage.getItem(key.USER_TOKENS))?.access?.token;
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});
axiosInstance.interceptors.response.use(null, ({ response }) => {
  if (response && response.data) {
    if (response.status === 401 || response.status === 403) {
      localStorage.clear();
      window.location = window.location.protocol + "//" + window.location.host;
    }
    return Promise.reject(response.data);
  }
});
let intervalCounter = false;
setInterval(() => {
  intervalCounter = true;
}, 1500000);
document.addEventListener("click", () => {
  if (intervalCounter) {
    intervalCounter = false;
    let refreshToken = JSON.parse(localStorage.getItem(key.USER_TOKENS))
      ?.refresh?.token;
    refreshTokenApi(refreshToken).then((tokens) =>
      localStorage.setItem(key.USER_TOKENS, JSON.stringify(tokens))
    );
  }
});
export const sessionStorageEffect =
  (key) =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }
    onSet((newValue) => {
      localStorage.setItem(key, JSON.stringify(newValue));
    });
  };
export const CheckAuth = () => {
  if (sessionStorage.getItem("isAuthorized")) {
    return true;
  }
  return false;
};

export const PrivateRoutes = () => {
  let isAuthenticated = JSON.parse(localStorage.getItem(key.USER_TOKENS))
    ?.access?.token;
  return <>{isAuthenticated ? <Outlet /> : <Navigate to="/" />}</>;
};

export const PublicRoutes = () => {
  let isAuthenticated = JSON.parse(localStorage.getItem(key.USER_TOKENS))
    ?.access?.token;
  return (
    <>{isAuthenticated ? <Navigate to="/user-dashboard" /> : <Outlet />}</>
  );
};

export const airflowCredential = {
  username: "admin",
  password: "admin",
};

export const docuSignData = {
  integrationKey: "b7361c16-fd21-469b-847f-df0461f70d81",
  redirectUri: "http://localhost:3000/contract",
  scope: "signature",
  accountId: 29105323,
};

export const dataTable = "data-table";
export const dataTableForm = "data-table-form";
export const setupDataTableForm = "setup-data-table-form";

export const getNodeIcon = (nodeType, status = "active") => {
  const isActive = status === "active";

  switch (nodeType) {
    case "SupplierTree":
      return (
        <img
          src={isActive ? AccountTreeActive : AccountTreeInactive}
          alt="Suppliers"
          style={{ marginRight: 8, width: 18, height: 18 }}
        />
      );
    case "Supplier":
      return (
        <img
          src={isActive ? FolderActive : FolderInactive}
          style={{ width: 14, height: 14, marginRight: 8 }}
          alt="Folder Icon"
        />
      );
    case "MSA":
      return (
        <img
          src={isActive ? MsaActive : MsaInactive}
          style={{ width: 20, height: 16, marginRight: 7 }}
          alt="Folder Icon"
        />
      );
    case "Supplement":
    case "Sub-Supplement":
      return (
        <img
          src={isActive ? SupplementActive : SupplementInactive}
          style={{ width: 18, height: 18, marginRight: 5 }}
          alt="Folder Icon"
        />
      );
    case "CCN":
      return (
        <img
          src={isActive ? CcnActive : CcnInactive}
          style={{ width: 18, height: 18, marginRight: 6 }}
          alt="Folder Icon"
        />
      );
    case "SOW":
      return (
        <img
          src={isActive ? SowActive : SowInactive}
          style={{ width: 18, height: 18, marginRight: 6 }}
          alt="Folder Icon"
        />
      );
    case "CR":
      return (
        <img
          src={isActive ? CrActive : CrInactive}
          style={{ width: 19, height: 19, marginRight: 8 }}
          alt="Folder Icon"
        />
      );
    default:
      return (
        <img
          src={isActive ? OthersActive : OthersInactive}
          style={{ width: 20, height: 20, marginRight: 6 }}
          alt="Folder Icon"
        />
      );
  }
};

export const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0;
};

export const contractTypeOptions = {
  Supplier: ["MSA", "SOW", "Others"],
  MSA: ["SOW", "Supplement", "CR", "CCN", "Others"],
  Supplement: ["SOW", "Supplement", "CR", "CCN", "Others"],
  SOW: ["CR", "CCN", "Others"],
  CCN: ["CCN", "Others"],
  CR: ["CR", "Others"],
};

export const contractTypes = [
  {
    type: "MSA",
    icon: { active: MsaActive, inactive: MsaInactive },
  },
  { type: "SOW", icon: { active: SowActive, inactive: SowInactive } },
  {
    type: "Supplement",
    icon: { active: SupplementActive, inactive: SupplementInactive },
  },
  { type: "CCN", icon: { active: CcnActive, inactive: CcnInactive } },
  { type: "CR", icon: { active: CrActive, inactive: CrInactive } },
  {
    type: "Others",
    icon: { active: OthersActive, inactive: OthersInactive },
  },
];

export const contractConfig = {
  primaryTypes: ["MSA", "SOW", "Others"],
  secondaryTypes: ["SOW", "Supplement", "Others"],
  amendmentTypes: ["CR", "CCN"],
};

export const determineContractClass = (
  contractType,
  parentContractType,
  config
) => {
  if (parentContractType === "Supplier") {
    if (config.primaryTypes.includes(contractType)) {
      return "primary";
    }
  } else {
    if (config.primaryTypes.includes(parentContractType)) {
      if (config.secondaryTypes.includes(contractType)) {
        return "secondary";
      }
      if (config.amendmentTypes.includes(contractType)) {
        return "amendment";
      }
    } else if (config.secondaryTypes.includes(parentContractType)) {
      if (config.amendmentTypes.includes(contractType)) {
        return "amendment";
      }
      if (config.secondaryTypes.includes(contractType)) {
        return "secondary";
      }
    } else if (config.amendmentTypes.includes(parentContractType)) {
      if (config.amendmentTypes.includes(contractType)) {
        return "amendment";
      }
    }
  }
};

export const convertBreadcrumbs = (contract) => {
  const formattedBreadcrumbs = [
    {
      title: "Suppliers",
      apiArgs: {
        supplier_id: undefined,
        contract_id: undefined,
        type: "Supplier",
      },
    },
  ];

  contract.breadcrumbs.forEach((breadcrumb) => {
    formattedBreadcrumbs.push({
      title: breadcrumb.title,
      apiArgs: {
        supplier_id: breadcrumb.type === "Supplier" ? breadcrumb.id : undefined,
        contract_id: breadcrumb.id || undefined,
        type: breadcrumb.type,
      },
    });
  });

  formattedBreadcrumbs.push({
    title: contract.contract_name,
    apiArgs: {
      supplier_id: undefined,
      contract_id: contract._id || undefined,
      type: contract.contract_type,
    },
  });

  return formattedBreadcrumbs;
};

export const navigateBasedOnContractStatus = (
  contract,
  history,
  setHeaderChanger,
  handleAddTab
) => {
  // Determine where to navigate based on contract status
  const whereToNavigate = [
    "Contract-in-Execution",
    "Sent for Signature",
    "Contract-Signed",
    "Signature-Rejected",
  ].includes(contract.contract_status)
    ? "contractTree"
    : "pipeline";

  // Building the breadcrumbs into the required format for being displayed in the contract screen
  const contractBreadcrumbs = [
    ...convertBreadcrumbs(contract).map((item, index, arr) =>
      index === arr.length - 1 && whereToNavigate === "contractTree"
        ? { ...item, inspectItem: true }
        : item
    ),
  ];

  // Update header based on the whether to navigate to Contract Tree or Contract Pipeline
  setHeaderChanger({
    header: "contract",
    headerItem: whereToNavigate === "contractTree" ? "1" : "2",
    headerItemName: whereToNavigate,
    stateSetter: {
      contract_id: contract._id,
      mode: { view: true, edit: false, create: false },
      breadcrumbs: contractBreadcrumbs,
      location: whereToNavigate,
    },
  });

  // Navigate and handle tabs based on destination
  if (whereToNavigate === "contractTree") {
    history("/contract");
    handleAddTab({
      id: contract._id,
      title: contract.contract_name,
      breadcrumbs: contractBreadcrumbs,
      children: <ContractRouter breadcrumbs={contractBreadcrumbs} />,
      type: contract.contract_type,
    });
  } else {
    history("/contract/view/form", {
      state: {
        contract_id: contract._id,
        mode: { view: true, edit: false, create: false },
        breadcrumbs: contractBreadcrumbs,
        location: "pipeline",
      },
    });
  }
};
