import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Select,
  Input,
  Layout,
  Tabs,
  Divider,
  Radio,
} from "antd"; //antd components
import { LoadingOutlined } from "@ant-design/icons";
import { useRecoilState } from "recoil";
import moment from "moment";
import { useNavigate, useLocation } from "react-router-dom";
import TopHeader from "../../Header";
import "../../../assets/style/setup.css";
import { ContractCurrency } from "../../../utils/data";
import RecentJourneyMessage from "../../../RecentJourneyMessage";

import {
  legalEntityCreateApi,
  editLegalEntityApi,
} from "../../../api/legalEntityApi";
import { createActivityLogApi } from "../../../api/logApi";
import { userDataAtom } from "../../../store/store";
import { CancelButton, SaveButton, SubmitButton } from "../../GlobalButton";
import { HeaderChangerAtom, RoleAccessAtom } from "../../../store/store";
import PopupModal from "../../../PopupModal";
import ModalMessage from "../../../ModalMessage";
import NotAuthorized from "../../NotAuthorized";
import { getCostCenterByLeIdApi } from "../../../api/costCenterApi";
import HeaderCard from "../../HeaderCard";
import DataTable from "../../Tables/DataTable";
import NotesAndComments from "../../NotesAndComments";
import {
  deleteS3FileApi,
  updateNotesCommentsApi,
} from "../../../api/commonApi";

function LegalEntityController() {
  // State variable to hold user information
  const userData = useRecoilState(userDataAtom);
  const roleUser = useRecoilState(RoleAccessAtom);
  // State variable to hold header data
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  //
  const [associationData, setAssociationData] = useState([]);
  // Variable to hold local header data
  let header = headerChanger.header;
  // Variable to hold PopupModal component
  const popupModalInstance = PopupModal();
  // Variable to hold manu key value
  const menuKey = "5";
  // Variable to use navigate function
  const navigate = useNavigate();
  // Variable to hold particular legal entity data
  let { state } = useLocation();
  if (state.data === null || state.data === undefined) {
    state.data = headerChanger.stateSetter;
  }
  // variable to hold button value for save and submit
  const stateButton = {
    button: 1,
  };
  // State variable to hold the legal entity data
  const [legalEntityField, setLegalEntityField] = useState({
    le_name: state.data.le_name,
    le_country: state.data.le_country,
    le_address: state.data.le_address,
  });
  // State variable to hold table loading visibility status
  const [tableLoader, setTableLoader] = useState(false);
  // State variable to hold save table loading visibility
  const [saveTableLoader, setSaveTableLoader] = useState(false);
  const [accessGranted, setAccessGranted] = useState();
  const [leId, setLeId] = useState("");
  const [mainPageloading, setMainPageLoading] = useState(true); // Initialize main page loading state
  const [status, setStatus] = useState(state.data && state.data.le_status);
  // variable to hold button disable CSS
  let disableVal = "disable-btn";
  // State variable to hold button visibility status
  const [disable, setDisable] = useState(disableVal);
  const [viewedFile, setViewedFile] = useState(false);
  // state for store new comments
  const [comments, setComments] = useState([]);
  const [url, setUrl] = useState([
    {
      fileType: "docx",
    },
  ]);
  // uploaded contract file
  const [leFile, setLeFile] = useState([]);
  const [fileKeysToDelete, setFileKeysToDelete] = useState([]);
  const [leTabKey, setLeTabKey] = useState("1");
  const [notesSaveLoader, setNotesSaveLoader] = useState(false);

  useEffect(() => {
    const checkAccess = () => {
      const hasAccess =
        roleUser &&
        roleUser[0] &&
        roleUser[0].page_access.system_setup.supplier_setup.view;
      setAccessGranted(hasAccess);
      setMainPageLoading(false);
    };

    checkAccess();
  }, [roleUser]);

  useEffect(() => {
    if (state.flag === "edit" || state.flag === "view") {
      getCostCenterByLeIdApi(state && state.data && state.data._id).then(
        (res) => {
          let association = res.map((data) => {
            let tableRow = {
              associated_item: "Cost Center",
              name: (
                <span
                  onClick={() => {
                    if (
                      roleUser &&
                      roleUser[0] &&
                      roleUser[0].page_access.system_setup.cost_center_setup
                        .view
                    ) {
                      setHeaderChanger({
                        header: "systemSetup",
                        headerItem: "1",
                        headerItemName: "systemSetup",
                        stateSetter: data,
                      });

                      navigate(`/organization-setup/view/cost-center`, {
                        state: data,
                      });
                    }
                  }}
                  style={{
                    color:
                      roleUser &&
                      roleUser[0] &&
                      roleUser[0].page_access.system_setup.cost_center_setup
                        .view
                        ? "var(--color-solid-darkblue)"
                        : "var(--color-solid-black)",
                    fontWeight: "100",
                    cursor:
                      roleUser &&
                      roleUser[0] &&
                      roleUser[0].page_access.system_setup.cost_center_setup
                        .view
                        ? "pointer"
                        : "default",
                  }}
                >
                  {data.cc_name}
                </span>
              ),
              association_date: moment(data.createdAt).format("DD-MMM-YY"),
            };
            return tableRow;
          });
          setAssociationData(association);
        }
      );
    }
  }, [state]);

  /* Function to handle create new legal entity data
   * @Param label - a form name
   * @Param e - input for legal entity name
   * @Param key - choice the legal entity country key
   * @Param value - choice the legal entity country value
   */
  const onCreateLegalEntity = (label, e, key, value) => {
    if (label === "le_name") {
      setLegalEntityField({
        ...legalEntityField,
        le_name: e.target.value === "" ? undefined : e.target.value,
      });
      e.target.value !== "" &&
      state.data &&
      state.data.le_name !== e.target.value
        ? setDisable("")
        : setDisable(disableVal);
    } else if (label === "le_country") {
      setLegalEntityField({
        ...legalEntityField,
        le_country: key === undefined ? undefined : key.value,
      });
      key !== undefined && state.data && state.data.le_country !== key.value
        ? setDisable("")
        : setDisable(disableVal);
    } else if (label === "le_address") {
      setLegalEntityField({
        ...legalEntityField,
        le_address: e.target.value === "" ? undefined : e.target.value,
      });
      e.target.value !== "" &&
      state.data &&
      state.data.le_address !== e.target.value
        ? setDisable("")
        : setDisable(disableVal);
    }
  };

  const handleRadioClick = (value) => {
    if (
      value !== "Active" &&
      state &&
      state.le_status === "Active" &&
      associationData.length > 0
    ) {
      popupModalInstance
        .infoModal(ModalMessage.LE_Status_Change)
        .then(async (userChoice) => {
          if (userChoice === "ok") {
            setStatus(value);
            state.le_status !== value ? setDisable("") : setDisable(disableVal);
          }
        });
    } else {
      popupModalInstance
        .infoModal(
          value === "Active"
            ? ModalMessage.LE_Status_Change_To_Active
            : ModalMessage.LE_Status_Change_Confirm
        )
        .then(async (userChoice) => {
          if (userChoice === "ok") {
            setStatus(value);
            state.le_status !== value ? setDisable("") : setDisable(disableVal);
          }
        });
    }
  };

  /* Function to handle the create legal entity form data stored
   * @param values - a user create form values
   */
  const onFinish = async (values) => {
    values["author"] =
      userData && userData[0] && userData[0].id
        ? userData[0].id
        : userData[0]._id
        ? userData[0]._id
        : "";
    values["author_name"] =
      userData && userData[0] && userData[0].first_name
        ? userData[0].first_name
        : "";
    values["comments"] = comments.length !== 0 ? comments : [];

    if (state.flag === "edit") {
      values["le_status"] =
        state.data && state.data.le_status === "In Draft"
          ? stateButton.button === 1
            ? "In Draft"
            : "Active"
          : stateButton.button === 1
          ? "In Draft"
          : status;
      values["updated_by"] = userData[0].id;

      const uploadForm = new FormData();
      if (leFile && leFile.length > 0) {
        for (let i = 0; i < leFile.length; i++) {
          uploadForm.append("notesCommentsFiles", leFile[i].originFileObj);
        }
      }

      // Remove undefined values from the values object
      Object.keys(values).forEach(
        (key) => values[key] === undefined && delete values[key]
      );

      if (stateButton.button === 2) {
        uploadForm.append("bodyData", JSON.stringify(values)); // Attach other form
        setTableLoader(true);
        popupModalInstance
          .infoModal(ModalMessage.LE_Edit_Submit)
          .then(async (userChoice) => {
            if (userChoice === "ok") {
              if (fileKeysToDelete.length !== 0) {
                fileKeysToDelete.map(async (fileKey) => {
                  await deleteS3FileApi({ fileKey: fileKey, flag: "setup" });
                });
              }
              editLegalEntityApi(
                state.data && state.data._id ? state.data._id : state.data.id,
                uploadForm
              ).then((res) => {
                let ssLog = {
                  ss_id: res.id ? res.id : res._id,
                  ss_name: values["le_name"],
                  code: "legal_entity",
                  author: userData[0].id,
                  action: "edit",
                  notes: RecentJourneyMessage.EDIT_LEGAL_ENTITY,
                  updated_on: new Date(),
                };
                createActivityLogApi(ssLog);
                setTableLoader(false);
                navigate(`/system-setup/?back=org-les`);
              });
            }
            if (userChoice === "cancel") {
              setTableLoader(false);
            }
          });
      } else {
        uploadForm.append("bodyData", JSON.stringify(values)); // Attach other form
        setSaveTableLoader(true);
        if (fileKeysToDelete.length !== 0) {
          fileKeysToDelete.map(async (fileKey) => {
            await deleteS3FileApi({ fileKey: fileKey, flag: "setup" });
          });
        }
        await editLegalEntityApi(
          state.data && state.data._id ? state.data._id : state.data.id,
          uploadForm
        ).then((res) => {
          let ssLog = {
            ss_id: res.id ? res.id : res._id,
            ss_name: values["le_name"],
            code: "legal_entity",
            author: userData[0].id,
            action: "edit",
            notes: RecentJourneyMessage.EDIT_LEGAL_ENTITY,
            updated_on: new Date(),
          };
          createActivityLogApi(ssLog);
          setTableLoader(false);
          setSaveTableLoader(false);
          popupModalInstance.successModal(
            values.le_name,
            ModalMessage.LE_Create_Save,
            ""
          );
        });
      }
    } else {
      values["updated_by"] = userData[0].id;
      // Remove undefined values from the values object
      Object.keys(values).forEach(
        (key) => values[key] === undefined && delete values[key]
      );
      if (stateButton.button === 1) {
        if (fileKeysToDelete.length !== 0) {
          fileKeysToDelete.map(async (fileKey) => {
            await deleteS3FileApi({ fileKey: fileKey, flag: "setup" });
          });
        }

        setSaveTableLoader(true);
        values["le_status"] = "In Draft";

        const uploadForm = new FormData();
        if (leFile && leFile.length > 0) {
          for (let i = 0; i < leFile.length; i++) {
            uploadForm.append("notesCommentsFiles", leFile[i].originFileObj);
          }
        }

        if (leId === "") {
          values["created_by"] = userData[0].id;
          if (values.le_address === "") delete values.le_address;
          uploadForm.append("bodyData", JSON.stringify(values)); // Attach other form
          legalEntityCreateApi(uploadForm).then((res) => {
            if (res.status === 304) {
              popupModalInstance.errorModal(
                ModalMessage.LegalEntity_Create_Error
              );
              setSaveTableLoader(false);
            } else {
              setLeId(res.id ? res.id : res._id);
              let notes =
                state.flag === "clone"
                  ? RecentJourneyMessage.CLONE_LEGAL_ENTITY
                  : RecentJourneyMessage.SAVE_LEGAL_ENTITY;

              let ssLog = {
                ss_id: res.id ? res.id : res._id,
                ss_name: values["le_name"],
                code: "legal_entity",
                author: userData[0].id,
                action: "view",
                notes: notes,
                updated_on: new Date(),
              };

              createActivityLogApi(ssLog);
              setSaveTableLoader(false);
              popupModalInstance.successModal(
                values.le_name,
                ModalMessage.LE_Clone_Save,
                ``
              );
            }
          });
        } else {
          uploadForm.append("bodyData", JSON.stringify(values)); // Attach other form
          await editLegalEntityApi(leId, uploadForm).then((res) => {
            let ssLog = {
              ss_id: res.id ? res.id : res._id,
              ss_name: values["le_name"],
              code: "legal_entity",
              author: userData[0].id,
              action: "edit",
              notes: RecentJourneyMessage.SAVE_LEGAL_ENTITY,
              updated_on: new Date(),
            };
            createActivityLogApi(ssLog);
            setTableLoader(false);
            setSaveTableLoader(false);
            popupModalInstance.successModal(
              values.le_name,
              ModalMessage.LE_Create_Save,
              ""
            );
          });
        }
      }

      // submit button handler
      if (stateButton.button === 2) {
        setTableLoader(true);
        values["le_status"] = "Active";

        const uploadForm = new FormData();
        if (leFile && leFile.length > 0) {
          for (let i = 0; i < leFile.length; i++) {
            uploadForm.append("notesCommentsFiles", leFile[i].originFileObj);
          }
        }

        if (leId === "") {
          values["created_by"] = userData[0].id;
          uploadForm.append("bodyData", JSON.stringify(values)); // Attach other form
          // Determine the modal message based on the state flag
          const modalMessage =
            state.flag === "clone"
              ? ModalMessage.LE_Clone_Submit
              : ModalMessage.LE_Create_Submit;
          popupModalInstance
            .infoModal(modalMessage)
            .then(async (userChoice) => {
              if (userChoice === "ok") {
                if (fileKeysToDelete.length !== 0) {
                  fileKeysToDelete.map(async (fileKey) => {
                    await deleteS3FileApi({ fileKey: fileKey, flag: "setup" });
                  });
                }

                legalEntityCreateApi(uploadForm).then((res) => {
                  if (res.status === 304) {
                    popupModalInstance.errorModal(
                      ModalMessage.LegalEntity_Create_Error
                    );
                    setTableLoader(false);
                  } else {
                    let notes =
                      state.flag === "clone"
                        ? RecentJourneyMessage.CLONE_LEGAL_ENTITY
                        : RecentJourneyMessage.SUBMIT_LEGAL_ENTITY;

                    let ssLog = {
                      ss_id: res.id ? res.id : res._id,
                      ss_name: values["le_name"],
                      code: "legal_entity",
                      author: userData[0].id,
                      action: "view",
                      notes: notes,
                      updated_on: new Date(),
                    };
                    createActivityLogApi(ssLog);
                    setTableLoader(false);
                    navigate(`/system-setup/?back=org-les`);
                  }
                });
              }
              if (userChoice === "cancel") {
                setTableLoader(false);
              }
            });
        } else {
          uploadForm.append("bodyData", JSON.stringify(values)); // Attach other form
          popupModalInstance
            .infoModal(ModalMessage.LE_Edit_Submit)
            .then(async (userChoice) => {
              if (userChoice === "ok") {
                if (fileKeysToDelete.length !== 0) {
                  fileKeysToDelete.map(async (fileKey) => {
                    await deleteS3FileApi({ fileKey: fileKey, flag: "setup" });
                  });
                }

                await editLegalEntityApi(leId, uploadForm).then((res) => {
                  let ssLog = {
                    ss_id: res.id ? res.id : res._id,
                    ss_name: values["le_name"],
                    code: "legal_entity",
                    author: userData[0].id,
                    action: "edit",
                    notes:
                      state.flag === "clone"
                        ? RecentJourneyMessage.CLONE_LEGAL_ENTITY
                        : RecentJourneyMessage.SUBMIT_LEGAL_ENTITY,
                    updated_on: new Date(),
                  };
                  createActivityLogApi(ssLog);
                  setTableLoader(false);
                  navigate(`/system-setup/?back=org-les`);
                });
              }
              if (userChoice === "cancel") {
                setTableLoader(false);
              }
            });
        }
      }
    }
  };

  /* Function to handle the create legal entity get any error show the user
   * @param errorInfo - a  object of errors from backend
   */
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  // Function to handle the return of the data from the API
  const LegalEntityDetails = () => {
    const formItemLayout = {
      labelCol: {
        span: 24,
      },
      wrapperCol: {
        span: 18,
      },
    };

    return (
      <Row type="flex" className="m-0">
        <Col span={24}>
          <div className="org-view-div">
            <div className="m-20">
              <Row align="middle" type="flex">
                <Col span={8}>
                  <Form.Item
                    {...formItemLayout}
                    className="org-supplier-label"
                    name="le_name"
                    label="Legal Entity Name"
                    colon={false}
                    rules={[
                      {
                        required: "true",
                        message: "Please enter legal entity name",
                        pattern: /^(?!\s*$).+/,
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter Legal Entity Name"
                      disabled={state.flag === "view"}
                      onChange={(e) => onCreateLegalEntity("le_name", e)}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    {...formItemLayout}
                    className="org-supplier-label"
                    name="le_country"
                    label="Legal Entity Country"
                    colon={false}
                  >
                    {state.flag === "view" ? (
                      <Input
                        disabled={state.flag === "view"}
                        placeholder="choose country"
                        options={ContractCurrency.map((data) => ({
                          label: data.country,
                          value: data.country,
                        }))}
                      />
                    ) : (
                      <Select
                        disabled={state.flag === "view"}
                        onChange={(key, value) => {
                          onCreateLegalEntity("le_country", key, value);
                        }}
                        showSearch={true}
                        placeholder="choose country"
                        allowClear={true}
                        options={ContractCurrency.map((data) => ({
                          label: data.country,
                          value: data.country,
                        }))}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    {...formItemLayout}
                    className="org-supplier-label"
                    name="le_address"
                    label="Legal Entity Address"
                    colon={false}
                    rules={[
                      {
                        message: "Please enter legal entity address",
                        pattern: /^(?!\s*$).+/,
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter Legal Entity Address"
                      disabled={state.flag === "view"}
                      onChange={(e) => onCreateLegalEntity("le_address", e)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {state.flag === "edit" &&
                state.data &&
                state.data.le_status !== "In Draft" && (
                  <Row align="middle" type="flex">
                    <Col span={8}>
                      <Form.Item
                        {...formItemLayout}
                        className="org-supplier-label"
                        name="le_status"
                        label="Legal Entity Status"
                        colon={false}
                      >
                        <div className="association-radio-status">
                          <Radio.Group
                            buttonStyle="solid"
                            // onChange={(e) => setGroupStatus(e.target.value)}
                            value={status}
                          >
                            <Radio
                              value={"Active"}
                              onClick={(e) => handleRadioClick(e.target.value)}
                            >
                              Active
                            </Radio>
                            <Radio
                              value={"Inactive"}
                              onClick={(e) => handleRadioClick(e.target.value)}
                            >
                              Inactive
                            </Radio>
                          </Radio.Group>
                        </div>
                      </Form.Item>
                    </Col>
                  </Row>
                )}
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  const Associations = () => {
    return (
      <DataTable
        type="association"
        dataTableData={associationData}
        logData={state.data}
      />
    );
  };

  const EntityLog = () => {
    return <DataTable type="entityLog" logData={state.data} />;
  };

  // Variable to hold the component returned by the function.
  const items = [
    {
      key: "1",
      label: `Legal Entity Details`,
      children: LegalEntityDetails(),
      show: true,
    },
    {
      key: "divider1",
      label: <Divider className="tab-divider" type="vertical" />,
      disabled: true,
      show: state.flag === "view" || state.flag === "edit",
    },
    {
      key: "2",
      label: `Associations`,
      children: Associations(),
      show: state.flag === "view" || state.flag === "edit",
    },
    {
      key: "divider2",
      label: <Divider className="tab-divider" type="vertical" />,
      disabled: true,
      show: state.flag === "view",
    },
    {
      key: "3",
      label: `Entity Log`,
      children: EntityLog(),
      show: state.flag === "view",
    },
    {
      key: "divider3",
      label: <Divider className="tab-divider" type="vertical" />,
      disabled: true,
      show: true,
    },
    {
      key: "4",
      label: `Notes And Comments`,
      children: (
        <NotesAndComments
          detailData={state.data}
          viewedFile={viewedFile}
          comments={comments}
          url={url}
          setUrl={setUrl}
          setFile={setLeFile}
          setFileKeysToDelete={setFileKeysToDelete}
          setViewedFile={setViewedFile}
          setComments={setComments}
          flag="setup"
          setDisable={setDisable}
        />
      ),
      show: true,
    },
  ].filter((items) => items.show);

  useEffect(() => {
    document.title = "Legal Entity Setup | Judo";
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const handleTabChange = (key) => {
    setLeTabKey(key);
  };

  const saveNotesCommentsData = async () => {
    let data = {
      author:
        userData && userData[0] && userData[0].id
          ? userData[0].id
          : userData[0]._id
          ? userData[0]._id
          : "",
      author_name:
        userData && userData[0] && userData[0].first_name
          ? userData[0].first_name
          : "",
      file_notes_data: state.data.file_notes_data,
      comments: comments,
    };

    // Prepare form data
    const uploadForm = new FormData();
    if (leFile && leFile.length > 0) {
      for (let i = 0; i < leFile.length; i++) {
        uploadForm.append("file", leFile[i].originFileObj);
      }
    }
    uploadForm.append("uploadForm", JSON.stringify(data)); // Attach other form
    console.log("formdata", uploadForm);
    setNotesSaveLoader(true);

    if (fileKeysToDelete.length !== 0) {
      fileKeysToDelete.map(async (fileKey) => {
        await deleteS3FileApi({ fileKey: fileKey, flag: "setup" });
      });
    }

    await updateNotesCommentsApi(
      state.data && state.data._id,
      uploadForm,
      "le"
    ).then((res) => {
      if (res) setNotesSaveLoader(false);
    });
  };

  return (
    <Layout className="header-layout">
      <TopHeader
        headerChange={header}
        feedRow={false}
        searchRow={false}
        menuKey={menuKey}
        sticky=""
      />
      {mainPageloading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh - 60px)",
          }}
        >
          <LoadingOutlined
            style={{
              fontSize: 50,
              color: "var(--color-solid-darkerblue)",
            }}
            spin
          />
        </div>
      ) : accessGranted ? (
        <div className="system-setup1">
          <Form
            name="create-legal-entity"
            onFinish={onFinish}
            onKeyDown={handleKeyDown}
            onFinishFailed={onFinishFailed}
            initialValues={{
              ["le_name"]: state.data && state.data.le_name,
              ["le_country"]: state.data && state.data.le_country,
              ["le_address"]: state.data && state.data.le_address,
              ["le_status"]: state.data && state.data.le_status,
            }}
          >
            <Row align="middle" type="flex" gutter={[8, 16]} className="m-10">
              <Col
                className="vertical-align"
                xs={24}
                sm={state.flag === "view" ? 24 : 20}
                md={state.flag === "view" ? 24 : 21}
                lg={state.flag === "view" ? 24 : 21}
                xl={state.flag === "view" ? 24 : 18}
              >
                <HeaderCard
                  mainMenu="Organization Setup"
                  subMenu="Legal Entity Setup"
                  itemName={
                    state.flag === "create"
                      ? "New Legal Entity"
                      : state.flag === "clone"
                      ? "Clone Legal Entity"
                      : state.data?.le_name
                  }
                  itemStatus={state.data?.le_status}
                  goBackLink="/system-setup/?back=org-ccs"
                  state={state.data}
                  showDetails={state.flag === "view" || state.flag === "edit"}
                />
              </Col>
              {state.flag !== "view" && (
                <>
                  <Col
                    className="vertical-align"
                    xs={8}
                    sm={4}
                    md={3}
                    lg={3}
                    xl={2}
                  >
                    <CancelButton
                      onClick={() => navigate(`/system-setup/?back=org-ss`)}
                    />
                  </Col>
                  <Col
                    className="vertical-align"
                    xs={8}
                    sm={4}
                    md={3}
                    lg={3}
                    xl={2}
                  >
                    <SaveButton
                      onClick={() => (stateButton.button = 1)}
                      type="primary"
                      htmlType="submit"
                      loading={saveTableLoader}
                      disabled={
                        state.flag === "create"
                          ? legalEntityField.le_name
                            ? false
                            : true
                          : state.flag === "edit"
                          ? state.data && state.data.le_status !== "In Draft"
                            ? true
                            : legalEntityField.le_name
                            ? false
                            : true
                          : false
                      }
                    />
                  </Col>
                  <Col
                    className="vertical-align"
                    xs={8}
                    sm={4}
                    md={3}
                    lg={3}
                    xl={2}
                  >
                    <SubmitButton
                      onClick={() => (stateButton.button = 2)}
                      type="primary"
                      htmlType="submit"
                      loading={tableLoader}
                      disabled={
                        (state.data && state.data.le_status === "In Draft") ||
                        state.flag === "create" ||
                        state.flag === "clone"
                          ? legalEntityField.le_name &&
                            legalEntityField.le_country &&
                            legalEntityField.le_address
                            ? false
                            : true
                          : disable
                      }
                    />
                  </Col>
                </>
              )}
            </Row>
            <div className="price-profile-content m-10">
              <Row type="flex" gutter={[14, 0]} className="m-0">
                <Col span={24}>
                  <Tabs
                    items={items}
                    onChange={handleTabChange}
                    tabBarExtraContent={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginTop: -6,
                        }}
                      >
                        {state.flag === "view" && leTabKey === "4" && (
                          <SaveButton
                            loading={notesSaveLoader}
                            onClick={() => {
                              saveNotesCommentsData();
                            }}
                            style={{ width: 140 }}
                          />
                        )}
                      </div>
                    }
                  />
                </Col>
              </Row>
            </div>
          </Form>
        </div>
      ) : (
        <NotAuthorized redirect={`/system-setup/?back=org-les`} />
      )}
    </Layout>
  );
}

export default LegalEntityController;
