import React, { useState, useContext, useRef, useEffect } from "react";
import { useNavigate } from "react-router";
import {
  Form,
  Select,
  Row,
  Col,
  Button,
  Radio,
  Input,
  Divider,
  Modal,
  Upload,
  message,
  Space,
  Image,
} from "antd";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import confirmation from "../../assets/images/icons/confirmation.svg";
import { CreateContractContext } from "./ContractTabContext";
import {
  BackButton,
  CancelButton,
  CreateButtonShort,
  UploadButton,
} from "../GlobalButton";
import { HeaderChangerAtom, isLiteAtom } from "../../store/store";
import { useRecoilState } from "recoil";
import { determineContractClass, contractConfig } from "../../utils/common";
import { getPricingProfileList } from "../../api/pricingProfileApi";
import { contractExtractionApi } from "../../api/contractsApi";
import dayjs from "dayjs";
import { getContractTemplateApi } from "../../api/contractTemplateApi";
import { getAllWorkflowsApi } from "../../api/workflowApi";

const ContractCreateModal = ({ mode }) => {
  const { setCloneModalContent, selectedType, tabBreadcrumbs } = useContext(
    CreateContractContext
  );
  const navigate = useNavigate();
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);

  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [createBtnLoader, setCreateBtnLoader] = useState(false);
  const [parentData, setParentData] = useState();
  const [processorName, setProcessorName] = useState([
    {
      id: "projects/enmatter/locations/us/processors/a9a438f0ccae0e73",
      label: "Contract-Extraction",
    },
  ]);
  const [contractFile, setContractFile] = useState(null);
  const [extractionLoader, setExtractionLoader] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [contractModal, setContractModal] = useState(false);
  const [contractAuthoring, setContractAuthoring] = useState(1);
  const [contractTemplate, setContractTemplate] = useState([]);
  const [workflowList, setWorkflowList] = useState([]);
  const [pricingProfile, setPricingProfile] = useState([]);
  const [cePricingProfile, setCePricingProfile] = useState();
  const [basicdata, setBasicData] = useState();
  const [lite] = useRecoilState(isLiteAtom);

  useEffect(() => {
    getContractTemplateApi().then((res) => {
      setContractTemplate(res.docs);
    });

    getAllWorkflowsApi().then((data) => {
      setWorkflowList(data);
    });
  }, []);

  const onContractAuthoringChange = (e) => {
    console.log("radio checked", e.target.value);
    setContractAuthoring(e.target.value);
    if (e.target.value === 3) {
      const supplierId =
        tabBreadcrumbs.length >= 2
          ? tabBreadcrumbs[1].apiArgs.supplier_id
          : null;

      if (supplierId) {
        getPricingProfileList(supplierId).then((res) => {
          console.log("pricingRes: ", res);
          setPricingProfile(res);
        });
      }
    }
  };

  const onChangePricingProfile = (key, value) => {
    if (value) {
      console.log(key, value);
      let selectedPP = pricingProfile.filter((pp) => pp._id === value.key);
      setCePricingProfile(selectedPP[0]);
    }
  };

  const handleProcessorChange = (selectedId) => {
    console.log("selectedIddd", selectedId);
    const selectedProcessor = processorName.find(
      (proc) => proc.id === selectedId
    );
    if (selectedProcessor) {
      setProcessorName([selectedProcessor]);
    }
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
      handleProcessDocument(contractFile);
    }, 0);
  };

  const handleProcessDocument = async (file) => {
    if (!file) {
      message.error("Please select a file first!");
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = async () => {
      const base64Content = reader.result.split(",")[1];

      try {
        setExtractionLoader(true);
        let data = {
          processorName: processorName,
          documentContent: base64Content,
        };

        const res = await contractExtractionApi(data);
        console.log("ce-res", res);
        if (res) {
          setExtractionLoader(false);
          console.log("resres", res);
          // Process response to handle arrays
          const processedRes = {};
          for (const [key, value] of Object.entries(res)) {
            if (Array.isArray(value)) {
              processedRes[key] = value.join(", ");
            } else {
              processedRes[key] = value;
            }
          }
          console.log("Processed Response Data:", processedRes);
          console.log("commercialdata", res.commercialProperties);
          // message.success("Document processed successfully!");
          let key = 0;

          const prepareCommercialData = (commericalExtractionData) => {
            const getMonthsInRange = (startDate, endDate) => {
              const months = [];
              let currentDate = dayjs(startDate);

              while (
                currentDate.isBefore(endDate) ||
                currentDate.isSame(endDate, "month")
              ) {
                months.push({
                  year: currentDate.year(),
                  month: currentDate.format("MMM").toLowerCase(),
                });
                currentDate = currentDate.add(1, "month");
              }

              return months;
            };

            // Helper function to convert string to case-sensitive format (capitalize first letter)
            const toCaseSensitive = (text) => {
              return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
            };

            // Function to transform the data
            const transformData = (data) => {
              const result = [];

              data.forEach((itemArray) => {
                let days = 0;
                let cost = 0;
                let startDate, endDate;
                let quantity = 1;

                // Process each entry and map type to appropriate keys
                const baseObject = {};

                itemArray.forEach(({ type, mentionText }) => {
                  const caseSensitiveType = toCaseSensitive(type); // Convert type to case-sensitive
                  switch (caseSensitiveType) {
                    case "Days":
                      days = parseInt(mentionText);
                      break;
                    case "End_date":
                      endDate = dayjs(mentionText, "DD/MM/YYYY"); // Store as dayjs object
                      baseObject["End Date"] = mentionText; // Keep as dayjs
                      break;
                    case "Start_date":
                      startDate = dayjs(mentionText, "DD/MM/YYYY"); // Store as dayjs object
                      baseObject["Start Date"] = mentionText; // Keep as dayjs
                      break;
                    case "Total_cost":
                      cost = parseInt(mentionText);
                      break;
                    case "Grade":
                      baseObject["Grade"] = mentionText;
                      break;
                    case "Quantity":
                      quantity = parseInt(mentionText);
                      break;
                    case "Rate_basis":
                      baseObject["Rate Basis"] = mentionText;
                      break;
                    case "Rate_period":
                      baseObject["Rate Period"] = mentionText;
                      break;
                    case "Location":
                      baseObject["Location"] = mentionText;
                      break;
                    case "Role":
                      baseObject["Role"] = mentionText;
                      break;
                    case "Price":
                      baseObject["Price"] = mentionText;
                      break;
                    case "Currency":
                      baseObject["Currency"] = mentionText;
                      break;
                    default:
                      break;
                  }
                });

                // Calculate the distribution of days and costs
                const monthsInRange = getMonthsInRange(startDate, endDate);
                const daysPerMonth = Math.floor(days / monthsInRange.length);
                const remainingDays = days % monthsInRange.length;
                const costPerMonth = Math.floor(cost / monthsInRange.length);
                const remainingCost = cost % monthsInRange.length;

                const daysObj = {};
                const costObj = {};

                // Distribute days and costs across months
                monthsInRange.forEach((month, index) => {
                  const { year, month: monthKey } = month;
                  if (!daysObj[year]) daysObj[year] = {};
                  if (!costObj[year]) costObj[year] = {};

                  daysObj[year][monthKey] =
                    daysPerMonth + (index < remainingDays ? 1 : 0);
                  costObj[year][monthKey] =
                    costPerMonth + (index < remainingCost ? 1 : 0);
                });

                baseObject["Days"] = daysObj;
                baseObject["Cost"] = costObj;

                // Duplicate the object based on the quantity
                for (let i = 0; i < quantity; i++) {
                  result.push({ ...baseObject, key: key });
                  key++;
                }
              });

              return result;
            };

            const transformedData = transformData(commericalExtractionData);
            console.log("transformedData1", transformedData);
            return transformedData;
          };

          let commercialTestData = prepareCommercialData(
            res.commercialProperties
          );

          console.log("commercialTestData: ", commercialTestData);

          console.log("pricingProfile****************: ", pricingProfile);
          const keys =
            cePricingProfile && cePricingProfile.ratetable_data.rt_items_desc;
          let ratecardData =
            cePricingProfile &&
            cePricingProfile.ratetable_data.rt_items_detail.map((detail) => {
              let dataObj = {};
              for (let i = 0; i < keys.length; i++) {
                dataObj[keys[i]] = detail[i];
              }
              return dataObj;
            });
          console.log("ratecardDataaa", ratecardData);

          let filteredRC = ratecardData.map(
            ({ ["Item Id"]: _, ...rest }) => rest
          );
          console.log("filteredRC: ", filteredRC);

          // function to compare all the keys present in rateCardObj and ignore extra keys in commercialObj
          const compareObjects = (commercialObj, rateCardObj) => {
            // get the keys from rateCardObj
            const keysToCompare = Object.keys(rateCardObj);

            // check if all the keys in rateCardObj match in commercialObj
            return keysToCompare.every(
              (key) => commercialObj[key] === rateCardObj[key]
            );
          };

          // check if every object in commercialData has a matching object in rateCardData
          const isAllMatch = commercialTestData.every((commercialObj) =>
            filteredRC.some((rateCardObj) =>
              compareObjects(commercialObj, rateCardObj)
            )
          );
          console.log(isAllMatch);
          if (isAllMatch) {
            const newContractBreadcrumbs = [
              ...tabBreadcrumbs,
              {
                title: `New ${
                  selectedType === "MSA" ? "Framework Agreement" : selectedType
                }`,
                apiArgs: {
                  supplied_id: undefined,
                  contract_id: undefined,
                  type: `${selectedType}`,
                },
              },
            ];

            message.success("Document processed successfully!");
            console.log("---setTimeout---");
            setHeaderChanger({
              header: "contract",
              headerItem: "3",
              headerItemName: headerChanger.headerItemName,
              stateSetter: {
                ...basicdata,
                contractExtractData: processedRes.extractedData,
                commercialExtractionData: commercialTestData,
                mode: { view: false, edit: false, create: true },
                breadcrumbs: newContractBreadcrumbs,
                location: "createContractForm",
              },
            });
            navigate(`/contract/create/form`, {
              state: {
                data: basicdata,
                contractExtractData: processedRes.extractedData,
                commercialExtractionData: commercialTestData,
                mode: { view: false, edit: false, create: true },
                breadcrumbs: newContractBreadcrumbs,
                location: "createContractForm",
              },
            });
          } else {
            message.error(
              "Extracted Commercial Data doesn't match with RateCard"
            );
          }
        } else {
          message.error("Try again later");
        }
      } catch (error) {
        setExtractionLoader(false);
        console.error("Error processing document:", error);
        message.error("Error processing document...Try again later");
      }
    };
  };

  const handleFileChange = (info) => {
    const uploadFile = info.file.originFileObj;
    if (info.file.status === "done" || info.file.status === "uploading") {
      if (uploadFile.type !== "application/pdf") {
        if (!hasError) {
          message.error("Upload only PDF files");
          setHasError(true);
        }
        return;
      }
      setHasError(false);
      console.log("info", info);
      console.log("info-file", info.file);
      console.log("info-fileList", info.fileList);
      setContractFile(uploadFile);
    } else if (info.file.status === "error") {
      message.error({
        content: `${info.file.name} file upload failed.`,
        top: 150,
      });
    }
  };

  const onFinish = (value) => {
    console.log("values: ", value);

    let data;
    let template =
      contractTemplate &&
      contractTemplate.length !== 0 &&
      contractTemplate.filter((data) => data._id === value.contract_template);

    let commercialOption =
      value.commercial_optionality === 1
        ? "table"
        : value.commercial_optionality === 2
        ? "value"
        : value.commercial_optionality === 3
        ? "none"
        : "";

    if (selectedType !== "MSA") {
      data = {
        title: value.title,
        supplier_id: tabBreadcrumbs[1].apiArgs.supplier_id
          ? tabBreadcrumbs[1].apiArgs.supplier_id
          : undefined,
        selectedType: value.contractType,
        selectedFramework: selectedType,
        contract_template: template[0],
        workflow: workflowList.find((wf) => wf._id === value.workflow),
        contract_class: determineContractClass(
          selectedType,
          tabBreadcrumbs[tabBreadcrumbs.length - 1].apiArgs.type,
          contractConfig
        ),
        parentData,
        commercial_options: commercialOption,
        // pricing_profile: pricingProfile ? pricingProfile : "",
      };
    } else {
      data = {
        title: value.title,
        supplier_id: tabBreadcrumbs[1].apiArgs.supplier_id
          ? tabBreadcrumbs[1].apiArgs.supplier_id
          : undefined,
        selectedType: value.contractType,
        selectedFramework: selectedType,
        contract_template: template[0],
        workflow: workflowList.find((wf) => wf._id === value.workflow),
        pricing_profile: cePricingProfile ? cePricingProfile : "",
        contract_class: determineContractClass(
          selectedType,
          tabBreadcrumbs[tabBreadcrumbs.length - 1].apiArgs.type,
          contractConfig
        ),
        commercial_options: commercialOption,
      };
    }
    console.log("Values**", value);
    setBasicData(data);

    const newContractBreadcrumbs = [
      ...tabBreadcrumbs,
      {
        title: `New ${
          selectedType === "MSA" ? "Framework Agreement" : selectedType
        }`,
        apiArgs: {
          supplied_id: undefined,
          contract_id: undefined,
          type: `${selectedType}`,
        },
      },
    ];

    if (contractAuthoring === 1) {
      setContractModal(false);
      setHeaderChanger({
        header: "contract",
        headerItem: "3",
        headerItemName: headerChanger.headerItemName,
        stateSetter: data,
      });
      navigate(`/contract/create/onlineauthoring`, { state: data });
    } else if (contractAuthoring === 3) {
      setContractModal(true);
    } else {
      setContractModal(false);
      //   setHeaderChanger({
      //     header: "contract",
      //     headerItem: "3",
      //     headerItemName: headerChanger.headerItemName,
      //     stateSetter: {
      //       data,
      //       mode: { view: false, edit: false, create: true },
      //       breadcrumbs: newContractBreadcrumbs,
      //       location: "createContractForm",
      //     },
      //   });
      navigate(`/contract/clone/form`, {
        state: {
          data,
          mode: { view: false, edit: false, clone: true, create: false },
          breadcrumbs: newContractBreadcrumbs,
          location: "createContractForm",
        },
      });
      setParentData();
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Form
        className="mt-10 confirmation-form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        initialValues={{
          ["supplier"]:
            tabBreadcrumbs && tabBreadcrumbs.length >= 1
              ? tabBreadcrumbs[1] && tabBreadcrumbs[1].title
              : "",
          ["contractType"]:
            selectedType === "MSA" ? "Framework Agreement" : selectedType,
          ["workflow"]: parentData && parentData.parent_workflow_id,
        }}
      >
        <Space align="">
          <Image src={confirmation} preview={false} />
          <div>
            <h3>Confirmation</h3>
            <span style={{ fontSize: "12px" }}>
              For {mode.create ? "creating" : "cloning"} the{" "}
              {selectedType === "MSA" ? "Framework Agreement" : selectedType}
            </span>
          </div>
        </Space>
        <div
          style={{
            height: "calc(100vh - 200px)",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          {/* Contract Authoring */}
          <Row gutter={[16, 8]}>
            <Col span={24}>
              <Form.Item
                name="contract_authoring"
                label="Contract Authoring"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Please select Contract Authoring!",
                  },
                ]}
              >
                <Radio.Group
                  onChange={onContractAuthoringChange}
                  value={contractAuthoring}
                  style={{ width: "100%" }}
                >
                  <Row justify="center">
                    {!lite && (
                      <Col span={8}>
                        <Radio value={1} style={{ whiteSpace: "nowrap" }}>
                          Online Authoring
                        </Radio>
                      </Col>
                    )}
                    <Col span={lite ? 24 : 8}>
                      <Radio value={2} style={{ whiteSpace: "nowrap" }}>
                        Offline Authoring
                      </Radio>
                    </Col>
                    {!lite && (
                      <Col span={8}>
                        <Radio value={3}>Smart Authoring</Radio>
                      </Col>
                    )}
                  </Row>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          {/* Contract Commercial Optionality */}
          <Row gutter={[16, 8]}>
            <Col span={24}>
              <Form.Item
                name="commercial_optionality"
                label="Contract Commercials"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Please select contract commercial optionality!",
                  },
                ]}
              >
                <Radio.Group style={{ width: "100%" }}>
                  <Row justify="center">
                    <Col span={8}>
                      <Radio value={1} style={{ whiteSpace: "nowrap" }}>
                        Commercial Line-Items
                      </Radio>
                    </Col>
                    <Col span={8}>
                      <Radio value={2} style={{ whiteSpace: "nowrap" }}>
                        Commercial Value
                      </Radio>
                    </Col>
                    <Col span={8}>
                      <Radio value={3}>No Commercials</Radio>
                    </Col>
                  </Row>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          {/* Supplier &&  Contract Type */}
          <Row gutter={[16, 8]}>
            <Col span={12}>
              <Form.Item
                name="supplier"
                label="Supplier"
                labelCol={{ span: 24 }}
              >
                <Input disabled className="confirmation-input" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="contractType"
                label="Contract Type"
                labelCol={{ span: 24 }}
              >
                <Input disabled className="confirmation-input" />
              </Form.Item>
            </Col>
          </Row>

          {/* Title */}
          <Row gutter={[16, 8]}>
            <Col span={24}>
              <Form.Item
                name="title"
                label="Title"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Please enter Title!",
                    pattern: /^(?!\s*$).+/,
                  },
                ]}
              >
                <Input placeholder="Enter your title" className="" />
              </Form.Item>
            </Col>
          </Row>

          {/* Contract Template */}
          <Row gutter={[16, 8]}>
            <Col span={24}>
              <Form.Item
                name="contract_template"
                label="Contract Template"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Please select template!",
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch={true}
                  className=""
                  placeholder="Select Contract Template"
                  options={
                    contractTemplate &&
                    contractTemplate
                      .filter(
                        (data) =>
                          data.supplier_id ===
                          (tabBreadcrumbs.length >= 2
                            ? tabBreadcrumbs[1].apiArgs.supplier_id
                            : tabBreadcrumbs[tabBreadcrumbs.length - 1].apiArgs
                                .contract_id)
                      )
                      .map((data) => ({
                        key: data._id,
                        label: data.contract_template_name,
                        value: data._id,
                      }))
                  }
                ></Select>
              </Form.Item>
            </Col>
          </Row>

          {/* Smart Authoring */}
          {contractAuthoring && contractAuthoring === 3 && (
            <>
              {/* Pricing Profile */}
              <Row gutter={[16, 8]}>
                <Col span={24}>
                  <Form.Item
                    name="pricing_profile"
                    label="Pricing Profile"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "Please select pricing profile!",
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      onChange={(key, value) =>
                        onChangePricingProfile(key, value)
                      }
                      showSearch
                      className=""
                      placeholder="Select Pricing Profile"
                      options={
                        pricingProfile &&
                        pricingProfile
                          // .filter(
                          //   (data) =>
                          //     data.supplier_id ===
                          //     (tabBreadcrumbs.length >= 2
                          //       ? tabBreadcrumbs[1].apiArgs.supplier_id
                          //       : tabBreadcrumbs[tabBreadcrumbs.length - 1]
                          //           .apiArgs.contract_id)
                          // )
                          .map((data) => ({
                            key: data._id,
                            label: data.price_profile_name,
                            value: data._id,
                          }))
                      }
                    ></Select>
                  </Form.Item>
                </Col>
              </Row>
              {/* Processor */}
              <Row gutter={[16, 8]}>
                <Col span={24}>
                  <Form.Item
                    name="processor"
                    label="Processor"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "Please select Processor!",
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      onChange={(value) => handleProcessorChange(value)}
                      showSearch={true}
                      className=""
                      placeholder="Select Processor"
                      options={
                        processorName &&
                        processorName.map((data) => ({
                          key: data.id,
                          label: data.label,
                          value: data.id,
                        }))
                      }
                    ></Select>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}

          {/* Workflow */}
          <Row gutter={[16, 8]}>
            <Col span={24}>
              <Form.Item
                labelCol={{ span: 24 }}
                name="workflow"
                label="Workflow"
                rules={[
                  {
                    required: true,
                    message: "Please select workflow!",
                  },
                ]}
              >
                <Select
                  placeholder="Select workflow"
                  className=""
                  allowClear={true}
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider
                        style={{
                          margin: "8px 0",
                        }}
                      >
                        or
                      </Divider>
                      <Button
                        style={{
                          width: "100%",
                        }}
                        className="mb-5 create-btn"
                        icon={<PlusOutlined />}
                        onClick={() => {
                          navigate(`/system-setup`);
                          localStorage.setItem("wf-create", true);
                          setHeaderChanger({
                            header: "systemSetup",
                            headerItem: "2",
                            headerItemName: "systemSetup",
                          });
                          sessionStorage.setItem("conTabKey", 5);
                        }}
                      >
                        Create New Workflow
                      </Button>
                    </>
                  )}
                  options={
                    workflowList &&
                    workflowList.map((workflow) => ({
                      key: workflow._id,
                      label: workflow.workflow_name,
                      value: workflow._id,
                    }))
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Extraction Modal */}
          <Modal
            open={contractModal}
            className="maximize-batch-content"
            onCancel={() => setContractModal(false)}
            footer={null}
            centered
            closeIcon={null}
            width="90%"
          >
            {/* <div className="system-setup1"> */}
            <Row>
              <Col span={24} align="center">
                <div>
                  <h3>Enter Contract Details</h3>
                  <hr className="mt-10 view-supplier-hr" />
                </div>
              </Col>
            </Row>
            <div className="contract-div">
              <Row
                align="middle"
                gutter={[40, 16]}
                justify="center"
                className="mt-25"
              >
                <Col span={16} align="right" className="vertical-align">
                  <div className="choose-box-div">
                    <Form.Item>
                      <Row type="flex" gutter={[64, 56]} align={"middle"}>
                        <Col
                          span={24}
                          align="middle"
                          className="vertical-align"
                        >
                          <span className="upload-note">
                            Click below to autofill Contract details through
                            Uploading
                          </span>
                        </Col>
                        <Col
                          span={24}
                          align="middle"
                          className="vertical-align"
                        >
                          <Col span={16} style={{ marginRight: 5 }}>
                            <div className="main-test">
                              <Upload
                                // {...props}
                                accept=".pdf"
                                showUploadList
                                listType="picture"
                                maxCount={1}
                                // beforeUpload={() => false}
                                onChange={handleFileChange}
                                customRequest={dummyRequest}
                                className="contract_authority-upload-wrapper"
                              >
                                <Col
                                  span={24}
                                  align="middle"
                                  style={{
                                    display: "flex",
                                    justifyContent: "end",
                                    alignItems: "end",
                                  }}
                                >
                                  <Col span={24} style={{ marginRight: 5 }}>
                                    <UploadButton
                                      className="template-upload"
                                      type="primary"
                                      // onClick={() =>
                                      //   setOpenCreateModal(true)
                                      // }
                                    />
                                  </Col>
                                </Col>
                              </Upload>
                              {extractionLoader && (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    margin: 20,
                                  }}
                                >
                                  <LoadingOutlined
                                    style={{
                                      fontSize: 20,
                                      color: "var(--color-solid-darkerblue)",
                                    }}
                                    spin
                                  />
                                  <span
                                    style={{
                                      marginLeft: 10,
                                      color: "var(--color-solid-darkerblue)",
                                    }}
                                  >
                                    Extracting Contract Details&nbsp;
                                    <span className="ellipsis"></span>
                                  </span>
                                </div>
                              )}
                            </div>
                          </Col>
                        </Col>
                      </Row>
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </div>
            {/* </div> */}
          </Modal>
        </div>
        <Row type="flex" align="middle">
          <Col
            span={24}
            align="middle"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 20,
            }}
          >
            <Col span={7} style={{ marginRight: 5 }}>
              <BackButton onClick={() => setCloneModalContent("type")} />
            </Col>
            <Col span={7}>
              <CancelButton
                onClick={() => {
                  setOpenCreateModal(false);
                  setParentData();
                }}
              />
            </Col>
            <Col span={7} style={{ marginLeft: 5 }}>
              <CreateButtonShort htmlType="submit" />
            </Col>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default ContractCreateModal;
