import React, { useState, useContext } from "react";
import { Form, Select, Row, Col, Button } from "antd";
import {
  CreateContractContext,
  ContractTabContext,
} from "./ContractTabContext";
import { CancelButton, NextButton } from "../GlobalButton";
import CreateContractTree from "./CreateContractTree";
import TreePathRepresent from "./TreePathRepresent";
import ContractCreateModal from "./ContractCreateModal";

const ContractCloneModal = () => {
  const formItemLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const contractOptions = [
    { label: "Framework Agreement", value: "MSA" },
    { label: "SOW", value: "SOW" },
    { label: "Supplement", value: "Supplement" },
    { label: "CCN", value: "CCN" },
    { label: "CR", value: "CR" },
    { label: "Others", value: "Others" },
  ];

  const {
    setCloneModal,
    cloneModalContent,
    setCloneModalContent,
    setSelectedType,
    setTreePathRepresent,
    setSelectedKeys,
    setTabBreadcrumbs,
  } = useContext(CreateContractContext);
  const { defaultBreadcrumbs } = useContext(ContractTabContext);

  const onSelectContractType = (values) => {
    console.log("contract_type: ", values);
  };

  return (
    <>
      {cloneModalContent === "type" && (
        <Form onFinish={onSelectContractType}>
          <Form.Item
            name="contract_type"
            label="Contract Type"
            {...formItemLayout}
          >
            <Select
              options={contractOptions}
              style={{ width: "100%" }}
              placeholder="Select a contract type"
              onChange={(value) => setSelectedType(value)}
            />
          </Form.Item>
          <div>
            <Row gutter={[12, 12]} justify="center">
              <Col span={24}>
                <div style={{ height: "calc(100vh - 520px)" }}>
                  <CreateContractTree />
                </div>
              </Col>
              <Col span={24}>
                <div style={{ height: "calc(100vh - 520px)" }}>
                  <TreePathRepresent />
                </div>
              </Col>
              <Col span={8}>
                <CancelButton
                  onClick={() => {
                    setTreePathRepresent([]);
                    setSelectedKeys([]);
                    setTabBreadcrumbs([...defaultBreadcrumbs]);
                    setCloneModal(false);
                  }}
                />
              </Col>
              <Col span={8}>
                <NextButton onClick={() => setCloneModalContent("metadata")} />
              </Col>
            </Row>
          </div>
        </Form>
      )}
      {cloneModalContent === "metadata" && (
        <>
          <ContractCreateModal mode={{ create: false, clone: true }} />
        </>
      )}
    </>
  );
};

export default ContractCloneModal;
