import React, { useContext, useMemo } from "react";
import { Tree, Empty } from "antd";
import { CreateContractContext } from "./ContractTabContext";
import { getNodeIcon } from "../../utils/common";

const TreePathRepresent = () => {
  const { selectedType, treePathRepresent } = useContext(CreateContractContext);

  const treePathRepresentTitleRender = (node) => {
    const isRootNode = node.type === "Supplier";

    return (
      <div
        className={`tree-node-container ${isRootNode ? "root-node" : ""}`}
        style={{
          paddingLeft: isRootNode ? 0 : 15, // Indent child nodes
        }}
      >
        {!isRootNode && (
          <div className="tree-node-connector">
            <span
              style={{
                display: "flex",
                alignItems: "center",
                padding: "5px 0px",
                color: node.disabled
                  ? "var(--color-solid-darkgrey)"
                  : node.key.startsWith(`new-contract-${selectedType}`)
                  ? "var(--color-solid-darkerblue)"
                  : "var(--color-solid-darkergrey)",
              }}
            >
              {getNodeIcon(node.type, node.disabled ? "inactive" : "active")}
              <span
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontSize: 13,
                  fontWeight: 600,
                  width: "100%",
                }}
              >
                <span
                  style={{
                    borderRadius: 20,
                    padding: "3px 8px",
                    marginRight: 8,
                    background: "var(--color-solid-darkblue)",
                    color: "white",
                    fontSize: 10,
                  }}
                >
                  {node.type === "MSA" ? "Framework Agreement" : node.type}
                </span>
                {node.title}
              </span>
            </span>
          </div>
        )}
        {isRootNode && (
          <span
            style={{
              display: "flex",
              alignItems: "center",
              padding: "5px 0px",
              color: node.disabled
                ? "var(--color-solid-darkgrey)"
                : "var(--color-solid-darkergrey)",
            }}
          >
            {getNodeIcon(node.type, node.disabled ? "inactive" : "active")}
            <span
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontSize: 13,
                fontWeight: 600,
                width: "100%",
              }}
            >
              <span
                style={{
                  borderRadius: 20,
                  padding: "3px 8px",
                  marginRight: 8,
                  background: "var(--color-solid-darkblue)",
                  color: "white",
                  fontSize: 10,
                }}
              >
                {node.type === "MSA" ? "Framework Agreement" : node.type}
              </span>
              {node.title}
            </span>
          </span>
        )}
      </div>
    );
  };

  const getAllKeys = (nodes) => {
    let keys = [];
    nodes.forEach((node) => {
      keys.push(node.key);
      if (node.children) {
        keys = keys.concat(getAllKeys(node.children));
      }
    });
    return keys;
  };

  const allExpandedKeys = useMemo(() => {
    return getAllKeys(treePathRepresent);
  }, [treePathRepresent]);

  return (
    <>
      {treePathRepresent.length !== 0 ? (
        <div className="contract-path-container">
          <div className="contract-path-header">
            <span
              className="contract-path-title"
              style={{ color: "var(--color-solid-darkerblue)" }}
            >
              New Contract Path
            </span>
          </div>
          <div className="tree-represent-container">
            <Tree
              expandedKeys={allExpandedKeys}
              selectable={false}
              showIcon={false}
              blockNode
              className="create-contract-tree-represent"
              rootClassName="create-contract-tree-represent-wrapper"
              treeData={treePathRepresent}
              switcherIcon={false}
              titleRender={treePathRepresentTitleRender}
            />
          </div>
        </div>
      ) : (
        <div className="contract-path-container">
          <div className="contract-path-header">
            <span
              className="contract-path-title"
              style={{ color: "var(--color-solid-darkergrey)" }}
            >
              New Contract Path
            </span>
          </div>
          <div className="empty-container">
            <span className="empty-text">
              <Empty
                className="create-contract-tree-represent-empty"
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <span>
                    {`Select a ${
                      selectedType === "MSA" ? `supplier` : `parent contract`
                    } to establish a new `}
                    <span className="selected-type">
                      {selectedType === "MSA"
                        ? "Framework Agreement"
                        : selectedType}
                    </span>
                    {` under it`}
                  </span>
                }
              />
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default TreePathRepresent;
