import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Modal,
  Input,
  Form,
  Select,
  Upload,
  Button,
  message,
  Image,
} from "antd"; //antd components
import {
  UploadOutlined,
  CloseOutlined,
  CompressOutlined,
} from "@ant-design/icons";
import CancelButtonIcon from "../../assets/images/icons/cancel_button_icon.svg";
import { useRecoilState } from "recoil";
import { createUserApi } from "../../api/usersApi";
import { getAllRolesApi } from "../../api/rolesApi";
import { createActivityLogApi } from "../../api/logApi";
import { userDataAtom } from "../../store/store";
import PopupModal from "../../PopupModal";
import ModalMessage from "../../ModalMessage";
import {
  CancelButton,
  CreateButtonShort,
  OkButton,
  UploadButton,
} from "../GlobalButton";
import Flags from "react-world-flags";
import countryList from "country-list";
import { timezones } from "../../utils/TimeZone";

const CreateUser = ({ setOpen, listUsers }) => {
  // State variable to hold user information
  const userData = useRecoilState(userDataAtom);
  // State variable to hold PopupModal component
  const popupModalInstance = PopupModal();
  // Form variable to handle the form data
  const [form] = Form.useForm();
  // State variabel to hold role data
  const [roleData, setRoleData] = useState([]);
  // State variable to hold user form field data
  const [userFormField, setUserFormField] = useState({
    email: null,
    role: null,
    first_name: null,
    last_name: null,
    line_manager: null,
  });
  const [profilePictureBase64, setProfilePictureBase64] = useState(null);
  const [uploadRule, setUploadRule] = useState(false);
  const [uploadRuleText, setUploadRuleText] = useState("");
  const [createLoader, setCreateLoader] = useState(false);

  const countries = countryList
    .getData()
    .map((country) => ({
      value: country.code,
      label: country.name,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [timeZonesData, setTimeZonesData] = useState();
  const [timeZone, setTimeZone] = useState();
  /* Function to handle set user form details
   * @param label -  The name of the input field
   * @param input - the event object that contains the input value
   * @param key -  a unique identifier for each field in the form
   * @param value - a role select option value
   */
  const onCreateUser = (label, input, key, value) => {
    if (label === "first_name") {
      setUserFormField({
        ...userFormField,
        first_name: input.target.value,
      });
    } else if (label === "last_name") {
      setUserFormField({
        ...userFormField,
        last_name: input.target.value,
      });
    } else if (label === "email") {
      setUserFormField({
        ...userFormField,
        email: input.target.value,
      });
    } else if (label === "role") {
      setUserFormField({
        ...userFormField,
        role: key.value,
      });
    } else if (label === "line_manager") {
      setUserFormField({
        ...userFormField,
        line_manager: input.target.value,
      });
    }
  };
  const handleCountryChange = (selectedOption) => {
    if (selectedOption === undefined) {
      form.setFieldsValue({ timezone: null });
    } else {
      form.setFieldsValue({ timezone: null });
      setSelectedCountry(selectedOption);
      setTimeZonesData(
        timezones.filter((data) => data.countryCode === selectedOption)
      );
    }
  };
  const handleTimeZoneChange = (selectedOption) => {
    setTimeZone(selectedOption);
    console.log("selectedOption", selectedOption);
  };

  /* Function to handle the create user form data stored
   * @param values - a user create form values
   */
  const onFinishUser = async (values) => {
    console.log("values: ", values);
    let data = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      role: values.role,
      line_manager: values.line_manager,
      status: "Email Not Verified",
      country: values.country,
      timezone: values.timezone,
    };

    if (profilePictureBase64) {
      data = {
        ...data,
        profile_picture: profilePictureBase64,
      };
    }

    console.log("picture: ", profilePictureBase64);

    setCreateLoader(true);
    popupModalInstance
      .infoModal(ModalMessage.User_Create)
      .then(async (userChoice) => {
        if (userChoice === "ok") {
          await createUserApi(data).then((res) => {
            console.log("res: ", res);

            if (
              res.status === true &&
              res.data.status === "Email Not Verified"
            ) {
              let umLog = {
                ss_id: res.data.id ? res.data.id : res.data._id,
                ss_name: res.data.first_name,
                code: "users",
                author: userData[0].id,
                action: "view",
                notes: "Submitted the User",
                updated_on: new Date(),
              };
              createActivityLogApi(umLog).then((res) => {});
              setOpen(false);
              listUsers(1, 20, null);
            } else if (res.status === false && res.data.status === "Active") {
              popupModalInstance.errorModal(
                ModalMessage.User_Create_Active_Error
              );
              setCreateLoader(false);
            } else if (
              res.status === false &&
              (res.data.status === "Inactive" || res.data.status === "Closed")
            ) {
              popupModalInstance.errorModal(
                ModalMessage.User_Create_Inactive_Error
              );
              setCreateLoader(false);
            } else if (
              res.status === false &&
              res.data.status === "Email Not Verified"
            ) {
              popupModalInstance
                .infoModal(ModalMessage.User_Create_NotVerified_Error)
                .then(async (userChoice) => {
                  if (userChoice === "ok") {
                    setOpen(false);
                    listUsers(1, 20, null);
                  } else {
                    setCreateLoader(false);
                  }
                });
            } else {
              popupModalInstance.errorModal(
                "An error occured while creating the user"
              );
              setCreateLoader(false);
            }
          });
        } else {
          setCreateLoader(false);
        }
      });
  };

  /* Function to handle the create user get any error show the user
   * @param errorInfo - a  object of errors from backend
   */
  const onFinishFailedUser = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  /* Function to handle the get all roles and set the useState
   */
  // const getAllRoles = () => {
  //   getAllRolesApi().then((res) => {
  //     setRoleData(
  //       res.filter(
  //         (res) => res.role_name !== "Admin" && state.group_status === "Active"
  //       )
  //     );
  //   });
  // };

  const getAllRoles = () => {
    getAllRolesApi().then((res) => {
      setRoleData(
        res.filter(
          (role) => role.role_name !== "Admin" && role.status === "Active"
        )
      );
    });
  };

  /*  Function that will be called when the component initiate and call the getAllRoles function
   */
  useEffect(() => {
    getAllRoles();
    console.log("getAllRoles", getAllRoles);
  }, []);

  const formItemLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const props = {
    name: "file",
    listType: "picture",
    maxCount: 1,
    onChange(info) {
      if (info.file.status !== "uploading") {
        if (info.fileList.length !== 0) {
          const reader = new FileReader();
          reader.readAsDataURL(info.file.originFileObj);
          reader.onloadend = () => {
            setProfilePictureBase64(reader.result);
          };
        } else {
          setProfilePictureBase64(null);
        }
      }

      if (info.file.status === "done") {
        if (info.fileList.length !== 0) {
          const reader = new FileReader();
          reader.readAsDataURL(info.file.originFileObj);
          reader.onloadend = () => {
            setProfilePictureBase64(reader.result);
          };
        } else {
          setProfilePictureBase64(null);
        }
        message.success({
          content: `${info.file.name} file uploaded successfully`,
          top: 100,
        });
      } else if (info.file.status === "error") {
        message.error({
          content: `${info.file.name} file upload failed.`,
          top: 150,
        });
      }
    },
    beforeUpload(file) {
      const acceptedFormats = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/gif",
        "image/bmp",
        "image/webp",
        "image/svg+xml",
      ];
      const formatCheck = acceptedFormats.includes(file.type);

      if (!formatCheck) {
        setUploadRule(true);
        setUploadRuleText(
          "You can only upload JPEG, JPG, PNG, GIF, BMP, WEBP, or SVG images."
        );
        message.error({
          content:
            "You can only upload JPEG, JPG, PNG, GIF, BMP, WEBP, or SVG images.",
          top: 150,
        });
        return Upload.LIST_IGNORE;
      }

      const isSizeValid = file.size / 1024 / 1024 < 10; // Check if file size is less than 10MB

      if (!isSizeValid) {
        setUploadRule(true);
        setUploadRuleText("File size must be less than 10MB.");
        message.error({
          content: "File size must be less than 10MB.",
          top: 150,
        });
        return Upload.LIST_IGNORE;
      } else {
        setUploadRule(false);
        setUploadRuleText("");
      }

      return isSizeValid;
    },
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <>
      <Form
        form={form}
        className="mt-10 create-user"
        onFinish={onFinishUser}
        onKeyDown={handleKeyDown}
        onFinishFailed={onFinishFailedUser}
        autoComplete="off"
      >
        <>
          <Row gutter={[16, 8]}>
            <Col span={24}>
              <Form.Item
                name="first_name"
                label="First Name"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Please enter first name!",
                    pattern: /^(?!\s*$).+/,
                  },
                ]}
              >
                <Input
                  placeholder=""
                  className=""
                  onChange={(e) => onCreateUser("first_name", e)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 8]}>
            <Col span={24}>
              <Form.Item
                name="last_name"
                label="Last Name"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Please enter last name!",
                    pattern: /^(?!\s*$).+/,
                  },
                ]}
              >
                <Input
                  placeholder=""
                  className=""
                  onChange={(e) => onCreateUser("last_name", e)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 8]}>
            <Col span={24}>
              <Form.Item
                name="email"
                label="Email"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Please enter Email!",
                    validator: (_, value) => {
                      if (value && value.trim().length > 0) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "Email cannot be empty or contain only spaces!"
                        )
                      );
                    },
                  },
                ]}
              >
                <Input
                  placeholder=""
                  type="email"
                  className=""
                  onChange={(e) => onCreateUser("email", e)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 8]}>
            <Col span={24}>
              <Form.Item
                name="role"
                label="Role"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Please enter Role!",
                  },
                ]}
              >
                <Select
                  onChange={(key, value) => {
                    onCreateUser("role", key, value);
                  }}
                  showSearch={true}
                  className=""
                  placeholder="choose role"
                  allowClear={true}
                  options={roleData.map((data) => ({
                    label: data.role_name,
                    value: data.id,
                  }))}
                ></Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 8]}>
            <Col span={24}>
              <Form.Item
                name="line_manager"
                label="Line Manager"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Please enter Line Manager email!",
                    validator: (_, value) => {
                      if (value && value.trim().length > 0) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "Email cannot be empty or contain only spaces!"
                        )
                      );
                    },
                  },
                ]}
              >
                <Input
                  placeholder="Enter email"
                  className=""
                  type="email"
                  onChange={(e) => onCreateUser("line_manager", e)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 8]}>
            <Col span={24}>
              <Form.Item
                name="country"
                label="Country"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Please select country!",
                  },
                ]}
              >
                <Select
                  onChange={handleCountryChange}
                  showSearch={true}
                  className=""
                  placeholder="select Country"
                  allowClear={true}
                  options={countries}
                ></Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 8]}>
            <Col span={24}>
              <Form.Item
                name="timezone"
                label="TimeZone"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Please select TimeZone!",
                  },
                ]}
              >
                <Select
                  onChange={handleTimeZoneChange}
                  showSearch
                  className=""
                  placeholder="select TimeZone"
                  allowClear
                  options={
                    timeZonesData &&
                    timeZonesData[0] &&
                    timeZonesData[0].timeZones &&
                    timeZonesData[0].timeZones.map((data, index) => ({
                      label: `${data} (${timeZonesData[0].UTCOffset[index]})`,
                      value: `${data} (${timeZonesData[0].UTCOffset[index]})`,
                    }))
                  }
                ></Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 8]}>
            <Col span={24}>
              <Form.Item
                name="profile_picture"
                label="Upload Profile Picture"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                {...formItemLayout}
              >
                <Upload
                  name="userProfilePicture"
                  {...props}
                  customRequest={dummyRequest}
                  headers={{
                    "Access-Control-Allow-Origin": "*",
                  }}
                  className="custom-upload-wrapper"
                >
                  <Col span={6}>
                    <UploadButton />
                  </Col>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          <Row type="flex" align="middle" className="mt-10" justify="center">
            <Col span={6} style={{ marginRight: 5 }}>
              <CancelButton
                onClick={() => {
                  setOpen(false);
                }}
              />
            </Col>
            <Col span={6} style={{ marginLeft: 5 }}>
              <CreateButtonShort loading={createLoader} htmlType="submit" />
            </Col>
          </Row>
        </>
      </Form>
    </>
  );
};

export default CreateUser;
