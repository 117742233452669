import { useEffect, useState } from "react";
import { pdfjs } from "react-pdf";
import {
  fetchRecipientStatusApi,
  fetchSignedDocumentApi,
} from "../api/docuSignApi";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const DocuSignRecipients = ({ selectedFile }) => {
  const [recipientStatus, setRecipientStatus] = useState([]);
  const [lastSignerSigned, setLastSignerSigned] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pdfData, setPdfData] = useState(null);
  const envelopeId = selectedFile?.docusign_envelope_id;
  const contractId = selectedFile?._id;
  console.log("envelopeId", envelopeId);

  // get recipient status
  const fetchRecipientStatus = async () => {
    try {
      const response = await fetchRecipientStatusApi(envelopeId, contractId);
      if (response.success) {
        console.log("response", response);
        const signers = response.data.signers.map((signer) => {
          let displayStatus = signer.status;
          let signedTimestamp = null;

          if (signer.status === "declined" || signer.status === "rejected") {
            displayStatus = "Declined";
          } else if (signer.status === "sent") {
            displayStatus = "Current Signer";
          } else if (signer.status === "created") {
            displayStatus = "Signature pending";
          } else if (signer.status === "completed") {
            displayStatus = "Signed";
            signedTimestamp = signer.signedDateTime || signer.completedDateTime;
          }

          return {
            ...signer,
            displayStatus,
            signedTimestamp,
            declinedReason: signer.declinedReason || null,
            declinedDateTime: signer.declinedDateTime || null,
          };
        });

        setRecipientStatus(signers);

        const lastSigner = signers[signers.length - 1];
        if (lastSigner.status === "completed") {
          setLastSignerSigned(true);
        } else {
          setLastSignerSigned(false);
        }
      }
    } catch (error) {
      console.error("Error fetching recipient status:", error);
    }
  };

  // get signed document
  const fetchSignedDocument = async () => {
    try {
      const response = await fetchSignedDocumentApi(envelopeId);
      if (response.success) {
        const base64Pdf = response.documentBase64;
        const pdfBlob = base64ToBlob(base64Pdf, "application/pdf");
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setPdfData(pdfUrl);
      }
    } catch (error) {
      console.error("Error fetching signed document:", error);
    }
  };

  const base64ToBlob = (base64, mime) => {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mime });
  };

  useEffect(() => {
    if (envelopeId) {
      fetchRecipientStatus();

      const intervalId = setInterval(() => {
        fetchRecipientStatus();
      }, 10000);

      return () => clearInterval(intervalId);
    }
  }, [envelopeId]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      <h3>Recipient Status</h3>
      <ul>
        {recipientStatus &&
          recipientStatus.map((recipient, index) => (
            <li key={index}>
              <strong>Signer {index + 1}:</strong> {recipient.name} (
              {recipient.email}) - Status: {recipient.displayStatus}
              {recipient.signedTimestamp && (
                <span>
                  {" "}
                  - Signed at:{" "}
                  {new Date(recipient.signedTimestamp).toLocaleString()}
                </span>
              )}
              {recipient.displayStatus === "Declined" &&
                recipient.declinedReason && (
                  <span>
                    {" "}
                    - Declined Reason: {recipient.declinedReason}
                    {new Date(recipient.declinedDateTime).toLocaleString()}
                  </span>
                )}
            </li>
          ))}
      </ul>
      {/* {pdfData ? (
        <Button type="primary" onClick={showModal} disabled={!pdfData}>
          View Signed Document
        </Button>
      ) : (
        ""
      )}
      <Modal
        title="Signed Document"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={800}
      >
        {lastSignerSigned && pdfData ? (
          <Document file={pdfData} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
        ) : (
          <p>Loading document...</p>
        )}
      </Modal> */}
    </div>
  );
};

export default DocuSignRecipients;
