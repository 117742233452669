import React, { useEffect, useState } from "react";
import {
  Image,
  Row,
  Col,
  Input,
  Form,
  Layout,
  Tabs,
  Radio,
  Modal,
  Button,
} from "antd"; //antd components
import {
  UpOutlined,
  DownOutlined,
  EditOutlined,
  RightOutlined,
} from "@ant-design/icons";
import moment from "moment";
import TopHeader from "../Header";
import arrow_left from "../../assets/images/icons/arrow_left.svg";
import "../../assets/style/contractpipeline.css";
import "../../assets/style/setup.css";
import { useNavigate, useLocation } from "react-router-dom";
import { editGroupAPi } from "../../api/groupsApi";
import { createActivityLogApi } from "../../api/logApi";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  userDataAtom,
  HeaderChangerAtom,
  SupplierTreeDataAtom,
} from "../../store/store";
import PopupModal from "../../PopupModal";
import ModalMessage from "../../ModalMessage";
import { SubmitButton, CancelButton } from "../GlobalButton";
import EditGroupModal from "./EditGroupModal";
import { getGroupByUser } from "../../api/groupsApi";
import { gettRootActionByUser } from "../../api/actionManagementApi";
import { getWorkflowByUser } from "../../api/workflowApi";
import { getContractByUser } from "../../api/contractsApi";
import ContractFile from "../Contract/ContractFile";
import { dataTable } from "../../utils/common";
import { associationCol } from "../../utils/tableColumns";
import HeaderCard from "../HeaderCard";
import DataTable from "../Tables/DataTable";

const EditGroup = ({ groupData, openEditModel, groupTableData }) => {
  const supplierTreeData = useRecoilValue(SupplierTreeDataAtom); //supplier tree data from global state
  // State variable to hold header data
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  // Variable to use navigate function
  const navigate = useNavigate();
  // Variable to hold particular legal entity data
  let { state } = useLocation();
  if (state === null || state === undefined) {
    state = headerChanger.stateSetter;
  }

  const [editState, setEditState] = useState(state);
  console.log("FilteredState: ", editState); // Output the filtered leads array
  const [selectedContract, setSelectedContract] = useState();
  // State variable to hold contract id and path
  const [finalPath, setFinalPath] = useState([]);
  console.log("state", state);
  const menuKey = "7";
  // Variable to hold local header data
  let header = headerChanger.header;
  // Variable to hold the login user data
  const userData = useRecoilState(userDataAtom);
  // Varaiable to access the global popup
  const popupModalInstance = PopupModal();
  // State variable to hold button loading visibility
  const [loader, setLoader] = useState(false);
  // Variable to hold button disable css
  let disableVal = "disable-btn";
  // State variable to hold selected single group detail data
  const [tableData, setTableData] = useState([]);
  // State variable to hold sort order column data
  const [sortOrder, setSortOrder] = useState(null);
  // State variable to hold sort order column index
  const [sortedColumn, setSortedColumn] = useState(null);
  // State variable to hold button disable and enable status
  const [disable, setDisable] = useState(disableVal);
  // State variable to hold table loading visibility status
  const [submitLoader, setSubmitLoader] = useState(false);
  // State variable to hold Group's status
  const [groupStatus, setGroupStatus] = useState(editState.group_status);
  // State variable to hold Association data of this group
  const [associationData, setAssociationData] = useState([]);
  const [openEditModal, setOpenEditModal] = useState(false);

  // Function to filter out duplicate objects based on a unique identifier
  const filterDuplicates = (array, type) => {
    const uniqueObjects = new Map();

    if (type === "normal") {
      array.forEach((item) => {
        const key = item.email;

        if (!uniqueObjects.has(key)) {
          uniqueObjects.set(key, item);
        }
      });

      return Array.from(uniqueObjects.values());
    } else if (type === "nested") {
      array.forEach((item) => {
        const key = item.lead[0].email;
        if (!uniqueObjects.has(key)) {
          uniqueObjects.set(key, item);
        }
      });

      return Array.from(uniqueObjects.values());
    }
  };

  useEffect(() => {
    if (editState.group_selection === "Departmental Group") {
      editState.leads.forEach((leadObj) => {
        leadObj.lead = filterDuplicates(leadObj.lead, "normal");
      });

      if (editState.leads[0] && editState.leads[0].lead.length !== 0) {
        editState.leads = filterDuplicates(editState.leads, "nested");
      }
    }
    groupMembersTableData(editState);
  }, [editState]);

  // Variable to hold column data
  const isColumnSorted = (column) => sortedColumn === column;
  //  Function to handle sorting of table columns
  const handleTableChange = (_, __, sorter) => {
    if (sorter.column) {
      setSortedColumn(sorter.column.dataIndex);
      setSortOrder(sorter.order);
    } else {
      setSortedColumn(null);
      setSortOrder(null);
    }
  };

  //Function which returns column title along with sorting indicators
  const renderColumnTitle = (column_title, dataIndex) => {
    return (
      <>
        <Row align="middle" type="flex">
          <Col
            sm={18}
            md={20}
            lg={20}
            xl={21}
            className="sorted-title"
            title={`${column_title}`}
          >
            {column_title}
          </Col>
          <Col sm={6} md={4} lg={4} xl={3} align="right">
            {isColumnSorted(dataIndex) && (
              <div className="custom-sort">
                {sortOrder === "ascend" && <UpOutlined />}
                {sortOrder === "descend" && <DownOutlined />}
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  };

  // Variable to hold all column data
  const groupMemberColumns = [
    {
      title: renderColumnTitle("User Name", "user_name"),
      dataIndex: "user_name",
      align: "left",
      sorter: (a, b) => a.user_name.localeCompare(b.user_name),
      sortOrder: isColumnSorted("user_name") ? sortOrder : false,
    },
    {
      title: renderColumnTitle("Status", "status"),
      dataIndex: "status",
      align: "left",
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortOrder: isColumnSorted("status") ? sortOrder : false,
    },
    {
      title: renderColumnTitle("Email", "email"),
      dataIndex: "email",
      align: "left",
      sorter: (a, b) => a.email.localeCompare(b.email),
      sortOrder: isColumnSorted("email") ? sortOrder : false,
    },
    {
      title: renderColumnTitle("Role", "role"),
      dataIndex: "role",
      align: "left",
      sorter: (a, b) => a.role.localeCompare(b.role),
      sortOrder: isColumnSorted("role") ? sortOrder : false,
    },
  ];

  const [responseData, setResponseData] = useState([]); //tree data
  useEffect(() => {
    // set supplier tree data from global state
    if (supplierTreeData.length) {
      setResponseData([...supplierTreeData]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplierTreeData]);

  // secondary function for set path of selected node
  const getPath = (model, id) => {
    let path,
      item = {
        id: model.id,
        name: model.contract_name
          ? model.contract_name
          : model[`${model.contract_type.toLowerCase()}_name`],
      };
    if (!model || typeof model !== "object") return;
    if (model.id === id) return [item];
    (model.children || []).some((child) => (path = getPath(child, id)));
    return path && [item, ...path];
  };

  const getTopPath = (contract) => {
    supplierTreeData.some((res) => {
      const pathVal = getPath(res, contract._id);
      if (pathVal) {
        setFinalPath([...pathVal]);
        return pathVal;
      }
      return false;
    });
    setSelectedContract(contract);
  };

  // Function call when click the back button
  const backButton = () => {
    setSelectedContract();
    setHeaderChanger({
      header: "systemSetup",
      headerItem: "2",
      headerItemName: "systemSetup",
    });
  };

  const changeHomeTab = () => {
    backButton();
  };

  useEffect(() => {
    let allAssociations = [];
    getGroupByUser(state.email).then((res) => {
      console.log("group res", res);
      res.map((data) => {
        let tableRow = {
          associated_item: "Group",
          name: (
            <span
              onClick={() => {
                setHeaderChanger({
                  header: "userManagement",
                  headerItem: "3",
                  headerItemName: "userManagement",
                  stateSetter: data,
                });
                navigate(`/user-management/groups/view`, {
                  state: {
                    groupId: data._id ? data._id : data.id,
                    groupName: data.group_name,
                  },
                });
              }}
              style={{
                color: "var(--color-solid-darkblue)",
                fontWeight: "100",
                cursor: "pointer",
              }}
            >
              {data.group_name}
            </span>
          ),
          association_date: moment(data.created_on).format("DD-MMM-YY"),
        };
        allAssociations.push(tableRow);
      });
    });
    gettRootActionByUser(state._id).then((res) => {
      console.log("action res", res);
      res.map((data) => {
        let tableRow = {
          associated_item: "Root Action",
          name: (
            <span
              onClick={() => {
                let finalPathOnModify = [
                  { id: "home", name: "home", type: "" },
                ];
                responseData.some((res) => {
                  const pathVal = getPath(res, data._id);
                  if (pathVal) {
                    finalPathOnModify = [...finalPathOnModify, ...pathVal];
                  }
                });
                setHeaderChanger({
                  header: "actionManagement",
                  headerItem: "1",
                  headerItemName: "actionManagement",
                  stateSetter: {
                    actionData: data,
                    finalPath: finalPathOnModify,
                  },
                });
                navigate(`/action-management/view-action`, {
                  state: { actionData: data, finalPath: finalPathOnModify },
                });
              }}
              style={{
                color: "var(--color-solid-darkblue)",
                fontWeight: "100",
                cursor: "pointer",
              }}
            >
              {data.action_name}
            </span>
          ),
          association_date: moment(data.created_on).format("DD-MMM-YY"),
        };
        allAssociations.push(tableRow);
      });
    });
    getContractByUser(state._id).then((res) => {
      console.log("contract res", res);
      res.map((data) => {
        let tableRow = {
          associated_item: "Contract",
          name: (
            <span
              onClick={() => {
                setHeaderChanger({
                  header: "contract",
                  headerItem: "2",
                  headerItemName: "contract",
                  stateSetter: data,
                });
                getTopPath(data);
              }}
              style={{
                color: "var(--color-solid-darkblue)",
                fontWeight: "100",
                cursor: "pointer",
              }}
            >
              {data.contract_name}
            </span>
          ),
          association_date: moment(data.createdAt).format("DD-MMM-YY"),
        };
        allAssociations.push(tableRow);
      });
    });
    getWorkflowByUser(state._id).then((res) => {
      console.log("wf res", res);
      res.map((data) => {
        let tableRow = {
          associated_item: "Workflow",
          name: (
            <span
              onClick={() => {
                setHeaderChanger({
                  header: "systemSetup",
                  headerItem: "2",
                  headerItemName: "systemSetup",
                  stateSetter: data,
                });
                navigate(`/contract-setup/view/workflow`, {
                  state: data,
                });
              }}
              style={{
                color: "var(--color-solid-darkblue)",
                fontWeight: "100",
                cursor: "pointer",
              }}
            >
              {data.workflow_name}
            </span>
          ),
          association_date: moment(data.createdAt).format("DD-MMM-YY"),
        };
        allAssociations.push(tableRow);
      });
    });
    setAssociationData(allAssociations);
  }, [state]);

  // Function to handle get the particular group detail and
  const groupMembersTableData = (rawData) => {
    const departmentMembers =
      rawData.department_members &&
      rawData.department_members.length !== 0 &&
      rawData.department_members[0].length !== 0
        ? rawData.department_members.map((member, index) => ({
            key: `department_member_${index}`,
            user_name: member[0].first_name + " " + member[0].last_name,
            status: member[0].status,
            email: member[0].email,
            role: "Department Member",
          }))
        : [];

    const leadChildren =
      rawData.leads &&
      rawData.leads.length !== 0 &&
      rawData.leads[0].lead.length !== 0
        ? rawData.leads.map((leadObj, index) => ({
            key: `lead_${index}`,
            user_name:
              leadObj.lead[0].first_name + " " + leadObj.lead[0].last_name,
            email: leadObj.lead[0].email,
            status: leadObj.lead[0].status,
            role: "Lead",
            children: leadObj.lead_members.map((lead, index) => ({
              key: `${lead.first_name + lead.last_name}_lead_members_${index}`,
              user_name: lead.first_name + " " + lead.last_name,
              email: lead.email,
              status: lead.status,
              role: "Lead Member",
            })),
          }))
        : [];

    const children = departmentMembers.concat(leadChildren);

    let memberData =
      rawData.group_selection === "Functional Group"
        ? rawData.group_members.map((data, index) => ({
            key: index + 1,
            user_name: data[0].first_name + " " + data[0].last_name,
            email: data[0].email,
            role: data[0].roleDetail[0].role_name,
            status: data[0].status,
          }))
        : rawData.group_selection === "Departmental Group"
        ? [
            {
              key: "department_head",
              user_name:
                rawData.department_head[0].first_name +
                " " +
                rawData.department_head[0].last_name,
              email: rawData.department_head[0].email,
              status: rawData.department_head[0].status,
              role: "Department Head",
              children: children,
            },
          ]
        : [];

    console.log("memberData: ", memberData);

    setTableData(memberData);
  };

  /* Function to handle the create Group Data form data stored
   * @param values - a user create form values
   */
  const onFinish = (values) => {
    console.log("values edit", values);
    values["group_type"] = editState.group_type;
    values["group_members"] = editState.group_members.every(
      (member) => member.length === 0
    )
      ? []
      : editState.group_members.map((member) => {
          return member[0] ? member[0].email : [];
        });

    values["department_head"] = editState.department_head[0]
      ? editState.department_head[0].email
      : "N/A";
    values["department_members"] = editState.department_members.every(
      (member) => member.length === 0
    )
      ? []
      : editState.department_members.map((member) => {
          return member[0] ? member[0].email : [];
        });
    if (editState.leads[0] && editState.leads[0].lead.length !== 0) {
      values["leads"] = editState.leads.map((lead) => {
        return {
          lead: lead.lead[0].email,
          lead_members: lead.lead_members.map((member) => {
            return member.email;
          }),
        };
      });
    } else {
      values["leads"] = [];
    }
    //values["group_status"] = "Active";
    values["updated_by"] = userData[0].id;
    values["updated_on"] = new Date();
    console.log("values", values);
    setLoader(true);

    popupModalInstance
      .infoModal(ModalMessage.Group_Update)
      .then(async (userChoice) => {
        if (userChoice === "ok") {
          await editGroupAPi(editState._id, values).then((res) => {
            console.log("res", res);
            let umLog = {
              ss_id: res.id ? res.id : res._id,
              ss_name: res.group_name,
              code: "groups",
              author: userData[0].id,
              action: "edit",
              notes: "Edited Group",
              updated_on: new Date(),
            };
            createActivityLogApi(umLog).then((res) => {
              console.log("LogRes: ", res);
            });
            setLoader(false);
            navigate(`/user-management/?back=set-groups`);
          });
        }
        if (userChoice === "cancel") {
          setLoader(false);
        }
      });
  };

  /* Function to handle the create Group get any error show the user
   * @param errorInfo - a  object of errors from backend
   */
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleRadioClick = (value) => {
    console.log("status checked", value);
    if (
      value !== "Active" &&
      editState.group_status === "Active" &&
      associationData.length > 0
    ) {
      popupModalInstance
        .infoModal(ModalMessage.User_Status_Change)
        .then(async (userChoice) => {
          console.log("userChoice", userChoice);
          if (userChoice === "ok") {
            setGroupStatus(value);
            editState.group_status !== value
              ? setDisable("")
              : setDisable(disableVal);
          }
        });
    } else {
      popupModalInstance
        .infoModal(ModalMessage.User_Status_Change_Confirm)
        .then(async (userChoice) => {
          console.log("userChoice", userChoice);
          if (userChoice === "ok") {
            setGroupStatus(value);
            editState.group_status !== value
              ? setDisable("")
              : setDisable(disableVal);
          }
        });
    }
  };

  const GroupDetails = () => {
    const formItemLayout = {
      labelCol: {
        span: 24,
      },
      wrapperCol: {
        span: 18,
      },
    };
    return (
      <div className="org-view-div">
        <div className="m-20">
          <Row align="middle" type="flex">
            <Col span={12}>
              <Form.Item
                {...formItemLayout}
                className="org-supplier-label"
                name="group_name"
                label="GROUP NAME"
                colon={false}
                rules={[
                  {
                    message: "Please enter group name",
                    pattern: /^(?!\s*$).+/,
                  },
                ]}
              >
                <Input onChange={() => setDisable("")} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                {...formItemLayout}
                className="org-supplier-label"
                name="group_status"
                label="GROUP STATUS"
                colon={false}
              >
                <div className="association-radio-status">
                  <Radio.Group
                    buttonStyle="solid"
                    // onChange={(e) => setGroupStatus(e.target.value)}
                    value={groupStatus}
                  >
                    <Radio
                      value={"Active"}
                      onClick={(e) => handleRadioClick(e.target.value)}
                    >
                      Active
                    </Radio>
                    <Radio
                      value={"Suspended"}
                      onClick={(e) => handleRadioClick(e.target.value)}
                    >
                      Suspended
                    </Radio>
                    <Radio
                      value={"Terminated"}
                      onClick={(e) => handleRadioClick(e.target.value)}
                    >
                      Terminated
                    </Radio>
                  </Radio.Group>
                </div>
              </Form.Item>
            </Col>
            {tableData && tableData.length !== 0 && (
              <Col span={24}>
                <Row>
                  <Col span={24} align="left">
                    <Col span={3}>
                      <Button
                        type="primary"
                        shape="round"
                        icon={<EditOutlined />}
                        onClick={() => setOpenEditModal(true)}
                        style={{
                          height: 35,
                          width: "100%",
                          fontSize: 12,
                          fontWeight: 600,
                        }}
                      >
                        Edit Members
                      </Button>
                    </Col>
                  </Col>
                </Row>
                <DataTable
                  type="group-member"
                  dataTableData={tableData}
                  colData={groupMemberColumns}
                  showSorterTooltip={false}
                  onChange={handleTableChange}
                  expandable={{ defaultExpandAllRows: true }}
                />
                <Modal
                  width={600}
                  centered
                  title="Edit Group Members"
                  open={openEditModal}
                  footer={null}
                  // onOk={handleOk}
                  onCancel={() => setOpenEditModal(false)}
                >
                  <EditGroupModal
                    editState={editState}
                    setOpenEditModal={setOpenEditModal}
                    setEditState={setEditState}
                    setDisable={setDisable}
                  />
                </Modal>
              </Col>
            )}
          </Row>
        </div>
      </div>
    );
  };

  const Associations = () => {
    return <DataTable type="association" dataTableData={associationData} />;
  };

  const items = [
    {
      key: "1",
      label: `Group Details`,
      children: GroupDetails(),
    },
    {
      key: "2",
      label: `Associations`,
      children: Associations(),
    },
  ];

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <Layout className="header-layout">
      <TopHeader
        headerChange={header}
        feedRow={false}
        searchRow={false}
        menuKey={menuKey}
        sticky=""
      />
      {!selectedContract && (
        <div className="system-setup1">
          <Form
            className="create-role"
            onFinish={onFinish}
            onKeyDown={handleKeyDown}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            initialValues={{ ...state }}
          >
            <Row align="middle" type="flex" gutter={[8, 16]} className="m-10">
              <Col
                className="vertical-align"
                xs={24}
                sm={20}
                md={21}
                lg={21}
                xl={20}
              >
                <HeaderCard
                  mainMenu="User Management"
                  subMenu="Edit Group Details"
                  itemName={state.group_name}
                  // itemStatus={state.status}
                  goBackLink="/user-management/?back=set-groups"
                  // state={""}
                  // showDetails={"create"}
                />
              </Col>
              <Col
                className="vertical-align"
                xs={8}
                sm={4}
                md={3}
                lg={3}
                xl={2}
              >
                <CancelButton
                  onClick={() => navigate(`/user-management/?back=set-groups`)}
                />
              </Col>
              <Col
                className="vertical-align"
                xs={8}
                sm={4}
                md={3}
                lg={3}
                xl={2}
              >
                <SubmitButton
                  type="primary"
                  htmlType="submit"
                  loading={submitLoader}
                  disabled={disable === "disable-btn" ? true : false}
                />
              </Col>
            </Row>

            <div className="price-profile-content m-10">
              <Row type="flex" gutter={[14, 0]} className="m-0">
                <Col span={24}>
                  <Tabs items={items} />
                </Col>
              </Row>
            </div>
          </Form>
        </div>
      )}
      {selectedContract && (
        <div className="pipeline-click">
          <Row
            align="top"
            type="flex"
            gutter={[8, 0]}
            className="m-10"
            style={{ display: "none" }}
          >
            <Col className="" xs={24} sm={24} md={23} lg={23} xl={24}>
              <div className="pipeline-breadcrumb mt-10">
                <span>
                  <Image
                    src={arrow_left}
                    style={{ width: 25 }}
                    preview={false}
                    onClick={() => {
                      setHeaderChanger({
                        header: "systemSetup",
                        headerItem: "1",
                        headerItemName: "systemSetup",
                      });
                      setSelectedContract();
                    }}
                    className="cursor-pointer"
                  />
                  <span className="breadcrumb-path">
                    {finalPath &&
                      finalPath.map((item, i) => {
                        return (
                          <>
                            <span key={item.id} className="path-text-pipeline">
                              {item.name === "home" ? "Suppliers" : item.name}
                            </span>
                            <span
                              style={{
                                display:
                                  i === finalPath.length - 1
                                    ? "none"
                                    : "inline-block",
                              }}
                              className="path-icon"
                            >
                              <RightOutlined />
                            </span>
                          </>
                        );
                      })}
                  </span>
                </span>
              </div>
            </Col>
          </Row>
          <Row type="flex" gutter={[8, 0]}>
            <Col span={24}>
              <ContractFile
                pipelinePath={finalPath}
                backButton={backButton}
                selected={selectedContract}
                changeHomeTab={changeHomeTab}
              />
            </Col>
          </Row>
        </div>
      )}
    </Layout>
  );
};

export default EditGroup;
