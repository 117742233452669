import React, { useState, useEffect } from "react";
import { Row, Col, Table, Button, Modal, Pagination } from "antd"; //antd components
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import { useRecoilState } from "recoil";
import { createActivityLogApi } from "../../../api/logApi";
import "../../../assets/style/setup.css";
import "../../../assets/style/contractpipeline.css";
import { CreateButton } from "../../GlobalButton";
import ActionButton from "../../ActionButton";
import { useNavigate } from "react-router-dom";
import RecentJourneyMessage from "../../../RecentJourneyMessage";
import {
  SupplierSetupUpdateAtom,
  HeaderChangerAtom,
  SupplierNameAtom,
  userDataAtom,
  RoleAccessAtom,
} from "../../../store/store";
import {
  suppliersPaginationApi,
  deleteSupplierApi,
  allSuppliersOnlyApi,
} from "../../../api/suppliersApi";
import moment from "moment";
import PopupModal from "../../../PopupModal";
import ModalMessage from "../../../ModalMessage";
import GlobalFilter from "../../GlobalFilter";

const SupplierSetup = () => {
  const popupModalInstance = PopupModal();
  //global state variable hold the dashboard user data to display
  const userData = useRecoilState(userDataAtom);
  //global state variable hold the navigating different routes
  const navigate = useNavigate();
  //state variable hold the recoil value about supplier setup
  const [supplierSetupUpdate, setSupplierSetupUpdate] = useRecoilState(
    SupplierSetupUpdateAtom
  );
  // state variable hold the header change value
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  //state variable hold the selectedd column data
  let selectedCol = supplierSetupUpdate.selectedCol;
  //state variable hold the unselectedd column data
  let unSelectedCol = supplierSetupUpdate.unSelectedCol;
  // State variable to hold all legal entity data from DB
  const [tableData, setTableData] = useState([]);
  // State variable to hold the editor true or false
  const [colEditor, openColEditor] = useState(false);
  // State variable to hold selected row key
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchVal, setSearchVal] = useState("null");
  // State variable to hold screen loading visibility
  const [loader, setLoader] = useState(false);
  //state variable hold the supplier data
  const [supplierData, setSupplierData] = useState([]);
  // State variable to hold user role deta
  const roleUser = useRecoilState(RoleAccessAtom); //global state
  // State variable to hold sort order for ascending & descending
  const [sortOrder, setSortOrder] = useState("ascend");
  // State variable to hold sorted the column names
  const [sortedColumn, setSortedColumn] = useState(null);
  // State variable to hold all filter conditions
  const [allFilterVal, setAllFilterVal] = useState({});
  const [sortColumnOptions, setSortColumnOptions] = useState({});

  // console.log("roleUser", roleUser);
  const [supplierName, setSupplierName] = useRecoilState(SupplierNameAtom);

  // Variablr to hold filter column with sorted
  const isColumnSorted = (column) => sortedColumn === column;

  /* Function to handle add sort condition to set the state
   * @Param sorter - a hold sorting condition
   */
  const handleTableChange = (_, __, sorter) => {
    const { column, order } = sorter;
    const newOrder = order || (sortOrder === "ascend" ? "descend" : "ascend");

    const sortOptions = column
      ? {
          column_name: column.dataIndex,
          order: newOrder,
        }
      : {
          ...sortColumnOptions,
          order: newOrder,
        };

    if (column) {
      setSortedColumn(column.dataIndex);
    }

    setSortColumnOptions(sortOptions);
    setSortOrder(newOrder);

    listSupplier(
      paginateData.pageNo,
      paginateData.limit,
      searchVal,
      allFilterVal,
      sortOptions
    );
  };

  //Function which returns column title along with sorting indicators
  const renderColumnTitle = (column_title, dataIndex) => {
    return (
      <>
        <Row align="middle" type="flex">
          <Col
            sm={18}
            md={20}
            lg={20}
            xl={21}
            className="sorted-title"
            title={`${column_title}`}
          >
            {column_title}
          </Col>
          <Col sm={6} md={4} lg={4} xl={3} align="right">
            {isColumnSorted(dataIndex) && (
              <div className="custom-sort">
                {sortOrder === "ascend" && <UpOutlined />}
                {sortOrder === "descend" && <DownOutlined />}
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  };

  const allColumns = [
    {
      title: renderColumnTitle("Supplier Name", "supplier_name"),
      dataIndex: "supplier_name",
      align: "left",
      width: 350,
      show: selectedCol.includes("Supplier Name") ? true : false,
      sorter: true,
      sortOrder: sortedColumn === "supplier_name" ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("Supplier Status", "supplier_status"),
      dataIndex: "supplier_status",
      align: "left",
      show: selectedCol.includes("Supplier Status") ? true : false,
      sorter: true,
      sortOrder: sortedColumn === "supplier_status" ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("Updated By", "updated_by"),
      dataIndex: "updated_by",
      align: "left",
      show: selectedCol.includes("Updated By") ? true : false,
      sorter: true,
      sortOrder: sortedColumn === "updated_by" ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: renderColumnTitle("Updated On", "updated_on"),
      dataIndex: "updated_on",
      align: "left",
      show: selectedCol.includes("Updated On") ? true : false,
      sorter: true,
      sortOrder: sortedColumn === "updated_on" ? sortOrder : false,
      ellipsis: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      width: 250,
      show:
        roleUser &&
        roleUser[0] &&
        (roleUser[0].page_access.system_setup.supplier_setup.view ||
          roleUser[0].page_access.system_setup.supplier_setup.create)
          ? true
          : false,
    },
  ];

  // State variabel to hold filter the all column datas
  const columnToShow = allColumns.filter((col) => col.show !== false);

  // State variable to hold pagination data
  const [paginateData, setPaginateData] = useState({
    pageNo: 1,
    limit: 20,
    total: 0,
  });

  const handleDeleteSupplier = (
    supplierId,
    supplier_name,
    inputSupplierName
  ) => {
    if (inputSupplierName === supplier_name) {
      deleteSupplierApi(supplierId).then((res) => {
        let umLog = {
          ss_id: supplierId,
          ss_name: supplier_name,
          code: "supplier_setup",
          author: userData[0].id,
          action: "delete",
          notes: RecentJourneyMessage.DELETE_SUPPLIER,
          updated_on: new Date(),
        };
        createActivityLogApi(umLog);
        popupModalInstance
          .successModal(supplier_name, ModalMessage.Supplier_Delete_Success, "")
          .then(async (userChoice) => {
            if (userChoice === "ok") {
              listSupplier(1, 20, searchVal, {}, sortColumnOptions);
            }
          });
      });
    } else {
      popupModalInstance.errorModal(ModalMessage.Supplier_Delete_Error);
    }
  };

  const handleViewSupplier = (data) => {
    let ssLog = {
      ss_id: data.id ? data.id : data._id,
      ss_name: data.supplier_name,
      code: "supplier_setup",
      author: userData[0].id,
      action: "view",
      notes: RecentJourneyMessage.VIEW_SUPPLIER,
      updated_on: new Date(),
    };
    createActivityLogApi(ssLog).then((res) => {
      console.log("LogRes: ", res);
    });
  };

  /* Function to handle the get Fx table data with pagination
   * @param pageNo - current active page number
   * @param limit - number of records per page
   * @param allFilterVal - filter column and value
   */
  const listSupplier = (page, limit, searchVal, allFilterVal, sortColumn) => {
    console.log(page, limit, searchVal, allFilterVal, sortColumn);

    setLoader(true);
    suppliersPaginationApi(
      userData[0].id,
      page,
      limit,
      searchVal,
      allFilterVal,
      sortColumn
    ).then((res) => {
      setLoader(false);
      console.log("res--", res);
      setSupplierData(res.docs);
      setPaginateData({
        ...paginateData,
        pageNo: page,
        limit: limit,
        total: res.totalDocs,
      });
      let supplierMaster = res.docs.map((data) => {
        // delete data.children;
        let tableRow = {
          key: data.id ? data.id : data._id,
          supplier_id: data.id ? data.id : data._id,
          supplier_name: (
            <span
              // onClick={() => rowClick(data.id)}
              onClick={() => {
                if (
                  roleUser &&
                  roleUser[0] &&
                  roleUser[0].page_access.system_setup.supplier_setup.view
                ) {
                  handleViewSupplier(data);
                  setHeaderChanger({
                    header: "systemSetup",
                    headerItem: "1",
                    headerItemName: "systemSetup",
                    stateSetter: data,
                  });
                  navigate(`/organization-setup/view/supplier-setup`, {
                    state: { data, flag: "view" },
                  });
                }
              }}
              style={{
                color:
                  roleUser &&
                  roleUser[0] &&
                  roleUser[0].page_access.system_setup.supplier_setup.view
                    ? "var(--color-solid-darkblue)"
                    : "var(--color-solid-black)",
                fontWeight: "100",
                cursor:
                  roleUser &&
                  roleUser[0] &&
                  roleUser[0].page_access.system_setup.supplier_setup.view
                    ? "pointer"
                    : "default",
              }}
            >
              {data.supplier_name}
            </span>
          ),
          // supplier_name_short: data.supplier_name_short
          //   ? data.supplier_name_short
          //   : "N/A",
          supplier_status: data.supplier_status,
          created_on: moment(data.createdAt).format("DD-MMM-YY"),
          created_by: data.created_user_name,
          updated_on: moment(data.updatedAt).format("DD-MMM-YY"),
          updated_by: data.updated_user_name,
          action: (
            <ActionButton
              AccessCheck={
                roleUser &&
                roleUser[0] &&
                roleUser[0].page_access.system_setup.supplier_setup
              }
              data={data}
              dataStatus={data.supplier_status}
              itemName={data.supplier_name}
              setHeaderChanger={setHeaderChanger}
              headerValue={{
                header: "systemSetup",
                headerItem: "1",
                headerItemName: "systemSetup",
              }}
              menuItem="organization-setup"
              subMenuItem="supplier-setup"
              // viewPath={`/organization-setup/view/supplier-setup`}
              // editPath={`/organization-setup/edit/supplier-setup`}
              // clonePath={`/organization-setup/clone/supplier-setup`}
              deleteInfo={ModalMessage.Supplier_Delete_Info}
              handleDelete={handleDeleteSupplier}
              handleView={handleViewSupplier}
            />
          ),
        };
        return tableRow;
      });
      console.log("supplierMaster", supplierMaster);
      setTableData(supplierMaster);
    });
  };

  // store supplier_name in global state
  const getAllSuppliers = async () => {
    await allSuppliersOnlyApi().then((res) => {
      setSupplierName(res.map((i) => i.supplier_name));
    });
  };

  useEffect(() => {
    getAllSuppliers();
  }, []);

  useEffect(() => {
    //To set default sorting on load, for Updated On column of the table
    handleTableChange("", "", {
      column: { dataIndex: "updated_on" },
      order: "descend",
    });
  }, [searchVal]);

  const onPageChange = (page, limit) => {
    listSupplier(page, limit, searchVal, allFilterVal, sortColumnOptions);
  };

  const rowClick = (rowId) => {
    console.log("rowId", rowId);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const selectCol = (currency) => {
    setSupplierSetupUpdate({
      selectedCol: [...selectedCol, currency],
      unSelectedCol: unSelectedCol.filter((col) => col !== currency),
    });
  };

  const unSelectCol = (currency) => {
    setSupplierSetupUpdate({
      unSelectedCol: [...unSelectedCol, currency],
      selectedCol: selectedCol.filter((col) => col !== currency),
    });
  };

  const onChangeSearch = (e) => {
    let searchValue = e.target.value;
    searchValue = searchValue.toLowerCase();
    // console.log("searchValue", searchValue);
    if (searchValue === "") {
      setSearchVal("null");
    } else {
      setSearchVal(searchValue);
    }
  };

  //Code below is for Global Filters

  /* Function to handle after select the filter data to filter the table data, after the change the
     allFilterVal state
   */
  useEffect(() => {
    if (Object.keys(allFilterVal || {}).length === 0) {
      listSupplier(1, 20, searchVal, allFilterVal, sortColumnOptions);
    } else {
      for (let key in allFilterVal) {
        if (
          Array.isArray(allFilterVal[key]) &&
          allFilterVal[key].length === 0
        ) {
          delete allFilterVal[key];
        }
      }
      if (Object.keys(allFilterVal || {}).length === 0) {
        listSupplier(1, 20, searchVal, allFilterVal, sortColumnOptions);
      } else {
        listSupplier(1, 20, searchVal, allFilterVal, sortColumnOptions);
      }
    }
  }, [allFilterVal]);

  useEffect(() => {
    document.title = "Supplier Setup | Judo";
  }, []);

  const onCreateSupplierSetup = () => {
    navigate(`/organization-setup/create/supplier-setup`, {
      state: { data: {}, flag: "create" },
    });
  };

  return (
    <div id="sys-setup-id" className="contract-pipeline">
      <Row align="middle" type="flex" gutter={[12, 0]} className="m-0">
        <Col
          className="p-0 vertical-align"
          xs={24}
          sm={24}
          md={
            roleUser &&
            roleUser[0] &&
            roleUser[0].page_access.system_setup.supplier_setup.create
              ? 15
              : 24
          }
          lg={
            roleUser &&
            roleUser[0] &&
            roleUser[0].page_access.system_setup.supplier_setup.create
              ? 15
              : 24
          }
          xl={
            roleUser &&
            roleUser[0] &&
            roleUser[0].page_access.system_setup.supplier_setup.create
              ? 22
              : 24
          }
        >
          <GlobalFilter
            selectedTab={"supplier"}
            initialTableData={tableData}
            allFilterVal={allFilterVal}
            setAllFilterVal={setAllFilterVal}
          />
        </Col>
        {roleUser &&
          roleUser[0] &&
          roleUser[0].page_access.system_setup.supplier_setup.create && (
            <Col xl={2} md={4} xs={24} className="vertical-align">
              <CreateButton onClick={onCreateSupplierSetup} />
            </Col>
          )}
      </Row>

      <div className="mt-10">
        <Table
          // rowSelection={rowSelection}
          showSorterTooltip={false}
          dataSource={tableData}
          columns={columnToShow}
          onChange={handleTableChange}
          bordered
          size="large "
          loading={loader}
          scroll={
            tableData.length === 0
              ? {}
              : { x: "calc(500px + 50%)", y: "calc(100vh - 255px)" }
          }
          pagination={false}
          id="supplier-setup-table"
          className="system-setup-table"
          expandable={{
            expandedRowRender: (record) => (
              <div className="float-left text-left supplier-collapse-details">
                <h4>Details About the supplier Entities:</h4>
                <Row
                  align="middle"
                  type="flex"
                  gutter={[24, 0]}
                  className="mb-10 collapse-head"
                >
                  <Col className="p-0" xs={24} sm={24} md={8} lg={8} xl={8}>
                    <p>Supplier Entity Name</p>
                  </Col>
                  <Col className="p-0" xs={24} sm={24} md={8} lg={8} xl={8}>
                    <p>Supplier Country</p>
                  </Col>
                  <Col className="p-0" xs={24} sm={24} md={8} lg={8} xl={8}>
                    <p>Supplier Address</p>
                  </Col>
                </Row>
                {supplierData
                  .filter((data) =>
                    data.id ? data.id : data._id === record.key
                  )[0]
                  .supplier_entities.map((entity) => (
                    <Row
                      align="middle"
                      type="flex"
                      gutter={[24, 0]}
                      className="mb-10"
                    >
                      <Col className="p-0" xs={24} sm={24} md={8} lg={8} xl={8}>
                        <p>{entity.supplier_entity}</p>
                      </Col>
                      <Col className="p-0" xs={24} sm={24} md={8} lg={8} xl={8}>
                        <p>{entity.supplier_country}</p>
                      </Col>
                      <Col className="p-0" xs={24} sm={24} md={8} lg={8} xl={8}>
                        <p>{entity.supplier_address}</p>
                      </Col>
                    </Row>
                  ))}
              </div>
            ),
          }}
          // onRow={(record, rowIndex) => {
          //   return {
          //     // onClick: () => { rowClick(record.key) },
          //     onMouseOver: () => {
          //       setShowEditOf(record.key);
          //       // console.log(record.key);
          //     },
          //     onMouseOut: () => {
          //       setShowEditOf(null);
          //     },
          //   };
          // }}
        />
        <div className="float-right mb-15 mt-15">
          <Pagination
            // simple
            // pageSizeOptions={[5, 10, 20, 50, 100]}
            defaultPageSize={paginateData.limit}
            responsive
            current={paginateData.pageNo}
            total={paginateData.total}
            showSizeChanger
            onShowSizeChange={onPageChange}
            onChange={onPageChange}
          />
        </div>
      </div>

      {colEditor && (
        <Modal
          className="col-edit-modal"
          centered
          open={colEditor}
          onOk={() => openColEditor(false)}
          onCancel={() => openColEditor(false)}
          closable={false}
          footer={null}
        >
          <div>
            <span className="col-editor-head">
              Select the tag to show in Supplier Setup.
            </span>
            <span
              className="float-right col-editor-save"
              onClick={() => openColEditor(false)}
            >
              save
            </span>
          </div>
          <div className="col-list p-10 mt-15">
            <Row gutter={[8, 16]}>
              {selectedCol &&
                selectedCol.length !== 0 &&
                selectedCol.map((col) => (
                  <Col
                    xs={12}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                    className="vertical-align"
                  >
                    <Button
                      type="primary"
                      className="selected-btn"
                      style={{
                        width: "100%",
                        whiteSpace: "normal",
                        minHeight: "100%",
                      }}
                      onClick={() => {
                        unSelectCol(col);
                      }}
                    >
                      {col}
                    </Button>
                  </Col>
                ))}
            </Row>
            <Row className="mt-20" gutter={[8, 16]}>
              {unSelectedCol &&
                unSelectedCol.length !== 0 &&
                unSelectedCol.map((col) => (
                  <Col
                    xs={12}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                    className="vertical-align"
                  >
                    <Button
                      type="primary"
                      className="select-btn"
                      style={{
                        width: "100%",
                        whiteSpace: "normal",
                        minHeight: "100%",
                      }}
                      onClick={() => {
                        // selectedCol.push(col);
                        selectCol(col);
                      }}
                    >
                      {col}
                    </Button>
                  </Col>
                ))}
            </Row>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default SupplierSetup;
