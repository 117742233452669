import React, { useState, useEffect, useForm } from "react";
import {
  Card,
  Row,
  Col,
  Image,
  Button,
  Input,
  Upload,
  Table,
  message,
  Modal,
  Switch,
  Tag,
  Select,
} from "antd"; //antd components
import { LoadingOutlined } from "@ant-design/icons";
import { Form, Layout } from "antd"; //antd components
import { useNavigate, Link, Navigate } from "react-router-dom";
import RecentJourneyMessage from "../../../RecentJourneyMessage";
import TopHeader from "../../Header";
import arrow_left from "../../../assets/images/icons/arrow_left.svg";
import arrow_right_disable from "../../../assets/images/icons/arrow_right_disable.svg";
import arrow_back from "../../../assets/images/icons/arrow_back_ios.svg";
import { useRecoilState } from "recoil";
import { HeaderChangerAtom } from "../../../store/store";
import { userDataAtom, WorkflowNameAtom } from "../../../store/store";
import WorkflowTemplate from "../../../template/Workflow_Template.xlsx";
import "../../../assets/style/setup.css";
import * as XLSX from "xlsx";
import { createWorkflowAPi } from "../../../api/workflowApi";
import { createActivityLogApi } from "../../../api/logApi";
import FlowDiagram from "../../FlowDiagram";
import PopupModal from "../../../PopupModal";
import ModalMessage from "../../../ModalMessage";
import { validateFileSize } from "../../../components/FileUploadUtils";
import {
  CancelButton,
  SaveButton,
  SubmitButton,
  ExcelButton,
  TableButton,
} from "../../GlobalButton";
import { getSingleGroupApiName, getAllGroupApi } from "../../../api/groupsApi";
import { RoleAccessAtom } from "../../../store/store";
import NotAuthorized from "../../NotAuthorized";
import { getUsersApi } from "../../../api/usersApi";
import HeaderCard from "../../HeaderCard";

const ExcelWorkFlow = () => {
  // state variable hold the header change value
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  //global state varaiable hold the dashboard user data to display
  const userData = useRecoilState(userDataAtom);
  //global state varaiable get the workflow name using useRecoilState
  const getWorkFlowName = useRecoilState(WorkflowNameAtom);
  const roleUser = useRecoilState(RoleAccessAtom);
  // global state variable hold the popup modal
  const popupModalInstance = PopupModal();
  //global state variable hold the header
  let header = headerChanger.header;
  // state variable hold the define value 1 means draft and value 2 means submit
  const state = {
    button: 1,
  };
  //global state variable hold the navigating different routes
  const history = useNavigate();
  // global state variable hold the key while switch header name
  const menuKey = "5";
  // state variable hold the work flow name from input field
  const [WorkFlowName, setWorkFlowName] = useState("");
  // state variable hold the table data
  const [tableData, setTableData] = useState([]);
  // state variable hold the object of json response
  const [metaData, setMetaData] = useState();
  // state variable hold the upload validation required true or false
  const [uploadRule, setUploadRule] = useState(false);
  // state variable hold the upload validation error text
  const [uploadRuleText, setUploadRuleText] = useState();
  // state variable hold the loader on api integration
  const [loader, setLoader] = useState(false);
  // state variable hold the work flow File
  const [workflowFile, setWorkFlowFile] = useState();
  // state variable hold the upload string for check the condition
  const [createMethod, setCreateMethod] = useState("upload");
  // state variable hold the loading while create the data and send to API
  const [saveTableLoader, setSaveTableLoader] = useState(false);
  //staete variable hold the value frominput
  const [checked, setChecked] = useState();

  const [usersData, setUsersData] = useState();

  const [groupdata, setGroupData] = useState();

  const [errorStatecheck, setErrorStateCheck] = useState(true);

  const [accessGranted, setAccessGranted] = useState();
  const [mainPageloading, setMainPageLoading] = useState(true); // Initialize main page loading state

  useEffect(() => {
    getUsersApi().then((res) => {
      setUsersData(res);
    });
    getAllGroupApi().then((res) => {
      setGroupData(res);
    });
  }, []);

  useEffect(() => {
    const checkAccess = () => {
      const hasAccess =
        roleUser &&
        roleUser[0] &&
        roleUser[0].page_access.system_setup.supplier_setup.view;
      setAccessGranted(hasAccess);
      setMainPageLoading(false);
    };

    checkAccess();
  }, [roleUser]);

  useEffect(() => {
    const outputObj = metaData;
    outerLoop: for (const levelList in outputObj) {
      const level = outputObj[levelList];
      for (let index = 0; index < level.approverList.length; index++) {
        const element = level.approverList[index];
        for (const arrayofEmail of element.emailId) {
          if (arrayofEmail.includes("@")) {
            const userdatanew = usersData.find(
              (usersData) => usersData.email === arrayofEmail
            );
            if (!userdatanew) {
              popupModalInstance.errorModal(
                `${arrayofEmail} ${ModalMessage.User_Not_Exits}`
              );
              setErrorStateCheck(false);
              break outerLoop;
            }
          } else {
            const groupdatanew = groupdata.find(
              (groupdata) => groupdata.group_name === arrayofEmail
            );
            if (!groupdatanew) {
              popupModalInstance.errorModal(
                `${arrayofEmail} ${ModalMessage.Group_Not_Exits}`
              );
              setErrorStateCheck(false);
              break outerLoop;
            }
          }
        }
      }
    }
  }, [metaData]);

  /* Function to handle work flow name from input field
   * @paran e - selcted value in input field
   */
  const onChangeWorkFlowName = (e) => {
    setWorkFlowName(e.target.value);
  };

  /*
   * Function to check the excel format and indicate file success or unsuccess
   *
   */
  // old code
  const props = {
    name: "file",
    listType: "picture",
    accept: ["xlsx", "xls"],
    maxCount: 1,
    defaultFileList: workflowFile ? [workflowFile] : [],
    onRemove() {
      setTableData([]);
      setErrorStateCheck(true);
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        if (info.fileList.length !== 0) {
          setWorkFlowFile(info.file);
        } else {
          setWorkFlowFile();
        }
      }
      if (info.file.status === "done") {
        if (info.fileList.length !== 0) {
          setWorkFlowFile(info.file);
        } else {
          setWorkFlowFile();
        }
        message.success({
          content: `${info.file.name} file uploaded successfully`,
          top: 100,
        });
      } else if (info.file.status === "error") {
        message.error({
          content: `${info.file.name} file upload failed.`,
          top: 150,
        });
      }
    },
    beforeUpload(file) {
      if (!validateFileSize(file)) {
        return Upload.LIST_IGNORE;
      }
      try {
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
          // Read the Excel file
          const workbook = XLSX.readFile(e.target.result);
          const sheetNames = workbook.SheetNames;
          const sheetName = sheetNames[0];
          // Assuming 'Template' is the name of the sheet in your Excel file
          const worksheet = workbook.Sheets[sheetName];

          // Convert the sheet data to JSON
          const workflowData = XLSX.utils.sheet_to_json(worksheet);
          setTableData(workflowData);

          const df = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

          // Create a graph to represent relationships
          const graph = {};
          const priorNodesByPerson = {};
          // Variable to track if the Prior Nodes are sequential
          let isSequential = true;

          for (const row of df) {
            // const seqyow = df[row];
            const groupPersonName = row["Group/Person Name"];
            const priorNode = row["Prior Node"];

            // Skip the row if any required column has a null value
            if (!groupPersonName || !priorNode) {
              console.log("Skipping row due to null values:", row);
              continue;
            }

            // Check if the Prior Nodes are sequential
            if (priorNode && priorNodesByPerson[groupPersonName]) {
              isSequential =
                isSequential &&
                (priorNodesByPerson[groupPersonName].length === 0 ||
                  priorNodesByPerson[groupPersonName][
                    priorNodesByPerson[groupPersonName].length - 1
                  ] === priorNode);
            }
            const groupPersonNames = row["Group/Person Name"]
              .split(",")
              .map((name) => name.trim()); // Split the names by comma and trim spaces

            for (const groupPersonName of groupPersonNames) {
              // Check if the Group/Person Name is already in the dictionary
              if (priorNodesByPerson[groupPersonName]) {
                // If present, add the current Prior Node to the existing array
                priorNodesByPerson[groupPersonName].push(row["Prior Node"]);
              } else {
                // If not present, create a new array with the current Prior Node
                priorNodesByPerson[groupPersonName] = [row["Prior Node"]];
              }
            }

            const priorNodes = row["Prior Node"]
              .split(",") // Split prior nodes by comma
              .map((node) => node.trim()); // Trim spaces around individual nodes
            // const conditions = row["Condition"]
            //   ? row["Condition"].split(",")
            //   : [];
            const conditions = row["Condition"];
            console.log("cd", conditions);

            for (const priorNode of priorNodes) {
              if (!priorNode) {
                continue; // Skip to the next iteration if Prior Node is null
              }
              graph[priorNode] = graph[priorNode] || {
                children: [],
                conditions: [],
              };
              graph[priorNode].children.push(row["Group/Person Name"]);
              graph[priorNode].conditions.push(conditions);
            }
          }

          function extractCondition(expression) {
            const regex = /\(\(([^)]+)\)/;
            const match = expression.match(regex);

            if (match && match[1]) {
              return match[1].trim();
            }

            return "";
          }
          function evalCondition(condition, conditionExp, approver) {
            let parts = condition.split(",").map((part, index, array) => {
              // Remove trailing bracket if it's the last part
              if (index === array.length - 1) {
                part = part.replace(/\)$/, ""); // Remove trailing ')'
              }
              return part.trim();
            });

            if (parts.length > 2) {
              if (parts[1] == approver) {
                return true;
              }
              if (parts[2] == approver) {
                return false;
              }
            }
          }
          function getPairedWith(approvers, currentIndex, currentApprover) {
            const pairedWith = [];
            for (let k = 0; k < approvers.length; k++) {
              if (k !== currentIndex) {
                const otherApprover = approvers[k].trim();
                const otherParent = graph[otherApprover]
                  ? graph[otherApprover].children[0]
                  : "";
                const otherNextNode = graph[otherApprover]
                  ? graph[otherApprover].children[0]
                  : "";
                if (
                  otherParent === graph[currentApprover].children[0] &&
                  otherNextNode === graph[currentApprover].children[0]
                ) {
                  pairedWith.push(otherApprover);
                }
              }
            }
            return pairedWith.length === 0 ? [] : pairedWith;
          }

          // Traverse the graph to construct levels
          const outputObj = {};
          let level = 1;

          function traverse(node, from, conditions) {
            console.log("Node: ", node);
            const currentLevel = `Level${level}`;
            if (
              node.children != null &&
              node.children.some((child) => child === "End")
            ) {
              return;
            }
            outputObj[currentLevel] = outputObj[currentLevel] || {
              approverList: [],
            };

            for (let i = 0; i < node.children.length; i++) {
              const approvers = node.children[i].split(", ");
              const emailIdEntry = df.find(
                (entry) => entry["Prior Node"] === approvers.join(",")
              );
              const emailIds = (
                emailIdEntry ? emailIdEntry["Email ID"] : ""
              ).split(", ");

              for (let j = 0; j < approvers.length; j++) {
                const singleApproverTrimmed = approvers[j].trim();
                // const emailId = emailIds[j] || "";
                // Skip processing if approver is "End"
                if (singleApproverTrimmed === "End") {
                  continue;
                }

                const approvalType =
                  (df.find((entry) =>
                    entry["Group/Person Name"].includes(singleApproverTrimmed)
                  ) || {})["Approval Type"] || "";

                const stepFulfillment =
                  (df.find((entry) =>
                    entry["Group/Person Name"].includes(singleApproverTrimmed)
                  ) || {})["Step Fulfillment"] || "";

                const nextNode = graph[singleApproverTrimmed];
                const nextNodes =
                  singleApproverTrimmed in graph
                    ? graph[singleApproverTrimmed].children
                    : [];
                const toField = Array.from(new Set(nextNodes))
                  .map((toNode) => toNode.trim())
                  .flatMap((toNode) =>
                    toNode.includes(",")
                      ? toNode.split(",").map((value) => value.trim())
                      : [toNode]
                  );

                let conditionExp = "";
                let conditionResult = false;

                if (conditions[j] && conditions[j].length) {
                  conditionExp = conditions[j].map((c) => c.trim()).join(", ");
                  // Evaluate the condition dynamically
                  conditionResult = evalCondition(
                    conditionExp,
                    singleApproverTrimmed
                  );
                } else {
                  // Check for comments in the "Condition" column
                  const conditionComment = df.find(
                    (entry) =>
                      entry["Prior Node"].includes(singleApproverTrimmed) &&
                      entry["Condition"]
                  );
                  if (conditionComment) {
                    conditionExp = extractCondition(
                      conditionComment["Condition"]
                    );
                    // Evaluate the condition dynamically
                    conditionResult = evalCondition(
                      conditionComment["Condition"],
                      conditionExp,
                      singleApproverTrimmed
                    );
                  }
                }

                const entry = {
                  approver: singleApproverTrimmed,
                  emailId: isSequential
                    ? df
                        .find(
                          (data) =>
                            data["Group/Person Name"] === node.children[i]
                        )
                        ["Email ID"].split(",")
                    : emailIds[j],
                  // approvalType,
                  stepFulfillment,
                  from: priorNodesByPerson[singleApproverTrimmed] || [],
                  //from, // Use the "from" parameter passed to the traverse function
                  to: toField,
                  pairedWith: getPairedWith(
                    approvers,
                    j,
                    singleApproverTrimmed
                  ),
                  conditionExp,
                  conditionResult,
                };
                // Check if the entry already exists for the approver
                const existingEntryIndex = outputObj[currentLevel][
                  "approverList"
                ].findIndex((existing) => existing.approver === entry.approver);

                if (existingEntryIndex !== -1) {
                  // Add the current condition to the existing "pairedWith" field
                  outputObj[currentLevel]["approverList"][
                    existingEntryIndex
                  ].pairedWith.push(entry.pairedWith[0]);

                  if (entry.stepFulfillment === "One of Many") {
                    const fromNodes =
                      (df.find((entry) =>
                        entry["Group/Person Name"].includes(
                          singleApproverTrimmed
                        )
                      ) || {})["Prior Node"] || "";
                    entry.from = [fromNodes];
                  }
                } else {
                  if (outputObj[currentLevel]["approverList"].length > 0) {
                    outputObj[currentLevel]["approverList"].push(entry);
                  } else {
                    outputObj[currentLevel] = {
                      approvalType,
                      approverList: [entry],
                    };
                  }
                  // outputObj[currentLevel].push(entry);
                  if (entry.stepFulfillment === "One of Many") {
                    const fromNodes =
                      (df.find((entry) =>
                        entry["Group/Person Name"].includes(
                          singleApproverTrimmed
                        )
                      ) || {})["Prior Node"] || "";
                    entry.from = [fromNodes];
                    entry.from = fromNodes
                      .split(", ")
                      .map((node) => node.trim()); // Split the string and use it as an array
                  }
                }

                if (nextNode) {
                  level++;
                  traverse(
                    nextNode,
                    [singleApproverTrimmed],
                    conditions[j] || []
                  );
                  level--;
                }
              }
            }
          }

          traverse(graph["Start"], ["START"], []);
          // Convert the output object to JSON
          const outputJson = JSON.stringify(outputObj, null, 4);
          console.log("outputObj: ", outputObj);
          setMetaData(outputObj);
          setErrorStateCheck(true);
          // Write the JSON to a file
        };
        fileReader.readAsArrayBuffer(file);
      } catch (error) {
        console.error("Error:", error);
      }

      const acceptedFormats = ["xls", "xlsx"];
      const formatCheck = acceptedFormats.includes(file.name.split(".")[1]);
      if (!formatCheck) {
        setUploadRule(true);
        setUploadRuleText("You can only upload the excel files.");

        popupModalInstance.errorModal(ModalMessage.Workflow_Excel_Upload_Error);
      } else {
        setUploadRule(false);
        setUploadRuleText("");
      }
      return formatCheck || Upload.LIST_IGNORE;
    },
  };

  // const props = {
  //   name: "file",
  //   listType: "picture",
  //   accept: ["xlsx", "xls"],
  //   maxCount: 1,
  //   defaultFileList: workflowFile ? [workflowFile] : [],
  //   onRemove() {
  //     setTableData([]);
  //     setErrorStateCheck(true);
  //   },
  //   onChange(info) {
  //     if (info.file.status !== "uploading") {
  //       if (info.fileList.length !== 0) {
  //         setWorkFlowFile(info.file);
  //       } else {
  //         setWorkFlowFile();
  //       }
  //     }
  //     if (info.file.status === "done") {
  //       if (info.fileList.length !== 0) {
  //         setWorkFlowFile(info.file);
  //       } else {
  //         setWorkFlowFile();
  //       }
  //       message.success({
  //         content: `${info.file.name} file uploaded successfully`,
  //         top: 100,
  //       });
  //     } else if (info.file.status === "error") {
  //       message.error({
  //         content: `${info.file.name} file upload failed.`,
  //         top: 150,
  //       });
  //     }
  //   },
  //   beforeUpload(file) {
  //     try {
  //       const fileReader = new FileReader();
  //       fileReader.onload = (e) => {
  //         // Read the Excel file
  //         const workbook = XLSX.readFile(e.target.result);
  //         const sheetNames = workbook.SheetNames;
  //         const sheetName = sheetNames[0];

  //         // Assuming 'Template' is the name of the sheet in your Excel file
  //         const worksheet = workbook.Sheets[sheetName];

  //         // Convert the sheet data to JSON
  //         const workflowData = XLSX.utils.sheet_to_json(worksheet);
  //         setTableData(workflowData);

  //         const df = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

  //         const graph = {};
  //         const priorNodesByPerson = {};
  //         let isSequential = true;

  //         for (const row of df) {
  //           const groupPersonName = row["Group/Person Name"];
  //           const priorNode = row["Prior Node"];
  //           const conditions = row["Condition"]
  //             ? row["Condition"].split(",")
  //             : [];

  //           if (!groupPersonName || !priorNode) {
  //             console.log("Skipping row due to null values:", row);
  //             continue;
  //           }

  //           if (priorNode && priorNodesByPerson[groupPersonName]) {
  //             isSequential =
  //               isSequential &&
  //               (priorNodesByPerson[groupPersonName].length === 0 ||
  //                 priorNodesByPerson[groupPersonName][
  //                   priorNodesByPerson[groupPersonName].length - 1
  //                 ] === priorNode);
  //           }

  //           const groupPersonNames = row["Group/Person Name"]
  //             .split(",")
  //             .map((name) => name.trim());

  //           for (const name of groupPersonNames) {
  //             if (priorNodesByPerson[name]) {
  //               priorNodesByPerson[name].push(row["Prior Node"]);
  //             } else {
  //               priorNodesByPerson[name] = [row["Prior Node"]];
  //             }
  //           }

  //           const priorNodes = priorNode.split(",").map((node) => node.trim());

  //           for (const prior of priorNodes) {
  //             if (!prior) continue;
  //             graph[prior] = graph[prior] || { children: [], conditions: [] };
  //             graph[prior].children.push(row["Group/Person Name"]);
  //             graph[prior].conditions.push(conditions);
  //           }
  //         }

  //         function evaluateConditions(condition, approver) {
  //           if (Array.isArray(condition)) {
  //             condition = condition.join(","); // Convert array to string
  //           } else if (typeof condition === "object") {
  //             console.error(
  //               "Condition is an object. Expected a string:",
  //               condition
  //             );
  //             return false;
  //           }

  //           if (typeof condition !== "string") {
  //             console.error("Invalid condition format:", condition);
  //             return false;
  //           }

  //           let parts = condition.split(",").map((part) => part.trim());

  //           if (parts.length > 2) {
  //             if (parts[1] === approver) {
  //               return true;
  //             }
  //             if (parts[2] === approver) {
  //               return false;
  //             }
  //           }
  //         }

  //         function traverse(node, from) {
  //           // Validate node and its children
  //           if (
  //             !node ||
  //             !Array.isArray(node.children) ||
  //             node.children.length === 0
  //           ) {
  //             return;
  //           }

  //           // Determine the current level
  //           const currentLevel = `Level${level}`;

  //           // Initialize the level in the output object if not already present
  //           outputObj[currentLevel] = outputObj[currentLevel] || {
  //             approverList: [],
  //           };

  //           for (const child of node.children) {
  //             // Ensure child is a string before splitting
  //             if (typeof child !== "string") {
  //               console.warn(
  //                 `Invalid child encountered at level ${currentLevel}:`,
  //                 child
  //               );
  //               continue;
  //             }

  //             // Process approvers
  //             const approvers = child.split(",").map((a) => a.trim());
  //             const entry = {
  //               approver: approvers[0],
  //               from,
  //               to: approvers.slice(1),
  //               conditions: node.conditions || [], // Use fallback for conditions
  //             };

  //             // Evaluate conditions for the current approver
  //             const conditionsMet = (node.conditions || []).every((cond) =>
  //               evaluateConditions(approvers[0], cond)
  //             );

  //             if (conditionsMet) {
  //               outputObj[currentLevel].approverList.push(entry);
  //             }

  //             // Recursively traverse the graph for the next approver
  //             if (graph[approvers[0]]) {
  //               level++; // Increment level before recursion
  //               traverse(graph[approvers[0]], approvers[0]);
  //               level--; // Decrement level after recursion
  //             } else {
  //               console.warn(
  //                 `Approver "${approvers[0]}" not found in the graph.`
  //               );
  //             }
  //           }
  //         }

  //         const outputObj = {};
  //         let level = 1;

  //         traverse(graph["Start"], "Start");
  //         const outputJson = JSON.stringify(outputObj, null, 4);
  //         console.log("Workflow Metadata:", outputObj);
  //         setMetaData(outputObj);
  //         setErrorStateCheck(true);
  //       };

  //       fileReader.readAsArrayBuffer(file);
  //     } catch (error) {
  //       console.error("Error reading file:", error);
  //     }

  //     const acceptedFormats = ["xls", "xlsx"];
  //     const isAccepted = acceptedFormats.includes(file.name.split(".").pop());

  //     if (!isAccepted) {
  //       setUploadRule(true);
  //       setUploadRuleText("You can only upload Excel files.");
  //       popupModalInstance.errorModal(ModalMessage.Workflow_Excel_Upload_Error);
  //     } else {
  //       setUploadRule(false);
  //       setUploadRuleText("");
  //     }

  //     return isAccepted || Upload.LIST_IGNORE;
  //   },
  // };

  console.log("metaData", metaData);

  /* Function to uplaod the file  and set succes
   * @param file - upload file imformation
   * @param onSuccess - indicate success while file upload correctly
   */
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  // function handle the column in table to display
  const columns = [
    {
      title: "STEP",
      dataIndex: "Step",
      key: "Step",
    },
    {
      title: "STEP FULFILLMENT",
      dataIndex: "Step Fulfillment",
      key: "Step Fulfillment",
    },
    {
      title: "APPROVAL TYPE",
      dataIndex: "Approval Type",
      key: "Approval Type",
    },
    {
      title: "GROUP / PERSON NAME",
      dataIndex: "Group/Person Name",
      key: "Group/Person Name",
    },
    {
      title: "EMAIL",
      dataIndex: "Email ID",
      key: "Email ID",
    },
    {
      title: "CONDITION",
      dataIndex: "Condition",
      key: "Condition",
    },
  ];

  /* Function to handle component label box for input field
   */
  const formItemLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 18,
    },
  };

  /* Function to handle submit form
   * @paran value - collection of component data in value
   */
  const onFinish = async (values) => {
    // handle workflow name to be unique
    // if (
    //   getWorkFlowName
    //     ? getWorkFlowName[0].includes(values.workflow_name)
    //     : window.alert("Something went wrong, Try again later")
    // ) {
    //   popupModalInstance.errorModal(ModalMessage.Workflow_Create_Error);
    // } else {
    let data = {
      workflow_name: values.workflow_name,
      workflow_metadata: metaData ? metaData : {},
      status: state.button === 2 ? "active" : "In Draft",
      created_by: userData[0].id,
      updated_by: userData[0].id,
      created_on: new Date(),
      updated_on: new Date(),
    };
    console.log("data: ", data);
    if (state.button === 1) {
      setSaveTableLoader(true);
      await createWorkflowAPi(data).then((res) => {
        if (res.status === 304) {
          popupModalInstance.errorModal(ModalMessage.Workflow_Create_Error);
          setSaveTableLoader(false);
        } else {
          console.log("res: ", res);
          let ssLog = {
            ss_id: res._id ? res._id : res.id,
            ss_name: values["workflow_name"],
            code: "workflow",
            author: userData[0].id,
            action: "view",
            notes: RecentJourneyMessage.SUBMIT_WORK_FLOW,
            updated_on: new Date(),
          };
          createActivityLogApi(ssLog).then((res) => {
            console.log("LogRes: ", res);
          });
          setSaveTableLoader(false);
          popupModalInstance.successModal(
            values.workflow_name,
            ModalMessage.Workflow_Save
            // `/system-setup/?back=con-wf`
          );
        }
      });
    }
    if (state.button === 2) {
      setLoader(true);
      popupModalInstance
        .infoModal(ModalMessage.Workflow_Submit)
        .then(async (userChoice) => {
          if (userChoice === "ok") {
            await createWorkflowAPi(data).then((res) => {
              console.log("res: ", res);
              if (res.status === 304) {
                popupModalInstance.errorModal(
                  ModalMessage.Workflow_Create_Error
                );
                setLoader(false);
              } else {
                let ssLog = {
                  ss_id: res._id ? res._id : res.id,
                  ss_name: values["workflow_name"],
                  code: "workflow",
                  author: userData[0].id,
                  action: "view",
                  notes: RecentJourneyMessage.SUBMIT_WORK_FLOW,
                  updated_on: new Date(),
                };
                createActivityLogApi(ssLog).then((res) => {
                  console.log("LogRes: ", res);
                });
                setSaveTableLoader(false);
                history(`/system-setup/?back=con-wf`);
              }
            });
          }
          if (userChoice === "cancel") {
            setLoader(false);
          }
        });
    }
    // }
  };

  /* Function to handle submit form failed
   *@ param errorInfo- getting error information
   */
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  /* Function to handle on change of excel flow
   *@ param value- corressponding selectedd value
   */
  const switchOnChange = (value) => {
    console.log("checked-value", value);
    setChecked(value);
  };

  useEffect(() => {
    document.title = "Workflow | Judo";
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <Layout className="header-layout">
      <TopHeader
        headerChange={header}
        feedRow={false}
        searchRow={false}
        menuKey={menuKey}
        sticky=""
      />{" "}
      {mainPageloading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh - 60px)",
          }}
        >
          <LoadingOutlined
            style={{
              fontSize: 50,
              color: "var(--color-solid-darkerblue)",
            }}
            spin
          />
        </div>
      ) : accessGranted ? (
        <div className="system-setup1">
          <Form
            name="create-pricingprofile"
            onFinish={onFinish}
            onKeyDown={handleKeyDown}
            onFinishFailed={onFinishFailed}
          >
            <Row align="middle" type="flex" gutter={[8, 16]} className="m-10">
              <Col
                className="vertical-align"
                xs={24}
                sm={16}
                md={18}
                lg={18}
                xl={20}
              >
                <HeaderCard
                  mainMenu="Contract Setup"
                  subMenu="Workflow"
                  itemName={"Create Workflow"}
                  // itemStatus={state.data.supplier_status}
                  goBackLink="/system-setup/?back=con-wf"
                  // state={state.data}
                  // showDetails={state.flag === "view" || state.flag === "edit"}
                />
              </Col>
              <Col
                className="vertical-align"
                xs={8}
                sm={4}
                md={3}
                lg={3}
                xl={2}
              >
                <CancelButton
                  onClick={() => history(`/system-setup/?back=con-wf`)}
                />
              </Col>
              {/* <Col
                className="vertical-align"
                xs={8}
                sm={4}
                md={3}
                lg={3}
                xl={2}
              >
                <SaveButton
                  onClick={() => (state.button = 1)}
                  type="primary"
                  htmlType="submit"
                  name="submit-btn"
                  loading={saveTableLoader}
                  disabled={WorkFlowName && errorStatecheck ? false : true}
                />
              </Col> */}
              <Col
                className="vertical-align"
                xs={8}
                sm={4}
                md={3}
                lg={3}
                xl={2}
              >
                <SubmitButton
                  type="primary"
                  onClick={() => (state.button = 2)}
                  htmlType="submit"
                  name="submit-btn"
                  loading={loader}
                  disabled={
                    WorkFlowName && workflowFile && errorStatecheck
                      ? false
                      : true
                  }
                />
              </Col>
            </Row>
            <div className="price-profile-content m-10">
              <Row align="middle" type="flex" gutter={[8, 16]} className="m-10">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Row>
                    <Col span={24}>
                      <div className="create-supplier-div">
                        <div className="m-20">
                          <Row align="middle" type="flex">
                            <Col span={8}>
                              <Form.Item
                                {...formItemLayout}
                                className="org-supplier-label"
                                name="workflow_name"
                                label="NAME OF THE WORKFLOW"
                                colon={false}
                                rules={[
                                  {
                                    message: "Please enter name",
                                    pattern: /^(?!\s*$).+/,
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="Enter your WorkFlow Name"
                                  onChange={onChangeWorkFlowName}
                                />
                              </Form.Item>
                            </Col>
                          </Row>

                          {createMethod === "upload" && (
                            <>
                              <div className="create-upload-entity-div">
                                <Row
                                  align="middle"
                                  type="flex"
                                  style={{ padding: "10px 30px 0px" }}
                                >
                                  <Col
                                    span={24}
                                    align="middle"
                                    className="vertical-align"
                                  >
                                    <h4 className="entity-text">
                                      Enter Workflow Details
                                    </h4>
                                  </Col>
                                  <Col
                                    span={8}
                                    className="vertical-align"
                                    align="right"
                                  ></Col>
                                </Row>
                                <hr className="mt-10 view-supplier-hr" />
                                <Row
                                  align="middle"
                                  gutter={[40, 16]}
                                  justify="center"
                                  className="mt-25"
                                >
                                  <Col
                                    span={8}
                                    align="right"
                                    className="vertical-align"
                                  >
                                    <div className="choose-input-div">
                                      <Form.Item
                                        rules={[
                                          {
                                            required: uploadRule,
                                            message: uploadRuleText,
                                          },
                                        ]}
                                      >
                                        <Row type="flex" gutter={[64, 56]}>
                                          <Col
                                            span={24}
                                            align="middle"
                                            className="vertical-align"
                                          >
                                            <span className="upload-note">
                                              Click below to enter Workflow
                                              Details through Excel
                                            </span>
                                          </Col>
                                          <Col span={24} align="middle">
                                            <Upload
                                              {...props}
                                              customRequest={dummyRequest}
                                            >
                                              <Col
                                                span={10}
                                                align="middle"
                                                className="vertical-align"
                                              >
                                                <ExcelButton />
                                              </Col>
                                            </Upload>
                                          </Col>
                                          <Col
                                            span={24}
                                            className="vertical-align"
                                            align="middle"
                                          >
                                            <div>
                                              <span className="upload-note">
                                                Download &nbsp;&nbsp;
                                                <Link
                                                  to={WorkflowTemplate}
                                                  download="Workflow_Template"
                                                  target="_blank"
                                                  rel="noreferrer"
                                                >
                                                  Workflow Template
                                                </Link>
                                              </span>
                                            </div>
                                          </Col>
                                        </Row>
                                      </Form.Item>
                                    </div>
                                  </Col>
                                  <Col
                                    span={8}
                                    align="left"
                                    className="vertical-align"
                                  >
                                    <div className="choose-input-div">
                                      <Row
                                        type="flex"
                                        gutter={
                                          workflowFile ? [64, 92] : [64, 56]
                                        }
                                      >
                                        <Col
                                          span={24}
                                          align="middle"
                                          className="vertical-align"
                                        >
                                          <span className="upload-note">
                                            Click below to view Worfklow Details
                                            in table mode
                                          </span>
                                        </Col>
                                        <Col span={24} align="middle">
                                          <Col
                                            span={10}
                                            align="middle"
                                            className="vertical-align"
                                          >
                                            <TableButton
                                              onClick={() => {
                                                setWorkFlowFile();
                                                setCreateMethod("table");
                                              }}
                                            />
                                          </Col>
                                        </Col>
                                        <Col span={24}>
                                          <div style={{ height: 46 }}></div>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </>
                          )}
                          {createMethod === "table" && (
                            <>
                              <div className="create-entity-div">
                                <Row
                                  align="middle"
                                  type="flex"
                                  style={{ padding: "15px 30px 0px" }}
                                >
                                  <Col span={16} className="vertical-align">
                                    <h4 className="entity-text">
                                      Workflow Details
                                    </h4>
                                  </Col>
                                </Row>
                                <hr className="mt-15 view-supplier-hr" />

                                <div
                                  className="m-10"
                                  style={{ padding: "0 15px" }}
                                >
                                  <span
                                    className={`${
                                      checked === false ? "fxsetup-status" : ""
                                    }`}
                                  >
                                    Excel View
                                  </span>
                                  &nbsp;&nbsp;
                                  <Switch
                                    onChange={switchOnChange}
                                    className="fx-setup-switch"
                                  ></Switch>
                                  &nbsp;&nbsp;
                                  <span
                                    className={`${
                                      checked === true ? "fxsetup-status" : ""
                                    }`}
                                  >
                                    Flow-chart View
                                  </span>
                                </div>

                                {!checked && (
                                  <Table
                                    style={{ margin: "20px 30px" }}
                                    id="workflow-table"
                                    className="workflow-setup-table"
                                    columns={columns}
                                    dataSource={tableData}
                                    pagination={false}
                                  />
                                )}
                                {checked && (
                                  <FlowDiagram
                                    flowData={metaData}
                                    from="workflow-view"
                                  />
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
      ) : (
        <NotAuthorized redirect={`/system-setup/?back=con-wf`} />
      )}
    </Layout>
  );
};

export default ExcelWorkFlow;
