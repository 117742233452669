import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Upload,
  Input,
  Layout,
  Select,
  message,
  Tabs,
  Table,
  InputNumber,
  DatePicker,
  Popconfirm,
  Divider,
  Modal,
  Radio,
  Tooltip,
} from "antd"; //antd components
import dayjs from "dayjs";
import moment from "moment";
import {
  PlusOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { useNavigate, useLocation, Link } from "react-router-dom";
import fxTableTemplate from "../../../template/Fx_Table_Template.xlsx";
import TopHeader from "../../Header";
import "../../../assets/style/setup.css";
import { getAllowedSupplierList } from "../../../api/suppliersApi";
import { getAllMsaApi } from "../../../api/msaApi";
import RecentJourneyMessage from "../../../RecentJourneyMessage";
import {
  createFxTableAPi,
  validateFxProfileApi,
} from "../../../api/fxTableApi";
import { createActivityLogApi } from "../../../api/logApi";
import { userDataAtom } from "../../../store/store";
import { validateFileSize } from "../../../components/FileUploadUtils";
import {
  CancelButton,
  SaveButton,
  SubmitButton,
  ExcelButton,
} from "../../GlobalButton";
import { useRecoilState } from "recoil";
import { HeaderChangerAtom, RoleAccessAtom } from "../../../store/store";
import PopupModal from "../../../PopupModal";
import ModalMessage from "../../../ModalMessage";
import NotAuthorized from "../../NotAuthorized";
import { editFxTableAPi } from "../../../api/fxTableApi";
import HeaderCard from "../../HeaderCard";
import { getPricingProfileByFx } from "../../../api/pricingProfileApi";
import { dataTable } from "../../../utils/common";
import { entityCol } from "../../../utils/tableColumns";
import DataTable from "../../Tables/DataTable";
import NotesAndComments from "../../NotesAndComments";
import {
  deleteS3FileApi,
  updateNotesCommentsApi,
} from "../../../api/commonApi";
import { isEqual } from "lodash";

//Variable to get the context of which table(Cfx/Sr) is being edited
const EditableContext = React.createContext(null);
//Function which enables editing of the row by returning the table row inside a Form component
const EditableRowCfx = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
//Function which enables editing of the row by returning the table row inside a Form component
const EditableRowSr = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
//Function which enables editing the individual cell in the row when the user clicks on it
const EditableCellCfx = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  state,
  handleSaveCfx,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSaveCfx({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `Enter correct value`,
          },
          {
            pattern:
              title === "Billing Currency"
                ? /^[A-Za-z]+$/
                : /^[0-9]*(\.[0-9]+)?$/,
            message: `Enter correct value`,
          },
        ]}
      >
        {title === "Billing Currency" ? (
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        ) : (
          <InputNumber
            controls={false}
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
          />
        )}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};
//Function which enables editing the individual cell in the row when the user clicks on it
const EditableCellSr = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSaveSr,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSaveSr({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `Enter correct value`,
          },
          {
            pattern:
              title === "Billing Currency"
                ? /^[A-Za-z]+$/
                : /^[0-9]*(\.[0-9]+)?$/,
            message: `Enter correct value`,
          },
        ]}
      >
        {title === "Billing Currency" ? (
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        ) : (
          <InputNumber
            controls={false}
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
          />
        )}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

const FxSetupController = () => {
  // State variable to hold user information
  const userData = useRecoilState(userDataAtom);
  const roleUser = useRecoilState(RoleAccessAtom);
  // State variable to hold header data
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  // Variable to hold PopupModal component
  const popupModalInstance = PopupModal();
  // Variable to hold local header data
  let header = headerChanger.header;
  // Variable to hold manu key value
  const menuKey = "7";
  // Variable to use navigate function
  const history = useNavigate();
  // Variable to hold particular fx table data
  let { state } = useLocation();
  if (state.data === null || state.data === undefined) {
    state.data = headerChanger.stateSetter;
  }
  // State variabel to hold fx table name
  const [fxTable, setFxTable] = useState(state.data.fx_table_name);
  const [accessGranted, setAccessGranted] = useState();
  // State vriable to hold msa value
  const [msa, setMSA] = useState(state.data.msa_name);
  const [msaId, setMsaId] = useState(state.data.msa);
  // State variable to hold supplier id
  const [supplierId, setSupplierId] = useState(
    state.data && state.data.supplier_id
  );
  const [status, setStatus] = useState(state.data && state.data.status);
  // State variable to hold all supplier data
  const [supplierData, setSupplierData] = useState([]);
  const [fxId, setFxId] = useState("");
  // State variable to hold all msa data
  const [msaData, setMsaData] = useState([]);
  // State variable to hold button loading visibility status
  const [loader, setLoader] = useState(false);
  // State variable to hold table loading visibility status
  const [saveTableLoader, setSaveTableLoader] = useState(false);
  const [fxDates, setFXDates] = useState({
    startDate: state.data.start_date,
    endDate: state.data.end_date,
  });
  // Variable to hold button value 1 & 2
  const stateButton = {
    button: 1,
  };
  // Variable can handle the form field values
  const [form] = Form.useForm();
  // variable to hold button disable CSS
  let disableVal = "disable-btn";
  // State variable to hold button visibility status
  const [disable, setDisable] = useState(disableVal);
  const [mainPageloading, setMainPageLoading] = useState(true); // Initialize main page loading state
  const [associationData, setAssociationData] = useState([]);
  const [viewedFile, setViewedFile] = useState(false);
  // state for store new comments
  const [comments, setComments] = useState([]);
  const [url, setUrl] = useState([
    {
      fileType: "docx",
    },
  ]);
  // uploaded contract file
  const [fxFile, setFxFile] = useState([]);
  const [fileKeysToDelete, setFileKeysToDelete] = useState([]);
  const [leTabKey, setLeTabKey] = useState("1");
  const [notesSaveLoader, setNotesSaveLoader] = useState(false);
  const [fxProfileData, setFxProfileData] = useState(
    state.data.fx_profile || null
  );
  const [originalData, setOriginalData] = useState({});
  const [enableSave, setEnableSave] = useState(false);
  const [enableSubmit, setEnableSubmit] = useState(false);

  // const [contractCurrency, setContractCurrency] = useState(
  //   state.data.contract_currency
  // );

  useEffect(() => {
    if (state.flag === "edit" || state.flag === "view") {
      getPricingProfileByFx(
        state.data && state.data._id
          ? state.data._id
          : state.data && state.data.id
      ).then((res) => {
        // console.log("pp res", res);
        let association = res.map((data) => {
          let tableRow = {
            associated_item: "Pricing Profile",
            name: (
              <span
                onClick={() => {
                  if (
                    roleUser &&
                    roleUser[0] &&
                    roleUser[0].page_access.system_setup.pricing_profile.view
                  ) {
                    setHeaderChanger({
                      header: "systemSetup",
                      headerItem: "2",
                      headerItemName: "systemSetup",
                      stateSetter: data,
                    });
                    history(`/contract-setup/view/pricing-profile`, {
                      state: data,
                    });
                  }
                }}
                style={{
                  color:
                    roleUser &&
                    roleUser[0] &&
                    roleUser[0].page_access.system_setup.pricing_profile.view
                      ? "var(--color-solid-darkblue)"
                      : "var(--color-solid-black)",
                  fontWeight: "100",
                  cursor:
                    roleUser &&
                    roleUser[0] &&
                    roleUser[0].page_access.system_setup.pricing_profile.view
                      ? "pointer"
                      : "default",
                }}
              >
                {data.price_profile_name}
              </span>
            ),
            association_date: moment(data.createdAt).format("DD-MMM-YY"),
          };
          return tableRow;
        });
        setAssociationData(association);
      });
    }

    if (state.flag !== "create") {
      setOriginalData({
        fx_table_name: state.data.fx_table_name,
        msa: state.data.msa_name === null ? undefined : state.data.msa_name,
        reporting_currency: state.data.reporting_currency,
        supplier_name:
          state.data.supplier_name === null
            ? undefined
            : state.data.supplier_name,
        fx_profile: state.data.fx_profile,
      });
    }
  }, [state]);

  useEffect(() => {
    const checkAccess = () => {
      const hasAccess =
        roleUser &&
        roleUser[0] &&
        roleUser[0].page_access.system_setup.supplier_setup.view;
      setAccessGranted(hasAccess);
      setMainPageLoading(false);
    };

    checkAccess();
  }, [roleUser]);

  // Function to handle to get all supplier data to set useState
  useEffect(() => {
    getAllowedSupplierList(userData[0].id).then((res) => {
      setSupplierData(res);
    });
    getAllMsaApi().then((res) => {
      // console.log("res", res);
      let filteredMsa = res.filter((data) => data.supplier_id === supplierId);
      setMsaData(
        filteredMsa
          ? filteredMsa
          : res.docs.filter((data) => data.supplier_id === supplierId)
      );
    });

    console.log("state: ", state);
  }, []);

  /* Function to handle change the supplier name
   * @Param value - a change the supplier name value
   */
  const onChangeSupplier = (key, value) => {
    console.log("value", value);
    if (key !== undefined) {
      setSupplierId(value.key);
      getAllMsaApi().then((res) => {
        console.log("res", res);
        let filteredMsa = res.filter((data) => data.supplier_id === value.key);
        setMsaData(
          filteredMsa
            ? filteredMsa
            : res.docs.filter((data) => data.supplier_id === value.key)
        );
      });
    } else {
      form.setFieldsValue({ msa: undefined });
      setMsaId(null);
      setMSA("N/A");
      setSupplierId("N/A");
      setMsaData([]);
    }
    key !== undefined && state.data && state.data.supplier_id !== key
      ? setDisable("")
      : setDisable(disableVal);
    shouldEnableSaveSubmit();
  };
  /* Function to handle change the MSA name
   * @Param key - a change the MSA name key
   * @Param value - a change the MSA name value
   */
  const onChangeMsa = (key, value) => {
    if (value !== undefined) {
      setDisable("");
      console.log("key", key, "val", value);
      setMSA(value.label);
      setMsaId(value.key);
    } else {
      setMSA("N/A");
      setMsaId(null);
    }
    key !== undefined && state.data && state.data.msa !== key
      ? setDisable("")
      : setDisable(disableVal);
  };
  /* Function to handle fx table name
   * @Param e - event from input
   */
  const handleFxTable = (e) => {
    setFxTable(e.target.value);
    e.target.value !== "" &&
    state.data &&
    state.data.fx_table_name !== e.target.value
      ? setDisable("")
      : setDisable(disableVal);
  };
  /* Fucntion to handle change year for fx table
   * @Param value - a change the year value
   */
  const handleYearChangeCfx = (value) => {
    setContratualFxYear(Number(value));
    setDataSourceCfx(
      alldataSourceCfx.filter((data) => data.year === Number(value))
      // .map((data, index) => ({
      //   id: index,
      //   billing_currency: data.billing_currency,
      //   amount: Number(data.amount),
      //   year: Number(data.year),
      // }))
    );
  };
  const handleYearChangeSpot = (value) => {
    setSpotRateYear(Number(value));
    setDataSourceSr(
      alldataSourceSr &&
        alldataSourceSr.filter((data) => data.Year === Number(value))
    );
  };
  // State variable to hold contratual length
  const [countCfx, setCountCfx] = useState(
    state.data && state.data.contratual_fx && state.data.contratual_fx.length
  );
  // State variable to hold spot rates length
  const [countSr, setCountSr] = useState(
    state.data && state.data.spot_rates && state.data.spot_rates.length
  );
  const [contratualFxYears, setContratualFxYears] = useState(
    state.data &&
      state.data.contratual_fx &&
      state.data.contratual_fx
        .filter((data, index, array) => {
          return array.findIndex((item) => item.year === data.year) === index;
        })
        .map((data) => data.year)
  );
  const [spotRateYears, setSpotRateYears] = useState(
    state.data &&
      state.data.spot_rates &&
      state.data.spot_rates
        .filter((data, index, array) => {
          return array.findIndex((item) => item.Year === data.Year) === index;
        })
        .map((data) => data.Year)
  );
  const [contratualFxYear, setContratualFxYear] = useState(
    contratualFxYears && Math.max(...contratualFxYears)
  );
  const [spotRateYear, setSpotRateYear] = useState(
    spotRateYears && Math.max(...spotRateYears)
  );
  // State variable to hold all contratual data
  const [alldataSourceCfx, setAllDataSourceCfx] = useState(
    state.data &&
      state.data.contratual_fx &&
      state.data.contratual_fx
        // .filter((data) => data.year === contratualFxYear)
        .map((data, index) => ({
          id: index,
          billing_currency: data.billing_currency,
          amount: Number(data.amount),
          year: Number(data.year),
        }))
  );
  // State variable to hold all contratual data
  const [dataSourceCfx, setDataSourceCfx] = useState(
    alldataSourceCfx &&
      alldataSourceCfx.filter((data) => data.year === contratualFxYear)
  );
  // State variable to hold all spot rates data
  const [alldataSourceSr, setAllDataSourceSr] = useState(
    state.data &&
      state.data.spot_rates &&
      state.data.spot_rates
        // .filter((data) => data.Year === spotRateYear)
        .map((data, index) => ({
          id: index,
          billing_currency: data.billing_currency,
          Jan: Number(data.Jan),
          Feb: Number(data.Feb),
          Mar: Number(data.Mar),
          Apr: Number(data.Apr),
          May: Number(data.May),
          Jun: Number(data.Jun),
          Jul: Number(data.Jul),
          Aug: Number(data.Aug),
          Sep: Number(data.Sep),
          Oct: Number(data.Oct),
          Nov: Number(data.Nov),
          Dec: Number(data.Dec),
          Year: Number(data.Year),
        }))
  );
  // State variable to hold all spot rates data
  const [dataSourceSr, setDataSourceSr] = useState(
    alldataSourceSr &&
      alldataSourceSr.filter((data) => data.Year === spotRateYear)
  );
  // State variable to hold upload validation required true or false
  const [uploadRule, setUploadRule] = useState(false);
  // State variable to hold upload validation error text
  const [uploadRuleText, setUploadRuleText] = useState();
  // State variable to hold fx excel file
  const [fxTableFile, setFxTableFile] = useState([]);

  // Variable to hold column for contratualfx screen
  const defaultColumnsCfx = [
    {
      title: "Billing Currency",
      dataIndex: "billing_currency",
      align: "center",
      sorter: (a, b) => a.billing_currency.localeCompare(b.billing_currency),
      editable: state.flag !== "view",
      show: true,
    },
    {
      title: "amount",
      dataIndex: "amount",
      align: "center",
      sorter: (a, b) => a.amount - b.amount,
      editable: state.flag !== "view",
      show: true,
    },
    {
      title: "year",
      dataIndex: "year",
      align: "center",
      sorter: (a, b) => a.year - b.year,
      editable: state.flag !== "view",
      show: true,
    },
    {
      title: <span className="hide-add">Operation</span>,
      dataIndex: "operation",
      width: 60,
      align: "center",
      show: state.flag !== "view",
      render: (_, record) =>
        state.flag !== "view" && dataSourceCfx.length >= 1 ? (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDeleteCfx(record.id)}
          >
            <a>
              {" "}
              <DeleteOutlined />
            </a>
          </Popconfirm>
        ) : null,
    },
  ].filter((col) => col.show);
  // Variable to hold column for spot rates screen
  const defaultColumnsSr = [
    {
      title: "Billing Currency",
      dataIndex: "billing_currency",
      align: "center",
      sorter: (a, b) => a.billing_currency.localeCompare(b.billing_currency),
      editable: state.flag !== "view",
      show: true,
      ellipsis: {
        showTitle: false,
      },
      render: (billing_currency) => (
        <Tooltip placement="bottomLeft" title={billing_currency}>
          {billing_currency}
        </Tooltip>
      ),
    },
    {
      title: "Jan",
      dataIndex: "Jan",
      align: "center",
      sorter: (a, b) => a.Jan - b.Jan,
      editable: state.flag !== "view",
      show: true,
      ellipsis: {
        showTitle: false,
      },
      render: (Jan) => (
        <Tooltip placement="bottomLeft" title={Jan}>
          {Jan}
        </Tooltip>
      ),
    },
    {
      title: "Feb",
      dataIndex: "Feb",
      align: "center",
      sorter: (a, b) => a.Feb - b.Feb,
      editable: state.flag !== "view",
      show: true,
      ellipsis: {
        showTitle: false,
      },
      render: (Feb) => (
        <Tooltip placement="bottomLeft" title={Feb}>
          {Feb}
        </Tooltip>
      ),
    },
    {
      title: "Mar",
      dataIndex: "Mar",
      align: "center",
      sorter: (a, b) => a.Mar - b.Mar,
      editable: state.flag !== "view",
      show: true,
      ellipsis: {
        showTitle: false,
      },
      render: (Mar) => (
        <Tooltip placement="bottomLeft" title={Mar}>
          {Mar}
        </Tooltip>
      ),
    },
    {
      title: "Apr",
      dataIndex: "Apr",
      align: "center",
      sorter: (a, b) => a.Apr - b.Apr,
      editable: state.flag !== "view",
      show: true,
      ellipsis: {
        showTitle: false,
      },
      render: (Apr) => (
        <Tooltip placement="bottomLeft" title={Apr}>
          {Apr}
        </Tooltip>
      ),
    },
    {
      title: "May",
      dataIndex: "May",
      align: "center",
      sorter: (a, b) => a.May - b.May,
      editable: state.flag !== "view",
      show: true,
      ellipsis: {
        showTitle: false,
      },
      render: (May) => (
        <Tooltip placement="bottomLeft" title={May}>
          {May}
        </Tooltip>
      ),
    },
    {
      title: "Jun",
      dataIndex: "Jun",
      align: "center",
      sorter: (a, b) => a.Jun - b.Jun,
      editable: state.flag !== "view",
      show: true,
      ellipsis: {
        showTitle: false,
      },
      render: (Jun) => (
        <Tooltip placement="bottomLeft" title={Jun}>
          {Jun}
        </Tooltip>
      ),
    },
    {
      title: "Jul",
      dataIndex: "Jul",
      align: "center",
      sorter: (a, b) => a.Jul - b.Jul,
      editable: state.flag !== "view",
      show: true,
      ellipsis: {
        showTitle: false,
      },
      render: (Jul) => (
        <Tooltip placement="bottomLeft" title={Jul}>
          {Jul}
        </Tooltip>
      ),
    },
    {
      title: "Aug",
      dataIndex: "Aug",
      align: "center",
      sorter: (a, b) => a.Aug - b.Aug,
      editable: state.flag !== "view",
      show: true,
      ellipsis: {
        showTitle: false,
      },
      render: (Aug) => (
        <Tooltip placement="bottomLeft" title={Aug}>
          {Aug}
        </Tooltip>
      ),
    },
    {
      title: "Sep",
      dataIndex: "Sep",
      align: "center",
      sorter: (a, b) => a.Sep - b.Sep,
      editable: state.flag !== "view",
      show: true,
      ellipsis: {
        showTitle: false,
      },
      render: (Sep) => (
        <Tooltip placement="bottomLeft" title={Sep}>
          {Sep}
        </Tooltip>
      ),
    },
    {
      title: "Oct",
      dataIndex: "Oct",
      align: "center",
      sorter: (a, b) => a.Oct - b.Oct,
      editable: state.flag !== "view",
      show: true,
      ellipsis: {
        showTitle: false,
      },
      render: (Oct) => (
        <Tooltip placement="bottomLeft" title={Oct}>
          {Oct}
        </Tooltip>
      ),
    },
    {
      title: "Nov",
      dataIndex: "Nov",
      align: "center",
      sorter: (a, b) => a.Nov - b.Nov,
      editable: state.flag !== "view",
      show: true,
      ellipsis: {
        showTitle: false,
      },
      render: (Nov) => (
        <Tooltip placement="bottomLeft" title={Nov}>
          {Nov}
        </Tooltip>
      ),
    },
    {
      title: "Dec",
      dataIndex: "Dec",
      align: "center",
      sorter: (a, b) => a.Dec - b.Dec,
      editable: state.flag !== "view",
      show: true,
      ellipsis: {
        showTitle: false,
      },
      render: (Dec) => (
        <Tooltip placement="bottomLeft" title={Dec}>
          {Dec}
        </Tooltip>
      ),
    },
    {
      title: "Year",
      dataIndex: "Year",
      align: "center",
      sorter: (a, b) => a.Year - b.Year,
      editable: state.flag !== "view",
      show: true,
      ellipsis: {
        showTitle: false,
      },
      render: (Year) => (
        <Tooltip placement="bottomLeft" title={Year}>
          {Year}
        </Tooltip>
      ),
    },
    {
      title: <span className="hide-add">Operation</span>,
      dataIndex: "operation",
      width: 60,
      align: "center",
      show: state.flag !== "view",
      render: (_, record) =>
        state.flag !== "view" && dataSourceSr.length >= 1 ? (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDeleteSr(record.id)}
          >
            <a>
              {" "}
              <DeleteOutlined />
            </a>
          </Popconfirm>
        ) : null,
    },
  ].filter((col) => col.show);
  // Variable to hold edit able table row keys
  const componentsCfx = {
    body: {
      row: EditableRowCfx,
      cell: EditableCellCfx,
    },
  };
  // Function to handle save after editing the cell in contratualfx Table
  const columnsCfx = defaultColumnsCfx.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSaveCfx,
      }),
    };
  });
  // Variable to hold edit able table cell keys
  const componentsSr = {
    body: {
      row: EditableRowSr,
      cell: EditableCellSr,
    },
  };
  // Function to handle save after editing the cell in spot rates Table
  const columnsSr = defaultColumnsSr.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSaveSr,
      }),
    };
  });
  /* Function to handle delete contratualfx table row
   * @Param id -  particular row id
   */
  const handleDeleteCfx = (id) => {
    const newData = dataSourceCfx.filter((item) => item.id !== id);
    const newData1 = alldataSourceCfx.filter((item) => item.id !== id);
    setDataSourceCfx(newData);
    setAllDataSourceCfx(newData1);
    setDisable("");
  };
  // Function to add  a new row in contratualfx Table
  const handleAddCfx = () => {
    const newData = {
      id: countCfx,
      billing_currency: "Currency",
      amount: 0,
      year: 0,
    };
    setDataSourceCfx([...dataSourceCfx, newData]);
    setAllDataSourceCfx([...alldataSourceCfx, newData]);
    setCountCfx(countCfx + 1);
    setDisable("");
  };

  useEffect(() => {
    setContratualFxYears(
      alldataSourceCfx &&
        alldataSourceCfx
          .filter((data, index, array) => {
            return array.findIndex((item) => item.year === data.year) === index;
          })
          .map((data) => data.year.toString())
    );
  }, [dataSourceCfx]);

  useEffect(() => {
    shouldEnableSaveSubmit();
    console.log("comments: ", comments);
  }, [originalData, fxProfileData, comments, fxFile]);

  /*  Function to validate fields before saving the edited cell value of a row in contratualfx Table
   * @param row - The modified row info
   */
  const handleSaveCfx = (row) => {
    const newData = [...alldataSourceCfx];
    const index = newData.findIndex((item) => row.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setAllDataSourceCfx(newData);
    setDataSourceCfx(newData.filter((data) => data.year === contratualFxYear));
    setDisable("");
  };
  /* Function to handle delete spot rates table row
   * @Param id -  particular row id
   */
  const handleDeleteSr = (id) => {
    const newData = dataSourceSr.filter((item) => item.id !== id);
    const newData1 = alldataSourceSr.filter((item) => item.id !== id);
    setAllDataSourceSr(newData1);
    setDataSourceSr(newData);
    setDisable("");
  };
  // Function to add  a new row in spot rates Table
  const handleAddSr = () => {
    const newData = {
      id: countSr,
      billing_currency: "Currency",
      Jan: 0,
      Feb: 0,
      Mar: 0,
      Apr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Aug: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dec: 0,
      Year: 0,
    };
    setDataSourceSr([...dataSourceSr, newData]);
    setAllDataSourceSr([...alldataSourceSr, newData]);
    setCountSr(countSr + 1);
    setDisable("");
  };
  /*  Function to validate fields before saving the edited cell value of a row in spot rates Table
   * @param row - The modified row info
   */
  const handleSaveSr = (row) => {
    const newData = [...alldataSourceSr];
    const index = newData.findIndex((item) => row.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setAllDataSourceSr(newData);
    setDataSourceSr(newData.filter((data) => data.Year === spotRateYear));
    setDisable("");
  };

  useEffect(() => {
    setSpotRateYears(
      alldataSourceSr &&
        alldataSourceSr
          .filter((data, index, array) => {
            return array.findIndex((item) => item.Year === data.Year) === index;
          })
          .map((data) => data.Year.toString())
    );
  }, [dataSourceSr]);
  // function for handle date change in all datepicker's in form
  const onDateChange = (date, dateString, field) => {
    // for start-date datepicker
    if (field === "start_date") {
      setFXDates({
        ...fxDates,
        startDate: dateString,
      });
      // moment(state.data.start_date).format("DD MMM YYYY") !==
      // moment(dateString).format("DD MMM YYYY")
      //   ? setDisable("")
      //   : setDisable(disableVal);
    }

    // for end-date datepicker
    if (field === "end_date") {
      setFXDates({
        ...fxDates,
        endDate: dateString,
      });
      // moment(state.data.end_date).format("DD MMM YYYY") !==
      // moment(dateString).format("DD MMM YYYY")
      //   ? setDisable("")
      //   : setDisable(disableVal);
    }
  };

  // function for disable dates
  const disabledDate = (dateValue, field) => {
    // disable date in start-date datepicker
    if (field === "start_date") {
      if (!fxDates.endDate) {
        return false;
      }
      return dateValue.isAfter(fxDates.endDate, "day");
    }

    // disable date in end-date datepicker
    if (field === "end_date") {
      if (!fxDates.startDate) {
        return false;
      }
      return dateValue.isBefore(fxDates.startDate, "day");
    }
  };

  const handleRadioClick = (value) => {
    console.log("status checked", value);
    if (
      value !== "Active" &&
      state.data.status === "Active" &&
      associationData.length > 0
    ) {
      popupModalInstance
        .infoModal(ModalMessage.FX_Status_Change)
        .then(async (userChoice) => {
          console.log("userChoice", userChoice);
          if (userChoice === "ok") {
            setStatus(value);
            state.data && state.data.status !== value
              ? setDisable("")
              : setDisable(disableVal);
          }
        });
    } else {
      popupModalInstance
        .infoModal(
          value === "Active"
            ? ModalMessage.FX_Status_Change_To_Active
            : ModalMessage.FX_Status_Change_Confirm
        )
        .then(async (userChoice) => {
          console.log("userChoice", userChoice);
          if (userChoice === "ok") {
            setStatus(value);
            state.data && state.data.status !== value
              ? setDisable("")
              : setDisable(disableVal);
          }
        });
    }
  };

  /* Function to handle the create Fx table form data stored
   * @param values - a user create form values
   */
  const onFinish = async (values) => {
    if (state.flag !== "create") {
      alldataSourceCfx.forEach((currency) => {
        delete currency.id;
      });
      alldataSourceSr.forEach((currency) => {
        delete currency.id;
      });
    }
    // values["fx_tables"] = fxTableFile ? fxTableFile.originFileObj : null;
    values["fx_tables"] = null;

    values["supplier_id"] = supplierId;
    values["contratual_fx"] = state.flag === "create" ? [] : alldataSourceCfx;
    values["spot_rates"] = state.flag === "create" ? [] : alldataSourceSr;
    values["msa"] = msaId ? msaId : null;
    values["msa_name"] = msa ? msa : null;
    values["updated_by"] = userData[0].id;
    values["author"] =
      userData && userData[0] && userData[0].id
        ? userData[0].id
        : userData[0]._id
        ? userData[0]._id
        : "";
    values["author_name"] =
      userData && userData[0] && userData[0].first_name
        ? userData[0].first_name
        : "";
    values["comments"] = comments.length !== 0 ? comments : [];
    values["reporting_currency"] = "USD";
    // values["contract_currency"] = contractCurrency;
    values["fx_profile"] = fxProfileData;

    const uploadForm = new FormData();
    if (fxFile && fxFile.length > 0) {
      for (let i = 0; i < fxFile.length; i++) {
        uploadForm.append("notesCommentsFiles", fxFile[i].originFileObj);
      }
    }
    // if (fxTableFile && fxTableFile.length > 0) {
    //   for (let i = 0; i < fxTableFile.length; i++) {
    //     uploadForm.append("fxTableFile", fxTableFile[i].originFileObj);
    //   }
    // }

    if (state.flag === "edit") {
      values["status"] =
        state.data && state.data.status === "In Draft"
          ? stateButton.button === 1
            ? "In Draft"
            : "Active"
          : stateButton.button === 1
          ? "In Draft"
          : status;
      console.log("values-active", values);

      if (stateButton.button === 2) {
        uploadForm.append("bodyData", JSON.stringify(values));
        popupModalInstance
          .infoModal(ModalMessage.FX_Edit_Submit)
          .then(async (userChoice) => {
            if (userChoice === "ok") {
              if (fileKeysToDelete.length !== 0) {
                fileKeysToDelete.map(async (fileKey) => {
                  await deleteS3FileApi({ fileKey: fileKey, flag: "setup" });
                });
              }
              await editFxTableAPi(
                state.data.id ? state.data.id : state.data._id,
                uploadForm
              ).then((res) => {
                if (res.status === 201) {
                  setLoader(false);
                  setLoader(true);
                  let values = {
                    spot_rates: dataSourceSr.map(({ id, ...rest }) => ({
                      ...rest,
                    })),
                    contratual_fx: dataSourceCfx.map(({ id, ...rest }) => ({
                      ...rest,
                    })),
                  };

                  console.log("Res: ", res);
                  let ssLog = {
                    ss_id: res.data.id ? res.data.id : res.data._id,
                    ss_name: res.data.fx_table_name,
                    code: "fx_table",
                    author: userData[0].id,
                    action: "edit",
                    notes: RecentJourneyMessage.EDIT_FX_SETUP,
                    updated_on: new Date(),
                  };
                  createActivityLogApi(ssLog).then((res) => {
                    console.log("LogRes: ", res);
                  });

                  history(`/system-setup/?back=con-fx`);
                } else {
                  setLoader(false);
                  Modal.error({
                    title: "Error on update",
                    className: "popup-model-success",
                    centered: true,
                    icon: false,
                    content: (
                      <>
                        <div style={{ fontWeight: 600 }} className="mb-10">
                          Fx Table update failed for following reasons:
                        </div>
                        {res.message.map((err, index) => {
                          return (
                            <div>
                              {index + 1}.&nbsp; {err}
                            </div>
                          );
                        })}
                      </>
                    ),
                  });
                }
              });
            }
            if (userChoice === "cancel") {
              setLoader(false);
            }
          });
      } else {
        if (fileKeysToDelete.length !== 0) {
          fileKeysToDelete.map(async (fileKey) => {
            await deleteS3FileApi({ fileKey: fileKey, flag: "setup" });
          });
        }
        uploadForm.append("bodyData", JSON.stringify(values));
        await editFxTableAPi(
          state.data.id ? state.data.id : state.data._id,
          uploadForm
        ).then((res) => {
          if (res.status === 201) {
            setSaveTableLoader(false);
            let ssLog = {
              ss_id: res.data._id ? res.data._id : res.data.id,
              ss_name: values["fx_table_name"],
              code: "fx_table",
              author: userData[0].id,
              action: "edit",
              notes: RecentJourneyMessage.EDIT_FX_SETUP,
              updated_on: new Date(),
            };
            createActivityLogApi(ssLog).then((res) => {
              console.log("LogRes: ", res);
            });
            popupModalInstance.successModal(
              values.fx_table_name,
              ModalMessage.FX_Create_Save,
              ``
            );
          } else {
            setSaveTableLoader(false);
            Modal.error({
              title: "Error on update",
              className: "popup-model-success",
              centered: true,
              icon: false,
              content: (
                <>
                  <div style={{ fontWeight: 600 }} className="mb-10">
                    Fx Table creation failed for following reasons:
                  </div>
                  {res.message.map((err, index) => {
                    return (
                      <div>
                        {index + 1}.&nbsp; {err}
                      </div>
                    );
                  })}
                </>
              ),
            });
          }
        });
      }
    } else {
      if (stateButton.button === 1) {
        if (fileKeysToDelete.length !== 0) {
          fileKeysToDelete.map(async (fileKey) => {
            await deleteS3FileApi({ fileKey: fileKey, flag: "setup" });
          });
        }
        setSaveTableLoader(true);
        values["status"] = "In Draft";
        console.log("values-draft", values);
        if (fxId === "") {
          values["created_by"] = userData[0].id;
          uploadForm.append("bodyData", JSON.stringify(values));
          await createFxTableAPi(uploadForm).then((res) => {
            if (res.status === 304) {
              popupModalInstance.errorModal(ModalMessage.Fx_Create_Error);
              setSaveTableLoader(false);
            }
            if (res.status === 201) {
              setFxId(res.data._id ? res.data._id : res.data.id);
              let ssLog = {
                ss_id: res.data.id ? res.data.id : res.data._id,
                ss_name: values["fx_table_name"],
                code: "fx_table",
                author: userData[0].id,
                action: "view",
                notes:
                  state.flag === "clone"
                    ? RecentJourneyMessage.CLONE_FX_SETUP
                    : RecentJourneyMessage.SAVE_FX_SETUP,
                updated_on: new Date(),
              };
              createActivityLogApi(ssLog).then((res) => {
                console.log("LogRes: ", res);
              });
              setSaveTableLoader(false);
              popupModalInstance.successModal(
                values.fx_table_name,
                ModalMessage.FX_Clone_Save,
                ``
              );
            }
            // else {
            //   setSaveTableLoader(false);
            //   Modal.error({
            //     title: "Error on create",
            //     className: "popup-model-success",
            //     centered: true,
            //     icon: false,
            //     content: (
            //       <>
            //         <div style={{ fontWeight: 600 }} className="mb-10">
            //           Fx Table creation failed for following reasons:
            //         </div>
            //         {res.message.map((err, index) => {
            //           return (
            //             <div>
            //               {index + 1}.&nbsp; {err}
            //             </div>
            //           );
            //         })}
            //       </>
            //     ),
            //   });
            // }
          });
        } else {
          uploadForm.append("bodyData", JSON.stringify(values));
          await editFxTableAPi(fxId, uploadForm).then((res) => {
            if (res.status === 201) {
              setFxId(res.data._id ? res.data._id : res.data.id);
              setSaveTableLoader(false);
              let ssLog = {
                ss_id: res.data._id ? res.data._id : res.data.id,
                ss_name: values["fx_table_name"],
                code: "fx_table",
                author: userData[0].id,
                action: "edit",
                notes: RecentJourneyMessage.SAVE_FX_SETUP,
                updated_on: new Date(),
              };
              createActivityLogApi(ssLog).then((res) => {
                console.log("LogRes: ", res);
              });
              popupModalInstance.successModal(
                values.fx_table_name,
                ModalMessage.FX_Create_Save,
                ``
              );
            } else {
              setSaveTableLoader(false);
              Modal.error({
                title: "Error on update",
                className: "popup-model-success",
                centered: true,
                icon: false,
                content: (
                  <>
                    <div style={{ fontWeight: 600 }} className="mb-10">
                      Fx Table creation failed for following reasons:
                    </div>
                    {res.message.map((err, index) => {
                      return (
                        <div>
                          {index + 1}.&nbsp; {err}
                        </div>
                      );
                    })}
                  </>
                ),
              });
            }
          });
        }
      }
      // submit button handler
      if (stateButton.button === 2) {
        setLoader(true);
        values["status"] = "Active";
        console.log("values-active", values);
        if (fxId === "") {
          values["created_by"] = userData[0].id;
          const modalMessage =
            state.flag === "clone"
              ? ModalMessage.FX_Clone_Submit
              : ModalMessage.FX_Create_Submit;
          uploadForm.append("bodyData", JSON.stringify(values));
          popupModalInstance
            .infoModal(modalMessage)
            // popupModalInstance
            //   .infoModal(ModalMessage.FX_Clone_Submit)
            .then(async (userChoice) => {
              if (userChoice === "ok") {
                if (fileKeysToDelete.length !== 0) {
                  fileKeysToDelete.map(async (fileKey) => {
                    await deleteS3FileApi({ fileKey: fileKey, flag: "setup" });
                  });
                }
                await createFxTableAPi(uploadForm).then((res) => {
                  console.log("ressss", res);
                  if (res.status === 304) {
                    popupModalInstance.errorModal(ModalMessage.Fx_Create_Error);
                    setLoader(false);
                  }
                  if (res.status === 201) {
                    let notesText =
                      state.flag === "clone"
                        ? RecentJourneyMessage.CLONE_FX_SETUP
                        : RecentJourneyMessage.SUBMIT_FX_SETUP;
                    let ssLog = {
                      ss_id: res.data.id ? res.data.id : res.data._id,
                      ss_name: values["fx_table_name"],
                      code: "fx_table",
                      author: userData[0].id,
                      action: "view",
                      notes: notesText,
                      updated_on: new Date(),
                    };
                    createActivityLogApi(ssLog).then((res) => {
                      console.log("LogRes: ", res);
                    });
                    setLoader(false);
                    history(`/system-setup/?back=con-fx`);
                  }
                  // else {
                  //   setLoader(false);
                  //   Modal.error({
                  //     title: "Error on create",
                  //     className: "popup-model-success",
                  //     centered: true,
                  //     icon: false,
                  //     content: (
                  //       <>
                  //         <div style={{ fontWeight: 600 }} className="mb-10">
                  //           Fx Table update failed for following reasons:
                  //         </div>
                  //         {res.message.map((err, index) => {
                  //           return (
                  //             <div>
                  //               {index + 1}.&nbsp; {err}
                  //             </div>
                  //           );
                  //         })}
                  //       </>
                  //     ),
                  //   });
                  // }
                });
              }
              if (userChoice === "cancel") {
                setLoader(false);
              }
            });
        } else {
          uploadForm.append("bodyData", JSON.stringify(values));
          popupModalInstance
            .infoModal(ModalMessage.FX_Edit_Submit)
            .then(async (userChoice) => {
              if (userChoice === "ok") {
                if (fileKeysToDelete.length !== 0) {
                  fileKeysToDelete.map(async (fileKey) => {
                    await deleteS3FileApi({ fileKey: fileKey, flag: "setup" });
                  });
                }
                await editFxTableAPi(fxId, uploadForm).then((res) => {
                  if (res.status === 201) {
                    setLoader(false);
                    console.log("Res: ", res);
                    let ssLog = {
                      ss_id: res.data._id ? res.data._id : res.data.id,
                      ss_name: values["fx_table_name"],
                      code: "fx_table",
                      author: userData[0].id,
                      action: "edit",
                      notes:
                        state.flag === "clone"
                          ? RecentJourneyMessage.CLONE_FX_SETUP
                          : RecentJourneyMessage.SUBMIT_FX_SETUP,
                      updated_on: new Date(),
                    };
                    createActivityLogApi(ssLog).then((res) => {
                      console.log("LogRes: ", res);
                    });
                    history(`/system-setup/?back=con-fx`);
                  } else {
                    setLoader(false);
                    Modal.error({
                      title: "Error on update",
                      className: "popup-model-success",
                      centered: true,
                      icon: false,
                      content: (
                        <>
                          <div style={{ fontWeight: 600 }} className="mb-10">
                            Fx Table update failed for following reasons:
                          </div>
                          {res.message.map((err, index) => {
                            return (
                              <div>
                                {index + 1}.&nbsp; {err}
                              </div>
                            );
                          })}
                        </>
                      ),
                    });
                  }
                });
              }
              if (userChoice === "cancel") {
                setLoader(false);
              }
            });
        }
      }
    }
  };
  /* Function to handle the create fx table get any error show the user
   * @param errorInfo - a  object of errors from backend
   */
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  // Function to handle to upload the excel file and validate
  const props = {
    name: "file",
    listType: "picture",
    maxCount: 1,
    onChange(info) {
      console.log("length", info);
      if (info.file.status === "done") {
        if (info.fileList.length !== 0) {
          setFxTableFile(info.fileList);

          console.log("info.fileList: ", info.fileList);

          const uploadFxExcel = new FormData();
          uploadFxExcel.append("fxProfileExcel", info.file.originFileObj);
          uploadFxExcel.append("supplierId", supplierId);
          validateFxProfileApi(uploadFxExcel).then((res) => {
            console.log("fxValidateRes: ", res);
            if (res.valid) {
              setFxProfileData(res.data);
            } else {
              popupModalInstance.errorModal(
                res.errors.map((error, index) => (
                  <span key={index} style={{ display: "block", width: "100%" }}>
                    {error}
                  </span>
                ))
              );
            }
          });
        } else {
          setFxTableFile([]);
        }
        message.success({
          content: `${info.file.name} file uploaded successfully`,
          top: 100,
        });
      } else if (info.file.status === "error") {
        message.error({
          content: `${info.file.name} file upload failed.`,
          top: 150,
        });
      }
    },
    beforeUpload(file) {
      if (!validateFileSize(file)) {
        return Upload.LIST_IGNORE;
      }
      const acceptedFormats = ["xls", "xlsx"];
      const formatCheck = acceptedFormats.includes(file.name.split(".")[1]);
      if (!formatCheck) {
        setUploadRule(true);
        setUploadRuleText("You can only upload the excel files.");
        popupModalInstance.errorModal(ModalMessage.FX_Excel_Upload_Error);
      } else {
        setUploadRule(false);
        setUploadRuleText("");
      }
      return formatCheck || Upload.LIST_IGNORE;
    },
  };
  /* Override for the default xhr behavior allowing for additional customization
   * @Param onSuccess -  a  callback function that will be executed when the request succeeds.
   */
  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const fxColumns = [
    {
      title: "To Currency",
      dataIndex: "To Currency",
      key: "toCurrency",
      sorter: (a, b) => a["To Currency"].localeCompare(b["To Currency"]),
      render: (text) => <span>{text}</span>,
    },
    {
      title: "From Currency",
      dataIndex: "From Currency",
      key: "fromCurrency",
      sorter: (a, b) => a["From Currency"].localeCompare(b["From Currency"]),
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Rate",
      dataIndex: "Rate",
      key: "rate",
      sorter: (a, b) => a.Rate - b.Rate,
      render: (text) => <span>{text.toFixed(2)}</span>,
    },
    {
      title: "End Date",
      dataIndex: "End Date",
      key: "endDate",
      sorter: (a, b) => new Date(a["End Date"]) - new Date(b["End Date"]),
      render: (text) => <span>{text}</span>,
    },
  ];
  // Function to handle stored fx cover screen column and data
  const fxCover = () => {
    const formItemLayout = {
      labelCol: {
        span: 24,
      },
      wrapperCol: {
        span: 18,
      },
    };
    return (
      <div>
        <Row>
          <Col span={24}>
            <div className="org-view-div">
              <div className="m-20">
                <Row align="middle" type="flex">
                  <Col span={8}>
                    <Form.Item
                      {...formItemLayout}
                      className="org-supplier-label"
                      label="FX Table Name"
                      name="fx_table_name"
                      colon={false}
                      rules={[
                        {
                          required: "true",
                          message: "Please enter name",
                          pattern: /^(?!\s*$).+/,
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter Fx Table Name"
                        disabled={state.flag === "view"}
                        onChange={handleFxTable}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      {...formItemLayout}
                      className="org-supplier-label"
                      label="Supplier Name"
                      name="supplier_name"
                      colon={false}
                      rules={[
                        {
                          required: stateButton.button === 2,
                          message: "Please select Supplier",
                        },
                      ]}
                    >
                      <Select
                        disabled={state.flag === "view"}
                        allowClear
                        onChange={(key, value) => onChangeSupplier(key, value)}
                        showSearch={true}
                        placeholder="Choose Supplier"
                        options={
                          supplierData &&
                          supplierData.map((data) => ({
                            key: data.id ? data.id : data._id,
                            label: data.supplier_name,
                            value: data.supplier_name,
                          }))
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      {...formItemLayout}
                      className="org-supplier-label"
                      label="MSA"
                      name="msa"
                      colon={false}
                      rules={[
                        {
                          message: "Please select MSA",
                        },
                      ]}
                    >
                      <Select
                        disabled={state.flag === "view"}
                        allowClear
                        onChange={(key, value) => onChangeMsa(key, value)}
                        showSearch={true}
                        placeholder="Choose MSA"
                        options={
                          msaData &&
                          msaData.map((data) => ({
                            key: data.id ? data.id : data._id,
                            label: data.contract_name,
                            value: data.contract_name,
                          }))
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item
                      {...formItemLayout}
                      className="org-supplier-label"
                      label="Reporting Currency"
                      name="reporting_currency"
                      colon={false}
                      rules={[
                        {
                          // required: "true",
                          message: "Please select Reporting Currency",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Reporting Currency"
                        disabled={state.flag === "view"}
                        // allowClear
                        options={[{ label: "USD", value: "USD", id: "USD" }]}
                        // onChange={handleFxTable}
                      />
                    </Form.Item>
                  </Col>
                  {/* <Col span={8}>
                    <Form.Item
                      {...formItemLayout}
                      className="org-supplier-label"
                      label="Contract Currency"
                      name="contract_currency"
                      colon={false}
                      rules={[
                        {
                          required: stateButton.button === 2,
                          message: "Please select Contract Currency",
                          // pattern: /^(?!\s*$).+/,
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Contract Currency"
                        disabled={state.flag === "view"}
                        allowClear
                        options={
                          fxProfileData &&
                          [
                            ...new Set(
                              fxProfileData.map((item) => item["From Currency"])
                            ),
                          ].map((currency) => ({
                            label: currency,
                            value: currency,
                            id: currency,
                          }))
                        }
                        onChange={(value) => {
                          setContractCurrency(value);
                        }}
                      />
                    </Form.Item>
                  </Col> 
                  <Col span={8}>
                    <Form.Item
                      {...formItemLayout}
                      className="org-supplier-label"
                      name="start_date"
                      label="Start Date"
                      colon={false}
                      initialValue={
                        state.data && state.data.start_date
                          ? dayjs(state.data.start_date)
                          : undefined
                      }
                    >
                      {state.flag === "view" ? (
                        <div className="formlayout-div">
                          {state.data && state.data.start_date
                            ? dayjs(state.data.start_date).format("DD MMM YYYY")
                            : "Not Selected"}
                        </div>
                      ) : (
                        <DatePicker
                          onChange={(date, dateString) =>
                            onDateChange(date, dateString, "start_date")
                          }
                          format={"DD MMM YYYY"}
                          className="report-input"
                          placeholder="Select Start Date"
                          disabledDate={(dateValue) =>
                            disabledDate(dateValue, "start_date")
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      {...formItemLayout}
                      className="org-supplier-label"
                      name="end_date"
                      label="End Date"
                      colon={false}
                      initialValue={
                        state.data && state.data.end_date
                          ? dayjs(state.data.end_date)
                          : undefined
                      }
                    >
                      {state.flag === "view" ? (
                        <div className="formlayout-div">
                          {state.data && state.data.end_date
                            ? dayjs(state.data.end_date).format("DD MMM YYYY")
                            : "Not Selected"}
                        </div>
                      ) : (
                        <DatePicker
                          onChange={(date, dateString) =>
                            onDateChange(date, dateString, "end_date")
                          }
                          format={"DD MMM YYYY"}
                          className="report-input"
                          placeholder="Select End Date"
                          disabledDate={(dateValue) =>
                            disabledDate(dateValue, "end_date")
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>*/}
                </Row>
                {/* {state.flag === "create" && ( */}
                <div className="create-upload-entity-div">
                  <Row
                    align="middle"
                    type="flex"
                    style={{ padding: "10px 30px 0px" }}
                  >
                    <Col span={24} align="middle" className="vertical-align">
                      <h4 className="entity-text">Enter FX Setup Details</h4>
                    </Col>
                  </Row>
                  <hr className="mt-10 view-supplier-hr" />
                  <Row
                    align="middle"
                    gutter={[40, 16]}
                    justify="center"
                    className="mt-25"
                  >
                    <Col span={8} align="right" className="vertical-align">
                      <div className="choose-input-div">
                        <Form.Item
                          rules={[
                            {
                              required: uploadRule,
                              message: uploadRuleText,
                            },
                          ]}
                        >
                          <Row type="flex" gutter={[64, 56]}>
                            <Col
                              span={24}
                              align="middle"
                              className="vertical-align"
                            >
                              <span className="upload-note">
                                Click below to upload FX Setup Details through
                                Excel
                              </span>
                            </Col>
                            <Col span={24} align="middle">
                              <Upload
                                {...props}
                                customRequest={dummyRequest}
                                onRemove={() => setFxProfileData([])}
                              >
                                <Col
                                  span={10}
                                  align="middle"
                                  className="vertical-align"
                                >
                                  <ExcelButton
                                    disabled={
                                      !supplierId || supplierId === "N/A"
                                    }
                                  />
                                </Col>
                              </Upload>
                            </Col>
                            <Col
                              span={24}
                              className="vertical-align"
                              align="middle"
                            >
                              <div>
                                <span className="upload-note">
                                  Download &nbsp;&nbsp;
                                  <Link
                                    to={fxTableTemplate}
                                    download="Fx_Table_Template"
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    FX Setup Excel Template
                                  </Link>
                                </span>
                              </div>
                            </Col>
                          </Row>
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* )} */}
                {/* {state.flag === "edit" &&
                  state.data &&
                  state.data.status !== "In Draft" && (
                    <Row align="middle" type="flex">
                      <Col span={8}>
                        <Form.Item
                          {...formItemLayout}
                          className="org-supplier-label"
                          name="status"
                          label="INVOICING PROFILE STATUS"
                          colon={false}
                        >
                          <div className="association-radio-status">
                            <Radio.Group
                              buttonStyle="solid"
                              // onChange={(e) => setGroupStatus(e.target.value)}
                              value={status}
                            >
                              <Radio
                                value={"Active"}
                                onClick={(e) =>
                                  handleRadioClick(e.target.value)
                                }
                              >
                                Active
                              </Radio>
                              <Radio
                                value={"Inactive"}
                                onClick={(e) =>
                                  handleRadioClick(e.target.value)
                                }
                              >
                                Inactive
                              </Radio>
                            </Radio.Group>
                          </div>
                        </Form.Item>
                      </Col>
                    </Row>
                  )} */}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  const fxProfile = () => {
    return (
      <Row>
        <Col span={24}>
          <div className="org-view-div">
            <div className="m-20">
              <Table
                className="data-table"
                dataSource={fxProfileData}
                columns={fxColumns}
                style={{ padding: "0 20%" }}
                pagination={false}
              />
            </div>
          </div>
        </Col>
      </Row>
    );
  };
  // Function to handle stored contractualFx screen column and data
  const contractualFx = () => {
    return (
      <div>
        <Row>
          <Col span={24}>
            <div className="org-view-div">
              <div className="m-20">
                <Row type="flex">
                  <Col span={22}>
                    <span
                      style={{
                        color: "var(--color-solid-darkergrey)",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 600,
                          color: "var(--color-solid-darkergrey)",
                        }}
                      >
                        {fxTable}
                      </span>{" "}
                      - ContratualFX
                    </span>
                  </Col>
                  <Col span={2}>
                    <Select
                      className="fx-year"
                      onChange={handleYearChangeCfx}
                      options={
                        contratualFxYears &&
                        contratualFxYears.map((data) => {
                          return { label: data, value: data };
                        })
                      }
                      value={contratualFxYear}
                      style={{ width: "100%" }}
                    />
                  </Col>
                </Row>
                <Row type="flex">
                  <Col span={24} className="vertical-align">
                    <DataTable
                      components={componentsCfx}
                      rowClassName={() => "editable-row"}
                      dataTableData={dataSourceCfx}
                      colData={columnsCfx}
                      type="fx"
                    />
                    {state.flag !== "view" && (
                      <div className="text-center mt-20">
                        <Button
                          onClick={handleAddCfx}
                          // icon={<PlusOutlined />}
                          shape="round"
                          className="add-btn"
                          size="medium"
                          style={{
                            width: 90,
                          }}
                        >
                          <PlusOutlined />
                        </Button>
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  };
  // Function to handle stored spot Rates screen column and data
  const spotRates = () => {
    return (
      <div>
        <Row>
          <Col span={24}>
            <div className="org-view-div">
              <div className="m-20">
                <Row type="flex">
                  <Col span={22}>
                    <span
                      style={{
                        color: "var(--color-solid-darkergrey)",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 600,
                          color: "var(--color-solid-darkergrey)",
                        }}
                      >
                        {fxTable}
                      </span>{" "}
                      - Spot Rate
                    </span>
                  </Col>
                  <Col span={2}>
                    <Select
                      className="fx-year"
                      onChange={handleYearChangeSpot}
                      options={
                        spotRateYears &&
                        spotRateYears.map((data) => {
                          return { label: data, value: data };
                        })
                      }
                      value={spotRateYear}
                      style={{ width: "100%" }}
                    />
                  </Col>
                </Row>
                <Row type="flex">
                  <Col span={24} className="vertical-align">
                    <DataTable
                      components={componentsSr}
                      rowClassName={() => "editable-row"}
                      dataTableData={dataSourceSr}
                      colData={columnsSr}
                      type="fx"
                    />
                    {state.flag !== "view" && (
                      <div className="text-center mt-20">
                        <Button
                          onClick={handleAddSr}
                          // icon={<PlusOutlined />}
                          shape="round"
                          className="add-btn"
                          size="medium"
                          style={{
                            width: 90,
                          }}
                        >
                          <PlusOutlined />
                        </Button>
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  const Associations = () => {
    return (
      <DataTable
        type="association"
        dataTableData={associationData}
        logData={state.data}
      />
    );
  };

  const EntityLog = () => {
    return <DataTable type="entityLog" logData={state.data} />;
  };

  // Variable to hold multible screen name and key
  const items = [
    {
      key: "1",
      label: `FX Cover`,
      children: fxCover(),
      show: true,
    },
    {
      key: "divider1",
      label: <Divider className="tab-divider" type="vertical" />,
      disabled: true,
      show: true,
    },
    {
      key: "2",
      label: `FX Profile`,
      children: fxProfile(),
      show: true,
    },
    // {
    //   key: "2",
    //   label: `Contractual FX`,
    //   children: contractualFx(),
    //   show: state.flag !== "create",
    // },
    // {
    //   key: "divider2",
    //   label: <Divider className="tab-divider" type="vertical" />,
    //   disabled: true,
    //   show: state.flag !== "create",
    // },
    // {
    //   key: "3",
    //   label: `Spot Rates`,
    //   children: spotRates(),
    //   show: state.flag !== "create",
    // },
    {
      key: "divider3",
      label: <Divider className="tab-divider" type="vertical" />,
      disabled: true,
      show: state.flag === "view" || state.flag === "edit",
    },
    {
      key: "4",
      label: `Associations`,
      children: Associations(),
      show: state.flag === "view" || state.flag === "edit",
    },
    {
      key: "divider4",
      label: <Divider className="tab-divider" type="vertical" />,
      disabled: true,
      show: state.flag === "view",
    },
    {
      key: "5",
      label: `Entity Log`,
      children: EntityLog(),
      show: state.flag === "view",
    },
    {
      key: "divider5",
      label: <Divider className="tab-divider" type="vertical" />,
      disabled: true,
      show: true,
    },
    {
      key: "6",
      label: `Notes And Comments`,
      children: (
        <NotesAndComments
          detailData={state.data}
          viewedFile={viewedFile}
          comments={comments}
          url={url}
          setUrl={setUrl}
          setFile={setFxFile}
          setFileKeysToDelete={setFileKeysToDelete}
          setViewedFile={setViewedFile}
          setComments={setComments}
          flag="setup"
          setDisable={setDisable}
        />
      ),
      show: true,
    },
  ].filter((items) => items.show);

  useEffect(() => {
    document.title = "FX Setup | Judo";
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const handleTabChange = (key) => {
    setLeTabKey(key);
  };

  const saveNotesCommentsData = async () => {
    let data = {
      author:
        userData && userData[0] && userData[0].id
          ? userData[0].id
          : userData[0]._id
          ? userData[0]._id
          : "",
      author_name:
        userData && userData[0] && userData[0].first_name
          ? userData[0].first_name
          : "",
      file_notes_data: state.data.file_notes_data,
      comments: comments,
    };

    // Prepare form data
    const uploadForm = new FormData();
    if (fxFile && fxFile.length > 0) {
      for (let i = 0; i < fxFile.length; i++) {
        uploadForm.append("file", fxFile[i].originFileObj);
      }
    }
    uploadForm.append("uploadForm", JSON.stringify(data)); // Attach other form
    console.log("formdata", uploadForm);
    setNotesSaveLoader(true);

    if (fileKeysToDelete.length !== 0) {
      fileKeysToDelete.map(async (fileKey) => {
        await deleteS3FileApi({ fileKey: fileKey, flag: "setup" });
      });
    }

    await updateNotesCommentsApi(
      state.data && state.data._id,
      uploadForm,
      "fx"
    ).then((res) => {
      if (res) setNotesSaveLoader(false);
    });
  };

  const shouldEnableSaveSubmit = () => {
    const formValues = form.getFieldsValue();
    // console.log("formValues: ", formValues);

    const areRequiredFieldsValid = Boolean(
      formValues.fx_table_name &&
        formValues.fx_table_name.trim().length > 0 &&
        formValues.supplier_name &&
        fxProfileData &&
        fxProfileData.length > 0
    );

    const otherFieldsChanged = !isEqual(
      {
        fx_table_name: formValues.fx_table_name,
        msa: formValues.msa === null ? undefined : formValues.msa,
        reporting_currency: formValues.reporting_currency,
        supplier_name:
          formValues.supplier_name === null
            ? undefined
            : formValues.supplier_name,
        fx_profile: fxProfileData,
      },
      originalData
    );

    const notesCommentsUpdated = Boolean(
      comments.length > 0 || fxFile.length > 0
    );

    // console.log("notesCommentsUpdated: ", notesCommentsUpdated);
    // console.log("otherFieldsChanged: ", otherFieldsChanged);
    // console.log("areRequiredFieldsValid: ", areRequiredFieldsValid);

    const formChanged = otherFieldsChanged || notesCommentsUpdated;

    const shouldEnableForSubmit =
      (formChanged ||
        state.flag === "clone" ||
        state.data.status === "In Draft") &&
      areRequiredFieldsValid;

    const shouldEnableForSave =
      (formChanged && state.data.status !== "Active") || state.flag === "clone";

    setEnableSave(shouldEnableForSave);
    setEnableSubmit(shouldEnableForSubmit);
  };

  return (
    <Layout className="header-layout">
      <TopHeader
        headerChange={header}
        feedRow={false}
        searchRow={false}
        menuKey={menuKey}
        sticky=""
      />
      {mainPageloading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh - 60px)",
          }}
        >
          <LoadingOutlined
            style={{
              fontSize: 50,
              color: "var(--color-solid-darkerblue)",
            }}
            spin
          />
        </div>
      ) : accessGranted ? (
        <div className="system-setup1">
          <Form
            form={form}
            name="clone-fx"
            onFinish={onFinish}
            onKeyDown={handleKeyDown}
            onFinishFailed={onFinishFailed}
            onValuesChange={() => shouldEnableSaveSubmit()}
            initialValues={{
              ["fx_table_name"]: state.data.fx_table_name,
              ["supplier_name"]: state.data.supplier_name,
              ["reporting_currency"]: "USD",
              // ["contract_currency"]: state.data.contract_currency,
              ["msa"]: state.data.msa_name,
            }}
          >
            <Row align="middle" type="flex" gutter={[8, 16]} className="m-10">
              <Col
                className="vertical-align"
                xs={24}
                sm={state.flag === "view" ? 24 : 20}
                md={state.flag === "view" ? 24 : 21}
                lg={state.flag === "view" ? 24 : 21}
                xl={state.flag === "view" ? 24 : 18}
              >
                <HeaderCard
                  mainMenu="Contract Setup"
                  subMenu="Fx Table"
                  itemName={
                    state.flag === "create"
                      ? "New Fx Table"
                      : state.flag === "clone"
                      ? "Clone Fx Table"
                      : state.data?.fx_table_name
                  }
                  itemStatus={state.data?.status}
                  goBackLink="/system-setup/?back=con-fx"
                  state={state.data}
                  showDetails={state.flag === "view" || state.flag === "edit"}
                />
              </Col>
              {state.flag !== "view" && (
                <>
                  <Col
                    className="vertical-align"
                    xs={8}
                    sm={4}
                    md={3}
                    lg={3}
                    xl={2}
                  >
                    <CancelButton
                      onClick={() => history(`/system-setup/?back=con-fx`)}
                    />
                  </Col>
                  <Col
                    className="vertical-align"
                    xs={8}
                    sm={4}
                    md={3}
                    lg={3}
                    xl={2}
                  >
                    <SaveButton
                      type="primary"
                      htmlType="submit"
                      onClick={() => (stateButton.button = 1)}
                      loading={saveTableLoader}
                      disabled={
                        // state.flag === "create"
                        //   ? fxTable
                        //     ? false
                        //     : true
                        //   : state.flag === "edit"
                        //   ? state.data && state.data.status !== "In Draft"
                        //     ? true
                        //     : fxTable
                        //     ? false
                        //     : true
                        //   : false
                        !enableSave
                      }
                    />
                  </Col>
                  <Col
                    className="vertical-align"
                    xs={8}
                    sm={4}
                    md={3}
                    lg={3}
                    xl={2}
                  >
                    <SubmitButton
                      type="primary"
                      htmlType="submit"
                      onClick={() => (stateButton.button = 2)}
                      loading={loader}
                      disabled={
                        // state.flag === "create"
                        //   ? fxTable && supplierId
                        //     ? // fxTableFile.length > 0 &&
                        //       // contractCurrency
                        //       false
                        //     : true
                        //   : state.flag === "edit"
                        //   ? state.data && state.data.status === "In Draft"
                        //     ? fxTable &&
                        //       supplierId &&
                        //       // fxDates.startDate &&
                        //       fxTableFile.length > 0
                        //       ? // dataSourceCfx.length > 0 &&
                        //         // spotRateYears.length > 0 &&
                        //         // contractCurrency
                        //         false
                        //       : true
                        //     : disable
                        //   : fxTable &&
                        //     supplierId &&
                        //     // fxDates.startDate &&
                        //     fxTableFile.length > 0
                        //   ? // dataSourceCfx.length > 0 &&
                        //     // dataSourceSr.length > 0 &&
                        //     // contractCurrency
                        //     false
                        //   : true
                        !enableSubmit
                      }
                    />
                  </Col>
                </>
              )}
            </Row>
            <div className="price-profile-content m-10">
              <Row type="flex" gutter={[14, 0]} className="m-0">
                <Col span={24}>
                  <Tabs
                    items={items}
                    onChange={handleTabChange}
                    tabBarExtraContent={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginTop: -6,
                        }}
                      >
                        {state.flag === "view" && leTabKey === "6" && (
                          <SaveButton
                            loading={notesSaveLoader}
                            onClick={() => {
                              saveNotesCommentsData();
                            }}
                            style={{ width: 140 }}
                          />
                        )}
                      </div>
                    }
                  />
                </Col>
              </Row>
            </div>
          </Form>
        </div>
      ) : (
        <NotAuthorized redirect={`/system-setup/?back=con-fx`} />
      )}
    </Layout>
  );
};

export default FxSetupController;
