import React, { useEffect, useState } from "react";
import { Image, Row, Col, Table, Modal, Input, Form, Pagination } from "antd"; //antd components
import moment from "moment";
import { EyeOutlined, UpOutlined, DownOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import edit from "../assets/images/icons/edit_pen.svg";
import deleteIcon from "../assets/images/icons/delete.svg";
import delete_disable from "../assets/images/icons/delete_disable.png";
import duplicate from "../assets/images/icons/duplicate.svg";
import create_new_filter from "../assets/images/icons/create_new.svg";
import "../assets/style/contractpipeline.css";
import "../assets/style/setup.css";
import { getAllRolesPaginationApi } from "../api/rolesApi";
import { createActivityLogApi } from "../api/logApi";
import { deleteUserRoleAPi } from "../api/rolesApi";
import { useRecoilState } from "recoil";
import { HeaderChangerAtom, userDataAtom } from "../store/store";
import RecentJourneyMessage from "../RecentJourneyMessage";
import {
  CancelButton,
  CreateButton,
  CreateButtonShort,
} from "../components/GlobalButton";
import PopupModal from "../PopupModal";
import ModalMessage from "../ModalMessage";
import GlobalFilter from "../components/GlobalFilter";

const RoleManagement = ({ tabKey }) => {
  // State variable to hold user information
  const userData = useRecoilState(userDataAtom);
  // Variable to hold PopupModal component
  const popupModalInstance = PopupModal();
  // State variable to hold all role data from DB
  const [tableData, setTableData] = useState([]);
  // State variable to hold the messageapi success visibility status
  const [open, setOpen] = useState(false);
  // Variable to use navigate the other pages
  const navigate = useNavigate();
  // State variable to hold loading visibility status
  const [loader, setLoader] = useState(false);
  // Global State variable to hold the header value
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  // Variable to access the form values
  const [form] = Form.useForm();
  // State variable to hold sort order data
  const [sortOrder, setSortOrder] = useState(null);
  // State variable to hold sort column data
  const [sortedColumn, setSortedColumn] = useState(null);
  // variable to  tore filter data
  const isColumnSorted = (column) => sortedColumn === column;
  // State variable to hold all filterd data
  const [allFilterVal, setAllFilterVal] = useState({});
  const [sortColumnOptions, setSortColumnOptions] = useState({});

  /* Function to handle if sort data changed
   * @param sorter - to set the filter data
   */
  const handleTableChange = (_, __, sorter) => {
    const { column, order } = sorter;
    const newOrder = order || (sortOrder === "ascend" ? "descend" : "ascend");

    const sortOptions = column
      ? {
          column_name: column.dataIndex,
          order: newOrder,
        }
      : {
          ...sortColumnOptions,
          order: newOrder,
        };

    if (column) {
      setSortedColumn(column.dataIndex);
    }

    setSortColumnOptions(sortOptions);
    setSortOrder(newOrder);

    roleTableData(
      paginateData.pageNo,
      paginateData.limit,
      allFilterVal,
      sortOptions
    );
  };

  /* Function to handle for visibility of message api popup
   */
  const showModal = () => {
    setOpen(true);
  };

  /* Function to handle for visibility of message api popup
   */
  const handleCancel = () => {
    form.resetFields();
    console.log("Clicked cancel button");
    setOpen(false);
  };

  //Function which returns column title along with sorting indicators
  const renderColumnTitle = (column_title, dataIndex) => {
    return (
      <>
        <Row align="middle" type="flex">
          <Col
            sm={18}
            md={20}
            lg={20}
            xl={21}
            className="sorted-title"
            title={`${column_title}`}
          >
            {column_title}
          </Col>
          <Col sm={6} md={4} lg={4} xl={3} align="right">
            {isColumnSorted(dataIndex) && (
              <div className="custom-sort">
                {sortOrder === "ascend" && <UpOutlined />}
                {sortOrder === "descend" && <DownOutlined />}
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  };

  // variable to hold add table column names
  const allColumns = [
    {
      title: renderColumnTitle("Role", "roles"),
      dataIndex: "roles",
      align: "left",
      width: 150,
      sorter: true,
      sortOrder: isColumnSorted("roles") ? sortOrder : false,
    },
    {
      title: renderColumnTitle("Role Description", "role_description"),
      dataIndex: "role_description",
      align: "left",
      width: 270,
      sorter: true,
      sortOrder: isColumnSorted("role_description") ? sortOrder : false,
    },
    {
      title: renderColumnTitle("Last Updated On", "last_updated_on"),
      dataIndex: "last_updated_on",
      align: "left",
      width: 200,
      sorter: true,
      sortOrder: isColumnSorted("last_updated_on") ? sortOrder : false,
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      width: 170,
    },
  ];

  /* Function to handle to set view for particular role details
   * @param roleId - a particular role id
   */
  const viewRole = (roleId, data) => {
    console.log("view", roleId);
    // getSingleRolesApi(roleId).then((res) => {
    //   console.log("getSingleRolesApi", res);

    setHeaderChanger({
      header: "userManagement",
      headerItem: "1",
      headerItemName: "userManagement",
      stateSetter: data,
    });

    navigate(`/user-management/roles/view`, {
      state: data,
    });
    // });
  };

  /* Function to handle to set edit for particular role details
   * @param roleId - a particular role id
   */
  const editRole = (roleId, data) => {
    console.log("view", roleId);
    // getSingleRolesApi(roleId).then((res) => {
    //   console.log("getSingleRolesApi", res);

    setHeaderChanger({
      header: "userManagement",
      headerItem: "1",
      headerItemName: "userManagement",
      stateSetter: data,
    });

    navigate(`/user-management/roles/edit-role-access`, {
      state: data,
    });
    // });
  };

  /* Function to handle to deltete the particular role
   * @param userID - a particular userid of which we want to delete the record
   * @param roleName - a particular role name
   * @param inputUserName - neet to match and reconfirm the user input name and role name
   */
  const deleteUserRole = (userId, roleName, inputUserName) => {
    if (roleName === inputUserName) {
      deleteUserRoleAPi(userId).then((res) => {
        let umLog = {
          ss_id: userId,
          ss_name: roleName,
          code: "roles",
          author: userData[0].id,
          action: "delete",
          notes: RecentJourneyMessage.DELETE_ROLES,
          updated_on: new Date(),
        };
        createActivityLogApi(umLog).then((res) => {
          console.log("LogRes: ", res);
        });
        popupModalInstance
          .successModal(roleName, ModalMessage.Role_Delete_Success, "")
          .then(async (userChoice) => {
            if (userChoice === "ok") {
              roleTableData(1, 20, {}, sortColumnOptions);
            }
          });
      });
    } else {
      popupModalInstance.errorModal(ModalMessage.Role_Delete_Error);
    }
  };

  // State variable to hold the pagination data
  const [paginateData, setPaginateData] = useState({
    pageNo: 1,
    limit: 20,
    total: 0,
  });

  /* Function to handle the get role data with pagination
   * @param pageNo - current active page number
   * @param limit - number of records per page
   * @param allFilterVal - filter column and value
   */
  const roleTableData = (page, limit, allFilterVal, sortColumn) => {
    setLoader(true);
    getAllRolesPaginationApi(page, limit, allFilterVal, sortColumn).then(
      (res) => {
        setLoader(false);
        console.log("getAllRolesPaginationApi", res.docs);
        setPaginateData({
          ...paginateData,
          pageNo: page,
          limit: limit,
          total: res.totalDocs,
        });
        let roleData = res.docs.map((data) => {
          let tableRow = {
            key: data.id ? data.id : data._id,
            roles: (
              <span
                onClick={() => {
                  let umLog = {
                    ss_id: data.id ? data.id : data._id,
                    ss_name: data.role_name,
                    code: "roles",
                    author: userData[0].id,
                    action: "view",
                    notes: RecentJourneyMessage.VIEW_ROLES,
                    updated_on: new Date(),
                  };
                  createActivityLogApi(umLog).then((res) => {
                    console.log("LogRes: ", res);
                  });
                  viewRole(data.id ? data.id : data._id, data);
                }}
                style={{
                  color: "var(--color-solid-darkblue)",
                  fontWeight: "100",
                  cursor: "pointer",
                }}
              >
                {data.role_name}
              </span>
            ),
            role_description: data.role_description,
            // data_access: data.data_access,
            last_updated_on: moment(data.updated_on).format("DD MMM YYYY"),
            action: (
              <>
                <div
                  style={{
                    justifyContent: "space-evenly",
                    display: "flex",
                  }}
                >
                  <EyeOutlined
                    title="View"
                    style={{
                      fontSize: "20px",
                      color: "var(--color-solid-darkgrey)",
                    }}
                    className="cursor-pointer"
                    onClick={() => {
                      let umLog = {
                        ss_id: data.id ? data.id : data._id,
                        ss_name: data.role_name,
                        code: "roles",
                        author: userData[0].id,
                        action: "view",
                        notes: RecentJourneyMessage.VIEW_ROLES,
                        updated_on: new Date(),
                      };
                      createActivityLogApi(umLog).then((res) => {
                        console.log("LogRes: ", res);
                      });
                      viewRole(data.id ? data.id : data._id, data);
                    }}
                  />
                  <Image
                    title="Edit"
                    src={edit}
                    preview={false}
                    style={{ width: "20px" }}
                    className="cursor-pointer"
                    onClick={() => editRole(data.id ? data.id : data._id, data)}
                  />
                  <Image
                    title="Clone"
                    src={duplicate}
                    style={{ width: "20px" }}
                    preview={false}
                    className="cursor-pointer"
                    onClick={() => {
                      setHeaderChanger({
                        header: "userManagement",
                        headerItem: "1",
                        headerItemName: "userManagement",
                        stateSetter: data,
                      });
                      navigate(`/user-management/roles/clone-role-access`, {
                        state: data,
                      });
                    }}
                  />
                  {data.predefined === true && data.status !== "In Draft" ? (
                    <>
                      <Image
                        title="Delete"
                        src={delete_disable}
                        style={{ width: "20px" }}
                        preview={false}
                        className="cursor-not-allowed"
                      />
                    </>
                  ) : (
                    <>
                      <Image
                        title="Delete"
                        src={deleteIcon}
                        style={{ width: "20px" }}
                        preview={false}
                        onClick={() => {
                          popupModalInstance
                            .confirModal(ModalMessage.Role_Delete_Info)
                            .then(async (userchoice) => {
                              if (userchoice.action === "delete") {
                                deleteUserRole(
                                  data.id ? data.id : data._id,
                                  data.role_name,
                                  userchoice.inputValue
                                );
                              }
                            });
                        }}
                        className="cursor-pointer"
                      />
                    </>
                  )}
                </div>
              </>
            ),
          };
          return tableRow;
        });
        setTableData(roleData);
      }
    );
  };

  /* Function to handle save the form field values
   * @param values - a form fields values for create role
   */
  const onFinish = (values) => {
    console.log("values", values);
    navigate(`/user-management/roles/create-role-access`, {
      state: values,
    });
  };

  /*  Function to handle error in the form validation
   * @param error - a form errors after validate the form fields
   */
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  /* Function to handle change the page number
   * @param page -  new page number.
   * @param limit - set a limit of data dor this page
   */
  const onPageChange = (page, limit) => {
    roleTableData(page, limit, allFilterVal, sortColumnOptions);
  };

  //  Function to handle get all role details once access the component
  useEffect(() => {
    //To set default sorting on load, for Updated On column of the table
    handleTableChange("", "", {
      column: { dataIndex: "last_updated_on" },
      order: "descend",
    });
  }, []);

  /* Function to handle after select the filter data to filter the table data, after the change the
   * allFilterVal state
   */
  useEffect(() => {
    if (Object.keys(allFilterVal || {}).length === 0) {
      roleTableData(1, 20, allFilterVal, sortColumnOptions);
    } else {
      for (let key in allFilterVal) {
        if (
          Array.isArray(allFilterVal[key]) &&
          allFilterVal[key].length === 0
        ) {
          delete allFilterVal[key];
        }
      }
      if (Object.keys(allFilterVal || {}).length === 0) {
        roleTableData(1, 20, allFilterVal, sortColumnOptions);
      } else {
        roleTableData(1, 20, allFilterVal, sortColumnOptions);
      }
    }
  }, [allFilterVal]);

  useEffect(() => {
    document.title = "Role Management | Judo";
  }, []);

  return (
    <div id="sys-setup-id" className="contract-pipeline">
      <Row align="middle" type="flex" gutter={[12, 0]} className="m-0">
        <Col
          className="p-0 vertical-align"
          xs={24}
          sm={24}
          md={15}
          lg={15}
          xl={22}
        >
          <GlobalFilter
            selectedTab={"roles"}
            initialTableData={tableData}
            allFilterVal={allFilterVal}
            setAllFilterVal={setAllFilterVal}
          />
        </Col>
        <Col className="vertical-align" xs={24} sm={24} md={2} lg={2} xl={2}>
          <CreateButton type="primary" onClick={showModal} />
          <Modal
            title={
              <div style={{ display: "flex" }}>
                <img src={create_new_filter} style={{ paddingRight: 6 }} />
                <p>Creating Role</p>
              </div>
            }
            centered
            open={open}
            footer={null}
            onCancel={handleCancel}
          >
            <Form
              form={form}
              className="mt-10 create-role"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Row gutter={[16, 8]}>
                <Col span={24}>
                  <Form.Item
                    name="role_name"
                    label="Role Name"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "Please enter Role Name!",
                        pattern: /^(?!\s*$).+/,
                      },
                    ]}
                  >
                    <Input placeholder="" className="" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 8]}>
                <Col span={24}>
                  <Form.Item
                    name="role_description"
                    label="Role Description"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "Please enter Role Description!",
                        pattern: /^(?!\s*$).+/,
                      },
                    ]}
                  >
                    <Input placeholder="" className="" />
                  </Form.Item>
                </Col>
              </Row>
              {/* <Row gutter={[16, 8]}>
                <Col span={24}>
                  <Form.Item
                    name="data_access"
                    label="Data Access"
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "Please Select",
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      showSearch={true}
                      className=""
                      placeholder=""
                      options={[
                        {
                          value: "All access",
                          label: "All access",
                        },
                        {
                          value: "Assigned Only",
                          label: "Assigned Only",
                        },
                      ]}
                    ></Select>
                  </Form.Item>
                </Col>
              </Row> */}
              <Row type="flex" align="middle" justify="center">
                <Col span={6} style={{ marginRight: 5 }}>
                  <CancelButton onClick={handleCancel} />
                </Col>
                <Col span={6} style={{ marginLeft: 5 }}>
                  <CreateButtonShort htmlType="submit" />
                </Col>
              </Row>
            </Form>
          </Modal>
        </Col>
      </Row>
      <div className="mt-10">
        <Table
          showSorterTooltip={false}
          dataSource={tableData}
          columns={allColumns}
          onChange={handleTableChange}
          pagination={false}
          bordered
          size="large "
          loading={loader}
          scroll={
            tableData.length === 0
              ? {}
              : { x: "calc(300px + 50%)", y: "calc(100vh - 255px)" }
          }
          className="system-setup-table mt-10"
        />
        <div className="float-right mb-15 mt-15">
          <Pagination
            defaultPageSize={paginateData.limit}
            responsive
            current={paginateData.pageNo}
            total={paginateData.total}
            showSizeChanger
            onShowSizeChange={onPageChange}
            onChange={onPageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default RoleManagement;
