import React, { useState, useEffect } from "react";
import TopHeader from "../../Header";
import { HeaderChangerAtom, RoleAccessAtom } from "../../../store/store";
import {
  Layout,
  Row,
  Col,
  Card,
  Image,
  Form,
  Input,
  Table,
  Button,
  Space,
  Popconfirm,
} from "antd"; //antd components
import {
  FullscreenOutlined,
  FullscreenExitOutlined,
  LoadingOutlined,
} from "@ant-design/icons"; //antd icons
import { useRecoilState } from "recoil";
import { Modal } from "antd";
import { Checkbox } from "antd";
import { CancelButton, SaveButton, SubmitButton } from "../../GlobalButton";
import arrow_right_disable from "../../../assets/images/icons/arrow_right_disable.svg";
import workspace_premium from "../../../assets/images/icons/workspace_premium.svg";
import arrow_back from "../../../assets/images/icons/arrow_back_ios.svg";
import arrow_left from "../../../assets/images/icons/arrow_left.svg";
import { useNavigate, useLocation } from "react-router-dom";
import edit from "../../../assets/images/icons/edit_pen.svg";
import deleteIcon from "../../../assets/images/icons/delete.svg";
import { updateClauseLibrary } from "../../../api/contractAuthorityApi";
import { userDataAtom } from "../../../store/store";
import PopupModal from "../../../PopupModal";
import ModalMessage from "../../../ModalMessage";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../../../assets/style/clauselibrary.css";
import NotAuthorized from "../../NotAuthorized";
import { dataTable } from "../../../utils/common";
import DataTable from "../../Tables/DataTable";
import { createActivityLogApi } from "../../../api/logApi";
import RecentJourneyMessage from "../../../RecentJourneyMessage";

function EditClauseLibrary() {
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  const roleUser = useRecoilState(RoleAccessAtom);
  let header = headerChanger.header;
  const State = {
    button: 1,
  };
  let { state } = useLocation();
  if (state === null || state === undefined) {
    state = headerChanger.stateSetter;
  }
  console.log("state", state);
  const { TextArea } = Input;
  //const [confirmationModel, setConfirmationModel] = useState(false);
  const menuKey = "5";
  //const navigate = useNavigate();
  // let disableVal = "disable-btn";
  // const [disable, setDisable] = useState(disableVal);
  const [disableVal, setDisableVal] = useState(true);

  const [tableLoader, setTableLoader] = useState(false);
  const [batchDetails, setBatchDetails] = useState([]);
  const [confirmationModel, setConfirmationModel] = useState(false);
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [headerName, setHeaderName] = useState(state && state.header_Name);
  const [headerDesc, setHeaderDesc] = useState(state && state.header_Desc);
  const [recordKey, setRecordKey] = useState("");
  const userData = useRecoilState(userDataAtom); //global state
  const popupModalInstance = PopupModal();
  const history = useNavigate();
  const navigate = useNavigate();
  const onChange = (checkedValues) => {
    console.log("checked = ", checkedValues);
  };
  const [accessGranted, setAccessGranted] = useState();
  const [mainPageloading, setMainPageLoading] = useState(true); // Initialize main page loading state

  useEffect(() => {
    const checkAccess = () => {
      const hasAccess =
        roleUser &&
        roleUser[0] &&
        roleUser[0].page_access.system_setup.supplier_setup.view;
      setAccessGranted(hasAccess);
      setMainPageLoading(false);
    };

    checkAccess();
  }, [roleUser]);

  useEffect(() => {
    setBatchDetails(data);
  }, []);

  const [clauseLibrary, setClauseLibrary] = useState({
    header_name: null,
    header_desc: null,
    batch_details: [],
  });

  const data = [
    {
      key: "1",
      batch_name: "Batch 1",
      batch_description: "Description 1",
      batch_content: "Content 1",
    },
  ];
  const [initialFormValues, setInitialFormValues] = useState({
    // header_name: state.header_name || "", // Set default value to empty string if state.header_name is undefined
    // header_desc: state.header_desc || "", // Set default value to empty string if state.header_desc is undefined
    batch_name: "",
    batch_desc: "",
    batch_content: "",
  });

  const [dataSource, setDataSource] = useState(
    state && state.batch_details
      ? state &&
          state.batch_details.map((data, index) => ({
            key: index,
            batch_name: data.batch_name,
            batch_desc: data.batch_desc,
            batch_content: data.batch_content,
          }))
      : []
  );

  const [editorDelta, setEditorDelta] = useState(
    (state && state.batch_details[0]?.batch_content) || {
      ops: [],
    }
  );
  const [modalVisible, setModalVisible] = useState(false);

  const handleEditorChange = (content, delta, source, editor) => {
    setEditorDelta(editor.getContents());
    setDisableVal(false);
  };

  const handleBatchNameChange = (e) => {
    setInitialFormValues((prevState) => ({
      ...prevState,
      batch_name: e.target.value,
    }));
    setDisableVal(false);
  };

  const handleBatchDescriptionChange = (e) => {
    setInitialFormValues((prevState) => ({
      ...prevState,
      batch_desc: e.target.value,
    }));
    setDisableVal(false);
  };

  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"],
    ["blockquote", "code-block"],
    ["link", "image"],
    [{ list: "ordered" }, { list: "bullet" }],
    // [{ script: "sub" }, { script: "super" }],
    // [{ indent: "-1" }, { indent: "+1" }],
    // [{ size: ["small", false, "large", "huge"] }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],
    ["clean"],
  ];

  ////HANDLE THE ONCHANGE EVENTS

  const handleHeaderName = (e) => {
    setHeaderName(e.target.value);
    setDisableVal(false);
  };

  const handleHeaderDescripition = (e) => {
    setHeaderDesc(e.target.value);
    setDisableVal(false);
  };

  const handleDeleteBatch = (record) => {
    let filtererdRecord = dataSource.filter((item) => item.key !== record.key);
    let aligned =
      filtererdRecord &&
      filtererdRecord.length !== 0 &&
      filtererdRecord.map((item, index) => {
        return {
          ...item,
          ["key"]: index,
        };
      });
    setDataSource(aligned);
  };

  const col = [
    {
      title: "Batch Name",
      dataIndex: "batch_name",
      key: "batch_name",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => (
        <span>
          <div
            style={{
              textAlign: "center",
            }}
          >
            <Image
              title="Edit"
              src={edit}
              preview={false}
              style={{ width: "20px", marginRight: "40px" }}
              className="cursor-pointer"
              onClick={() => handleViewBatch(record)}
            />

            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDeleteBatch(record)}
            >
              <Image
                title="Delete"
                src={deleteIcon}
                style={{ width: "20px" }}
                preview={false}
                className="cursor-pointer"
              />
            </Popconfirm>
          </div>
        </span>
      ),
    },
  ];

  const formItemLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 18,
    },
  };

  // const handleViewBatch = (batchData) => {
  //   console.log("View batch data:", batchData);
  //   console.log("State ********", state);
  //   setRecordKey(batchData.key);

  //   setInitialFormValues({
  //     batch_name: batchData.batch_name,
  //     batch_desc: batchData.batch_desc,
  //     batch_content: batchData.batch_content,
  //   });
  //   setConfirmationModel(true);
  // };

  const handleViewBatch = (batchData) => {
    console.log("View batch data:", batchData);
    setRecordKey(batchData.key);

    // Correctly set the initial form values for the selected batch
    setInitialFormValues({
      batch_name: batchData.batch_name,
      batch_desc: batchData.batch_desc,
      batch_content: batchData.batch_content, // Ensure this is set correctly
    });

    // Set the content in the editor
    setEditorDelta(batchData.batch_content);

    setConfirmationModel(true);
  };

  const handlemodal = (values) => {
    const exitsBatchName = dataSource.find(
      (data) => data.batch_name === values.batch_name
    );
    if (exitsBatchName) {
      popupModalInstance.warningModal(ModalMessage.Cl_Name_Warning);
    } else {
      const updatedDataSource = dataSource.map((item) => {
        if (item.key === recordKey) {
          return {
            ...item,
            batch_name: values.batch_name,
            batch_desc: values.batch_desc,
            batch_content: editorDelta,
          };
        }
        return item;
      });

      setDataSource(updatedDataSource);
      setConfirmationModel(false);
    }
  };

  useEffect(() => {
    console.log("DataSource: ", dataSource);
  }, [dataSource]);

  const onFinish = async (values) => {
    //
    values = {
      ...values,
      batch_details: dataSource.map(({ key, ...rest }) => ({
        ...rest,
      })),
      updated_on: new Date(),
      updated_by: userData[0].id,
    };
    console.log("valuess", values);
    if (State.button === 1) {
      await updateClauseLibrary(
        state && state.id ? state.id : state && state._id,
        values
      )
        .then((res) => {
          let ssLog = {
            ss_id: res.id ? res.id : res._id,
            ss_name: values["header_name"],
            code: "clause_library",
            author: userData[0].id,
            action: "edit",
            notes: RecentJourneyMessage.EDIT_CLAUSE_LIBRARY,
            updated_on: new Date(),
          };
          createActivityLogApi(ssLog);
          console.log("resss", res);
          popupModalInstance.successModal(
            "",
            ModalMessage.CL_Edit_Info
            // "/contract_authority/clause-library"
          );
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (State.button === 2) {
      popupModalInstance
        .infoModal(ModalMessage.CL_Edit_Submit)
        .then(async (userChoice) => {
          if (userChoice === "ok") {
            console.log("Submiting values", values);
            await updateClauseLibrary(
              state && state.id ? state.id : state && state._id,
              values
            )
              .then((res) => {
                let ssLog = {
                  ss_id: res.id ? res.id : res._id,
                  ss_name: values["header_name"],
                  code: "clause_library",
                  author: userData[0].id,
                  action: "edit",
                  notes: RecentJourneyMessage.EDIT_CLAUSE_LIBRARY,
                  updated_on: new Date(),
                };
                createActivityLogApi(ssLog);
                console.log("resss", res);
                popupModalInstance.successModal(
                  "",
                  ModalMessage.CL_Edit_Info,
                  "/contract_authority/clause-library"
                );
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (userChoice === "cancel") {
            setTableLoader(false);
          }
        });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <Layout className="header-layout">
      <TopHeader
        headerChange={header}
        feedRow={false}
        searchRow={false}
        menuKey={menuKey}
        sticky=""
      />
      {mainPageloading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh - 60px)",
          }}
        >
          <LoadingOutlined
            style={{
              fontSize: 50,
              color: "var(--color-solid-darkerblue)",
            }}
            spin
          />
        </div>
      ) : accessGranted ? (
        <div className="system-setup1">
          <Form
            name="edit-clause-library"
            initialValues={{
              header_name: state && state.header_name,
              header_desc: state && state.header_desc,
              batch_name: state && state.batch_details[0]?.batch_name,
              batch_desc: state && state.batch_details[0]?.batch_desc,
              batch_content: state && state.batch_details[0]?.batch_content,
            }}
            onFinish={onFinish}
            onKeyDown={handleKeyDown}
          >
            <Row align="middle" type="flex" gutter={[8, 16]} className="m-10">
              <Col
                className="vertical-align"
                xs={24}
                sm={16}
                md={18}
                lg={18}
                xl={18}
              >
                <Card className="setup-header-card">
                  <Row type="flex" gutter={[8, 16]}>
                    <Col
                      className="vertical-align"
                      xs={6}
                      sm={6}
                      md={4}
                      lg={4}
                      xl={2}
                    >
                      <Row type="flex" gutter={[8, 16]}>
                        <Col span={12}>
                          <Image
                            src={arrow_left}
                            style={{ width: 25 }}
                            preview={false}
                            className="cursor-pointer"
                            onClick={() =>
                              navigate(`/contract_authority/clause-library`)
                            }
                          />
                        </Col>
                        <Col span={12}>
                          <Image
                            src={arrow_right_disable}
                            style={{ width: 20 }}
                            preview={false}
                            className="cursor-not-allowed"
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      className="vertical-align"
                      xs={17}
                      sm={17}
                      md={19}
                      lg={19}
                      xl={20}
                    >
                      <span>Contract Authority</span> &nbsp; &nbsp;
                      <Image src={arrow_back} preview={false} /> &nbsp; &nbsp;
                      <span
                        className="cursor-pointer"
                        onClick={() =>
                          navigate(`/contract_authority/clause-library`)
                        }
                      >
                        Clause Library
                      </span>
                      &nbsp; &nbsp;
                      <Image src={arrow_back} preview={false} /> &nbsp; &nbsp;
                      <span>
                        {state && state.header_name
                          ? state.header_name
                          : "View Clause Header"}
                      </span>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col
                className="vertical-align"
                xs={8}
                sm={4}
                md={3}
                lg={3}
                xl={2}
              >
                <CancelButton
                  onClick={() => history(`/contract_authority/clause-library`)}
                />
              </Col>
              <Col
                className="vertical-align"
                xs={8}
                sm={4}
                md={3}
                lg={3}
                xl={2}
              >
                <SaveButton
                  disabled={state.status === "Active" ? true : false}
                  onClick={() => (State.button = 1)}
                  type="primary"
                  htmlType="submit"
                />
              </Col>
              <Col
                className="vertical-align"
                xs={8}
                sm={4}
                md={3}
                lg={3}
                xl={2}
              >
                <SubmitButton
                  onClick={() => (State.button = 2)}
                  disabled={disableVal}
                  type="primary"
                  htmlType="submit"
                />
              </Col>
            </Row>

            <div className="price-profile-content m-10">
              <Row align="middle" type="flex" gutter={[8, 16]} className="m-0">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Row>
                    <Col span={24}>
                      <div className="create-supplier-div">
                        <div className="m-20">
                          <Row align="middle" type="flex">
                            <Col span={8}>
                              <Form.Item
                                {...formItemLayout}
                                className="org-supplier-label"
                                name="header_name"
                                label="HEADER NAME"
                                colon={false}
                                rules={[
                                  {
                                    message: "Please enter header name",
                                    pattern: /^(?!\s*$).+/,
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="AXY Company"
                                  onChange={(e) => handleHeaderName(e)}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={8}>
                              <Form.Item
                                {...formItemLayout}
                                className="org-supplier-label"
                                name="header_desc"
                                label="HEADER DESCRIPTION"
                                colon={false}
                                rules={[
                                  {
                                    message: "Please enter header description",
                                    pattern: /^(?!\s*$).+/,
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="XYZ"
                                  onChange={(e) => handleHeaderDescripition(e)}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <div className="create-entity-div">
                            <Row
                              align="middle"
                              type="flex"
                              style={{ padding: "10px 30px 0px" }}
                            >
                              <Col span={18} className="vertical-align">
                                <h4 className="entity-text">
                                  Content Batches{" "}
                                </h4>
                              </Col>
                            </Row>
                            <hr className="mt-10 view-supplier-hr" />
                            <Row>
                              <DataTable
                                type="clause-library"
                                dataTableData={dataSource}
                                colData={col}
                                showSorterTooltip={false}
                              />
                            </Row>
                            {confirmationModel && (
                              <Modal
                                centered
                                open={confirmationModel}
                                onOk={() => setConfirmationModel(false)}
                                onCancel={() => {
                                  setConfirmationModel(false);
                                }}
                                width={690}
                                footer={false}
                              >
                                <div>
                                  <Form
                                    initialValues={initialFormValues}
                                    onFinish={handlemodal}
                                    onKeyDown={handleKeyDown}
                                  >
                                    <div style={{ display: "flex" }}>
                                      <img
                                        src={workspace_premium}
                                        style={{ width: 25, height: 25 }}
                                      />
                                      <p
                                        style={{
                                          paddingTop: 1,
                                          paddingLeft: 6,
                                          fontWeight: 600,
                                        }}
                                      >
                                        Edit Batch
                                      </p>
                                    </div>

                                    {/* <Row
                                    align="middle"
                                    type="flex"
                                    gutter={[40, 4]}
                                  >
                                    <Col span={24}>
                                      <Row
                                        gutter={[16, 8]}
                                        style={{ marginTop: "10px" }}
                                      >
                                        <Col span={24}>
                                          <Form.Item
                                            name="header_name"
                                            label="Header Name"
                                            labelCol={{ span: 24 }}
                                          >
                                            <Input
                                              placeholder="Axy Company"
                                              className=""
                                              style={{
                                                borderColor: "#0092ff",
                                              }}
                                            />
                                          </Form.Item>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row> */}
                                    <Row
                                      align="middle"
                                      type="flex"
                                      gutter={[40, 4]}
                                      style={{ marginTop: "10px" }}
                                    >
                                      <Col span={24}>
                                        <Row gutter={[16, 8]}>
                                          <Col span={24}>
                                            <Form.Item
                                              name="batch_name"
                                              label="Batch Name"
                                              labelCol={{ span: 24 }}
                                              rules={[
                                                {
                                                  message:
                                                    "Please enter batch name",
                                                  pattern: /^(?!\s*$).+/,
                                                },
                                              ]}
                                            >
                                              <Input
                                                placeholder=""
                                                className=""
                                                onChange={handleBatchNameChange}
                                                style={{
                                                  borderColor: "#0092ff",
                                                }}
                                              />
                                            </Form.Item>
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>

                                    <Row
                                      align="middle"
                                      type="flex"
                                      gutter={[40, 4]}
                                    >
                                      <Col span={24}>
                                        <Row gutter={[16, 8]}>
                                          <Col span={24}>
                                            <Form.Item
                                              name="batch_desc"
                                              label="Batch Description"
                                              labelCol={{ span: 24 }}
                                              rules={[
                                                {
                                                  message:
                                                    "Please enter batch description",
                                                  pattern: /^(?!\s*$).+/,
                                                },
                                              ]}
                                            >
                                              <Input
                                                placeholder=""
                                                className=""
                                                onChange={
                                                  handleBatchDescriptionChange
                                                }
                                                style={{
                                                  borderColor: "#0092ff",
                                                }}
                                              />
                                            </Form.Item>
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                    <Row
                                      align="middle"
                                      type="flex"
                                      gutter={[40, 4]}
                                    >
                                      <Col span={24}>
                                        <Row gutter={[16, 8]}>
                                          <Col span={24}>
                                            <Form.Item
                                              name="batch_content"
                                              labelCol={{ span: 24 }}
                                            >
                                              <Row
                                                justify="space-between"
                                                align="middle"
                                                className="mb-10"
                                              >
                                                <Col span={12}>
                                                  <span>Batch Content</span>
                                                </Col>
                                                <Col span={12} align="right">
                                                  <Button
                                                    type="primary"
                                                    icon={
                                                      <FullscreenOutlined />
                                                    }
                                                    onClick={openModal}
                                                  />
                                                </Col>
                                              </Row>
                                              <ReactQuill
                                                id="min-quill"
                                                theme="snow"
                                                value={editorDelta}
                                                onChange={handleEditorChange}
                                                modules={{
                                                  toolbar: toolbarOptions,
                                                }}
                                                placeholder="Batch Content..."
                                              />
                                            </Form.Item>
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                    <Modal
                                      className="maximize-batch-content"
                                      title="Batch Content"
                                      open={modalVisible}
                                      onCancel={closeModal}
                                      footer={null}
                                      width="90%"
                                      styles={{
                                        body: {
                                          height: "80vh",
                                          overflow: "auto",
                                        },
                                      }}
                                      closeIcon={
                                        <FullscreenExitOutlined className="exit-fullscreen-icon" />
                                      }
                                      centered
                                    >
                                      <ReactQuill
                                        id="max-quill"
                                        theme="snow"
                                        value={editorDelta}
                                        onChange={handleEditorChange}
                                        modules={{
                                          toolbar: toolbarOptions,
                                        }}
                                        style={{ height: "100%" }}
                                        placeholder="Batch Content..."
                                      />
                                    </Modal>
                                    {/* <Checkbox
                                      onChange={onChange}
                                      style={{ marginLeft: "5px" }}
                                    >
                                      Make as default Batch
                                    </Checkbox> */}
                                    <Row gutter={[16, 8]} className="mt-15">
                                      <Col span={16} offset={4}>
                                        <Row type="flex" align={"middle"}>
                                          <Col span={11} offset={1}>
                                            <CancelButton
                                              onClick={() =>
                                                setConfirmationModel(false)
                                              }
                                            />
                                          </Col>
                                          <Col span={11} offset={1}>
                                            <SaveButton htmlType="submit" />
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                  </Form>
                                </div>
                              </Modal>
                            )}
                            {/* {confirmationModel && (
                            <Modal
                              centered
                              open={confirmationModel}
                              onOk={() => setConfirmationModel(false)}
                              onCancel={() => {
                                setConfirmationModel(false);
                              }}
                              width={450}
                              footer={false}
                            >
                              <div>
                                <Form
                                  initialValues={initialFormValues}
                                  onFinish={handlemodal}
                                >
                                  <Col span={24}>
                                    <Row>
                                      <Image
                                        src={workspace_premium}
                                        preview={false}
                                      />
                                      <h3>Creating Batch</h3>
                                      <Row
                                        align="middle"
                                        type="flex"
                                        gutter={[40, 4]}
                                      >
                                        <Col span={24}>
                                          <Row
                                            gutter={[16, 8]}
                                            style={{ marginTop: "10px" }}
                                          >
                                            <Col span={24}>
                                              <Form.Item
                                                name="batch_name"
                                                label="Batch Name"
                                                labelCol={{ span: 24 }}
                                              >
                                                <Input
                                                  placeholder=""
                                                  className=""
                                                  // onChange={(e) =>
                                                  //   handlebatch_name(e)
                                                  // }
                                                  style={{
                                                    borderColor: "#0092ff",
                                                  }}
                                                />
                                              </Form.Item>
                                            </Col>
                                          </Row>
                                        </Col>
                                      </Row>
                                      <Row
                                        align="middle"
                                        type="flex"
                                        gutter={[40, 4]}
                                      >
                                        <Col span={24}>
                                          <Row gutter={[16, 8]}>
                                            <Col span={24}>
                                              <Form.Item
                                                name="batch_desc"
                                                label="Batch Description"
                                                labelCol={{ span: 24 }}
                                              >
                                                <Input
                                                  placeholder=""
                                                  className=""
                                                  // onChange={(e) =>
                                                  //   handleBatchDesc(e)
                                                  // }
                                                  style={{
                                                    borderColor: "#0092ff",
                                                  }}
                                                />
                                              </Form.Item>
                                            </Col>
                                          </Row>
                                        </Col>
                                      </Row>
                                      <Row
                                        align="middle"
                                        type="flex"
                                        gutter={[40, 4]}
                                      >
                                        <Col span={24}>
                                          <Row gutter={[16, 8]}>
                                            <Col span={24}>
                                              <Form.Item
                                                name="batch_content"
                                                label="Batch Content"
                                                labelCol={{ span: 24 }}
                                              >
                                                <Input
                                                  placeholder=""
                                                  className=""
                                                  // onChange={(e) =>
                                                  //   handleBatchContent(e)
                                                  // }
                                                  style={{
                                                    borderColor: "#0092ff",
                                                  }}
                                                />
                                              </Form.Item>
                                            </Col>
                                          </Row>
                                        </Col>
                                      </Row>
                                      <p>Make as default Batch</p>
                                      <Row gutter={[16, 8]}>
                                        <Col span={24} className="text-center">
                                          <Space align="center">
                                            <Button
                                              className="wf-create-btn mt-10"
                                              onClick={() =>
                                                setConfirmationModel(false)
                                              }
                                            >
                                              Cancel
                                            </Button>
                                            <Button
                                              htmlType="submit"
                                              className="wf-create-btn mt-10"
                                            >
                                              Save
                                            </Button>
                                          </Space>
                                        </Col>
                                      </Row>
                                    </Row>
                                  </Col>
                                </Form>
                              </div>
                            </Modal>
                          )} */}
                            <Row
                              align="middle"
                              type="flex"
                              style={{ padding: "10px 0px 30px 15px" }}
                              gutter={[8, 16]}
                            ></Row>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
      ) : (
        <NotAuthorized redirect={`/contract_authority/clause-library`} />
      )}
    </Layout>
  );
}

export default EditClauseLibrary;
