import React, { useEffect, useState } from "react";
import { Image, Row, Col, Table, Modal, Pagination } from "antd"; //antd components
import moment from "moment";
import { EyeOutlined, UpOutlined, DownOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import edit from "../assets/images/icons/edit_pen.svg";
import deleteIcon from "../assets/images/icons/delete.svg";
import duplicate from "../assets/images/icons/duplicate.svg";
import account_box from "../assets/images/icons/account_box.svg";
import "../assets/style/contractpipeline.css";
import "../assets/style/setup.css";
import { getAllGroupPaginationApi, deleteGroupApi } from "../api/groupsApi";
import { createActivityLogApi } from "../api/logApi";
import EditGroup from "../components/Groups/EditGroup";
import CreateGroup from "../components/Groups/CreateGroup";
import CloneGroup from "../components/Groups/CloneGroup";
import { useRecoilState } from "recoil";
import { HeaderChangerAtom, userDataAtom, GroupNameAtom } from "../store/store";
import PopupModal from "../PopupModal";
import ModalMessage from "../ModalMessage";
import { CreateButton } from "../components/GlobalButton";
import GlobalFilter from "../components/GlobalFilter";
import RecentJourneyMessage from "../RecentJourneyMessage";

const GroupManagement = ({ tabKey }) => {
  // Variable to hold PopupModal component
  const popupModalInstance = PopupModal();
  // State variable to hold user information
  const userData = useRecoilState(userDataAtom);
  // State variable to hold all role data from DB
  const [tableData, setTableData] = useState([]);
  // State variable to hold create group Modal visibility status
  const [openCreate, setOpenCreate] = useState(false);
  // State variable to hold clone group Modal visibility status
  const [openClone, setOpenClone] = useState(false);
  // State variable to hold loading visibility status
  const [loader, setLoader] = useState(false);
  // State variable to hold edit group Modal visibility status
  const [editModel, openEditModel] = useState(false);
  // State variable to hold single group data
  const [groupData, setGroupData] = useState({});
  // Variable to use navigate function
  const navigate = useNavigate();
  // State variable to hold header data
  const [headerChanger, setHeaderChanger] = useRecoilState(HeaderChangerAtom);
  // State variable to hold sort order for ascending & descending
  const [sortOrder, setSortOrder] = useState(null);
  // State variable to hold sorted the column names
  const [sortedColumn, setSortedColumn] = useState(null);
  // State variable to hold all filter conditions
  const [allFilterVal, setAllFilterVal] = useState({});
  const [sortColumnOptions, setSortColumnOptions] = useState({});

  const isColumnSorted = (column) => sortedColumn === column;

  /* Function to handle add sort condition to set the state
   * @Param sorter - a hold sorting condition
   */
  const handleTableChange = (_, __, sorter) => {
    const { column, order } = sorter;
    const newOrder = order || (sortOrder === "ascend" ? "descend" : "ascend");

    const sortOptions = column
      ? {
          column_name: column.dataIndex,
          order: newOrder,
        }
      : {
          ...sortColumnOptions,
          order: newOrder,
        };

    if (column) {
      setSortedColumn(column.dataIndex);
    }

    setSortColumnOptions(sortOptions);
    setSortOrder(newOrder);

    groupTableData(
      paginateData.pageNo,
      paginateData.limit,
      allFilterVal,
      sortOptions
    );
  };

  //Function which returns column title along with sorting indicators
  const renderColumnTitle = (column_title, dataIndex) => {
    return (
      <>
        <Row align="middle" type="flex">
          <Col
            sm={18}
            md={20}
            lg={20}
            xl={21}
            className="sorted-title"
            title={`${column_title}`}
          >
            {column_title}
          </Col>
          <Col sm={6} md={4} lg={4} xl={3} align="right">
            {isColumnSorted(dataIndex) && (
              <div className="custom-sort">
                {sortOrder === "ascend" && <UpOutlined />}
                {sortOrder === "descend" && <DownOutlined />}
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  };

  // Variable to hold a all column and style
  const allColumns = [
    {
      title: renderColumnTitle("Group Name", "groups"),
      dataIndex: "groups",
      align: "left",
      sorter: true,
      sortOrder: isColumnSorted("groups") ? sortOrder : false,
    },
    {
      title: renderColumnTitle("Active Users", "users"),
      dataIndex: "users",
      align: "left",
      width: 150,
      sorter: true,
      sortOrder: isColumnSorted("users") ? sortOrder : false,
    },
    {
      title: renderColumnTitle("Type", "group_type"),
      dataIndex: "group_type",
      align: "left",
      width: 250,
      sorter: true,
      sortOrder: isColumnSorted("group_type") ? sortOrder : false,
    },
    {
      title: renderColumnTitle("Updated On", "last_updated_on"),
      dataIndex: "last_updated_on",
      align: "left",
      width: 200,
      sorter: true,
      sortOrder: isColumnSorted("last_updated_on") ? sortOrder : false,
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      width: 270,
    },
  ];

  /* Function to handle view the particular group
   * @param groupId - hold a particular group ID
   * @Param groupName - hold a particular group name
   */
  const viewGroup = (group_data) => {
    let umLog = {
      ss_id: group_data._id,
      ss_name: group_data.group_name,
      code: "groups",
      author: userData[0].id,
      action: "view",
      notes: RecentJourneyMessage.VIEW_GROUP,
      updated_on: new Date(),
    };
    createActivityLogApi(umLog).then((res) => {
      console.log("LogRes: ", res);
    });

    setHeaderChanger({
      header: "userManagement",
      headerItem: "3",
      headerItemName: "userManagement",
      stateSetter: group_data,
    });

    navigate(`/user-management/groups/view`, {
      state: group_data,
    });
  };

  // State variable to hold pagination data
  const [paginateData, setPaginateData] = useState({
    pageNo: 1,
    limit: 20,
    total: 0,
  });

  const getMemberCount = (group_data) => {
    let totalMembers = 0;

    const addActiveMembers = (members) => {
      totalMembers += members.filter(
        (member) => member.status === "Active"
      ).length;
    };

    if (group_data.group_selection === "Departmental Group") {
      addActiveMembers(group_data.department_head);
      group_data.department_members.forEach(addActiveMembers);

      group_data.leads.forEach((lead) => {
        addActiveMembers(lead.lead);
        totalMembers += lead.lead_members.filter(
          (member) => member.status === "Active"
        ).length;
      });
    } else if (
      group_data.group_selection === "Functional Group" ||
      group_data.group_selection === null
    ) {
      group_data.group_members.forEach(addActiveMembers);
    }

    return totalMembers;
  };

  /* Function to handle the get group data with pagination
   * @param pageNo - current active page number
   * @param limit - number of records per page
   * @param allFilterVal - filter column and value
   */
  const groupTableData = (page, limit, allFilterVal, sortColumn) => {
    setLoader(true);
    getAllGroupPaginationApi(page, limit, allFilterVal, sortColumn).then(
      (res) => {
        console.log("getPaginationGroupApi", res);
        setPaginateData({
          ...paginateData,
          pageNo: page,
          limit: limit,
          total: res.totalDocs,
        });
        let roleData = res.docs.map((data) => {
          // console.log(res);
          let tableRow = {
            key: data._id,
            groups: (
              <span
                onClick={() => viewGroup(data)}
                style={{
                  color: "var(--color-solid-darkblue)",
                  fontWeight: "100",
                  cursor: "pointer",
                }}
              >
                {data.group_name}
              </span>
            ),
            group_type: data.group_selection,
            users: getMemberCount(data),
            last_updated_on: moment(data.updated_on).format("DD MMM YYYY"),
            action: (
              <>
                <div
                  style={{
                    justifyContent: "space-evenly",
                    display: "flex",
                  }}
                >
                  <EyeOutlined
                    title="View"
                    style={{
                      fontSize: "20px",
                      color: "var(--color-solid-darkgrey)",
                    }}
                    className="cursor-pointer"
                    onClick={() => {
                      viewGroup(data);
                    }}
                  />
                  <Image
                    title="Edit"
                    src={edit}
                    preview={false}
                    style={{ width: "20px" }}
                    className="cursor-pointer"
                    onClick={() => {
                      navigate(`/user-management/groups/edit`, {
                        state: data,
                      });
                    }}
                  />
                  <Image
                    title="Clone"
                    src={duplicate}
                    style={{ width: "20px" }}
                    preview={false}
                    className="cursor-pointer"
                    onClick={() =>
                      navigate(`/user-management/groups/clone`, {
                        state: data,
                      })
                    }
                  />
                  <Image
                    title="Delete"
                    src={deleteIcon}
                    style={{ width: "20px" }}
                    preview={false}
                    className="cursor-pointer"
                    onClick={() => {
                      popupModalInstance
                        .confirModal(ModalMessage.Group_Delete_Info)
                        .then(async (userchoice) => {
                          if (userchoice.action === "delete") {
                            deleteGroup(
                              data.id ? data.id : data._id,
                              data.group_name,
                              userchoice.inputValue
                            );
                          }
                        });
                    }}
                  />
                </div>
              </>
            ),
          };
          return tableRow;
        });
        setLoader(false);
        setTableData(roleData);
      }
    );
  };

  /* Function to handle change the page number
   * @param page -  new page number.
   * @param limit - set a limit of data dor this page
   */
  const onPageChange = (page, limit) => {
    groupTableData(page, limit, allFilterVal, sortColumnOptions);
  };

  /* Function to handle to deltete the particular role
   * @param userID - a particular userid of which we want to delete the record
   * @param roleName - a particular role name
   * @param inputUserName - neet to match and reconfirm the user input name and role name
   */
  const deleteGroup = (groupId, groupName, input) => {
    if (input === groupName) {
      setLoader(true);
      deleteGroupApi(groupId).then((res) => {
        console.log("deleteGroupApi", res);
        let umLog = {
          ss_id: groupId,
          ss_name: groupName,
          code: "groups",
          author: userData[0].id,
          action: "delete",
          notes: RecentJourneyMessage.DELETE_GROUP,
          updated_on: new Date(),
        };
        createActivityLogApi(umLog).then((res) => {
          console.log("LogRes: ", res);
        });
        setLoader(false);
        popupModalInstance
          .successModal(groupName, ModalMessage.Group_Delete_Success, "")
          .then(async (userChoice) => {
            if (userChoice === "ok") {
              groupTableData(1, 20, allFilterVal, sortColumnOptions);
            }
          });
      });
    } else {
      popupModalInstance.errorModal(ModalMessage.Group_Delete_Error);
    }
  };

  //  Function to handle get all role details once access the component
  useEffect(() => {
    //To set default sorting on load, for Updated On column of the table
    handleTableChange("", "", {
      column: { dataIndex: "last_updated_on" },
      order: "descend",
    });
  }, []);

  /* Function to handle after select the filter data to filter the table data, after the change the
   * allFilterVal state
   */
  useEffect(() => {
    if (Object.keys(allFilterVal || {}).length === 0) {
      groupTableData(1, 20, allFilterVal, sortColumnOptions);
    } else {
      for (let key in allFilterVal) {
        if (
          Array.isArray(allFilterVal[key]) &&
          allFilterVal[key].length === 0
        ) {
          delete allFilterVal[key];
        }
      }
      if (Object.keys(allFilterVal || {}).length === 0) {
        groupTableData(1, 20, allFilterVal, sortColumnOptions);
      } else {
        groupTableData(1, 20, allFilterVal, sortColumnOptions);
      }
    }
  }, [allFilterVal]);

  useEffect(() => {
    document.title = "Group Management | Judo";
  }, []);

  return (
    <div id="sys-setup-id" className="contract-pipeline">
      <Row align="middle" type="flex" gutter={[12, 0]} className="m-0">
        <Col
          className="p-0 vertical-align"
          xs={24}
          sm={24}
          md={15}
          lg={15}
          xl={22}
        >
          <GlobalFilter
            selectedTab={"groups"}
            initialTableData={tableData}
            allFilterVal={allFilterVal}
            setAllFilterVal={setAllFilterVal}
          />
        </Col>
        <Col className="vertical-align" xs={24} sm={24} md={2} lg={2} xl={2}>
          <CreateButton type="primary" onClick={() => setOpenCreate(true)} />
          {openCreate && (
            <Modal
              width={600}
              title={
                <div style={{ display: "flex" }}>
                  <img src={account_box} style={{ width: 25, height: 25 }} />
                  <p style={{ paddingTop: 1, paddingLeft: 6 }}>
                    Creating Group
                  </p>
                </div>
              }
              centered
              open={openCreate}
              footer={null}
              onCancel={() => setOpenCreate(false)}
            >
              <CreateGroup
                setOpenCreate={setOpenCreate}
                groupTableData={groupTableData}
              />
            </Modal>
          )}
          {openClone && (
            <Modal
              title="Clone Group"
              centered
              open={openClone}
              footer={null}
              onCancel={() => setOpenClone(false)}
            >
              <CloneGroup
                setOpenClone={setOpenClone}
                cloneData={groupData}
                groupTableData={groupTableData}
              />
            </Modal>
          )}
          {editModel && (
            <Modal
              title="Edit Group"
              centered
              open={editModel}
              footer={null}
              onCancel={() => {
                openEditModel(false);
              }}
            >
              <EditGroup
                groupData={groupData}
                openEditModel={openEditModel}
                groupTableData={groupTableData}
              />
            </Modal>
          )}
        </Col>
      </Row>
      <div className="mt-10">
        <Table
          loading={loader}
          showSorterTooltip={false}
          dataSource={tableData}
          columns={allColumns}
          onChange={handleTableChange}
          pagination={false}
          bordered
          size="large "
          scroll={
            tableData.length === 0
              ? {}
              : { x: "calc(300px + 50%)", y: "calc(100vh - 255px)" }
          }
          className="system-setup-table mt-10"
        />
        <div className="float-right mt-15">
          <Pagination
            defaultPageSize={paginateData.limit}
            responsive
            current={paginateData.pageNo}
            total={paginateData.total}
            showSizeChanger
            onShowSizeChange={onPageChange}
            onChange={onPageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default GroupManagement;
