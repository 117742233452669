const RecentJourneyMessage = {
  SAVE_CLAUSE_LIBRARY: "Saved the Clause Library",
  SUBMIT_CLAUSE_LIBRARY: "Submitted the Clause Library",
  EDIT_CLAUSE_LIBRARY: "Edited the Clause Library",
  CLONE_CLAUSE_LIBRARY: "Cloned the Clause Library",
  VIEW_CLAUSE_LIBRARY: "Viewed the Clause Library",
  DELETE_CLAUSE_LIBRARY: "Deleted the Clause Library",

  SAVE_TEMPLATE: "Saved the Template",
  SUBMIT_TEMPLATE: "Submitted the Template",
  EDIT_TEMPLATE: "Edited the Template",
  CLONE_TEMPLATE: "Cloned the Template",
  VIEW_TEMPLATE: "Viewed the Template",
  DELETE_TEMPLATE: "Deleted the Template",

  SAVE_SUPPLIER: "Saved the Supplier",
  SUBMIT_SUPPLIER: "Submitted the Supplier",
  EDIT_SUPPLIER: "Edited the Supplier",
  CLONE_SUPPLIER: "Cloned the Supplier",
  VIEW_SUPPLIER: "Viewed the Supplier",
  DELETE_SUPPLIER: "Deleted the Supplier",

  SAVE_LEGAL_ENTITY: "Saved the Legal Entity",
  SUBMIT_LEGAL_ENTITY: "Submitted the Legal Entity",
  EDIT_LEGAL_ENTITY: "Edited the Legal Entity",
  CLONE_LEGAL_ENTITY: "Cloned the Legal Entity",
  VIEW_LEGAL_ENTITY: "Viewed the Legal Entity",
  DELETE_LEGAL_ENTITY: "Deleted the Legal Entity",

  SAVE_COST_CENTER: "Saved the Cost Center",
  SUBMIT_COST_CENTER: "Submitted the Cost Center",
  EDIT_COST_CENTER: "Edited the Cost Center",
  CLONE_COST_CENTER: "Cloned the Cost Center",
  VIEW_COST_CENTER: "Viewed the Cost Center",
  DELETE_COST_CENTER: "Deleted the Cost Center",

  SAVE_RATE_CARD: "Saved the Rate Card",
  SUBMIT_RATE_CARD: "Submitted the Rate Card",
  EDIT_RATE_CARD: "Edited the Rate Card",
  CLONE_RATE_CARD: "Cloned the Rate Card",
  VIEW_RATE_CARD: "Viewed the Rate Card",
  DELETE_RATE_CARD: "Deleted the Rate Card",

  SAVE_PRICING_PROFILE: "Saved the Pricing Profile",
  SUBMIT_PRICING_PROFILE: "Submitted the Pricing Profile",
  EDIT_PRICING_PROFILE: "Edited the Pricing Profile",
  CLONE_PRICING_PROFILE: "Cloned the Pricing Profile",
  VIEW_PRICING_PROFILE: "Viewed the Pricing Profile",
  DELETE_PRICING_PROFILE: "Deleted the Pricing Profile",

  SAVE_INVOICING_PROFILE: "Saved the Invoicing Profile",
  SUBMIT_INVOICING_PROFILE: "Submitted the Invoicing Profile",
  EDIT_INVOICING_PROFILE: "Edited the Invoicing Profile",
  CLONE_INVOICING_PROFILE: "Cloned the Invoicing Profile",
  VIEW_INVOICING_PROFILE: "Viewed the Invoicing Profile",
  DELETE_INVOICING_PROFILE: "Deleted the Invoicing Profile",

  SAVE_FX_SETUP: "Saved the FX Setup",
  SUBMIT_FX_SETUP: "Submitted the FX Setup",
  EDIT_FX_SETUP: "Edited the FX Setup",
  CLONE_FX_SETUP: "Cloned the FX Setup",
  VIEW_FX_SETUP: "Viewed the FX Setup",
  DELETE_FX_SETUP: "Deleted the FX Setup",

  SAVE_WORK_FLOW: "Saved the Work Flow",
  SUBMIT_WORK_FLOW: "Submitted the Work Flow",
  EDIT_WORK_FLOW: "Edited the Work Flow",
  CLONE_WORK_FLOW: "Cloned the Work Flow",
  VIEW_WORK_FLOW: "Viewed the Work Flow",
  DELETE_WORK_FLOW: "Deleted the Work Flow",

  SAVE_USER: "Saved the User",
  SUBMIT_USER: "Submitted the User",
  EDIT_USER: "Edited the User",
  CLONE_USER: "Cloned the User",
  VIEW_USER: "Viewed the User",
  DELETE_USER: "Deleted the User",

  SAVE_ROLES: "Saved the Roles",
  SUBMIT_ROLES: "Submitted the Roles",
  EDIT_ROLES: "Edited the Roles",
  CLONE_ROLES: "Cloned the Roles",
  VIEW_ROLES: "Viewed the Roles",
  DELETE_ROLES: "Deleted the Roles",

  SAVE_ACTION_MANAGEMENT: "Saved the Action Management",
  SUBMIT_ACTION_MANAGEMENT: "Submitted the Action Management",
  EDIT_ACTION_MANAGEMENT: "Edited the Action Management",
  CLONE_ACTION_MANAGEMENT: "Cloned the Action Management",
  VIEW_ACTION_MANAGEMENT: "Viewed the Action Management",
  DELETE_ACTION_MANAGEMENT: "Deleted the Action Management",

  SAVE_ACTION_INSTANCE: "Saved the Action Instance",
  SUBMIT_ACTION_INSTANCE: "Submitted the Action Instance",
  EDIT_ACTION_INSTANCE: "Edited the Action Instance",
  CLONE_ACTION_INSTANCE: "Cloned the Action Instance",
  VIEW_ACTION_INSTANCE: "Viewed the Action Instance",
  DELETE_ACTION_INSTANCE: "Deleted the Action Instance",
};

export default RecentJourneyMessage;
